import { useResetSignup } from "hooks/useResetSignup";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const SignupProvider = () => {
  const resetSignup = useResetSignup();

  useEffect(() => {
    return () => {
      resetSignup();
    };
  }, []);

  return <Outlet />;
};
