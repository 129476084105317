import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { FeedMediaViewer } from "./FeedMediaViewer";
import { styled } from "@mui/material/styles";
import imageThumbnail from "@assets/icons/imageThumbnail.svg";
import { Avatar, Box } from "@mui/material";
import { Container } from "./crud/Container";
import FeedIndividualMedia from "./FeedIndividualMedia";

const StyledRowGrid = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  cursor: "pointer",
  backgroundColor: "#DEDEDE"
});
const StyledGrid = styled(Grid)({
  backgroundColor: "#DEDEDE",
  cursor: "pointer"
});
const StyledDiv = styled("div")`
  position: relative;
  width: 100%;
  .square-content {
    max-height: 505px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const FeedMediaGrid = (props: { media }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const adjustHeight = () => {
      if (ref.current) {
        const width = ref.current.offsetWidth;
        const newHeight = Math.min(width, 505);
        ref.current.style.height = `${newHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  }, []);

  const [mediaViewer, setMediaViewer] = useState(Boolean);
  const [currentIndex, setCurrentIndex] = useState();
  const [media, setMedia] = useState(Boolean);

  const toggleMediaViewer = (media, index) => {
    setMediaViewer(true);
    setCurrentIndex(index);
    setMedia(media);
  };
  useEffect(() => {
    if (mediaViewer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [mediaViewer]);
  const closeMediaViewer = () => {
    setMediaViewer(false);
  };
  const renderMedia = () => {
    let media = props.media;
    let remainingMedia = 0;
    const totalMedia = props.media.length;
    if (totalMedia > 5) {
      remainingMedia = totalMedia - 5;
      media = media.slice(0, 5);
    }
    const landscapeMedia = media.filter(
      (item) =>
        (item.metadata?.width > item.metadata?.height &&
          item.metadata?.orientation == 1) ||
        (item.metadata?.width < item.metadata?.height &&
          item.metadata?.orientation == 6)
    );
    const portraitMedia = media.filter(
      (item) =>
        (item.metadata?.width <= item.metadata?.height &&
          item.metadata?.orientation == 1) ||
        (item.metadata?.width >= item.metadata?.height &&
          item.metadata?.orientation == 6) ||
        ((item.metadata?.width == undefined ||
          item.metadata?.height == undefined) &&
          (item.path.includes(".jpg") || item.path.includes(".jpeg"))) ||
        item.path.includes(".mp4") ||
        item.path.includes(".m3u8")
    );
    if (totalMedia === 1) {
      if (portraitMedia.length == 1) {
        return (
          <StyledGrid
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            style={{ height: "100%" }}
          >
            <FeedIndividualMedia
              onClick={() => {
                toggleMediaViewer(props.media, props.media[0].mediaId);
              }}
              src={props.media[0].baseUrl + props.media[0].path}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </StyledGrid>
        );
      }
      if (landscapeMedia.length == 1) {
        return (
          <StyledGrid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            style={{ height: "100%" }}
          >
            <FeedIndividualMedia
              onClick={() => {
                toggleMediaViewer(props.media, props.media[0].mediaId);
              }}
              src={props.media[0].baseUrl + props.media[0].path}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </StyledGrid>
        );
      }
    } else if (totalMedia === 2) {
      if (landscapeMedia.length === 2) {
        return (
          <>
            <StyledRowGrid
              style={{
                height: "calc(50% - 3px)",
                marginBottom: "3px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              direction={"row"}
              xs={12}
            >
              <FeedIndividualMedia
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
              xs={12}
            >
              <FeedIndividualMedia
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 2) {
        return (
          <>
            <StyledGrid
              style={{
                height: "100%",
                marginRight: "3px",
                width: "calc(50% - 3px)",
                textAlign: "center"
              }}
              direction={"column"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                height: "100%",
                width: "calc(50% - 0px)",
                textAlign: "center"
              }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledGrid
              style={{
                height: "100%",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"row"}
              xs={12}
              justifyContent={"center"}
              height={"100%"}
              width={"50%"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
          </>
        );
      }
    } else if (totalMedia === 3) {
      if (landscapeMedia.length === 3) {
        return (
          <>
            <StyledGrid
              style={{
                height: "50%",
                marginBottom: "3px",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "50%",
                height: "50%"
              }}
              direction={"column"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "100%",
                height: "50%"
              }}
              direction={"column"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 3) {
        return (
          <>
            <StyledGrid
              style={{
                width: "calc(50% - 3px)",
                height: "100%",
                marginRight: "3px",
                textAlign: "center"
              }}
              direction={"column"}
              xs={6}
              justifyContent={"center"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                width: "50%",
                flexDirection: "column",
                rowGap: "3px"
              }}
            >
              <StyledGrid
                style={{
                  width: "calc(100% - 3px)",
                  height: "50%",
                  marginRight: "3px",
                  textAlign: "center"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                  }}
                  src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </StyledGrid>
              <StyledGrid
                style={{
                  width: "calc(100% - 3px)",
                  height: "calc(50% - 3px)",
                  marginRight: "3px",
                  textAlign: "center"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                  }}
                  src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </StyledGrid>
            </div>
          </>
        );
      }
      if (landscapeMedia.length === 1 && portraitMedia.length === 2) {
        return (
          <>
            <StyledGrid
              direction={"row"}
              style={{
                height: "100%",
                width: "calc(50% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                width: "50%",
                flexDirection: "row",
                rowGap: "3px"
              }}
            >
              <StyledGrid
                style={{
                  height: "calc(50% - 3px)",
                  width: "100%"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                  }}
                  src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              </StyledGrid>
              <StyledGrid
                style={{
                  height: "50%",
                  width: "100%"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                  }}
                  src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              </StyledGrid>
            </div>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledRowGrid
              direction={"row"}
              style={{
                height: "100%",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                width: "50%",
                flexDirection: "row",
                rowGap: "3px"
              }}
            >
              <StyledGrid
                direction={"row"}
                style={{
                  height: "calc(50% - 3px)",
                  width: "100%"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                  }}
                  src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              </StyledGrid>
              <StyledGrid
                style={{
                  height: "50%",
                  width: "100%"
                }}
              >
                <FeedIndividualMedia
                  onClick={() => {
                    toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                  }}
                  src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover"
                  }}
                />
              </StyledGrid>
            </div>
          </>
        );
      }
    } else if (totalMedia === 4) {
      if (landscapeMedia.length === 4) {
        return (
          <>
            <StyledGrid
              style={{
                height: "50%",
                marginBottom: "3px",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                height: "50%",
                width: "50%",
                marginBottom: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                height: "50%",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                height: "50%",
                width: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 4) {
        return (
          <>
            <StyledGrid
              style={{
                height: "50%",
                width: "calc(50% - 3px)",
                marginBottom: "3px",
                marginRight: "3px"
              }}
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
            <StyledGrid
              style={{ height: "50%", width: "calc(50% - 0px)" }}
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
            <StyledGrid
              display={"flex"}
              style={{
                height: "50%",
                marginBottom: "3px",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
              justifyContent={"center"}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
            <StyledGrid
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              style={{ height: "50%" }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 2 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledGrid
              style={{
                marginRight: "3px",
                width: "calc(50% - 3px)",
                height: "50%",
                marginBottom: "3px"
              }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              xs={6}
              style={{
                marginRight: "3px",
                width: "calc(50% - 3px)",
                height: "50%",
                marginBottom: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                marginRight: "3px",
                width: "calc(50% - 3px)",
                height: "50%"
              }}
              direction={"column"}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              xs={6}
              style={{
                marginRight: "3px",
                width: "calc(50% - 3px)",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 3 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledRowGrid
              xs={12}
              marginBottom={"3px"}
              style={{ height: "50%" }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledRowGrid>

            <StyledRowGrid
              style={{
                marginRight: "3px",
                width: "calc(34% - 3px)",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                marginRight: "3px",
                width: "calc(33% - 3px)",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </StyledRowGrid>
            <StyledRowGrid style={{ width: "33%", height: "50%" }}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 3) {
        return (
          <>
            <StyledRowGrid
              xs={6}
              style={{
                marginRight: "3px",
                marginBottom: "3px",
                height: "50%",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              xs={6}
              style={{
                marginBottom: "3px",
                height: "50%",
                width: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledGrid
              xs={6}
              style={{
                marginRight: "3px",
                width: "calc(50% - 3px)",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledRowGrid xs={6} style={{ height: "50%", width: "50%" }}>
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{ height: "100%", objectFit: "cover", width: "100%" }}
              />
            </StyledRowGrid>
          </>
        );
      }
    } else if (totalMedia >= 5) {
      if (landscapeMedia.length === 5) {
        return (
          <>
            <StyledGrid
              direction={"column"}
              xs={6}
              style={{
                width: "calc(50% - 3px)",
                marginRight: "3px",
                marginBottom: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              xs={6}
              style={{
                height: "50%",
                marginBottom: "3px",
                width: "calc(50% - 2px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                width: "calc(33.3% - 3px)",
                marginRight: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                width: "calc(33.3% - 3px)",
                marginRight: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              direction={"column"}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "33%",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[4].mediaId);
                }}
                src={landscapeMedia[4].baseUrl + landscapeMedia[4].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 5) {
        return (
          <>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(50% - 3px)",
                marginBottom: "3px",
                marginRight: "3px"
              }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                marginBottom: "3px",
                width: "50%"
              }}
              xs={6}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(34% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(33% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              display={"flex"}
              justifyContent={"center"}
              direction={"column"}
              style={{
                height: "50%",
                width: "33%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[4].mediaId);
                }}
                src={portraitMedia[4].baseUrl + portraitMedia[4].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 3 && landscapeMedia.length === 2) {
        return (
          <>
            <StyledGrid
              style={{
                width: "calc(50% - 3px)",
                marginRight: "3px",
                marginBottom: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "50%",
                marginBottom: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>

            <StyledRowGrid
              style={{
                height: "50%",
                marginRight: "3px",
                width: "calc(34% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                marginRight: "3px",
                width: "calc(33% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "33%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
          </>
        );
      }
      if (portraitMedia.length === 2 && landscapeMedia.length === 3) {
        return (
          <>
            <StyledGrid
              style={{
                width: "calc(50% - 3px)",
                marginRight: "3px",
                marginBottom: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "50%",
                marginBottom: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>

            <StyledGrid
              style={{
                width: "calc(33% - 3px)",
                marginRight: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "calc(33% - 3px)",
                marginRight: "3px",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                width: "34%",
                height: "50%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 1 && landscapeMedia.length === 4) {
        return (
          <>
            <StyledGrid
              marginBottom={"3px"}
              style={{
                height: "calc(50% )",
                marginRight: "3px",
                width: "calc(50% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              marginBottom={"3px"}
              style={{
                height: "calc(50%)",
                width: "50%"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[1].mediaId);
                }}
                src={landscapeMedia[1].baseUrl + landscapeMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>

            <StyledGrid
              style={{
                height: "50%",
                marginRight: "3px",
                width: "calc(33% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[2].mediaId);
                }}
                src={landscapeMedia[2].baseUrl + landscapeMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                height: "50%",
                marginRight: "3px",
                width: "calc(33% - 3px)"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[3].mediaId);
                }}
                src={landscapeMedia[3].baseUrl + landscapeMedia[3].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
            <StyledGrid
              style={{
                height: "50%",
                width: "34%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledGrid>
          </>
        );
      }
      if (portraitMedia.length === 4 && landscapeMedia.length === 1) {
        return (
          <>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "100%"
              }}
              xs={12}
              marginBottom={"3px"}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, landscapeMedia[0].mediaId);
                }}
                src={landscapeMedia[0].baseUrl + landscapeMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[0].mediaId);
                }}
                src={portraitMedia[0].baseUrl + portraitMedia[0].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[1].mediaId);
                }}
                src={portraitMedia[1].baseUrl + portraitMedia[1].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "calc(25% - 3px)",
                marginRight: "3px"
              }}
            >
              <FeedIndividualMedia
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[2].mediaId);
                }}
                src={portraitMedia[2].baseUrl + portraitMedia[2].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
            <StyledRowGrid
              style={{
                height: "50%",
                width: "25%"
              }}
            >
              <FeedIndividualMedia
                remainingMediaCount={remainingMedia}
                onClick={() => {
                  toggleMediaViewer(props.media, portraitMedia[3].mediaId);
                }}
                src={portraitMedia[3].baseUrl + portraitMedia[3].path}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
              />
            </StyledRowGrid>
          </>
        );
      }
    }
    if (props.media[0].mimeType == "application/pdf") {
      return (
        <FeedIndividualMedia
          mimeType={"application/pdf"}
          metaData={props.media[0].metadata}
          src={props.media[0].baseUrl + props.media[0].path}
          style={{ width: "100%", height: "100%" }}
          pdf={props.media[0]}
        />
      );
    }
  };
  return (
    <>
      {mediaViewer && (
        <FeedMediaViewer
          close={closeMediaViewer}
          mediaFiles={media}
          currentIndex={currentIndex}
        />
      )}

      <Container flexDirection={"column"} style={{ position: "relative" }}>
        {props.media[0].mimeType != "application/pdf" && (
          <Box
            sx={{
              position: "absolute",
              top: "27px",
              left: "12px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#000000BF",
              width: "45px",
              height: "24px",
              borderRadius: "16px",
              padding: "4px 8px",
              zIndex: "999"
            }}
          >
            <Avatar
              variant="square"
              style={{ width: "16px", height: "16px" }}
              src={imageThumbnail}
            />
            <span
              data-testid="media-count"
              style={{
                fontWeight: "400",
                fontSize: "12px",
                color: "#fff",
                marginLeft: "5px"
              }}
            >
              {props.media.length}
            </span>
          </Box>
        )}

        <StyledDiv
          className="square-wrapper"
          ref={ref}
          style={{
            left: props.media[0].mimeType != "application/pdf" ? 0 : "0px",
            width:
              props.media[0].mimeType != "application/pdf"
                ? "100%"
                : "calc(100% + 30px)"
          }}
        >
          <Grid
            container
            className="square-content"
            style={{
              marginTop: "15px"
            }}
          >
            {renderMedia()}
          </Grid>
        </StyledDiv>
      </Container>
    </>
  );
};
