import {
  Autocomplete,
  Backdrop,
  Box,
  Button as MuiButton,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { Control, Controller, useController } from "react-hook-form";
import { Add } from "@mui/icons-material";
import { useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { CloseIcon } from "@components/Icons";
import {
  ModelAvailableTwilioNumber,
  useAdminAvailableTwilioNumberGet,
  useAdminTwilioNumberGet,
  useAdminTwilioNumberPurchase
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Button } from "@components/Button";
import { Column, DataGridTable } from "@components/DataGridTable";
import { enqueueSnackbar } from "notistack";
import { Loader } from "@components/crud/Loader";
import { FormInput } from "@components/FormInput";
import colors from "theme/colors";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  display: "block",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  maxHeight: "calc(100vh - 64px)",
  overflowY: "auto",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& .table-view": {
    height: "550px"
  }
}));

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const TwilioNumberSearchAdd = ({
  control,
  name,
  disabled,
  province,
  required
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  name: string;
  disabled?: boolean;
  province?: string;
  required?: boolean;
}) => {
  const { field } = useController({
    name: name,
    control: control
  });
  const autocompleteRef = useRef<HTMLDivElement | null>(null);
  const [selectedNumber, setSelectedNumber] = useState<string | undefined>(
    () => undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);

  const { data: numbersList, isLoading: isLoadingNumbers } =
    useAdminAvailableTwilioNumberGet({
      locality: control._formValues?.province || province
    });

  const { data: availableNumber, isLoading: isLoadingAvailableNumbers } =
    useAdminTwilioNumberGet({ filter: "NOT_IN_USE" });

  const { mutate: purchaseNumber } = useAdminTwilioNumberPurchase();

  const handleAddAccountClick = () => {
    const inputElement = autocompleteRef.current?.querySelector("input");
    if (inputElement) {
      inputElement.blur();
      setOpenPurchaseDialog(true);
    }
  };

  const onPurchaseClick = (number: string) => {
    setLoading(true);
    try {
      purchaseNumber(
        {
          data: {
            country: control._formValues.country || "US",
            province: control._formValues?.province || province,
            number: number
          }
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Number purchased successfully!", {
              variant: "success"
            });
            setSelectedNumber(number);
            field.onChange(number);
            setOpenPurchaseDialog(false);
            setLoading(false);
          },
          onError: () => {
            enqueueSnackbar("Failed to purchase number!", {
              variant: "error"
            });
            setLoading(false);
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to purchase number!", {
        variant: "error"
      });
    }
  };

  const LIST_COLUMNS: Column[] = [
    {
      headerName: "Twillio Phone Number",
      field: "friendlyName",
      width: 200,
      sortable: false,
      borderLeft: "none",
      borderRight: "none",
      align: "left"
    },
    {
      headerName: "Location",
      field: "location",
      width: 150,
      sortable: false,
      borderLeft: "none",
      borderRight: "none",
      align: "left",
      renderCell: () => (
        <span>
          {control._formValues?.province || province},{" "}
          {control._formValues?.country || "US"}
        </span>
      )
    },
    {
      headerName: "Action",
      field: "action",
      width: 150,
      sortable: false,
      borderLeft: "none",
      borderRight: "none",
      align: "left",
      renderCell: (params) => {
        return (
          <Button
            isLoading={loading}
            disabled={loading}
            variant="admin-primary"
            onClick={() => onPurchaseClick(params.phoneNumber)}
          >
            Purchase
          </Button>
        );
      }
    }
  ];

  const numberOptions = useMemo(() => {
    let options: string[] = [];

    if (availableNumber?.data && availableNumber.data?.numbers) {
      options = availableNumber.data.numbers
        .filter((number) => !number.usedById)
        .filter(
          (number) =>
            number.province === control._formValues?.province ||
            number.province === province
        )
        .map((number) => number.number as string);
    }

    if (control._formValues.sportsgravyUser?.sgNumber) {
      options.push(control._formValues.sportsgravyUser.sgNumber);
      setSelectedNumber(control._formValues.sportsgravyUser.sgNumber);
    }
    if (control._formValues?.sgNumber) {
      options.push(control._formValues.sgNumber);
      setSelectedNumber(control._formValues.sgNumber);
    }

    return options;
  }, [
    availableNumber,
    control._formValues.sportsgravyUser,
    control._formValues.sgNumber
  ]);
  return (
    <>
      <Grid item xs={12}>
        <Controller
          key={selectedNumber}
          name={name}
          control={control}
          rules={{
            required: required ? "SportsGravy Number is required" : undefined
          }}
          render={({ field }) => (
            <Box ref={autocompleteRef}>
              {!disabled && (
                <>
                  <StyledFormLabel required={required}>
                    <Typography display="inline" variant="formLabel">
                      SportsGravy Number
                    </Typography>
                  </StyledFormLabel>
                  <Grid
                    spacing={3}
                    sx={{ padding: "0px 0px 12px 0px", marginTop: "4px" }}
                  >
                    <Autocomplete
                      disablePortal
                      defaultValue={selectedNumber}
                      value={selectedNumber}
                      disableClearable={false}
                      options={numberOptions}
                      filterOptions={(options, state) => {
                        const filtered = options.filter(
                          (option) =>
                            option
                              ?.toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                        );
                        if (!filtered.some((option) => option === "NEW")) {
                          filtered.push("NEW");
                        }
                        return filtered;
                      }}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option) => {
                        if (option === "NEW")
                          return (
                            <li
                              style={{
                                borderBottom: "1px solid #E5E5E5"
                              }}
                              key={option}
                            >
                              <Grid
                                container
                                padding="10px 16px 10px 16px"
                                style={{ backgroundColor: "#E5E5E5" }}
                                xs={12}
                                direction="column"
                              >
                                <Grid item>
                                  <MuiButton
                                    style={{
                                      color: "#007AFF",
                                      textTransform: "capitalize",
                                      fontSize: "14px"
                                    }}
                                    onClick={handleAddAccountClick}
                                    startIcon={<Add />}
                                  >
                                    Purchase New Number
                                  </MuiButton>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        return (
                          <li
                            {...props}
                            style={{
                              borderBottom: "1px solid #E5E5E5"
                            }}
                            key={option}
                          >
                            {option}
                          </li>
                        );
                      }}
                      renderInput={(params) => {
                        return <TextField {...params} />;
                      }}
                      loading={isLoadingNumbers}
                      loadingText="Loading..."
                      onChange={(event, newValue) => {
                        setSelectedNumber(newValue);
                        field.onChange(newValue);
                      }}
                    />
                  </Grid>
                </>
              )}
              {disabled && (
                <FormInput
                  name={name}
                  disabled
                  required={required}
                  control={control}
                  type="text"
                  label="SportsGravy Number"
                />
              )}
            </Box>
          )}
        />
      </Grid>
      {openPurchaseDialog && (
        <Backdrop
          sx={{
            overflow: "hidden",
            overflowY: "none",
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={true}
        >
          <Container>
            <StyledBox>
              <StyledBoxHeader>
                <Grid
                  container
                  style={{
                    padding: "18px 20px 20px 32px",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <Grid sx={{ maxWidth: "88%" }}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#fff",
                        fontStyle: "normal",
                        lineHeight: "20px",
                        fontWeight: "600"
                      }}
                    >
                      Buy Twillio Phone Number
                    </Typography>
                  </Grid>
                  <Grid>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenPurchaseDialog(false)}
                    />
                  </Grid>
                </Grid>
              </StyledBoxHeader>
              <Loader isLoading={isLoadingAvailableNumbers}>
                {numbersList?.data && numbersList?.data?.length > 0 && (
                  <DataGridTable
                    columns={LIST_COLUMNS}
                    rows={numbersList?.data as ModelAvailableTwilioNumber[]}
                  />
                )}
              </Loader>
            </StyledBox>
          </Container>
        </Backdrop>
      )}
    </>
  );
};
