import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { organizationAtom } from "../../recoil/auth";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { FormInput } from "@components/FormInput";
import {
  ModelOrganizationSportLocation,
  useAdminSportLocationLocationIdDelete,
  useAdminSportLocationLocationIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
export const SportLocationView = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [locationToDelete, setLocationToDelete] =
    useState<ModelOrganizationSportLocation | null>(null);

  const [organizationId] = useRecoilState(organizationAtom);
  const { data: locationDetails, isLoading: isLoading } =
    useAdminSportLocationLocationIdGet(locationId!);
  console.log(organizationId);
  const { control, reset } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    if (locationDetails) {
      const data = locationDetails.data;
      const formattedAdress = `${data.lines?.join(", ")}, ${data.locality}, ${
        data.province
      }, ${data.country}, ${data.postalCode}`;
      reset(
        { name: data.name, location: formattedAdress },
        { keepDirty: true }
      );
    }
  }, [locationDetails]);
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminSportLocationLocationIdDelete();

  const onConfirmDelete = async () => {
    if (!locationToDelete?.locationId) return;
    try {
      await deleteAsync({ locationId: locationToDelete.locationId });
      enqueueSnackbar("Sport Location deleted successfully", {
        variant: "success"
      });
      setLocationToDelete(null);
      navigate("/sport-locations");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete skill.", {
        variant: "error"
      });
      setLocationToDelete(null);
    }
  };
  return (
    <Container>
      <Toolbar
        title="View Sports Location"
        backBtnClick={() => navigate("/sport-locations")}
        editBtnClick={() => navigate(`/sport-locations/${locationId}/edit`)}
        deleteBtnClick={() => setLocationToDelete(locationDetails!.data)}
      />
      <Loader isLoading={isLoading}>
        <Form>
          <Grid data-testid="sportLocation-view-form" container spacing={3}>
            <Grid xs={12} md={12} container alignItems="center">
              <Grid xs={11} data-testid="sportLocation-location">
                <FormInput
                  type="text"
                  name="location"
                  control={control}
                  rules={{
                    required: "Location is required"
                  }}
                  label="Location"
                  required
                  disabled
                />
              </Grid>
            </Grid>
            <Grid xs={12} md={12} alignItems="center" container direction="row">
              <Grid xs={6} data-testid="sportLocation-name">
                <FormInput
                  control={control}
                  name="name"
                  disabled
                  rules={{
                    required: "Name is required"
                  }}
                  label="Name"
                  type="text"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!locationToDelete}
        title="Delete Sport Location?"
        body={`Are you sure you want to delete ${locationToDelete?.name}?`}
        close={() => setLocationToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setLocationToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
