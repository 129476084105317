import { Box } from "@mui/system";
import { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.min.js";
import styled from "styled-components";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
import { LoadingSpinner } from "@components/LoadingSpinner";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  pdfWorker,
  import.meta.url
).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/"
};

const StyledBox = styled(Box)`
  background-color: #efefef;
  padding: 16px 24px;
  width: calc(100% + 48px);
  height: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin: 12px -24px 0 -24px;
  @media (max-width: 465px) {
    width: calc(100% + 40px);
  }
`;

const StyledPage = styled(Page)`
  &.pdf-page {
    background-color: #efefef !important;
    padding-top: 20px;
    canvas {
      width: 300px !important;
      height: 300px !important;
      border-radius: 5px;
    }
  }
`;

const PageCount = styled(Box)`
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1;
  font-weight: 400;
  font-size: 11px;
  margin-left: 5px;
`;

const CurrentPage = styled(Box)`
  position: absolute;
  top: 10px;
  right: 20px;
`;

const StyledPageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: hidden;
  width: calc(100% + 24px);
`;

const PDFViewer = ({
  src,
  isMobile
}: {
  src: ModelMedia;
  isMobile: boolean;
}) => {
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  return (
    <StyledBox
      style={{
        alignItems:
          !numPages || numPages < 2 || !isMobile ? "center" : "flex-start"
      }}
    >
      <PageCount>
        <Grid container>
          <Grid>
            <Typography style={{ fontWeight: 500, fontSize: "11px" }}>
              {src?.metadata?.documentName} •
            </Typography>
          </Grid>
          <Grid>
            <Typography
              style={{
                marginLeft: "5px",
                fontWeight: 400,
                fontSize: "10px",
                color: "#666"
              }}
            >
              {numPages}
              {numPages !== undefined && numPages > 1 ? " Pages" : " Page"}
            </Typography>
          </Grid>
        </Grid>
      </PageCount>
      <Document
        file={src?.baseUrl && src?.path ? src?.baseUrl + src?.path : ""}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<LoadingSpinner />}
      >
        <StyledPageContainer
          style={{
            justifyContent:
              !numPages || numPages < 2 || !isMobile ? "center" : "flex-start"
          }}
        >
          {[...Array(numPages)].slice(0, 3).map((_, index) => (
            <StyledPage
              loading={<LoadingSpinner />}
              key={index}
              className={"pdf-page"}
              scale={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              pageNumber={index + 1}
              width={300}
              height={300}
              devicePixelRatio={5}
            />
          ))}
        </StyledPageContainer>
      </Document>
      <CurrentPage>
        <Typography
          style={{ fontWeight: 400, fontSize: "10px", color: "#666" }}
        >
          Page 1
        </Typography>
      </CurrentPage>
    </StyledBox>
  );
};

export default memo(PDFViewer);
