import { Grid, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormSwitch } from "@components/FormSwitch";
import { hasPermission } from "@services/Casbin";

export const FSGOAdvertiserSettings = () => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const { data: settings, isLoading: loading } = useConfigGet();

  const { mutate: save } = useConfigPut();
  const onSave = (key: string, value) => {
    save(
      {
        data: [{ key, value }]
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };
  const form = useForm({
    mode: "onBlur"
  });
  const { reset, control, setValue } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (settings) {
      reset({
        "live-stream.advertiser-approval-required": settings.data.find(
          (s) => s.key === "live-stream.advertiser-approval-required"
        )?.value,
        "live-stream.advertisement-live-on-approval": settings.data.find(
          (s) => s.key === "live-stream.advertisement-live-on-approval"
        )?.value
      });
    }
  }, [settings]);

  return (
    <>
      <Loader isLoading={loading}>
        {settings && (
          <Grid item container direction="column" spacing="20px">
            <Grid item marginTop="20px">
              <Typography
                style={{
                  fontSize: "12px",
                  font: "inter",
                  lineHeight: "14.52px",
                  fontWeight: 400,
                  opacity: "50%",
                  marginRight: "10px"
                }}
              >
                ADVERTISERS SETTINGS
              </Typography>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item marginTop="10px">
                <Typography
                  style={{
                    color: "#1E2941",
                    fontSize: "13px",
                    font: "inter",
                    lineHeight: "normal",
                    width: "212px"
                  }}
                >
                  Require Advertiser Approval for Advertisements
                </Typography>
              </Grid>
              <Grid item>
                <FormSwitch
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      `live-stream.advertiser-approval-required`,
                      e.target.checked
                    );
                    onSave(
                      "live-stream.advertiser-approval-required",
                      e.target.checked
                    );
                  }}
                  name={`live-stream.advertiser-approval-required`}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item marginTop="10px">
                <Typography
                  style={{
                    color: "#1E2941",
                    fontSize: "13px",
                    font: "inter",
                    lineHeight: "normal",
                    width: "212px"
                  }}
                >
                  Upon advertisement approval automatically set advertisement
                  status to active
                </Typography>
              </Grid>
              <Grid item>
                <FormSwitch
                  disabled={disabled}
                  onChange={(e) => {
                    setValue(
                      `live-stream.advertisement-live-on-approval`,
                      e.target.checked
                    );
                    onSave(
                      "live-stream.advertisement-live-on-approval",
                      e.target.checked
                    );
                  }}
                  name={`live-stream.advertisement-live-on-approval`}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Loader>
    </>
  );
};
