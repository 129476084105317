import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import {
  FormLabel,
  Grid,
  Typography,
  styled,
  Button,
  IconButton
} from "@mui/material";
import {
  useAdminQAFeatureFeatureIdGet,
  useAdminFeatureGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TestCaseList } from "./TestCaseList";
import { Add, AddBox, IndeterminateCheckBox } from "@mui/icons-material";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import {
  organizationAtom,
  profileAtom,
  selectedReleaseAtom,
  selectedTesterAtom
} from "@recoil/auth";
import formatFullName from "@utils/formatFullName";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const QAFeatureView = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const selectedTesterValue = useRecoilValue(selectedTesterAtom);
  const profileValue = useRecoilValue(profileAtom);
  const [releaseId, setReleaseId] = useState(selectedReleaseValue);
  const [releases, setReleases] = useState<
    { label: string; value: string; status?: string }[]
  >([]);

  const [platforms, setPlatforms] = useState<string[]>([]);

  const [testerId, setTesterId] = useState<string>("ALL");
  const [testers, setTesters] = useState<{ label: string; value: string }[]>([
    { label: "All", value: "ALL" }
  ]);

  useEffect(() => {
    if (searchParams.has("releaseId")) {
      setReleaseId(searchParams.get("releaseId") as string);
    }
    if (searchParams.has("platform")) {
      setPlatform(searchParams.get("platform") as string);
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      testers &&
      testers.length &&
      (selectedTesterValue || profileValue?.personId)
    ) {
      if (
        selectedTesterValue &&
        testers.find((tester) => tester.value === selectedTesterValue)
      ) {
        setTesterId(selectedTesterValue);
      } else if (
        testers.find((tester) => tester.value === profileValue!.personId)
      ) {
        setTesterId(profileValue!.personId!);
      } else {
        setTesterId("ALL");
      }
    }
  }, [testers]);
  const form = useForm({
    mode: "onBlur"
  });

  const { data: data, isLoading: isLoading } = useAdminQAFeatureFeatureIdGet(
    featureId!,
    {
      releaseId: releaseId as string
    }
  );

  const { control, reset } = form;

  const [platform, setPlatform] = useState("IOS");
  // const [feature, setFeature] = useState<ModelFeature>();
  const [subFeatureFilter, setSubFeatureFilter] = useState("");
  const [subFeatureExpanded, setSubFeatureExpanded] = useState<
    { featureId: string; isExpanded: boolean }[]
  >([]);

  useEffect(() => {
    if (data) {
      const feature = data.data;
      const defaultValues = {
        name: feature.feature?.name,
        parentId: feature.feature?.parentId,
        iosTesterId: feature.iosTesterId,
        andTesterId: feature.andTesterId,
        webtesterId: feature.webTesterId
      };
      if (feature.feature?.releases) {
        setReleases(
          feature.feature.releases.map((release) => ({
            label: release.release.name,
            value: release.releaseId,
            status: release.release.releaseStatus
          }))
        );
      }
      if (feature.testers) {
        setTesters([
          { label: "All", value: "ALL" },
          ...feature.testers.map((tester) => ({
            label: formatFullName(tester.person),
            value: tester.personId
          }))
        ]);
      }
      setPlatforms(feature.feature!.platforms!);
      if (!searchParams.get("platform"))
        setPlatform(
          feature.feature!.platforms?.includes("WEB") ? "WEB" : "IOS" || "IOS"
        );

      if (feature.feature?.children) {
        const expandedValues = [] as {
          featureId: string;
          isExpanded: boolean;
        }[];
        feature.feature.children.map((child) => {
          expandedValues.push({
            featureId: child.featureId as string,
            isExpanded: true
          });
        });
        setSubFeatureExpanded([...expandedValues]);
      }
      // setFeature(feature.feature);
      reset(defaultValues);
    }
  }, [data]);

  const findParentNames = (data, featureId) => {
    const result = [];

    const traverse = (features, parentNames) => {
      for (const feature of features) {
        const currentNames = [...parentNames];
        if (feature.featureId === featureId) {
          result.push(currentNames);
        } else if (feature.children && feature.children.length > 0) {
          currentNames.push({ label: feature.name, value: feature.featureId });
          traverse(feature.children, currentNames);
        }
      }
    };
    traverse(data, []);
    setParents(result[0]);
  };
  const [parents, setParents] = useState<{ label: string; value: string }[]>(
    []
  );
  const { data: features, isLoading: isFetchingFeatures } = useAdminFeatureGet({
    flatten: false,
    pageSize: 100
  });

  useEffect(() => {
    if (features?.data.features)
      findParentNames(features.data.features, featureId);
  }, [features]);

  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const organizationId = useRecoilValue(organizationAtom);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.features", "ADD");
      const edit = await checkPermission("tech.features", "EDIT");
      const del = await checkPermission("tech.features", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
    };
    fetchPermissions();
  }, [organizationId]);

  return (
    <Loader isLoading={isLoading || isFetchingFeatures}>
      <Container>
        <Toolbar
          title="View QA Feature"
          showFilters={
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              marginLeft="10px"
            >
              <Grid item marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">
                  Release
                </Typography>
              </Grid>
              <Grid
                marginRight="10px"
                minWidth="85px"
                data-testid="releaseWidget"
              >
                <FormSelect
                  name="release"
                  options={releases}
                  value={releaseId}
                  isReleaseSelect
                  onChange={(e) => {
                    setReleaseId(e.target.value);
                  }}
                  sx={{
                    minWidth: 85
                  }}
                />
              </Grid>

              <Grid item marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">Tester</Typography>
              </Grid>
              <Grid
                marginRight="10px"
                minWidth="85px"
                data-testid="testerWidget"
              >
                <FormSelect
                  name="testerId"
                  options={testers}
                  value={testerId}
                  onChange={(e) => {
                    setTesterId(e.target.value);
                  }}
                />
              </Grid>
              <Grid item marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">
                  Platform
                </Typography>
              </Grid>
              <Grid
                marginRight="10px"
                minWidth="85px"
                data-testid="platformWidget"
              >
                <FormSelect
                  name="platform"
                  onChange={(e) => {
                    setPlatform(e.target.value);
                  }}
                  options={
                    platforms.map((platform) => ({
                      label: FEATURE_PLATFORMS_OPTIONS.find(
                        (opt) => opt.value === platform
                      )!.label,
                      value: platform
                    })) || FEATURE_PLATFORMS_OPTIONS
                  }
                  value={platform}
                />
              </Grid>

              <Grid marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">View</Typography>
              </Grid>
              <Grid flex={1} data-testid="viewWidget">
                <FormSelect
                  name="view"
                  value={subFeatureFilter}
                  options={[
                    { label: "All", value: "" },
                    ...(data?.data.feature?.children?.map((feat) => ({
                      label: (feat.name +
                        " (" +
                        (feat.testCases ? feat.testCases.length : "0") +
                        " Test cases)") as string,
                      value: feat.featureId
                    })) || [])
                  ]}
                  onChange={(e) => {
                    setSubFeatureFilter(e.target.value);
                    if (e.target.value !== "") {
                      const expanded = subFeatureExpanded;
                      const idx = subFeatureExpanded.findIndex(
                        (f) => f.featureId === e.target.value
                      );
                      expanded[idx].isExpanded = true;
                      setSubFeatureExpanded([...expanded]);
                    }
                  }}
                />
              </Grid>
            </Grid>
          }
          backBtnClick={() => {
            navigate("/qa-features");
          }}
          editBtnClick={
            permissions.edit
              ? () => {
                  navigate(`/qa-features/${featureId}/edit`);
                }
              : undefined
          }
        />
        <Grid
          item
          container
          direction="column"
          spacing="15px"
          paddingLeft="1.5rem"
          paddingRight="1.5rem"
        >
          <Grid item container direction="row" spacing="24px">
            <Grid item xs={6}>
              <FormInput
                name="name"
                disabled
                control={control}
                label="Feature Name"
                required
                type="text"
              />
            </Grid>
          </Grid>
          {parents && parents.length > 0 && (
            <Grid item marginBottom="-10px">
              <StyledFormLabel>
                <Typography display="inline" variant="formLabel">
                  Parent Feature
                </Typography>
              </StyledFormLabel>
            </Grid>
          )}
          {parents &&
            parents.map((parent) => {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  spacing="24px"
                  key={parent.value}
                >
                  <Grid item xs={6}>
                    <FormSelect
                      disabled
                      value={parent.value}
                      options={parents}
                      name="parents"
                    />
                  </Grid>
                </Grid>
              );
            })}
          <Grid
            item
            container
            direction="row"
            spacing="24px"
            marginBottom="-10px"
          >
            <Grid item xs={6}>
              <StyledFormLabel required>
                <Typography variant="formLabel">Platforms</Typography>
              </StyledFormLabel>
            </Grid>
            <Grid item xs={6}>
              <StyledFormLabel required>
                <Typography variant="formLabel">Tester</Typography>
              </StyledFormLabel>
            </Grid>
          </Grid>
          {platforms.map((platform) => {
            let key = "";
            switch (platform) {
              case "IOS":
                key = "iosTesterId";
                break;
              case "AND":
                key = "andTesterId";
                break;
              case "WEB":
                key = "webTesterId";
                break;
            }
            return (
              <Grid
                item
                container
                direction="row"
                spacing="24px"
                key={platform}
              >
                <Grid item xs={6} data-testid={"PLATFORM_" + platform}>
                  <FormSelect
                    name={`platform_${platform}`}
                    disabled
                    options={FEATURE_PLATFORMS_OPTIONS}
                    value={platform}
                  />
                </Grid>
                <Grid item xs={6} data-testid={key}>
                  <FormSelect
                    name={key}
                    control={control}
                    options={testers}
                    disabled
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        {subFeatureFilter === "" && (
          <div style={{ marginTop: "25px" }} data-testid="testCaseList-main">
            <TestCaseList
              release={releaseId!}
              releaseList={releases}
              key={featureId}
              testerId={testerId === "ALL" ? undefined : testerId}
              addTestCaseClick={() => {
                navigate(`/testcases/${featureId}/create`);
              }}
              featureId={featureId!}
              platform={platform}
              includeChildren={false}
            />
          </div>
        )}
        {data?.data?.feature?.children &&
          subFeatureExpanded.length === data.data.feature.children.length &&
          data.data.feature.children.map((child, index) => {
            if (
              subFeatureFilter === child.featureId ||
              subFeatureFilter === ""
            ) {
              return (
                <Grid
                  container
                  direction="column"
                  key={child.featureId}
                  marginTop={subFeatureFilter !== "" ? "20px" : ""}
                  data-testid={"SUB_FEATURE_VIEW_" + child.featureId}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      border: "0px 0px 1px 0px",
                      padding: "8px 32px 8px 32px",
                      backgroundColor: "#E8EEFF",
                      minHeight: "56px"
                    }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                      xs={9.5}
                    >
                      {subFeatureFilter === "" && (
                        <Grid item marginLeft="-15px">
                          <IconButton
                            onClick={() => {
                              const expanded = subFeatureExpanded;
                              expanded[index].isExpanded =
                                !expanded[index].isExpanded;
                              setSubFeatureExpanded([...expanded]);
                            }}
                          >
                            {subFeatureExpanded[index].isExpanded ? (
                              <IndeterminateCheckBox color="primary" />
                            ) : (
                              <AddBox color="primary" />
                            )}
                          </IconButton>
                        </Grid>
                      )}
                      <Grid item flex={1}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: "#666666",
                            fontWeight: 600
                          }}
                        >
                          {child.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<Add color="primary" />}
                        onClick={() => {
                          navigate(`/features/${child.featureId}/create`);
                        }}
                      >
                        <Typography
                          color="primary"
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontWeight: 600
                          }}
                        >
                          Add Sub Feature
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  {subFeatureExpanded[index].isExpanded && (
                    <TestCaseList
                      releaseList={releases}
                      release={releaseId!}
                      key={child.featureId}
                      testerId={testerId === "ALL" ? undefined : testerId}
                      addTestCaseClick={() => {
                        navigate(`/testcases/${child.featureId}/create`);
                      }}
                      featureId={child.featureId!}
                      platform={platform}
                    />
                  )}
                </Grid>
              );
            }
          })}
      </Container>
    </Loader>
  );
};
