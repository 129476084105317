import {
  accessTokenAtom,
  casbinModel,
  casbinPolicy,
  enforcerAtom,
  profileAtom,
  refreshTokenAtom,
  selectedPaginationAtom
} from "@recoil/auth";
import {
  basicFormInputAtom,
  consentFormAtom,
  duplicateAccountAtom,
  duplicatePendingAccountsAtom,
  inviteAtom,
  kidInviteAtom,
  passwordAtom,
  pendingAccountAtom,
  personAtom,
  signatureAtom,
  signupTokenAtom,
  userAtom
} from "@recoil/signup";
import { useResetRecoilState } from "recoil";

export const useResetSignup = () => {
  const resetters = [
    accessTokenAtom,
    basicFormInputAtom,
    consentFormAtom,
    duplicateAccountAtom,
    duplicatePendingAccountsAtom,
    inviteAtom,
    kidInviteAtom,
    passwordAtom,
    pendingAccountAtom,
    personAtom,
    profileAtom,
    refreshTokenAtom,
    signatureAtom,
    signupTokenAtom,
    userAtom,
    casbinModel,
    casbinPolicy,
    enforcerAtom,
    selectedPaginationAtom
  ].map((atom) => useResetRecoilState(atom));

  const reset = () => {
    resetters.forEach((resetter) => resetter());
  };

  return reset;
};
