import { Container } from "@components/crud/Container";
import { Typography, Box } from "@mui/material";
import NoPermissionIcon from "@assets/icons/no-permission.svg";

export const NoPermission = () => {
  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <img src={NoPermissionIcon} />
        <Typography
          style={{
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "30px"
          }}
        >
          You don't have permission to view this page
        </Typography>
        <Typography
          style={{
            marginTop: "12px",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#666666"
          }}
        >
          Ask admin to add permission for you to view this page
        </Typography>
      </Box>
    </Container>
  );
};
