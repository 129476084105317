import { VerticalListTooltip } from "@components/VerticalListTooltip";

export const DataGridRenderCellTooltipPositions = (
  positionsSelected,
  positionOptions
) => {
  if (
    !positionsSelected ||
    !Array.isArray(positionsSelected) ||
    !positionsSelected.length
  )
    return null;
  const textToShow =
    positionOptions.find((option) => option.value === positionsSelected[0])
      ?.label || positionsSelected[0];
  const displayed = textToShow;
  const remaining = positionsSelected
    ?.slice(1)
    ?.map(
      (position) =>
        positionOptions.find((option) => option.value === position)?.label
    );
  return <VerticalListTooltip text={displayed} tooltipList={remaining} />;
};
