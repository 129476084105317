import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminFaqPost,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { DISPLAY_CHANNEL_OPTIONS, RELATED_TO_OPTIONS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const FaqCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
    reset
  } = useForm({
    mode: "onBlur"
  });
  const { data: categories, isLoading: categoriesLoading } =
    useAdminHelpCategoryGet();
  const categoriesOptions = categories?.data.helpCategory?.map((category) => ({
    label: category.name,
    value: category.helpCategoryId
  }));
  const { mutate: save, isLoading: isSaving } = useAdminFaqPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      values["question"] = values["question"]
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Faq added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/faqs");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add faq!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add FAQ" />
      <Form>
        <Grid data-testid="faq-add-form" container spacing={3}>
          <Grid data-testid="faq-question" xs={12} md={6}>
            <FormInput
              control={control}
              name="question"
              type="text"
              label="Question"
              required={true}
              onChange={(e) => {
                setValue("question", capitalizeEveryWord(e.target.value));
              }}
              rules={{
                required: "Question is required"
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: "24px" }}>
          <Grid data-testid="faq-answer" xs={12} md={12}>
            <FormInput
              control={control}
              name="answer"
              type="text"
              label="Answer"
              multiline
              rows={4}
              required={true}
              rules={{
                required: "Answer is required"
              }}
            />
          </Grid>
          <Grid data-testid="faq-category" xs={12} md={6}>
            <FormSelect
              options={categoriesOptions || []}
              isLoading={categoriesLoading}
              control={control}
              name="categoryId"
              type="text"
              label="Category"
              required={true}
              rules={{
                required: "Category is required"
              }}
            />
          </Grid>
          <Grid data-testid="faq-relatedTo" xs={12} md={6}>
            <FormSelect
              options={RELATED_TO_OPTIONS}
              control={control}
              name="relatedTo"
              type="text"
              label="Related To"
              required={true}
              rules={{
                required: "Related To is required"
              }}
            />
          </Grid>
          <Grid data-testid="faq-displayChannel" xs={12} md={6}>
            <FormMultiSelect
              options={DISPLAY_CHANNEL_OPTIONS}
              control={control}
              name="displayChannel"
              label="Display Channel"
              required={true}
              rules={{
                required: "Display Channels is required"
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/faqs")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
