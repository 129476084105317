import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import { getReportPostList, getReportCommentList } from "@services/Network";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
export const ReportTableView = () => {
  const [refreshKey] = React.useState(0);
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { filter, type } = useParams<{ filter: string; type: string }>() as {
    filter: string;
    type: string;
  };
  const [tableType] = React.useState({
    type: type?.charAt(0).toUpperCase() + type.slice(1),
    filter: filter?.charAt(0).toUpperCase() + filter.slice(1)
  });
  const [COLUMNS, setCOLUMNS] = React.useState<GridColDef[]>([]);
  function dateFormat(date) {
    const formatedDate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    //@ts-ignore
    return formatedDate.toLocaleDateString("en-US", options);
  }
  React.useEffect(() => {
    if (filter === "flagged") {
      setCOLUMNS([
        {
          headerName: `${tableType.type.slice(0, -1)} Author`,
          field: "createdBy",
          minWidth: 150,
          valueGetter: ({ row }) =>
            row?.[row.$kind == "Post" ? "createdBy" : "user"]?.person
              ?.firstName +
              " " +
              row?.[row.$kind == "Post" ? "createdBy" : "user"]?.person
                ?.lastName || "",
          flex: 1
        },
        {
          headerName: "Post Date",
          field: "postDate",
          minWidth: 110,
          valueGetter: ({ row }) => dateFormat(row?.createdAt),
          flex: 1
        },
        ...(organizationId
          ? [
              {
                headerName: "Team/Training Program",
                field: "team/trainingProgramName",
                minWidth: 120,
                flex: 1,
                renderCell: DataGridRenderCellTooltip("name", 1),
                sortable: false
              }
            ]
          : []),
        {
          headerName: "Times Flagged",
          field: "timesFlagged",
          valueGetter: ({ row }) => row?._count?.reports || 0,
          minWidth: 90,
          flex: 1
        }
      ]);
    } else if (filter === "approved") {
      setCOLUMNS([
        {
          headerName: "Date Approved",
          field: "createdAt",
          minWidth: 110,
          valueGetter: ({ row }) => dateFormat(row.createdAt),
          flex: 1
        },
        {
          headerName: "Approved By",
          field: "takenBy",
          minWidth: 110,
          valueGetter: ({ row }) =>
            row.takenBy?.person?.firstName +
              " " +
              row.takenBy?.person?.lastName || "",
          flex: 1
        },
        {
          headerName: `${tableType.type.slice(0, -1)} Author`,
          field: "createdBy",
          minWidth: 150,
          valueGetter: ({ row }) =>
            row?.[type.slice(0, -1)]?.[
              row.$kind == "PostCommentAction" ? "user" : "createdBy"
            ]?.person?.firstName +
              " " +
              row?.[type.slice(0, -1)]?.[
                row.$kind == "PostCommentAction" ? "user" : "createdBy"
              ]?.person?.lastName || "",
          flex: 1
        },
        {
          headerName: "Post Date",
          field: "postDate",
          minWidth: 110,
          flex: 1,
          valueGetter: ({ row }) =>
            dateFormat(row?.[type.slice(0, -1)]?.createdAt)
        },
        ...(organizationId
          ? [
              {
                headerName: "Team/Training Program",
                field: "team/trainingProgramName",
                minWidth: 120,
                renderCell: DataGridRenderCellTooltip("name", 1),
                flex: 1,
                sortable: false
              }
            ]
          : []),
        {
          headerName: "Times Flagged",
          field: "timesFlagged",
          valueGetter: ({ row }) =>
            row?.[type.slice(0, -1)]?._count?.reports || 0,
          minWidth: 90,
          flex: 1
        }
      ]);
    } else if (filter === "removed") {
      setCOLUMNS([
        {
          headerName: "Date Removed",
          field: "createdAt",
          minWidth: 110,
          flex: 1,
          valueGetter: ({ row }) => dateFormat(row.createdAt)
        },
        {
          headerName: "Removed By",
          field: "takenBy",
          valueGetter: ({ row }) =>
            row.takenBy?.person?.firstName +
              " " +
              row.takenBy?.person?.lastName || "",
          minWidth: 110,
          flex: 1
        },
        {
          headerName: `${tableType.type.slice(0, -1)} Author`,
          field: "createdBy",
          minWidth: 150,
          valueGetter: ({ row }) =>
            row?.[type.slice(0, -1)]?.[
              row.$kind == "PostCommentAction" ? "user" : "createdBy"
            ]?.person?.firstName +
              " " +
              row?.[type.slice(0, -1)]?.[
                row.$kind == "PostCommentAction" ? "user" : "createdBy"
              ]?.person?.lastName || "",
          flex: 1
        },
        {
          headerName: "Post Date",
          field: "postDate",
          valueGetter: ({ row }) =>
            dateFormat(row?.[type.slice(0, -1)]?.createdAt),
          minWidth: 110,
          flex: 1
        },
        ...(organizationId
          ? [
              {
                headerName: "Team/Training Program",
                field: "team/trainingProgramName",
                minWidth: 120,
                renderCell: DataGridRenderCellTooltip("name", 1),
                flex: 1,
                sortable: false
              }
            ]
          : []),
        {
          headerName: "Times Flagged",
          field: "timesFlagged",
          valueGetter: ({ row }) =>
            row?.[type.slice(0, -1)]?._count?.reports || 0,
          minWidth: 90,
          flex: 1
        }
      ]);
    }
  }, [filter]);

  const onView = (row) => {
    navigate(`${row?.[type.slice(0, -1) + "Id"]}`);
  };

  const filterConfig = {
    field: "interval",
    placeholderOption: {
      label: "All",
      value: ""
    },
    options: [
      {
        label: "Today",
        value: "DAY"
      },
      {
        label: "This Week",
        value: "WEEK"
      },
      {
        label: "This Month",
        value: "MONTH"
      }
    ]
  };
  return (
    <>
      <TableView
        title={`${tableType.filter} ${tableType.type}`}
        useGet={type == "comments" ? getReportCommentList : getReportPostList}
        action={
          filter != "flagged"
            ? filter?.slice(0, -1).toUpperCase()
            : filter?.toUpperCase()
        }
        columns={COLUMNS}
        filterConfig={filterConfig}
        isDeleteDisabled={() => true}
        hideSortButton={true}
        isEditDisabled={() => true}
        onView={onView}
        refreshKey={refreshKey}
        getRowId={(row) => {
          if (row.$kind === "PostCommentAction") {
            return row.commentActionId;
          } else if (row.$kind === "Post") {
            return row.postId;
          } else if (row.$kind === "PostComment") {
            return row.commentId;
          } else {
            return row.postActionId;
          }
        }}
        defaultSort={
          filter === "flagged" ? [{ field: "timesFlagged", sort: "desc" }] : []
        }
        formSelectType="Interval"
        backButton={true}
      />
    </>
  );
};
