import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const PaymentInfoHeader = styled(Box)`
  margin: 0.5rem 0;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
    margin-top: 0;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const FormFieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: 0,

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
    marginTop: "1.5rem",
  },

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",

    "&.right-spacing": {
      marginRight: "1.5rem",
    },
  },
}));

const ButtonContainer = styled(Box)`
  display: flex;
  margin-top: 3rem;
`;

const SUBSCRIPTIONS_MOBILE_VIEW = styled(Box)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    display: flex;
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: none;
  }
`;

const SubscriptionCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  border: "2px solid rgba(100, 116, 139, 0.6)",
  borderRadius: "6px",
  padding: "20px",
  marginBottom: "10px",
  cursor: "pointer",

  "& .card-text": {
    whiteSpace: "pre-line",
  },

  "&.selected": {
    backgroundColor: theme.palette.info.main,

    "& .card-text": {
      color: theme.palette.white.main,
    },
  },

  "&.div:last-child": {
    marginBottom: ".5rem",
  },
}));

const SubscriptionCardHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SubscriptionPlan = styled(Box)`
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
`;

const SubscriptionPriceContainer = styled(Box)`
  display: flex;
  white-space: nowrap;
`;

const SubscriptionPrice = styled(Box)`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
`;

const SubscriptionBillingPeriod = styled(Box)`
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

const SubscriptionItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SubscriptionFeature = styled(Box)`
  width: 70%;
  text-align: left;
`;

const SubscriptionFeatureHeading = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const SubscriptionFeatureInfo = styled(Box)`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #64748b;
`;

const SubscriptionFeatureDetail = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #64748b;
`;

const SUBSCRIPTIONS_DESKTOP_VIEW = styled(Box)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    display: none;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
  }
  margin: 1rem 0;
`;

const FeaturesContainer = styled(Box)`
  width: 40%;
  height: 100%;
`;

const FeatureHeaderContainer = styled(Box)`
  margin-bottom: 2px;
`;

const Features = styled(Box)`
  height: 80px;
  display: flex;
  flex-direction: column;
`;

const Feature = styled(Box)`
  margin-top: 20px;
  width: 100%;
  padding: 0;
`;

const LabelText = styled(Typography)`
  font-size: 16px;
  line-height: 18px;
`;

const SubscriptionsContainer = styled(Box)`
  display: flex;
  width: 60%;
`;

const SubscriptionColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  padding: 0.25rem;
`;

const SubscriptionPanel = styled(Box)(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  border: "#b3b3b3",
  borderRadius: "0.5em",
  borderStyle: "solid",
  borderWidth: "2px",

  "& .panel-text": {
    whiteSpace: "pre-line",
  },

  "&.selected": {
    backgroundColor: theme.palette.info.main,

    "& .panel-text": {
      color: theme.palette.white.main,
    },
  },
}));

const PanelInfo = styled(Box)`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const LargeInfoText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 700;
`;

const FREE_PLAN = "Free";
const PERSONAL_PLAN = "Personal";
const FAMILY_PLAN = "Family";

const SUBSCRIPTION_PLANS_COPY = [
  {
    name: FREE_PLAN,
    price: "$0.00",
    features: [
      {
        name: "Photo & Video Sharing",
        description:
          "Easily capture and share visual feedback with your kids/atheltes",
        value: "Unlimited",
      },
      {
        name: "User Accounts",
        description:
          "These are the accounts included for your family (Dad, Mom, Kids)",
        value: "Parents/+\n All Kids",
      },
      {
        name: "Recruiting Pages",
        description:
          "Provide recruiters with the best photos and videos of your kids/athletes",
        value: "N/A",
      },
      {
        name: "Connections",
        description:
          "Conenct with family, friends, acquaintences and organizations",
        value: "Unlimited",
      },
      {
        name: "Cloud Storage",
        description: "Cloud storage for your photos, videos and documents",
        value: "50 GB",
      },
    ],
  },
  {
    name: PERSONAL_PLAN,
    price: "$4.99",
    features: [
      {
        name: "Photo & Video Sharing",
        description:
          "Easily capture and share visual feedback with your kids/atheltes",
        value: "Unlimited",
      },
      {
        name: "User Accounts",
        description:
          "These are the accounts included for your family (Dad, Mom, Kids)",
        value: "Parents/+\n All Kids",
      },
      {
        name: "Recruiting Pages",
        description:
          "Provide recruiters with the best photos and videos of your kids/athletes",
        value: "Up to 2",
      },
      {
        name: "Connections",
        description:
          "Conenct with family, friends, acquaintences and organizations",
        value: "Unlimited",
      },
      {
        name: "Cloud Storage",
        description: "Cloud storage for your photos, videos and documents",
        value: "100 GB",
      },
    ],
  },
  {
    name: FAMILY_PLAN,
    price: "$9.99",
    features: [
      {
        name: "Photo & Video Sharing",
        description:
          "Easily capture and share visual feedback with your kids/atheltes",
        value: "Unlimited",
      },
      {
        name: "User Accounts",
        description:
          "These are the accounts included for your family (Dad, Mom, Kids)",
        value: "Parents/+\n All Kids",
      },
      {
        name: "Recruiting Pages",
        description:
          "Provide recruiters with the best photos and videos of your kids/athletes",
        value: "Unlimited",
      },
      {
        name: "Connections",
        description:
          "Conenct with family, friends, acquaintences and organizations",
        value: "Unlimited",
      },
      {
        name: "Cloud Storage",
        description: "Cloud storage for your photos, videos and documents",
        value: "2 TB",
      },
    ],
  },
];

const monthOptions = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const yearOptions = [] as { value: string; label: string }[];

for (let i = 0; i < 7; i++) {
  const yearString = `${currentYear + i}`;
  yearOptions.push({ value: yearString, label: yearString });
}

export const SubscriptionPlans = () => {
  const navigate = useNavigate();
  const [subscriptionPlan, setSubscriptionPlan] = useState(FREE_PLAN);
  const [creditCardMonth, setCreditCardMonth] = useState("");
  const [creditCardYear, setCreditCardYear] = useState("");

  const {
    control,
    formState: { isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      creditCardNumber: "",
      creditCardExpirationMonth: "",
      creditCardExpirationYear: "",
      creditCardCVV: "",
      creditCardName: "",
    },
  });

  const selectPlan = (plan) => {
    setSubscriptionPlan(plan);
  };

  const onContinue = () => {
    navigate("/sign-up/success");
  };

  return (
    <FormContainer>
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Subscription Plans
      </Typography>

      <SUBSCRIPTIONS_MOBILE_VIEW>
        {SUBSCRIPTION_PLANS_COPY.map((plan) => (
          <SubscriptionCard
            key={plan.name}
            className={subscriptionPlan === plan.name ? "selected" : ""}
            onClick={() => selectPlan(plan.name)}
          >
            <SubscriptionCardHeader>
              <SubscriptionPlan className="card-text">
                {plan.name}
              </SubscriptionPlan>
              <SubscriptionPriceContainer>
                <SubscriptionPrice className="card-text">
                  {plan.price}
                </SubscriptionPrice>
                <SubscriptionBillingPeriod className="card-text">
                  /month
                </SubscriptionBillingPeriod>
              </SubscriptionPriceContainer>
            </SubscriptionCardHeader>
            {plan.features.map((feature) => (
              <SubscriptionItem key={`${plan.name}__${feature.name}`}>
                <SubscriptionFeature>
                  <SubscriptionFeatureHeading className="card-text">
                    {feature.name}
                  </SubscriptionFeatureHeading>
                  <SubscriptionFeatureInfo className="card-text">
                    {feature.description}
                  </SubscriptionFeatureInfo>
                </SubscriptionFeature>
                <SubscriptionFeatureDetail className="card-text">
                  {feature.value}
                </SubscriptionFeatureDetail>
              </SubscriptionItem>
            ))}
          </SubscriptionCard>
        ))}
      </SUBSCRIPTIONS_MOBILE_VIEW>

      <SUBSCRIPTIONS_DESKTOP_VIEW>
        <FeaturesContainer>
          <FeatureHeaderContainer>
            <LabelText variant="h1">Features</LabelText>
          </FeatureHeaderContainer>
          <Features>
            {SUBSCRIPTION_PLANS_COPY[0].features.map((feature) => (
              <Feature key={`${feature.name}`}>
                <LabelText variant="h1">{feature.name}</LabelText>
                <Typography variant="caption">{feature.description}</Typography>
              </Feature>
            ))}
          </Features>
        </FeaturesContainer>
        <SubscriptionsContainer>
          {SUBSCRIPTION_PLANS_COPY.map((plan) => (
            <SubscriptionColumn key={`${plan.name}`}>
              <LabelText mb=".5rem" variant="h1">
                {plan.name}
              </LabelText>
              <SubscriptionPanel
                className={subscriptionPlan === plan.name ? "selected" : ""}
                onClick={() => selectPlan(plan.name)}
              >
                {plan.features.map((feature) => (
                  <PanelInfo key={`${feature.name}`}>
                    <Typography className="panel-text" variant="caption">
                      {feature.value}
                    </Typography>
                  </PanelInfo>
                ))}
                <PanelInfo>
                  <LargeInfoText className="panel-text" variant="caption">
                    {plan.price}
                  </LargeInfoText>
                </PanelInfo>
              </SubscriptionPanel>
            </SubscriptionColumn>
          ))}
        </SubscriptionsContainer>
      </SUBSCRIPTIONS_DESKTOP_VIEW>

      {subscriptionPlan !== FREE_PLAN && (
        <>
          <PaymentInfoHeader>
            <Typography
              variant="h2"
              color="text.general.primary"
              sx={{ fontWeight: 400 }}
            >
              Payment Information
            </Typography>
          </PaymentInfoHeader>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="creditCardNumber"
                type="text"
                required
                label="Cred Card Number"
                control={control}
                rules={{ required: "Credit Card Number is required" }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer className="right-spacing">
              <FormSelect
                name="creditCardExpirationMonth"
                value={creditCardMonth}
                onChange={(e) => setCreditCardMonth(e.target.value)}
                options={monthOptions}
                required
                label="Expiration Month"
                control={control}
                rules={{ required: "Expiration Month is required" }}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <FormSelect
                name="creditCardExpirationYear"
                value={creditCardYear}
                onChange={(e) => setCreditCardYear(e.target.value)}
                options={yearOptions}
                required
                label="Expiration Year"
                control={control}
                rules={{ required: "Expiration Year is required" }}
              />
            </FormFieldContainer>
          </FormRow>
          <FormRow>
            <FormFieldContainer>
              <FormInput
                name="creditCardName"
                type="text"
                required
                label="Cred Card Name"
                control={control}
                rules={{ required: "Credit Card Name is required" }}
              />
            </FormFieldContainer>
          </FormRow>
        </>
      )}
      <ButtonContainer>
        <Button
          variant="primary"
          disabled={!isValid && subscriptionPlan !== FREE_PLAN}
          type="submit"
          onClick={onContinue}
        >
          Continue
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};
