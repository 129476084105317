import { Box } from "@mui/system";
import { memo } from "react";
import ReactPlayer from "react-player";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import FeedPDFViewer from "@pages/feed/components/FeedPDFViewer";
import { styled } from "@mui/material";

const StyledBox = styled(Box)`
  position: relative;
`;
const StyledReactPlayer = styled(ReactPlayer)(() => ({
  "& video": {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  }
}));
const StyledPlayIcon = styled(PlayArrowOutlinedIcon)`
  background-color: #000000;
  opacity: 0.75;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const FeedIndividualMedia = (props: {
  src: string;
  style;
  onClick?;
  metaData?;
  mimeType?;
  remainingMediaCount?;
  pdf?;
}) => {
  if (
    props.src.split(".").pop() === "jpg" ||
    props.src.split(".").pop() === "jpeg" ||
    props.src.split(".").pop() === "png" ||
    props.src.split(".").pop() === "jpe"
  ) {
    if (props.remainingMediaCount) {
      return (
        <div
          data-testid="feed-individual-media-view"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            position: "relative"
          }}
        >
          <img src={props.src} style={props.style} />
          <div
            onClick={props.onClick}
            style={{
              display: "flex",
              position: "absolute",
              transform: "translate(-50%, -50%)",
              color: "#fff",
              fontSize: "24px",
              backgroundColor: "#000",
              fontWeight: "bold",
              width: "100%",
              height: "100%",
              opacity: "0.5",
              top: "50%",
              left: "50%",
              textAlign: "center"
            }}
          >
            <div style={{ margin: "auto" }}>+{props.remainingMediaCount}</div>
          </div>
        </div>
      );
    } else {
      return (
        <img
          src={props.src}
          onClick={props.onClick}
          style={props.style}
          data-testid="feed-individual-media-view"
        />
      );
    }
  }

  if (
    props.src.split(".").pop() === "mp4" ||
    props.src.split(".").pop() === "m3u8"
  ) {
    return (
      <StyledBox style={props.style}>
        <StyledReactPlayer
          onClick={props.onClick}
          height={props.style.height}
          width={props.style.width || "100%"}
          url={props.src}
          style={props.style}
        />
        <StyledPlayIcon fontSize="large" />
      </StyledBox>
    );
  }

  if (props.mimeType == "application/pdf") {
    return <FeedPDFViewer src={props.pdf} />;
  }
};

export default memo(FeedIndividualMedia);
