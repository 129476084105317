import Grid from "@mui/material/Unstable_Grid2";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import MediaViewer from "@components/MediaViewer";
import { ModelMedia } from "@sportsgravyengineering/sg-api-react-sdk";
let localMediaIds = [] as string[];
export const MediaSelector = (props: {
  uploadedFiles: Array<ModelMedia>;
  imagePlaceHolder: string;
  resetMedia?: boolean;
  maxImages?: number;
  disabled?: boolean;
  onlyImages?: boolean;
  setFilesToUpload?: (files: (File | ModelMedia)[]) => void;
  removeFiles?: (files: (File | ModelMedia)[]) => void;
  onAddFiles?: (files: (File | ModelMedia)[]) => void;
  filesStored?: (ModelMedia | File)[];
}) => {
  const [files, setFiles] = useState<(File | ModelMedia)[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!(props.uploadedFiles && props.uploadedFiles.length > 0))
      localMediaIds = [];
  }, []);

  useEffect(() => {
    if (!(props.uploadedFiles && props.uploadedFiles.length > 0)) {
      setFiles([]);
      localMediaIds = [];
      if (inputFileRef.current) {
        inputFileRef.current.value = "";
      }
    }
  }, [props.resetMedia]);

  useEffect(() => {
    if (props.uploadedFiles.length > 0) {
      localMediaIds = [];
      setFiles(props.uploadedFiles);
      for (const i of props.uploadedFiles) {
        localMediaIds.push(i.mediaId as string);
      }
    }
  }, [props.uploadedFiles]);
  useEffect(() => {
    if (props.setFilesToUpload) props.setFilesToUpload(files);
  }, [files]);
  useEffect(() => {
    if (props.filesStored && props.filesStored.length > 0) {
      setFiles(props.filesStored);
    }
  }, [props.filesStored]);
  const onImageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tempFiles = (event.target as HTMLInputElement).files;
    if (tempFiles && tempFiles?.[0] !== undefined) {
      const file = tempFiles[0];
      if (props.onAddFiles) props.onAddFiles([...files, file]);
      setFiles((prevFiles) => [...prevFiles, file]);
    }
    event.target!.value = "";
  };
  return (
    <>
      {files.map((item, index) => {
        if (item) {
          return (
            <Grid
              sx={{ maxWidth: "fit-content" }}
              xs={12}
              data-testid={"MEDIA_SELECTOR_" + index}
              key={Math.random().toString()}
            >
              <MediaViewer
                file={item}
                disabled={!!props.disabled}
                onSave={(mId) => {
                  localMediaIds.push(mId);
                  if (localMediaIds.length > files.length) {
                    const duplicateLength = localMediaIds.length - files.length;
                    localMediaIds = localMediaIds.slice(duplicateLength);
                  }
                }}
                onRemove={(selectedIndex) => {
                  localMediaIds = [...localMediaIds].filter(
                    (value, subIndex) => subIndex != selectedIndex
                  );
                  if (props.removeFiles)
                    props.removeFiles(
                      files.filter(
                        (value, subIndex) => subIndex != selectedIndex
                      )
                    );
                  setFiles((prevFiles) =>
                    prevFiles.filter(
                      (value, subIndex) => subIndex != selectedIndex
                    )
                  );
                }}
                index={index}
              />
            </Grid>
          );
        } else return <></>;
      })}
      {files.length < (props.maxImages ? props.maxImages : 5) && (
        <Grid xs={12} md={2} data-testid="addMoreImages">
          <img
            src={props.imagePlaceHolder}
            style={{
              width: "190px",
              height: "190px"
            }}
            onClick={() => {
              inputFileRef.current?.click();
            }}
          />
        </Grid>
      )}
      <input
        type="file"
        disabled={!!props.disabled}
        onChange={onImageChange}
        ref={inputFileRef}
        style={{ display: "none" }}
        accept={props.onlyImages ? "image/*" : "image/*, video/*"}
      />
    </>
  );
};
