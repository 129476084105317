/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button as MuiButton,
  Typography,
  Divider,
  Popover,
  styled,
  Container
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Loader } from "@components/crud/Loader";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";
import { capitalize } from "@utils/capitalize";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { FSOFilter } from "./FSOFilter";
import { TimeFilter } from "./TimeFilter";
const StyledOption = styled("option")({
  fontSize: "13px",
  padding: "5px 10px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#E5E5E5"
  }
});

const StyledDiv = styled("div")({
  "& svg > g:first-of-type": {
    transform: "translate(-75px, 0px)"
  }
});
export const CustomPieChart = ({
  title,
  description,
  releaseId,
  platform,
  testerId,
  featureId,
  useGet,
  setFeatureBreakDown,
  chartId,
  hasFilter,
  defaultTimeFilter
}: {
  title: string;
  description: string;
  isLoading?: boolean;
  releaseId?: string;
  platform?: string;
  testerId?: string;
  featureId?: string;
  useGet: (params, options) => any;
  setFeatureBreakDown?: (value) => void;
  chartId?: string;
  hasFilter?: boolean;
  defaultTimeFilter?: string | undefined;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [chartData, setChartData] = useState<any>(undefined);
  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover3" : undefined;
  const handleClose = () => {
    setAnchorEl2(null);
  };

  const query = React.useMemo(() => {
    const buildQuery = {} as {
      releaseId?: string;
      testerId?: string;
      platform?: string;
      parentId?: string;
      dtStart?: string;
      dtEnd?: string;
      keys?: any;
      organizationId?: string;
    };

    if (chartId !== "") {
      buildQuery.keys = [{ key: chartId }];
    }
    if (organizationId) {
      buildQuery.organizationId = organizationId;
    }
    if (selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.keys[0].dtStart = selectedTimeRange[0];
      buildQuery.keys[0].dtEnd = selectedTimeRange[1];
    }
    return buildQuery;
  }, [
    releaseId,
    testerId,
    platform,
    featureId,
    selectedTimeRange,
    chartId,
    organizationId
  ]);
  const { isFetching, data } = useGet(
    {
      keys: JSON.stringify(query.keys),
      ...(query.organizationId && { organizationId: query.organizationId })
    },
    {
      query: {
        queryKey: [
          releaseId,
          testerId,
          platform,
          featureId,
          refreshKey,
          chartId,
          organizationId
        ],
        refetchOnWindowFocus: false
      }
    }
  );

  const generateDataset = (data) => {
    if (!data) {
      setNoDataFound(true);
      return [];
    }
    const dataset = Object.entries(data).flatMap(([, valueObj]) =>
      Object.entries(valueObj).map(([label, value]) => ({
        label: capitalize(label),
        value
      }))
    );
    return dataset;
  };

  const getTotalValue = (items) => {
    return items.reduce((total, item) => total + item.value, 0);
  };

  useEffect(() => {
    if (data) {
      if (data.featureBreakdown && setFeatureBreakDown)
        setFeatureBreakDown(data.featureBreakdown);

      if (hasFilter && data[0]?.data?.[0]?.filters.length > 0)
        setSelectedFilter(data[0]?.data?.[0]?.filters[0]);
      if (!hasFilter || data[0]?.data?.[0]?.filters.length === 0)
        setChartData(data[0]?.data?.[0]?.value);
      else
        setChartData(
          data[0]?.data?.[0]?.filterValues[
            selectedFilter ? selectedFilter : data[0]?.data?.[0]?.filters[0]
          ]
        );
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedFilter != "" &&
      data?.[0]?.data?.[0]?.filterValues[selectedFilter]
    )
      setChartData(data?.[0]?.data?.[0]?.filterValues[selectedFilter]);
  }, [selectedFilter]);

  window.addEventListener("scroll", handleClose);
  return (
    <Grid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "32px",
        minHeight: "300px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          {!organizationId &&
            hasFilter &&
            data[0]?.data?.[0]?.filters.length > 0 && (
              <MuiButton
                endIcon={
                  anchorEl2 ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                variant="outlined"
                style={{
                  color: "#64748B",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "none",
                  marginRight: "16px"
                }}
                onClick={(event) => {
                  setAnchorEl2(anchorEl2 ? null : event.currentTarget);
                }}
              >
                {selectedFilter}
              </MuiButton>
            )}
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            defaultTimeFilter={defaultTimeFilter}
          />
        </Grid>
      </Grid>
      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isFetching}>
        {chartData && data[0]?.data?.[0]?.value && !noDataFound && (
          <StyledDiv>
            <PieChart
              colors={["#007AFF", "#FF9F0A"]}
              margin={{ top: 15, left: 15, bottom: 15 }}
              series={[
                {
                  arcLabel: (item) =>
                    `${(
                      (item.value / getTotalValue(generateDataset(chartData))) *
                      100
                    ).toFixed(0)}%`,
                  data: generateDataset(chartData)
                }
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "#fff"
                }
              }}
              width={500}
              height={250}
              slotProps={{
                legend: {
                  padding: 90
                }
              }}
            />
            {organizationId && hasFilter && (
              <FSOFilter data={data} setChartData={setChartData} />
            )}
          </StyledDiv>
        )}
        {(!data[0]?.data?.[0]?.value || noDataFound) && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        )}
      </Loader>
      {hasFilter && (
        <Popover
          id={id2}
          open={open2}
          anchorEl={anchorEl2}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Container style={{ padding: 0 }}>
            {data[0]?.data?.[0]?.filters.map((option) => (
              <StyledOption
                onClick={(e) => {
                  //@ts-ignore
                  setSelectedFilter(e.target.value);
                  setAnchorEl2(null);
                }}
                key={option}
                value={option}
              >
                {option}
              </StyledOption>
            ))}
          </Container>
        </Popover>
      )}
    </Grid>
  );
};
