import { styled } from "@mui/material/styles";
import { Divider, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "../../components/crud/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@components/Button";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../../recoil/auth";
import { ReportPostView } from "@components/ReportPostView";
import { GridColDef } from "@mui/x-data-grid";
import { RenderTableView } from "@components/RenderTableView";
import { PostCommentAction } from "@components/PostCommentAction";
import { getReportDetail, reportAction } from "@services/Network";
import { SkeletonFeedCommentLoader } from "@components/SkeletonFeedCommentLoader";
import { profileAtom } from "@recoil/auth";
import { PostFlagActionType } from "@sportsgravyengineering/sg-api-react-sdk";
import { VerticalListTooltip } from "@components/VerticalListTooltip";
import { objectPropertyRecursive } from "@utils/objectPropertyRecursive";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300,
    color: "#1E293B",
    fontFamilt: "Inter"
  }
}));
const TableHeader = styled(Typography)(() => ({
  color: "#B3B3B3",
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Inter"
}));
const TableValue = styled(Typography)(() => ({
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  fontFamily: "Inter"
}));
const StyeldButtonGrid = styled(Grid)(() => ({
  "& Button": {
    margin: "12px"
  }
}));

export const ReportDetailView = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const userId = useRecoilValue(profileAtom)?.userId;
  const { filter, type, id } = useParams<{
    filter: string;
    type: string;
    id: string;
  }>() as { filter: string; type: string; id: string };
  const [onRemove, setOnRemove] = useState(false);
  const [removeConfirmation, setRemoveconfirmation] = useState(false);
  const [approveConfirmation, setApproveConfirmation] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [deleteExplanation, setDeleteExplanation] = useState({ message: "" });
  const [tableType] = useState({
    type: type?.charAt(0).toUpperCase() + type.slice(1),
    filter: filter?.charAt(0).toUpperCase() + filter.slice(1)
  });

  const getReport = getReportDetail(type?.slice(0, -1), id, {});
  useEffect(() => {
    if (getReport?.error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [getReport?.error]);
  //@ts-ignore
  const { data, isLoading } = getReport;

  useEffect(() => {
    if (onRemove || approveConfirmation || removeConfirmation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [onRemove, approveConfirmation, removeConfirmation]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(`/reports/${filter}/${type}`);
  };
  const handleRemoveClick = () => {
    if (type == "posts") {
      if (!organizationId) {
        setDisplayMessage(
          "Removing this post will delete it from SportsGravy. Please provide an explanation to the author of this post."
        );
      } else {
        setDisplayMessage(
          "Removing this post will delete it from the feed of users within your organization who received this content. Please provide an explanation to the original poster author."
        );
      }
    }
    if (type == "comments") {
      if (!organizationId) {
        setDisplayMessage(
          "Removing this comment will delete it from SportsGravy. Please provide an explanation to the author of this comment."
        );
      } else {
        setDisplayMessage(
          "Removing this comment will delete it from the feed of users within your organization who view this post. Please provide an explanation to the commenter."
        );
      }
    }
    setOnRemove(true);
  };
  const handleApproveClick = () => {
    setApproveConfirmation(true);
  };
  const USER_COLUMNS: GridColDef[] = [
    {
      headerName: "Date Reported",
      field: "dateReported",
      maxWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => dateFormat(row?.createdAt)
    },
    {
      headerName: "Reporter",
      field: "reporter",
      maxWidth: 200,
      flex: 1,
      valueGetter: ({ row }) =>
        row?.user?.person?.firstName + " " + row?.user?.person?.lastName
    },
    {
      headerName: "Issue Category",
      field: "issueCategory",
      maxWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => row?.reason?.parent?.name
    },
    {
      headerName: "Issue",
      field: "issue",
      minWidth: 100,
      flex: 1,
      valueGetter: ({ row }) => row?.reason?.name
    }
  ];
  const handleDeleteConfirmation = () => {
    setOnRemove(false);
    setRemoveconfirmation(true);
  };
  const handleDeleteClose = () => {
    setOnRemove(false);
  };
  function dateFormat(date) {
    const formatedDate = new Date(date);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    //@ts-ignore
    return formatedDate.toLocaleDateString("en-US", options);
  }

  const mutation = reportAction();
  const reportActionClick = (action: PostFlagActionType) => {
    mutation.mutate(
      {
        data: {
          ...(organizationId && { organizationId: organizationId }),
          postId: data.postId,
          ...(type == "comments" && { commentId: id }),
          explanation: deleteExplanation.message,
          action: action
        }
      },
      {
        onSuccess: () => {
          navigate(`/reports/${filter}/${type}`);
        }
      }
    );
  };
  return (
    <>
      {isLoading == false && (
        <Container>
          <StyledToolbar>
            <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
              <Grid
                xs={6}
                md={"auto"}
                sx={{ flexGrow: { md: "1 !important" } }}
              >
                <Typography variant="h2">{`${filter
                  ?.charAt(0)
                  .toUpperCase()}${filter?.slice(1)} ${type
                  ?.charAt(0)
                  .toUpperCase()}${type?.slice(1)} Detail`}</Typography>
              </Grid>
              <StyeldButtonGrid
                data-testid="report-button"
                xs={6}
                md={"auto"}
                container
                justifyContent={"flex-end"}
                alignItems={"center"}
                spacing={2}
                margin={"12px"}
              >
                <Button onClick={handleBackClick} variant="admin-secondary">
                  Back to List
                </Button>
                {filter === "flagged" && (
                  <Button variant="admin-success" onClick={handleApproveClick}>
                    Approve
                  </Button>
                )}
                {(filter === "approved" || filter == "flagged") && (
                  <Button variant="admin-error" onClick={handleRemoveClick}>
                    Remove
                  </Button>
                )}
              </StyeldButtonGrid>
            </Grid>
          </StyledToolbar>
          <Divider />
          <StyledToolbar sx={{ maxWidth: "fit-content" }}>
            <Container data-testid="report-post-header">
              <Grid container columnSpacing={30}>
                <Grid>
                  <TableHeader>
                    {tableType.type.slice(0, -1)} Author
                  </TableHeader>
                  {type == "posts" && (
                    <Link
                      to={`/users/${data?.createdBy.personId}`}
                      target="_blank"
                    >
                      <TableValue sx={{ color: "#3B6CF8" }}>
                        {data?.createdBy?.person?.firstName +
                          " " +
                          data?.createdBy?.person?.lastName}
                      </TableValue>
                    </Link>
                  )}
                  {type == "comments" && (
                    <Link
                      to={`/users/${data?.comments[0].user?.person?.personId}`}
                      target="_blank"
                    >
                      <TableValue sx={{ color: "#3B6CF8" }}>
                        {data?.comments[0]?.user?.person?.firstName +
                          " " +
                          data?.comments[0]?.user?.person?.lastName}
                      </TableValue>
                    </Link>
                  )}
                </Grid>
                <Grid>
                  <TableHeader>{tableType.type.slice(0, -1)} Date</TableHeader>
                  {type == "posts" && (
                    <TableValue>{dateFormat(data?.createdAt)}</TableValue>
                  )}
                  {type == "comments" && (
                    <TableValue>
                      {dateFormat(data?.comments[0]?.createdAt)}
                    </TableValue>
                  )}
                </Grid>
                {organizationId && (
                  <Grid>
                    <TableHeader>Team/Training Program</TableHeader>
                    <TableValue>
                      <VerticalListTooltip
                        text={
                          data?.teams
                            .concat(data?.programs)
                            ?.slice(0, 1)
                            ?.map((record) =>
                              objectPropertyRecursive(record, "name")
                            )
                            ?.join(" / ") || "-"
                        }
                        tooltipList={
                          data?.teams
                            .concat(data?.programs)
                            ?.slice(1)
                            ?.map((record) =>
                              objectPropertyRecursive(record, "name")
                            ) || []
                        }
                      />{" "}
                    </TableValue>
                  </Grid>
                )}
                <Grid>
                  <TableHeader>Times Flagged</TableHeader>
                  <TableValue>
                    <a
                      style={{ color: "#3B6CF8", cursor: "pointer" }}
                      href="#tableDetails"
                    >
                      {type == "posts"
                        ? data?.reports.length
                        : data?.comments[0]?.reports.length}
                    </a>
                  </TableValue>
                </Grid>
              </Grid>
            </Container>
          </StyledToolbar>
          <StyledToolbar sx={{ margin: "0 auto" }}>
            <ReportPostView userId={userId} content={data} rePost={false} />
          </StyledToolbar>
          <StyledToolbar>
            {((type == "posts" && data.reports.length > 0) ||
              (type == "comments" && data.comments[0].reports.length > 0)) && (
              <Container id="tableDetails">
                <RenderTableView
                  title=""
                  hideToolbar={true}
                  rows={
                    type == "posts" ? data?.reports : data?.comments[0]?.reports
                  }
                  columns={USER_COLUMNS}
                  getRowId={(row) => row?.reportId}
                  searchable={false}
                  hasActionColumn={false}
                  hideFooter={true}
                />
              </Container>
            )}
          </StyledToolbar>
        </Container>
      )}
      {onRemove && (
        <PostCommentAction
          onClick={handleDeleteConfirmation}
          setMessage={setDeleteExplanation}
          id={data.postId}
          displayMessage={displayMessage}
          action={"delete"}
          button={`Remove ${tableType.type.slice(0, -1)}`}
          onClose={handleDeleteClose}
          title={`Remove ${tableType.type.slice(0, -1)} ${
            !organizationId ? "from SportsGravy" : "within Organization"
          }`}
          field={[
            {
              field: `${tableType.type.slice(0, -1)} Author`,
              value:
                type === "posts"
                  ? `${data?.createdBy?.person?.firstName} ${data?.createdBy?.person?.lastName}`
                  : `${data?.comments[0]?.user?.person?.firstName} ${data?.comments[0]?.user?.person?.lastName}`
            },
            ...(organizationId
              ? [
                  {
                    field: "Organization Removed From",
                    value: `${organizations.find(
                      (org) => org.organizationId === organizationId
                    )?.name}`
                  }
                ]
              : [])
          ]}
        />
      )}
      <ConfirmationDialog
        open={removeConfirmation}
        title={`Remove ${tableType.type.slice(0, -1)}`}
        body={`Are you sure you want to remove this ${type?.slice(0, -1)} ${
          !organizationId ? "from SportsGravy" : "within your organization"
        }? This change cannot be undone.`}
        close={() => setRemoveconfirmation(false)}
        onConfirm={() => {
          setRemoveconfirmation(false);
          reportActionClick("REMOVE");
        }}
        onCancel={() => setRemoveconfirmation(false)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
      <ConfirmationDialog
        title={`Are you sure you want to Approve this ${type?.slice(0, -1)}?`}
        body=""
        open={approveConfirmation}
        close={() => setApproveConfirmation(false)}
        onCancel={() => setApproveConfirmation(false)}
        onConfirm={() => {
          reportActionClick("APPROVE");
          navigate(`/reports/${filter}/${type}`);
        }}
      />
      {isLoading && (
        <Container alignItems="center">
          <Grid container>
            <SkeletonFeedCommentLoader type={"feed"} />
          </Grid>
        </Container>
      )}
    </>
  );
};
