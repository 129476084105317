import { Loader } from "@components/crud/Loader";
import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getGameSystems } from "@services/Network";
import {
  ModelSport,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Positions",
    field: "positions",
    minWidth: 150,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1),
    sortable: true
  },
  {
    headerName: "Skills",
    field: "skills",
    minWidth: 150,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1),
    sortable: true
  },
  {
    headerName: "Game Concepts",
    field: "gameConcepts",
    minWidth: 150,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1),
    sortable: true
  }
];

export const GameSystems = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [organizationId] = useRecoilState(organizationAtom);
  const { data: sports } = useAdminSportGet({
    organizationId: organizationId!
  });
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [permissions, setPermissions] = useState({
    edit: false,
    add: false
  });

  const filterConfig = React.useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Sports", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.map((sport: ModelSport) => ({
              label: sport.name,
              value: sport.sportId
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => navigate("/game-systems/create");
  const onEdit = (row) => navigate(`/game-systems/${row.systemId}/edit`);
  const onView = (row) => navigate(`/game-systems/${row.systemId}`);
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId as string,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      setPermissionLoading(true);
      const edit = await checkPermission("admin.game-systems", "EDIT");
      const add = await checkPermission("admin.game-systems", "ADD");
      setPermissions({
        edit,
        add
      });
      setPermissionLoading(false);
    };
    fetchPermissions();
  }, []);
  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Game Systems"
        useGet={getGameSystems}
        columns={LIST_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        {...(permissions.add && { onAdd })}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
      />
    </Loader>
  );
};
