import { Button } from "@components/Button";
import { InfoListCard } from "@components/InfoListCard";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { duplicateAccountAtom } from "@recoil/signup";
import { formatPhone } from "@utils/phoneFormatters";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const DuplicateAccountWarning = () => {
  const navigate = useNavigate();
  const duplicateAccount = useRecoilValue(duplicateAccountAtom);

  useEffect(() => {
    if (!duplicateAccount) {
      navigate("/");
    }
  }, [duplicateAccount, navigate]);

  const formatAccountToInfoList = (account) => {
    return [
      {
        label: "Name",
        value: `${account?.firstName} ${account?.lastName}`,
      },
      { label: "Email", value: account?.email },
      {
        label: "Mobile Phone",
        value: formatPhone(account?.phone) || "N/A",
      },
      { label: "Account Status", value: "Active" },
    ];
  };

  return (
    <Grid container spacing={3} justifyContent={"space-evenly"}>
      <Grid xs={12}>
        <Typography
          variant="h2"
          color="text.general.primary"
          sx={{ fontWeight: 400 }}
        >
          Duplicate Account Warning
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography variant="body1" color="text.general.secondary">
          You already have an active user account within the SportsGravy system.
          Please sign in or click forgot password if you have forgotten your
          sign in credentials.
        </Typography>
      </Grid>
      <Grid xs={12} alignContent={"space-around"}>
        <Typography variant="h4">Existing Account Details</Typography>
        <InfoListCard infoList={formatAccountToInfoList(duplicateAccount)} />
      </Grid>
      <Grid xs={12} md={6}>
        <Button
          variant="secondary"
          type="button"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password
        </Button>
      </Grid>
      <Grid xs={12} md={6}>
        <Button variant="primary" type="button" onClick={() => navigate("/")}>
          Sign In
        </Button>
      </Grid>
    </Grid>
  );
};
