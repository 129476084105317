import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import { Tabs, styled, Grid, Typography, Tab } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { useEffect, useState } from "react";
import { TechnologyDashboard } from "./Technology/TechnologyDashboard";
import { FSODashboard } from "./FSODashboard";
import { SGAppDashboard } from "./SGApp/SGAppDashboard";
import { hasPermission } from "@services/Casbin";
import { NoPermission } from "./Technology/ProductEngineering/components/NoPermission";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  top: 2,
  paddingBottom: 1,
  zIndex: 1,
  overflow: "visible",
  paddingLeft: 24,

  "& .MuiTabs-fixed.MuiTabs-scroller": {
    position: "static"
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.white.main,
    height: "1px",
    marginBottom: "-1px",
    zIndex: 1,
    transition: "none"
  },

  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 20px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "1px solid transparent",
      borderRadius: "12px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0"
    }
  }
}));

export const Dashboard = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [tabs, setTabs] = useState<string[]>([]);

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  useEffect(() => {
    const dashboardTabs: string[] = [];
    const fetchPermissions = async () => {
      if (!organizationId) {
        const checkPermission = async (permissionId, permission) => {
          const res = await hasPermission(
            "ORGANIZATION",
            organizationId!,
            permissionId as string,
            permission as string
          );
          return res;
        };
        if (await checkPermission("general.dashboard-sg", "VIEW")) {
          dashboardTabs.push("SG App");
        }
        if (await checkPermission("general.dashboard-finance", "VIEW")) {
          dashboardTabs.push("Finance");
        }
        if (await checkPermission("general.dashboard-marketing", "VIEW")) {
          dashboardTabs.push("Marketing");
        }
        if (await checkPermission("general.dashboard-sales", "VIEW")) {
          dashboardTabs.push("Sales");
        }
        if (await checkPermission("general.dashboard-csm", "VIEW")) {
          dashboardTabs.push("Customer Success");
        }
        if (await checkPermission("general.dashboard-tech", "VIEW")) {
          dashboardTabs.push("Technology");
        }
        setTabs(dashboardTabs);
        setActiveTab(dashboardTabs[0]);
      }
    };

    fetchPermissions();
  }, [organizationId]);

  return (
    <Container>
      <Toolbar title="Dashboard" />
      {!organizationId && tabs.length > 0 && (
        <Grid container direction="column" spacing="25px">
          <Grid
            item
            width="100%"
            style={{
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <StyledTabs
              onChange={(e, value) => {
                setActiveTab(value);
              }}
              value={activeTab}
            >
              {!organizationId &&
                tabs.map((tab) => {
                  return (
                    <Tab
                      key={tab}
                      label={
                        <Grid
                          container
                          sx={{
                            flex: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "15px"
                          }}
                        >
                          <Typography variant="tableHeader">{tab}</Typography>
                        </Grid>
                      }
                      value={tab}
                    />
                  );
                })}
            </StyledTabs>
          </Grid>
          {activeTab === "SG App" && (
            <div style={{ marginLeft: "25px" }}>
              <SGAppDashboard />
            </div>
          )}
          {activeTab === "Technology" && (
            <div style={{ marginLeft: "25px" }}>
              <TechnologyDashboard />
            </div>
          )}
        </Grid>
      )}
      {organizationId && <FSODashboard />}
      {!organizationId && tabs.length === 0 && <NoPermission />}
    </Container>
  );
};
