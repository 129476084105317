import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  useAdminDirectoryPersonIdGet,
  useAdminDepartmentGet,
  useAdminJobTitleGet,
  useLookupCountryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { FormTimePicker } from "@components/FormTimePicker";
import { convertTimeToDate } from "@utils/convertStringAndTime";

export const ResourceView = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset, control } = form;

  const { data: data, isLoading: isLoading } = useAdminDirectoryPersonIdGet(
    userId!
  );

  const [country, setCountry] = React.useState<string>();
  const [showRadiusField, setShowRadiusField] = React.useState(false);
  React.useEffect(() => {
    if (data?.data) {
      const person = data.data;
      setCountry(person.addressPrimary?.country);
      reset({
        firstName: person.firstName,
        middleName: person.middleName,
        lastName: person.lastName,
        suffix: person.suffix,
        jobTitleId: person.sportsgravyUser?.jobTitleId,
        departmentId: person.sportsgravyUser?.departmentId,

        startTime: person.sportsgravyUser?.startTime
          ? convertTimeToDate(person.sportsgravyUser.startTime)
          : "",
        endTime: person.sportsgravyUser?.endTime
          ? convertTimeToDate(person.sportsgravyUser.endTime)
          : "",
        timeZone: person.sportsgravyUser?.timeZone,
        radius: person.sportsgravyUser?.jobTitle?.maxAccountsDemo || 0,
        workEmail: person.sportsgravyUser?.workEmail || "",
        email: person.email,
        mobilePhone: person.phone,
        sgNumber: person.sportsgravyUser?.sgNumber,
        skypeId: person.sportsgravyUser?.skypeId,
        whatsappNumber: person.sportsgravyUser?.whatsappNumber,
        country: person.addressPrimary?.country,
        city: person.addressPrimary?.locality
      });
      setShowRadiusField(
        person.sportsgravyUser?.department?.name?.toUpperCase() === "SALES"
      );
    }
  }, [data]);

  const suffixOptions = [
    { value: "", label: "" },
    { value: "Jr", label: "Jr" },
    { value: "Sr", label: "Sr" },
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" }
  ];

  const [jobTitles, setJobTitles] = React.useState<
    { label: string; value: string }[]
  >([]);

  const { data: jobTitlesData, isLoading: isLoadingJobTitles } =
    useAdminJobTitleGet();

  React.useEffect(() => {
    if (jobTitlesData?.data) {
      setJobTitles(
        jobTitlesData.data.jobTitles?.map((jt) => {
          return {
            label: jt.name!,
            value: jt.jobtitleId!
          };
        }) || []
      );
    }
  }, [jobTitlesData]);

  const [departments, setDepartments] = React.useState<
    { label: string; value: string }[]
  >([]);

  const { data: departmentsData, isLoading: isLoadingDepatrtments } =
    useAdminDepartmentGet();

  React.useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(
        departmentsData.data.departments?.map((dep) => {
          return {
            label: dep.name!,
            value: dep.departmentId!
          };
        }) || []
      );
    }
  }, [departmentsData]);

  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });

  const { data: countriesResponse, isFetching: isFetchingCountries } =
    useLookupCountryGet({
      query: {
        staleTime: Infinity
      }
    });
  const countries = React.useMemo(() => {
    return countriesResponse?.data.map((country) => {
      return {
        label: country.name as string,
        value: country.countryId as string
      };
    });
  }, [countriesResponse]);
  return (
    <Container>
      <Toolbar
        title="View Resource"
        backBtnClick={() => {
          navigate("/directory");
        }}
      />
      <Loader isLoading={isLoading || isFetchingCountries}>
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={3} data-testid="directory-firstName">
                <FormInput
                  name="firstName"
                  control={control}
                  rules={{ required: "First Name is required" }}
                  label="First Name"
                  type="text"
                  required={true}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <FormInput
                  name="middleName"
                  control={control}
                  label="Middle Name"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={3} data-testid="directory-lastName">
                <FormInput
                  name="lastName"
                  control={control}
                  rules={{ required: "Last Name is required" }}
                  label="Last Name"
                  type="text"
                  required={true}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <FormSelect
                  name="suffix"
                  control={control}
                  label="Suffix"
                  options={suffixOptions}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px"
                }}
              >
                ADDRESS DETAILS
              </Typography>
              <HeaderUnderLine />
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6}>
                <FormSelect
                  name="country"
                  control={control}
                  label="Country"
                  options={countries || []}
                  isLoading={isFetchingCountries}
                  required
                  rules={{
                    required: "Job Title is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  name="city"
                  control={control}
                  label="City"
                  type="text"
                  required
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px"
                }}
              >
                CONTACT DETAILS
              </Typography>
              <HeaderUnderLine />
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="directory-work-email">
                <FormInput
                  name="workEmail"
                  control={control}
                  rules={{ required: "Work email is required" }}
                  label="Work Email"
                  type="email"
                  required={true}
                  disabled
                />
              </Grid>

              <Grid item xs={6} data-testid="directory-email">
                <FormInput
                  name="email"
                  control={control}
                  label="Personal Email"
                  type="email"
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="directory-mobilePhone">
                <FormInput
                  name="mobilePhone"
                  type="tel"
                  country={countriesResponse?.data?.find(
                    (cou) => cou.countryId === country
                  )}
                  required
                  label="Mobile Phone"
                  control={control}
                  rules={{
                    required: "Mobile Phone is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} data-testid="directory-mobilePhone">
                <FormInput
                  name="sgNumber"
                  type="tel"
                  country={countriesResponse?.data?.find(
                    (cou) => cou.countryId === country
                  )}
                  label="SportsGravy Number"
                  control={control}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="directory-skypeId">
                <FormInput
                  name="skypeId"
                  control={control}
                  label="Skype ID"
                  type="text"
                  disabled
                />
              </Grid>
              <Grid item xs={6} data-testid="directory-whatsappNumber">
                <FormInput
                  name="whatsappNumber"
                  type="tel"
                  country={countriesResponse?.data?.find(
                    (cou) => cou.countryId === country
                  )}
                  label="Whatsapp Number"
                  control={control}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="permissionNames"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  letterSpacing: "10%",
                  lineHeight: "14.52px"
                }}
              >
                JOB DETAILS
              </Typography>
              <HeaderUnderLine />
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid
                item
                xs={showRadiusField ? 4 : 6}
                data-testid="directory-department"
              >
                <FormSelect
                  name="departmentId"
                  control={control}
                  label="Department"
                  options={departments}
                  isLoading={isLoadingDepatrtments}
                  required
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={showRadiusField ? 4 : 6}
                data-testid="directory-jobTitle"
              >
                <FormSelect
                  name="jobTitleId"
                  control={control}
                  label="Job"
                  options={jobTitles}
                  isLoading={isLoadingJobTitles}
                  required
                  rules={{
                    required: "Job is required"
                  }}
                  disabled
                />
              </Grid>
              {showRadiusField && (
                <Grid item xs={4}>
                  <FormInput
                    disabled
                    control={control}
                    label="Radius"
                    required
                    type="text"
                    name="radius"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {country === "US" ? "Miles" : "Km"}
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={3} data-testid="directory-startTime">
                <FormTimePicker
                  name="startTime"
                  control={control}
                  label="Start Time"
                  required
                  rules={{
                    required: "Start Time is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={3} data-testid="directory-endTime">
                <FormTimePicker
                  name="endTime"
                  control={control}
                  label="End Time"
                  required
                  rules={{
                    required: "End Time is required"
                  }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} data-testid="directory-timeZone">
                <FormSelect
                  control={control}
                  required
                  name="timeZone"
                  label="Time Zone"
                  disabled
                  options={timeZoneOptions}
                  rules={{
                    required: "Time Zone is required"
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Loader>
    </Container>
  );
};
