import { Avatar, Grid, styled } from "@mui/material";
import { Box } from "@mui/system";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import TeamIcon from "../../../../src/assets/icons/teamIcon.svg";
import personGroupIcon from "../../../../src/assets/icons/personGroupIcon.svg";
const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main
}));

const StyledPersonOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));
type ConnectionGRoup = {
  id: string;
  name: string;
  description: string;
  orgOwned: string;
  kind?: string;
};
export const CalendarConnectionGroupSearch = ({
  connectionGroup,
  onClick,
  ...props
}: {
  connectionGroup: ConnectionGRoup | null;
  onClick: () => void;
}) => {
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((org) => org.organizationId === orgId);

  let thumbnailContent;
  if (org) {
    if (
      (connectionGroup?.kind === "Team" ||
        connectionGroup?.kind === "TrainingProgram") &&
      !connectionGroup?.orgOwned
    ) {
      thumbnailContent = (
        <Avatar
          variant="square"
          style={{ width: "32px", height: "32px" }}
          src={TeamIcon}
          alt="Team Icon"
        />
      );
    } else if (
      connectionGroup?.kind === "ConnectionGroup" &&
      !connectionGroup?.orgOwned
    ) {
      thumbnailContent = (
        <Avatar
          variant="square"
          style={{ width: "32px", height: "32px" }}
          src={personGroupIcon}
          alt="Person Group Icon"
        />
      );
    } else if (org.avatarId) {
      thumbnailContent = (
        <Avatar
          variant="square"
          style={{ width: "32px", height: "32px" }}
          src={org.avatar?.baseUrl + org.avatar?.path}
          alt="Org Avatar"
        />
      );
    } else {
      if (connectionGroup?.kind === "ConnectionGroup") {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px" }}
            src={personGroupIcon}
            alt="Org Avatar"
          />
        );
      }
      if (
        connectionGroup?.kind === "Team" ||
        connectionGroup?.kind === "TrainingProgram"
      ) {
        thumbnailContent = (
          <Avatar
            variant="square"
            style={{ width: "32px", height: "32px" }}
            src={TeamIcon}
            alt="Org Avatar"
          />
        );
      }
    }
  }
  return (
    <StyledPersonOption
      container
      {...props}
      direction="row"
      alignItems="center"
      onClick={onClick}
    >
      <Grid item xs={1}>
        <ProfileThumbnail>{thumbnailContent}</ProfileThumbnail>
      </Grid>
      <Grid item xs={11}>
        <span>{connectionGroup?.name}</span>
        <br />
        <span style={{ color: "#64748B" }}>{connectionGroup?.description}</span>
      </Grid>
    </StyledPersonOption>
  );
};
