import { Container } from "@components/crud/Container";
import { Grid, styled, Tabs, Tab, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import React, { useEffect, useState } from "react";
import {
  ModelConfig,
  ModelSetting,
  useAdminSettingsGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { Metric } from "./Metric/Metric";
import { hasPermission } from "@services/Casbin";
import { NoPermission } from "./Technology/ProductEngineering/components/NoPermission";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  top: 2,
  paddingBottom: 1,
  zIndex: 1,
  overflow: "visible",
  paddingLeft: 24,

  "& .MuiTabs-fixed.MuiTabs-scroller": {
    position: "static"
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.white.main,
    height: "1px",
    marginBottom: "-1px",
    zIndex: 1,
    transition: "none"
  },

  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 20px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "1px solid transparent",
      borderRadius: "12px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0"
    }
  }
}));

export const FSODashboard = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
  const [maxSelections, setMaxSelections] = useState(5);
  const [tabs, setTabs] = useState<string[]>([]);

  const { data: settings, isLoading: isSettingLoading } = useAdminSettingsGet({
    parentId: "org-dashboard",
    organizationId: organizationId!
  });

  const { data: config, isLoading: isConfigLoading } = useConfigGet();

  useEffect(() => {
    if (config?.data) {
      const maxSelection = (config.data as ModelConfig[]).find(
        (c) => c.key === "dashboard.reporting-widgets-count"
      );
      if (maxSelection) {
        setMaxSelections(parseInt(maxSelection.value as string));
      }
    }
  }, [config]);

  useEffect(() => {
    const dashboardTabs: string[] = [];
    const fetchPermissions = async () => {
      if (organizationId) {
        const checkPermission = async (permissionId, permission) => {
          const res = await hasPermission(
            "ORGANIZATION",
            organizationId!,
            permissionId as string,
            permission as string
          );
          return res;
        };
        if (await checkPermission("general.dashboard-org", "VIEW")) {
          dashboardTabs.push("Organizations");
        }
        if (await checkPermission("general.dashboard-users", "VIEW")) {
          dashboardTabs.push("Users");
        }
        if (await checkPermission("general.dashboard-feed", "VIEW")) {
          dashboardTabs.push("Feed");
        }
        if (await checkPermission("general.dashboard-live-stream", "VIEW")) {
          dashboardTabs.push("Live Streams");
        }
        if (await checkPermission("general.dashboard-media", "VIEW")) {
          dashboardTabs.push("Media");
        }
        setTabs(dashboardTabs);
        setActiveTab(dashboardTabs[0]);
      }
    };

    fetchPermissions();
  }, [organizationId]);

  return (
    <Container>
      {tabs.length > 0 && (
        <Grid container direction="column" spacing="15px">
          <Grid
            item
            width="100%"
            style={{
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <StyledTabs
              onChange={(e, value) => {
                setActiveTab(value);
              }}
              value={activeTab}
            >
              {organizationId &&
                tabs.map((tab) => {
                  return (
                    <Tab
                      key={tab}
                      label={
                        <Grid
                          container
                          sx={{
                            flex: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "15px"
                          }}
                        >
                          <Typography variant="tableHeader">{tab}</Typography>
                        </Grid>
                      }
                      value={tab}
                    />
                  );
                })}
            </StyledTabs>
          </Grid>
          <Loader isLoading={isSettingLoading || isConfigLoading}>
            {activeTab === "Organizations" && (
              <div style={{ marginLeft: "15px" }}>
                <Metric
                  chartKey="organization."
                  config={settings?.data as ModelSetting[]}
                  maxSelections={maxSelections}
                />
              </div>
            )}
            {activeTab === "Users" && (
              <div style={{ marginLeft: "15px" }}>
                <Metric
                  chartKey="organization-users"
                  config={settings?.data as ModelSetting[]}
                  maxSelections={maxSelections}
                />
              </div>
            )}
            {activeTab === "Feed" && (
              <div style={{ marginLeft: "15px" }}>
                <Metric
                  chartKey="organization-feed"
                  config={settings?.data as ModelSetting[]}
                  maxSelections={maxSelections}
                />
              </div>
            )}
            {activeTab === "Live Streams" && (
              <div style={{ marginLeft: "15px" }}>
                <Metric
                  chartKey="organization-live-streams"
                  config={settings?.data as ModelSetting[]}
                  maxSelections={maxSelections}
                />
              </div>
            )}
            {activeTab === "Media" && (
              <div style={{ marginLeft: "15px" }}>
                <Metric
                  chartKey="organization-media"
                  config={settings?.data as ModelSetting[]}
                  maxSelections={maxSelections}
                />
              </div>
            )}
          </Loader>
        </Grid>
      )}
      {tabs.length == 0 && <NoPermission />}
    </Container>
  );
};
