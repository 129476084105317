/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from "@components/crud/Loader";
import {
  Column,
  ColumnGrouping,
  DataGridTable
} from "@components/DataGridTable";
import { Grid, Typography, Divider, styled } from "@mui/material";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import {
  adminMetricPneSprintBreakdownGet,
  ModelJiraIssue
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import NoDataIcon from "@assets/icons/no-data-icon.svg";

const StyledBoxValue = styled(Typography)`
  color: #1e293b;
  font-weight: 600;
  font-size: 2vw;
  line-height: 48px;

  @media (min-width: 1300px) {
    font-size: 32px;
  }
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

const dateFormat = (iosDate: string): string => {
  const date = new Date(iosDate);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${month} ${day}, ${year}'`;
};

const convertToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  if (hours === 0 && minutes === 0) {
    return "";
  }
  const formattedHours = hours > 0 ? `${hours}h` : "";
  const formattedMinutes = minutes > 0 ? ` ${minutes}m` : "";
  const formattedTime = `${formattedHours}${formattedMinutes}`;
  return formattedTime.trim();
};

export const SprintCardBreakdown = ({ resourceId }: { resourceId: string }) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [timeFilter, setTimeFilter] = useState<
    "TODAY" | "YESTERDAY" | "CURRENT" | "PREVIOUS"
  >("TODAY");
  const [refreshKey, setRefreshKey] = useState(0);

  const query = useMemo(() => {
    const buildQuery = {} as {
      accountId: string;
      timeFilter: "TODAY" | "YESTERDAY" | "CURRENT" | "PREVIOUS";
    };

    if (resourceId) {
      buildQuery.accountId = resourceId;
    }

    buildQuery.timeFilter = timeFilter;

    return buildQuery;
  }, [resourceId, timeFilter]);

  const { data: scb, isLoading: isLoading } = useQuery(
    ["adminMetricPneFeatureCostOverview", resourceId, timeFilter],
    () => adminMetricPneSprintBreakdownGet(query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60,
      refetchOnWindowFocus: true
    }
  );

  const COLUMNS: Column[] = [
    {
      field: "resource",
      headerName: "Resource",
      width: 190,
      sortable: false,
      align: "left",
      renderCell: () => {
        if (
          !scb?.data?.user?.person?.firstName ||
          !scb?.data?.user?.person?.lastName ||
          !scb?.data ||
          !scb?.data?.user
        )
          return <Typography>-</Typography>;
        return (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#000"
            }}
          >
            {scb?.data?.user?.person?.firstName}{" "}
            {scb?.data?.user?.person?.lastName}
          </Typography>
        );
      }
    },
    {
      field: "key",
      headerName: "Card #",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        return (
          <StyledLink
            href={`https://sportsgravy.atlassian.net/browse/${params.key}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.key}
          </StyledLink>
        );
      }
    },
    {
      headerName: "Est hrs",
      field: "estimate",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.originalEstimate) return <Typography>-</Typography>;
        return (
          <Typography>{convertToTime(params.originalEstimate)}</Typography>
        );
      }
    },
    {
      headerName: "Act hrs",
      field: "actual",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        if (!params.timeSpent) return <Typography>-</Typography>;
        return <Typography>{convertToTime(params.timeSpent)}</Typography>;
      }
    },
    {
      headerName: "Over/Under %",
      field: "overUnder",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        const { originalEstimate, timeSpent } = params;
        if (!originalEstimate || !timeSpent) return <Typography>0%</Typography>;

        const overtime = timeSpent - originalEstimate;
        const percentage = Math.abs(
          Math.round((overtime / originalEstimate) * 100)
        );
        if (overtime > 0)
          return (
            <Typography color="#E82C2C" fontWeight={600}>
              {percentage}%
            </Typography>
          );
        if (overtime < 0)
          return (
            <Typography color="#1ABC9C" fontWeight={600}>
              -{percentage}%
            </Typography>
          );
        if (overtime === 0) return <Typography>0%</Typography>;

        return <Typography>{percentage}</Typography>;
      }
    },
    {
      headerName: "Review to In Progress",
      field: "reviewToProgress",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #E5E5E5",
      renderCell: (params) => {
        const { reviewIterations } = params;
        return (
          <Typography
            color={reviewIterations > 0 ? "#E82C2C" : "#000"}
            fontWeight={reviewIterations > 0 ? "600" : "500"}
          >
            {reviewIterations}
          </Typography>
        );
      }
    },
    {
      headerName: "QA to In Progress",
      field: "qaToProgress",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        const { qaIterations } = params;
        return (
          <Typography
            color={qaIterations > 0 ? "#E82C2C" : "#000"}
            fontWeight={qaIterations > 0 ? "600" : "500"}
          >
            {qaIterations}
          </Typography>
        );
      }
    }
  ];

  const COLUMN_GROUPING: ColumnGrouping[] = [
    {
      label: "Resource",
      width: 190,
      columns: [],
      align: "left"
    },
    {
      label: "Card #",
      columns: [],
      border: "1px solid #E5E5E5",
      width: 100
    },
    {
      label: "Est hrs",
      columns: [],
      width: 100,
      border: "1px solid #E5E5E5"
    },
    {
      label: "Act hrs",
      columns: [],
      width: 100,
      border: "1px solid #E5E5E5"
    },
    {
      label: "Over/Under %",
      columns: [],
      width: 100,
      border: "1px solid #E5E5E5"
    },
    {
      label: "Cycles",
      border: "1px solid #E5E5E5",
      columns: ["reviewToProgress", "qaToProgress"]
    }
  ];
  return (
    <Grid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "1px",
        width: "calc(100vw - 340px)",
        minHeight: isLoading || !scb || !scb?.data ? "400px" : "0px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Sprint Card Breakdown
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          <TimeFilter
            setSelectedTimeRange={setSelectedTimeRange}
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeFilter={setTimeFilter}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            isQaMetric={false}
            defaultTimeFilter="TODAY"
            customTimeFilterOptions={[
              { label: "Today", value: "TODAY" },
              { label: "Yesterday", value: "YESTERDAY" },
              { label: "This Sprint", value: "CURRENT" },
              { label: "Last Sprint", value: "PREVIOUS" }
            ]}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isLoading}>
        {(!scb || !scb?.data) && !isLoading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <Grid container direction="row" item>
            {scb &&
              scb.data.insights &&
              (
                scb.data.insights as Array<{
                  title: string;
                  value: string;
                  type?: string;
                }>
              ).map((con, index) => {
                return (
                  <Grid
                    item
                    container
                    direction="column"
                    key={con.title}
                    xs={index < 4 ? 3 : 2}
                    minHeight="121px"
                    padding="24px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: "1px solid rgba(229, 229, 229, 1)"
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          color: "#64748B",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px"
                        }}
                      >
                        {con.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {(con.type === "text" || !con.type) && (
                        <StyledBoxValue>{con.value}</StyledBoxValue>
                      )}
                      {con.type === "date" && (
                        <StyledBoxValue>{dateFormat(con.value)}</StyledBoxValue>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}
        {scb &&
        scb?.data &&
        scb?.data.issueResponse &&
        (scb?.data.issueResponse as Array<ModelJiraIssue>).length > 0 ? (
          <DataGridTable
            rows={scb?.data.issueResponse as Array<any>}
            columns={COLUMNS}
            columnGrouping={COLUMN_GROUPING}
            totalValues={[]}
          />
        ) : (
          <></>
        )}
      </Loader>
    </Grid>
  );
};
