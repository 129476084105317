import { Loader } from "@components/crud/Loader";
import { Box, Grid, styled } from "@mui/material";
import {
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { FormInput } from "@components/FormInput";
import { hasPermission } from "@services/Casbin";

const StyledSwitch = styled(Box)`
  display: flex;
  height: 50px;
  width: 100px;
  margin-top: 27px;
  margin-left: 8px;
  border-radius: 6px;
  padding: 4px;
  background: #f5f5f5;
  box-shadow: 0px 1px 2px 0px #0000001a;
  button {
    width: 50%;
    height: 98%;
    padding: 8px;
    border-radius: 4px;
    background: #f5f5f5;
    border: none;
    cursor: pointer;
  }
  button.selected {
    box-shadow: 0px 0px 4px 0px #0000001f;
    background: #ffffff;
  }
`;

export const FeedSetting = () => {
  const { reset, control, getValues } = useForm({
    mode: "onBlur"
  });
  const [selected, setSelected] = useState("Hr");
  const { data: settings, isLoading: isLoading } = useConfigGet();
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (settings && settings.data) {
      reset({
        "ad-frequency": settings.data.find(
          (item) => item.key === "feed.ad-frequency"
        )?.value,
        "ad-edit-time": (() => {
          const value = settings.data.find(
            (item) => item.key === "feed.edit-time-limit"
          )?.value as string;
          if (value.toString().includes(":")) {
            const parts = value.includes(":") && value.split(":");
            return parts[0] !== "00" ? parts[0] : parts[1];
          } else {
            return value;
          }
        })()
      });
    }
    const editTimeLimit = settings?.data.find(
      (item) => item.key === "feed.edit-time-limit"
    )?.value as string;

    if (editTimeLimit && editTimeLimit.toString().includes(":")) {
      const timePart = editTimeLimit.split(":")[1];
      setSelected(timePart !== "00" ? "Min" : "Hr");
    } else {
      setSelected("Hr");
    }
  }, [settings]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  const { mutate: save } = useConfigPut();
  const onSave = (key: string, value) => {
    if (settings?.data) {
      save(
        {
          data: [{ key, value }]
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Saved Successfully!", {
              variant: "success"
            });
          },
          onError: () => {
            enqueueSnackbar("Failed to save !", {
              variant: "error"
            });
          }
        }
      );
    }
  };
  return (
    <Grid item container direction="column">
      <Loader isLoading={isLoading}>
        <Grid xs={6} data-testid="feed-ad-frequency">
          <FormInput
            disabled={disabled}
            control={control}
            label="Feed Post Ads Frequency"
            name="ad-frequency"
            type="number"
            onBlur={() =>
              onSave("feed.ad-frequency", getValues("ad-frequency"))
            }
          />
        </Grid>
        <Grid
          xs={6}
          sx={{ marginTop: "24px", display: "flex", alignItems: "center" }}
          data-testid="feed-ad-edit-time"
        >
          <FormInput
            disabled={disabled}
            control={control}
            label="Full Edit Post Time Frame"
            name="ad-edit-time"
            type="number"
            onBlur={() =>
              onSave(
                "feed.edit-time-limit",
                selected === "Hr"
                  ? `${getValues("ad-edit-time")}:00`
                  : `00:${getValues("ad-edit-time")}`
              )
            }
          />
          <StyledSwitch>
            <button
              disabled={disabled}
              className={`option ${selected === "Hr" ? "selected" : ""}`}
              onClick={() => {
                setSelected("Hr");
                onSave(
                  "feed.edit-time-limit",
                  `${getValues("ad-edit-time")}:00`
                );
              }}
            >
              Hr
            </button>
            <button
              disabled={disabled}
              className={`option ${selected === "Min" ? "selected" : ""}`}
              onClick={() => {
                setSelected("Min");
                onSave(
                  "feed.edit-time-limit",
                  `00:${getValues("ad-edit-time")}`
                );
              }}
            >
              Min
            </button>
          </StyledSwitch>
        </Grid>
      </Loader>
    </Grid>
  );
};
