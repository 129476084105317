import { TableView } from "@components/TableView";
import { getFaqs } from "@services/Network";
import {
  ModelHelpCategory,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { DISPLAY_CHANNEL_OPTIONS, RELATED_TO_OPTIONS } from "@utils/constants";
import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Question",
    field: "question",
    minWidth: 150,
    flex: 1
  },
  {
    headerName: "Answer",
    field: "answer",
    minWidth: 250,
    flex: 1,
    height: 10
  },
  {
    headerName: "Category",
    field: "category",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.helpCategory.name
  },
  {
    headerName: "Related To",
    field: "relatedTo",
    minWidth: 150,
    flex: 1,
    valueFormatter: ({ value }) =>
      RELATED_TO_OPTIONS.find((o) => o.value === value)?.label
  },
  {
    headerName: "Display Channel",
    field: "displayChannel",
    minWidth: 200,
    flex: 1,
    valueFormatter: (row) => {
      return row.value
        .map((val) => {
          const option = DISPLAY_CHANNEL_OPTIONS.find(
            (opt) => opt.value === val
          );
          return option ? option.label : val;
        })
        .join(", ");
    }
  }
];

export const Faq = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const onAdd = () => navigate("/faqs/create");
  const onEdit = (row) => navigate(`/faqs/${row.faqId}/edit`);
  const onView = (row) => navigate(`/faqs/${row.faqId}`);

  const { data: categories } = useAdminHelpCategoryGet();
  const filterConfig = React.useMemo(() => {
    const options =
      categories?.data?.helpCategory?.map((category: ModelHelpCategory) => ({
        label: category.name as string,
        value: category.helpCategoryId as string
      })) || [];

    return {
      field: "category",
      placeholderOption: { label: "All", value: "" },
      options: options
    };
  }, [categories]);

  return (
    <>
      <TableView
        title="FAQ’s"
        useGet={getFaqs}
        columns={LIST_COLUMNS}
        getRowId={(row) => row.faqId}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        filterConfig={filterConfig}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        sortingMode="client"
      />
    </>
  );
};
