export const isHLSVideo = (value: string) => {
  const splittedArray = value.split(".");
  if (
    splittedArray.length &&
    splittedArray[splittedArray.length - 1] === "m3u8"
  ) {
    return true;
  }
  return false;
};
