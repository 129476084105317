import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const TermsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  inset: 0;
  overflow-y: auto;
  height: 16rem;
`;

export const TermsCopy = () => (
  <TermsContainer>
    <div>
      <p className=" mt-1 text-dark">
        <i>Date Last Modified</i> : <u> March 1, 2020</u>
      </p>
      <p className="card-text">
        The www.SportsGravy.com website (the “<b>Website</b>”), the
        SportsGravy™ mobile application (the “<b>App</b>”), and the related
        online and mobile services (collectively with the Website and the App,
        the “<b>Services</b>”) are made available by Sports Gravy, LLC, a
        Minnesota limited liability company (“<b>SportsGravy</b>”), subject to
        these Terms of Use (“<b>Terms of Use</b>”).
      </p>
      <p className="card-text">
        By accessing and/or using the Services, or by clicking “Accept” while
        registering for an account through the Services (“<b>Account</b>”), You
        agree to be bound by these Terms of Use, as they may be amended from
        time to time, and You further agree that SportsGravy may collect,
        process, transfer, use and disclose Your information in accordance with
        SportsGravy’s [
        <a
          href="http://www.sportsgravy.com/legal/privacy-notice"
          className="tagDecoration"
          id="tagColorPrivacyPolicy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
        ].
        <b>
          PLEASE READ THESE TERMS OF USE CAREFULLY. IF YOU DO NOT AGREE TO THESE
          TERMS OF USE, DO NOT DOWNLOAD THE APP OR CONTINUE TO ACCESS OR USE THE
          SERVICES AND DO NOT REGISTER FOR AN ACCOUNT.
        </b>
      </p>
      <p className="card-text">
        <b>
          IMPORTANT: THESE TERMS OF USE CONTAIN A MANDATORY ARBTIRATION
          PROVISION (SECTION 23) AND className ACTION WAIVER (SECTION 23) THAT
          AFFECT YOUR RIGHT TO BRING A CLAIM IN COURT, IN FRONT OF A JURY AND AS
          A MEMBER OF A ACTION.{" "}
        </b>
      </p>
      <ol className="paddingLeftTerms card-text">
        <li>
          <p className="card-text pl-2 pb-3">
            <u>
              <b className="pr-2">Compliance.</b>
            </u>
            To ensure You remain in compliance with these Terms of Use,
            SportsGravy recommends reviewing the current Terms of Use each time
            You access or use the Services. All access to and use of the
            Services, Accounts and the materials, content, information and data
            contained on or provided through the Services and Accounts are
            subject to compliance with these Terms of Use and all applicable
            laws, rules and regulations. If You have any questions or comments
            regarding these Terms of Use or the Services, please contact
            SportsGravy at the contact information listed in Section 26 below.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Eligibility.</b>
            </u>
            The Services may be downloaded, accessed or used by sports
            organizations, associations, leagues, teams and other entities (“
            <b>Organizations</b>”), coaches and managers of teams, sports
            participants (each a “<b>Player</b>”), parents or legal guardians of
            Players (each a “<b>Guardian</b>”), and visitors to the Website. If
            You are under the age of eighteen (18), You are not eligible to
            download, access, use or register for the Services or an Account
            unless You are a Player and Your Guardian has agreed to be bound by
            these Terms of Use individually and on Your behalf. If You download,
            access, use or register for the Services (i) on behalf of an
            Organization, “You” includes yourself individually and such
            Organization and You represent and warrant that you are a duly
            authorized representative of such Organization and have the
            authority to bind such Organization to these Terms of Use, or (ii)
            as a Guardian, You are agreeing to these Terms of Use individually
            and on behalf of each Player under the age of eighteen (18) for whom
            you are the parent or legal guardian. SportsGravy reserves the right
            to demand proof of eligibility at any time and to suspend or
            terminate Your Account and/or Your access to and use of all or any
            part of the Services if satisfactory evidence of eligibility is not
            promptly provided.{" "}
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">General Use.</b>
            </u>
            The Services are made available, subject to these Terms of Use, to
            allow You to (i) obtain information about the Services, (ii)
            register for an Account, (iii) organize, track, administer and
            communicate regarding sports organization, association, league and
            team scheduling and registration, and Player performance, testing
            and development, and (iv) request and receive support with respect
            to the Services. Subject to these Terms of Use, SportsGravy grants
            You a limited, non-exclusive, non-transferable and revocable license
            to download, access and use the Services solely for the applicable
            purposes described in this Section 3 and for Your personal,
            non-commercial benefit. Except as otherwise expressly set forth
            herein, You shall have no other rights to the Services, and no other
            rights shall be implied.{" "}
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Your Account.</b>
            </u>
            You may not have more than one (1) Account without SportsGravy’s
            permission and You must access the App and non-public portions of
            the Website through Your Account. The security and confidentiality
            of Your Account username and password is Your sole responsibility.
            You shall ensure that no unauthorized person shall have access to
            Your Account, username or password and shall immediately notify
            SportsGravy of any unauthorized access or use of which You become
            aware. You agree to bear all responsibility and liability for all
            actions under or through Your Account as well as all fees and/or
            charges incurred from the use of Your Account, whether authorized or
            unauthorized. You may update Your Account by changing the Account
            settings.{" "}
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Registration Data.</b>
            </u>
            All of Your information and data provided to SportsGravy in
            connection with registering for Your Account (“
            <b>Registration Data</b>”) must be true, accurate, current and
            complete at all times. You must immediately update Your Registration
            Data to keep it true, accurate, current and complete.
            Notwithstanding anything to the contrary, You hereby authorize
            SportsGravy and its affiliates, contractors and suppliers to use,
            copy, sublicense and disclose Registration Data (i) as part of
            providing the Services, (ii) if required by applicable law, where
            necessary to enforce these Terms of Use and/or to protect any of
            SportsGravy’s or other parties’ legal rights, (iii) in an aggregated
            form which does not include Your identifying information, and (iv)
            as permitted by SportsGravy’s [
            <a
              href="http://www.sportsgravy.com/legal/privacy-notice"
              id="tagColorPrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            ]. You are solely responsible to obtain any necessary rights,
            authorizations and consents with respect to Your Registration Data,
            including, without limitation, to grant the foregoing rights to
            SportsGravy and its affiliates, contractors and suppliers. If You
            provide SportsGravy Your email address or register for an Account,
            You hereby consent to and authorize SportsGravy to (a) send notices
            regarding the Services to your specified email address, instead of
            notice by other means such as mail, and (b) send other messages to
            such email address, such as changes to features of the Services and
            special offers (collectively, “<b>Other Messages</b>”). If You do
            not want to receive Other Messages, You may opt out by contacting
            SportsGravy using the contact information located in Section 26
            below. Opting out of receiving Other Messages may prevent You from
            receiving email messages regarding updates, changes to the Services
            or offers.
          </p>
        </li>
        <li>
          <p className="card-text pl-2">
            <u>
              <b className="pr-2">Prohibited Uses.</b>
            </u>
            In addition to uses otherwise prohibited by SportsGravy, these Terms
            of Use or applicable law, You shall not, and You shall not allow any
            third party to, do any of the following:
          </p>
          <ul className="card-text ulStyleTag">
            <li className="card-text">
              Download, access, use or register for the Services if You are
              under eighteen (18) years old, unless You are a Player and Your
              Guardian has agreed to these Terms of Use on Your behalf.
            </li>
            <li className="card-text">
              Change or delete any copyright or proprietary notices from the
              Services.
            </li>
            <li className="card-text">
              Access or use the Services to send unsolicited commercial email to
              any person, or to threaten, harm or harass another user’s Account,
              use and/or access of the Services, including, but not limited to,
              attempting to gain access to another user’s Account.
            </li>
            <li className="card-text">
              Access or use the Services by artificial means or in a manner
              that, in SportsGravy’s sole discretion, would inflict an excessive
              load on SportsGravy’s or its suppliers’ hardware or software.
            </li>
            <li className="card-text">
              Post, upload or transmit: (i) any data, content or materials meant
              to market or advertise services or products that are competitive
              with the Services, solicit any other users of the Services for
              such purposes and/or use data, content or materials gathered from
              the Services for such purposes, (ii) any data, content or
              materials of any type that infringe or violate any rights of any
              party, including, but not limited to, any patent, copyright,
              trademark, trade secret or any proprietary or other right of
              SportsGravy or a third party, (iii) any data, content or materials
              that are illegal, threatening, obscene, defamatory, harmful,
              invasive to privacy or otherwise violate the rights of SportsGravy
              or a third party, and/or (iv) any disabling code (defined as
              computer code designed to interfere with the normal operation of
              the Services or SportsGravy’s or a third party’s hardware or
              software) or any program routine, device or other undisclosed
              feature, including, but not limited to, a time bomb, virus,
              software lock, drop-dead device, malicious logic, worm, Trojan
              horse or trap door which is designed to delete, disable,
              deactivate, interfere with or otherwise harm the Services or
              SportsGravy’s or a third party’s hardware or software.
            </li>
            <li className="card-text">
              Use the Services for any benchmarking, monitoring or testing
              availability or performance, or any other competitive use,
              including, but not limited to, creating services, products or
              software that are substantially similar to the Services.
            </li>
            <li className="card-text">
              Decompile, disassemble or otherwise reverse engineer or attempt to
              reconstruct or derive any source code (or underlying ideas,
              algorithms, structures or organizations), modify or create a
              derivative work of the Services by any means whatsoever.
            </li>
            <li className="card-text">
              Distribute, disclose or allow use of any portion of the Services
              or Your Account in any format through any timesharing device,
              service bureau, network or by any other means, to or by any third
              party.
            </li>
            <li className="card-text">
              Use or access any data, content or materials for any purpose or in
              any manner other than real-time viewing (which prohibits, for
              example, screenshots, downloading and storing) unless expressly
              permitted by the features of the Services.
            </li>
            <li className="card-text">
              Use or access the Services or any data, content or materials
              provided through the Services in violation of international,
              federal, state or local law or other applicable rules or
              regulations. If You access the Services from outside the United
              States, You do so at Your own risk and are solely responsible for
              compliance with laws of Your jurisdiction.
            </li>
          </ul>
        </li>
        <li>
          <p className="card-text pl-2 pt-3 pb-3">
            <u>
              <b className="pr-2">User Content.</b>
            </u>
            The Services may contain user-generated or provided data, content
            and materials (“<b>User Content</b>”). User Content is the sole
            responsibility of the user that submitted that User Content.
            SportsGravy does not control User Content generated or provided by
            users and is under no circumstances responsible or liable in any way
            for any User Content that is made available through the Services.
            SportsGravy does not endorse any User Content, and does not warrant
            or guarantee the integrity, truthfulness, accuracy or quality of any
            User Content. SportsGravy reserves the right (but is not obligated)
            to edit or remove any User Content in whole or part, at any time and
            for any or no reason. You agree that SportsGravy is not liable for
            any loss or damage of any kind resulting from availability, access
            and/or use of any User Content. You further agree that the data,
            content and materials You generate or provide through the Services
            (“<b>Your Content</b>”) shall be truthful and not misleading. You
            acknowledge and agree that Your Content will be made available to
            other users of the Services (subject to the features of the Service
            which may restrict what users can view and access Your Content). You
            hereby grant to SportsGravy and its affiliates, contractors and
            suppliers a nonexclusive, perpetual, irrevocable, world-wide,
            royalty-free, assignable and sublicensable (through multiple tiers),
            license to reproduce, copy, use, host, store, sublicense, reproduce,
            create derivative works from, modify, publish, edit, translate,
            distribute, perform and display, including digitally or
            electronically, Your Content and Your name, voice and likeness (to
            the extent they are part of Your Content), (i) in connection with
            the Services, as specified under Third Party Licenses and/or for the
            interoperation of any third party products, (ii) if required by
            applicable law, where necessary to enforce these Terms of Use and/or
            to protect any of SportsGravy’s or other parties’ legal rights,
            (iii) in an aggregated form which does not include Your identifying
            information, and (iv) as permitted by SportsGravy’s [
            <a
              href="http://www.sportsgravy.com/legal/privacy-notice"
              className="tagDecoration"
              id="tagColorPrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            ]. You are solely responsible to obtain any necessary rights,
            authorizations and consents with respect to Your Content, including,
            without limitation, to grant the foregoing rights to SportsGravy and
            its affiliates, contractors and suppliers.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Releases.</b>
            </u>
            You acknowledge and agree that (i) You are solely responsible and
            liable for Your interactions with other users of the Services, (ii)
            SportsGravy does not control the acts, omissions, policies or
            procedures of other users of the Services or any Organizations, and
            (iii) You are aware of and fully assume the risks inherent in
            participating in any athletic or related activities, including,
            without limitation, death, sickness, injury, psychological stress,
            loss or damage to person or property. Without limiting the
            foregoing, You understand the risks associated with the access to
            and use of the Services and any User Content and other data, content
            and materials made available through the Services, and acknowledge
            that You are using the Services and such other data, content and
            materials at Your own risk and that You are personally responsible
            for verifying their suitability for Your needs through Your own
            investigation. To the maximum extent permitted by law, SportsGravy
            is not liable for, and You hereby waive, release, relinquish and
            forever discharge SportsGravy, its employees, members, managers,
            governors, suppliers, agents, contractors, affiliates,
            representatives, successors and assigns (collectively, the “
            <b>SportsGravy Parties</b>”), from any and all claims,
            counterclaims, demands, causes of action, suits, liabilities,
            injuries to person or property (including, but not limited to,
            death, incapacity and disability), damages, losses, costs and
            expenses, whether known or unknown, liquidated or unliquidated,
            fixed or contingent, direct or indirect, arising out of or related
            to (a) Your access to and use of the Services, (b) access to and use
            of any User Content and other data, content and materials made
            available through the Services, (c) Your interactions with other
            users of the Services, (d) the acts, omissions, policies or
            procedures of any Organizations, and (e) Your participation in any
            athletic activities. You agree to not sue SportsGravy and the other
            SportsGravy Parties, or assist any other person or entity in any
            suit against SportsGravy and the other SportsGravy Parties, with
            respect to any matters released in this Section 8. The terms of this
            release shall also apply to any of Your relatives, next of kin,
            heirs, beneficiaries, employers, successors or assigns who assert or
            attempt to assert any claims on Your or their behalf.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Ownership.</b>
            </u>
            You acknowledge and agree that as between You and SportsGravy,
            SportsGravy is and shall remain the exclusive owner of the Services,
            SportsGravy’s data, information and content (expressly excluding
            User Content and Your Content), and all patent, copyright, trade
            secret, trademark and other intellectual property rights therein.
            You shall not challenge or assist any third party to dispute or
            challenge SportsGravy’s or its licensors’ ownership of such rights
            or the validity or enforceability of such rights. SportsGravy
            expressly reserves all rights not expressly set forth in these Terms
            of Use.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Accessibility and Updates.</b>
            </u>
            You are solely responsible for providing, maintaining and ensuring
            compatibility with the access requirements for the Services, and all
            hardware, software, electrical or other physical requirements for
            use of the Services, including, without limitation, mobile devices,
            telecommunications and internet access, connections, web browsers or
            other equipment, programs and services required to download, access
            and use the Services. To use the App, You must have a compatible
            mobile device and SportsGravy does not guarantee that the App will
            be compatible with any specific mobile device. You are solely
            responsible for Your mobile device data or related charges.
            SportsGravy may from time to time issue upgraded versions of the App
            and may automatically update the version of the App that You are
            using.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Third Party Products.</b>
            </u>
            Access to the Services may include hardware, software and other
            proprietary materials from SportsGravy’s third party suppliers that
            are subject to license rights, terms and restrictions (collectively,
            “Third Party Licenses”). In such event, Your rights to the Services
            are expressly subordinate and subject to the Third Party Licenses
            and you must comply with Third Party Licenses. If SportsGravy’s
            rights provided by a third party supplier are limited, suspended or
            terminated for any reason, Your rights shall also be so limited,
            suspended or terminated. To the extent of any conflict between the
            terms of these Terms of Use and the terms of a Third Party License,
            the terms of the Third Party License shall control with respect to
            the applicable third party product.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Warranties.</b>
            </u>
            You represent and warrant that (i) these Terms of Use constitute
            Your legal, valid and binding obligation enforceable against You in
            accordance with their terms, subject to the principles of equity,
            bankruptcy, insolvency, reorganization and other laws affecting
            creditors’ rights generally, (ii) You have all requisite authority
            to enter into these Terms of Use on behalf of Yourself individually,
            any applicable Organization, and/or any Player under the age of
            eighteen (18), as applicable, and to carry out the transactions
            contemplated by these Terms of Use, and the execution, delivery and
            performance of these Terms of Use have been duly authorized, (iii)
            Your agreement to these Terms of Use and performance and compliance
            with the terms of these Terms of Use will not conflict with, result
            in a breach of, constitute a default under or require the consent of
            any third party under any license, sublicense, lease, contract,
            agreement or instrument to which You or Your affiliates are bound or
            to which Your properties are subject, (iv) You are the lawful owner
            of Your Content and Your Registration Data, or to the extent You are
            not the lawful owner, You have all rights necessary for You to
            provide, upload and make available Your Content and Your
            Registration Data and license it as specified herein, and (v) Your
            Content and Your Registration Data will not violate or in any way
            infringe on any patent, copyright, trade secret, trademark,
            intellectual property or other rights of a third party.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Termination.</b>
            </u>
            Access to the Services is provided at the discretion of SportsGravy.
            SportsGravy has the right at any time, with or without prior notice
            and without liability, to suspend or terminate Your access to the
            Services or Your Account, and to refuse any and all current or
            future use of all or any portion thereof, for any reason, including,
            but not limited to, if SportsGravy believes that You are in
            violation of any of the provisions of these Terms of Use, Your
            Account is being used without Your authorization, or the information
            You have provided is inaccurate, not current or incomplete.
            SportsGravy shall also have the right, but not the obligation, at
            any time to change or discontinue any aspect or feature of the
            Services and/or change or remove any of the User Content from the
            Services, in its sole discretion. In the event You desire to
            terminate Your Account You may do so by contacting SportsGravy using
            the contact information set forth in Section 26 below. Upon
            termination of Your Account, all of the information associated with
            Your Account may be retained or deleted without notice. SportsGravy
            shall have no liability for such retention or deletion.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Indemnification.</b>
            </u>
            To the fullest extent permitted by law, You shall indemnify, defend
            and hold SportsGravy and the other SportsGravy Parties harmless from
            and against any and all losses, liabilities, suits, actions,
            obligations, fines, damages, judgments, penalties, claims, causes of
            action, charges, costs and expenses (including, but not limited to,
            attorneys’ fees, disbursements and court costs prior to trial, at
            trial and on appeal) arising out of or resulting from, or alleged to
            result from (i) Your breach of these Terms of Use, (ii) Your access
            to and use of the Services, and/or (iii) Your Content and Your
            Registration Data; provided, the foregoing provisions of this
            Section 14 shall not apply to the extent such losses, costs and
            expenses arise out of the gross negligence or willful misconduct of
            SportsGravy.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Disclaimers.</b>
            </u>
            EXCEPT AS EXPRESSLY SET FORTH IN THESE TERMS OF USE, SPORTSGRAVY
            DOES NOT MAKE AND HEREBY DISCLAIMS ALL EXPRESS AND IMPLIED
            WARRANTIES, REPRESENTATIONS AND ENDORSEMENTS OF ANY KIND WHATSOEVER
            (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT) WITH
            REGARD TO THE SERVICES, USER CONTENT OR ANY OTHER DATA, CONTENT AND
            MATERIALS CONTAINED IN OR PROVIDED THROUGH THE SERVICES. THE
            SERVICES AND USER CONTENT ARE PROVIDED ON AN “AS IS” BASIS. IF YOU
            ARE DISSATISFIED WITH THE SERVICES, USER CONTENT, THESE TERMS OF USE
            OR ANY PORTION OF THE FOREGOING, YOUR SOLE AND EXCLUSIVE REMEDY IS
            TO DISCONTINUE USING THE SERVICES. SPORTSGRAVY DOES NOT GUARANTEE
            CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE SERVICES, AND
            OPERATION OF THE SERVICES MAY BE INTERFERED WITH BY NUMEROUS
            FACTORS.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Liability.</b>
            </u>
            SUBJECT TO THE OTHER LIMITATIONS AND DISCLAIMERS IN THESE TERMS OF
            USE, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
            SHALL SPORTSGRAVY OR ANY OTHER SPORTSGRAVY PARTIES BE LIABLE UNDER
            ANY LEGAL THEORY FOR (I) ANY INDIRECT, SPECIAL, EXEMPLARY,
            CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THESE
            TERMS OF USE, THE SERVICES OR USER CONTENT, INCLUDING, WITHOUT
            LIMITATION, DAMAGES FOR LOSS OF PROFITS, INTEREST OR INTERRUPTION OF
            BUSINESS, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, OR (II) ANY DAMAGES EXCEPT TO THE EXTENT OF ACTUAL,
            DIRECT DAMAGES INCURRED BY YOU, NOT TO EXCEED ONE HUNDRED AND NO/100
            DOLLARS ($100.00). THE EXISTENCE OF MULTIPLE CLAIMS SHALL NOT
            INCREASE THIS LIMIT. Any of Your claims arising in connection with
            these Terms of Use, the Services or User Content must be brought
            within one (1) year of the date that the event giving rise to such
            action occurred.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Force Majeure.</b>
            </u>
            SportsGravy shall not be responsible for delays or failures of
            performance resulting from acts beyond its reasonable control. Such
            acts shall include, but are not limited to, acts of God, strikes,
            public internet and private internet connection failures, third
            party service providers, lockouts, riots, acts of war, acts of
            terror, epidemics, government regulations, fire, communication line
            failures, power failures, earthquakes and other disasters.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Personal Information.</b>
            </u>
            SportsGravy may collect Your personal and non-personal information
            when You download, register for, access and/or use the Services. You
            agree that SportsGravy may collect, process, transfer, use and
            disclose Your information in accordance with SportsGravy’s [
            <a
              href="http://www.sportsgravy.com/legal/privacy-notice"
              className="tagDecoration"
              id="tagColorPrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            ] which is incorporated herein by reference and deemed a part
            hereof.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Children’s Personal Information.</b>
            </u>
            The Children’s Online Privacy Protection Act of 1998 and its rules
            require that online service providers inform parents and guardians
            about their practices for collecting, using and disclosing personal
            information from children under the age of thirteen (13). They also
            require online service providers to obtain verifiable consent from a
            child’s parent or guardian for certain collection, use and
            disclosure of the child’s personal information. Copies of
            SportsGravy’s [
            <a
              href="http://www.sportsgravy.com/legal/privacy-notice"
              className="tagDecoration"
              id="tagColorPrivacyPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Notice
            </a>
            ] and SportsGravy’s [
            <a
              href="http://www.sportsgravy.com/legal/childrens-privacy-notice"
              id="tagColorChildPrivacyPolicy"
              className="tagChildDecoration"
              target="_blank"
              rel="noreferrer"
            >
              Children’s Privacy Notice
            </a>
            ] should be provided to parents and legal guardians of any children
            whose information may submitted to or through the Services.
          </p>
        </li>
        <li>
          <p className="card-text pl-2  pb-3">
            <u>
              <b className="pr-2">Feedback.</b>
            </u>
            If You submit any ideas, concepts, feedback or related information
            concerning the Services to SportsGravy through the Services or by
            other means, You hereby grant, and/or warrant that the owner of such
            content or intellectual property has expressly granted to
            SportsGravy, a royalty-free, perpetual, irrevocable, world-wide,
            non-exclusive, transferable, sublicensable (through multiple tiers)
            license to reproduce, copy, use, create derivative works from,
            modify, publish, edit, translate, distribute, perform and display,
            including digitally or electronically, such content or intellectual
            property in any media or medium, or any form, format or forum now
            known or hereafter developed.
          </p>
        </li>
        <li>
          <p className="card-text pl-2 pb-3">
            <u>
              <b className="pr-2">Assignment.</b>
            </u>
            You may not assign or transfer these Terms of Use, or any of Your
            rights or obligations hereunder, without the prior written consent
            of SportsGravy, and any attempted assignment or transfer without
            SportsGravy’s prior written consent shall be null and void.
            SportsGravy may assign these Terms of Use to an affiliate or the
            surviving entity or its successor in the event of its merger or the
            sale of all or substantially all of its assets. All of the terms and
            provisions of these Terms of Use shall be binding upon and shall
            inure to the benefit of the parties hereto and their respective
            personal representatives, estates, heirs, successors and permitted
            assigns.
          </p>
        </li>
        <li>
          <p className="card-text pl-2 pb-3">
            <u>
              <b className="pr-2">Governing Law.</b>
            </u>
            These Terms of Use shall be governed by, construed and enforced
            according to the laws of the State of Minnesota, without regard to
            its conflict or choice of law principles. Subject to Section 23
            below, any action arising out of or relating to these Terms of Use
            shall be brought only in the state or federal courts of Hennepin
            County, Minnesota, and all parties expressly consent to such courts’
            jurisdiction and irrevocably waive any objection with respect to the
            same, including any objection based on forum non conveniens.
          </p>
        </li>
        <li>
          <p className="card-text pl-2 pb-3">
            <u>
              <b className="pr-2">Arbitration.</b>
            </u>
            If any claim arising out of or related to these Terms of Use and/or
            the Services cannot be settled by the disputants, the claim shall be
            resolved pursuant to informal arbitration by an arbitrator selected
            under the Commercial Arbitration Rules of the American Arbitration
            Association (as then in effect for expedited proceedings) and
            located in Hennepin County, Minnesota, and such arbitration shall be
            conducted in that same location under such rules. Notwithstanding
            the foregoing, no disputant shall be required to seek arbitration
            regarding any cause of action that would entitle such disputant to
            injunctive relief. The determination of the arbitrator shall be
            conclusive and binding upon the disputants and a court judgment upon
            the same may be entered in any court having competent jurisdiction
            thereof. Subject to Section 14 above, the expenses of arbitration
            shall be borne equally by the opposing disputants. ALL PARTIES
            EXPRESSLY WAIVE THEIR RIGHTS AND ABILITIES TO MAINTAIN A className
            ACTION OR SIMILAR PROCEEDING IN ANY FORUM, INCLUDING, BUT NOT
            LIMITED TO, ARIBTRATION AND COURT, AND ANY AND ALL CLAIMS MAY ONLY
            BE BROUGHT IN A PARTY’S INDIVIDUAL CAPACITY AND NOT AS A MEMBER OF
            ANY PURPORTED className ACTION OR SIMILAR PROCEEDING.
          </p>
        </li>
        <li>
          <p className="card-text pl-2 pb-3">
            <u>
              <b className="pr-2">Intellectual Property.</b>
            </u>
            Except with respect to suspected copyright infringement which is
            addressed in further detail in Section 25 below, if You find or
            suspect intellectual property infringement with respect to the
            Services or User Content, believe that the Services or any User
            Content otherwise violate these Terms of Use, or have questions,
            complaints or claims regarding the Services, please immediately
            notify SportsGravy using the contact information located in Section
            26 below. Upon receiving such information, SportsGravy will
            investigate the matter, in its sole discretion.
          </p>
        </li>
        <li>
          <p className="card-text pl-2">
            <u>
              <b className="pr-2">Copyright Infringement.</b>
            </u>
            If You believe or suspect that Your copyrighted work has been
            infringed by, through or in connection with the Services or any User
            Content, please notify SportsGravy’s designated agent in writing in
            accordance with the Digital Millennium Copyright Act of 1998 (the “
            <b>DMCA</b>”) using the following contact information:
          </p>
          <div className="ml-3">
            <table>
              <tbody>
                <tr>
                  <td id="paddingBottom">Address:</td>
                  <td className="padding">
                    <p className="mb-0">Sports Gravy, LLC</p>
                    <p className="mb-0">Attn: General Counsel</p>
                    <p className="mb-0"> 7300 France Avenue South, Suite 200</p>
                    <p className="mb-2">Edina, Minnesota 55435</p>
                  </td>
                </tr>
                <tr>
                  <td className="p-1">Email:</td>
                </tr>
              </tbody>
            </table>
          </div>
        </li>
      </ol>
    </div>
  </TermsContainer>
);
