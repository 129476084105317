import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelScoringOption,
  ModelSport,
  getLookupCountryGetQueryKey,
  getSportSportIdGetQueryKey,
  lookupCountryGet,
  sportSportIdGet,
  useSportSportIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, styled, FormLabel, Box } from "@mui/material";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { hasPermission } from "@services/Casbin";
import { SearchInput } from "@components/SearchInput";
import CountryList from "./CountryList";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

export const SportView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState({
    delete: false
  });
  const [sportToDelete, setSportToDelete] = useState<ModelSport | null>(null);
  const { sportId } = useParams();
  const [image, setImage] = useState<string | undefined>(undefined);
  const [portraitImage, setPortraitImage] = useState<string | undefined>(
    undefined
  );
  const [landscapeImage, setLandscapeImage] = useState<string | undefined>(
    undefined
  );
  const [search, setSearch] = useState("");

  const [sportQuery, countryQuery] = useQueries({
    queries: [
      {
        queryKey: getSportSportIdGetQueryKey(sportId as string),
        queryFn: () =>
          sportSportIdGet(sportId as string).then((res) => res.data),
        onError: () => {
          navigate("/not-found");
        }
      },
      {
        queryKey: getLookupCountryGetQueryKey(),
        queryFn: () => lookupCountryGet().then((res) => res.data)
      }
    ]
  });
  const { data: sport, isLoading: isSportLoading } = sportQuery;
  const { data: countries, isLoading: isCountriesLoading } = countryQuery;

  const { mutateAsync, isLoading } = useSportSportIdDelete();

  const onConfirmDelete = async () => {
    if (!sportToDelete?.sportId) return;
    try {
      await mutateAsync({ sportId: sportToDelete.sportId });
      enqueueSnackbar("Sport deleted successfully", { variant: "success" });
      setSportToDelete(null);
      navigate("/sports");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete sport", {
        variant: "error"
      });
      setSportToDelete(null);
    }
  };
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("admin.sports", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  const isDeleteDisabled = (sport) => {
    return sport?._count?.organizations === 0 && sport?._count?.users === 0;
  };

  const defaultValues = useMemo<{
    name: string;
    isIntervalBased: boolean;
    intervalSingular?: string;
    intervalPlural?: string;
    intervalAbbreviation?: string;
    scoringOptions?: ModelScoringOption[];
    countries: {
      countryId: string;
      countryName: string;
      selected: boolean;
      name: string;
      contestType?: string;
    }[];
  }>(() => {
    if (!countries || !sport) return { name: "", countries: [] };
    return {
      name: sport.name!,
      isIntervalBased: !!sport.isIntervalBased,
      intervalSingular: sport.intervalSingular,
      intervalPlural: sport.intervalPlural,
      intervalAbbreviation: sport.intervalAbbreviation,
      scoringOptions: sport.scoringOptions,
      countries:
        sport.countries &&
        countries.length > 0 &&
        countries
          .filter((country) =>
            sport.countries.some((c) => c.countryId === country.countryId)
          )
          .reduce((acc, country) => {
            const sportCountry = sport?.countries.find(
              (c) => c.countryId === country.countryId
            );
            acc[country.countryId!] = {
              countryId: country.countryId!,
              countryName: country.name!,
              selected: sportCountry ? true : false,
              name: sportCountry ? sportCountry.name : "",
              contestType:
                sportCountry && sportCountry.isMatch ? "MATCH" : "GAME"
            };
            return acc;
          }, {})
    };
  }, [countries, sport]);

  const { control, reset } = useForm({
    mode: "onBlur",
    defaultValues
  });
  useEffect(() => {
    reset(defaultValues);
    setImage((sport?.icon?.baseUrl || "") + (sport?.icon?.path || ""));
    if (sport?.portraitImage) setPortraitImage(sport?.portraitImage);
    if (sport?.landscapeImage) setLandscapeImage(sport?.landscapeImage);
  }, [defaultValues, sport]);

  const [allowScoring, setAllowScoring] = useState(false);
  const [allowMultipleScoring, setAllowMultipleScoring] = useState(false);

  useEffect(() => {
    if (sport) {
      if (sport.scoringOptions && sport.scoringOptions.length > 0) {
        setAllowScoring(true);
        if (sport.scoringOptions[0].name) setAllowMultipleScoring(true);
      }
    }
  }, [sport]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearch = useDebounce(search, 100);

  const renderCountryList = useMemo(() => {
    return (
      <CountryList
        countryList={defaultValues.countries}
        control={control}
        search={debouncedSearch}
        disabled={true}
      />
    );
  }, [defaultValues.countries, debouncedSearch]);

  return (
    <Container>
      <Toolbar
        title="View Sport"
        backBtnClick={() => navigate("/sports")}
        editBtnClick={() => navigate(`/sports/${sportId}/edit`)}
        {...(!isDeleteDisabled(sport) &&
          permissions.delete && {
            deleteBtnClick: () => setSportToDelete(sport!)
          })}
      />
      <Form>
        <Loader
          isLoading={
            isCountriesLoading ||
            isSportLoading ||
            defaultValues.countries.length === 0 ||
            !defaultValues?.name
          }
        >
          <Grid container spacing={3} data-testid="sport-icon">
            {image && (
              <>
                <Grid xs={12} md={12}>
                  <StyledFormLabel required={true}>
                    <Typography display="inline" variant="formLabel">
                      {"Sports Icon"}
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid container spacing={3} xs={12} md={12} paddingLeft="12px">
                  <Grid xs={12} md={2}>
                    <img
                      src={image}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid xs={6} data-testid="sport-name">
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
              />
            </Grid>
            <Grid xs={6}></Grid>
            {portraitImage && (
              <>
                <Grid xs={12} md={12}>
                  <StyledFormLabel required={true}>
                    <Typography display="inline" variant="formLabel">
                      {"Default Portrait Image"}
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid
                  container
                  xs={12}
                  md={12}
                  paddingLeft="12px"
                  data-testid="sport-portraitImage"
                >
                  <Grid xs={12} md={2}>
                    <img
                      src={
                        portraitImage &&
                        portraitImage.baseUrl + portraitImage.path
                      }
                      style={{
                        width: "100%",
                        height: "290px"
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {landscapeImage && (
              <>
                <Grid xs={12} md={12}>
                  <StyledFormLabel required={true}>
                    <Typography display="inline" variant="formLabel">
                      {"Default Landscape Image"}
                    </Typography>
                  </StyledFormLabel>
                </Grid>
                <Grid
                  container
                  paddingLeft="12px"
                  xs={12}
                  md={12}
                  data-testid="sport-landscapeImage"
                >
                  <Grid xs={12} md={2}>
                    <img
                      src={
                        landscapeImage &&
                        landscapeImage.baseUrl + landscapeImage.path
                      }
                      style={{
                        width: "290px",
                        height: "163px"
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid>
              <FormCheckbox
                disabled
                control={control}
                name="isIntervalBased"
                label="Sport has intervals"
              />
            </Grid>
            {sport?.isIntervalBased && (
              <Grid container direction="row" spacing="25px" xs={12} md={12}>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalSingular"
                    type="text"
                    label="Singular Interval"
                    rules={{
                      required: "Singular Interval is Required"
                    }}
                    required={true}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalPlural"
                    type="text"
                    label="Plural Interval"
                    rules={{
                      required: "Plural Interval is Required"
                    }}
                    required={true}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={4}>
                  <FormInput
                    control={control}
                    name="intervalAbbreviation"
                    type="text"
                    label="Interval Abbreviation"
                    rules={{
                      required: "Interval Abbreviation is Required"
                    }}
                    required={true}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            )}
            {sport?.isIntervalBased && (
              <Grid container direction="row" spacing="30px" xs={12}>
                <Grid>
                  <FormCheckbox
                    disabled
                    control={control}
                    inverseValue={allowScoring}
                    name="allowScoring"
                    label="Allow Scoring"
                  />
                </Grid>
                {allowScoring && (
                  <Grid container direction="row" spacing="30px">
                    <Grid>
                      <FormCheckbox
                        disabled
                        inverseValue={allowMultipleScoring}
                        control={control}
                        name="allowMultipleScoring"
                        label="Allow Multiple Scoring Options"
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
            {allowScoring &&
              sport?.scoringOptions &&
              sport?.scoringOptions!.map((option, idx) => (
                <Grid
                  container
                  direction="row"
                  spacing="25px"
                  xs={12}
                  md={12}
                  key={option.optionId}
                  data-testid="scoring-options-container"
                >
                  {allowMultipleScoring && (
                    <Grid xs={6}>
                      <FormInput
                        control={control}
                        name={`scoringOptions[${idx}].name`}
                        type="text"
                        label="Scoring Option"
                        rules={{
                          required: "Scoring Option is Required"
                        }}
                        required={true}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  <Grid xs={6}>
                    <FormInput
                      control={control}
                      name={`scoringOptions[${idx}].value`}
                      type="text"
                      label="Scoring Values"
                      rules={{
                        required: "Scoring Values is Required"
                      }}
                      required={true}
                      disabled={true}
                    />
                    <Grid marginLeft="10px">
                      <Typography
                        variant="permissionNames"
                        style={{
                          color: "#B3B3B3"
                        }}
                      >
                        Options separated by comma
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            <Grid xs={12}>
              <StyledLabel>Participating Countries</StyledLabel>
              <HeaderUnderLine width="100%" />
              <Box sx={{ marginTop: "8px" }}>
                <SearchInput
                  placeholder="Search"
                  required={false}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
            </Grid>
            {renderCountryList}
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!sportToDelete}
        title="Delete Sport?"
        body={`Are you sure you want to delete ${sportToDelete?.name}?`}
        close={() => setSportToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setSportToDelete(null)}
        isConfirming={isLoading}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
