import { styled } from "@mui/material/styles";
import { Divider, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Container } from "../../components/crud/Container";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));
const StyledLink = styled(Link)(() => ({
  color: "#3B6CF8",
  fontWeight: 400,
  "&:hover": {
    color: "#1E2941",
    cursor: "pointer"
  },
  "&:visited": {
    color: "#3B6CF8",
    "&:hover": {
      color: "#1E2941",
      cursor: "pointer"
    }
  }
}));
const StyledBox = styled(Box)(() => ({
  margin: "26px",
  "& h3": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#1E2941",
    letterSpacing: "1.2px",
    opacity: 0.5,
    fontFamily: "Inter"
  },
  "& h4": {
    margin: "10px 0px",
    fontSize: "14px",
    lineHeight: "20px",
    textDecoration: "underline",
    width: "fit-content"
  }
}));
export const Report = () => {
  return (
    <Container>
      <StyledToolbar>
        <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
          <Grid xs={12} md={"auto"} sx={{ flexGrow: { md: "1 !important" } }}>
            <Typography variant="h2">Reports</Typography>
          </Grid>
          <Grid
            xs={12}
            md={"auto"}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={2}
          ></Grid>
        </Grid>
      </StyledToolbar>
      <Divider />
      <StyledBox data-testid="report-links">
        <Typography variant="h3">Feed Reports</Typography>
        <Typography variant="h4">
          <StyledLink to="flagged/posts">Flagged Posts</StyledLink>
        </Typography>
        <Typography variant="h4">
          <StyledLink to="approved/posts">Approved Flagged Posts</StyledLink>
        </Typography>
        <Typography variant="h4">
          <StyledLink to="removed/posts">Removed Flagged Posts</StyledLink>
        </Typography>
        <Typography variant="h4">
          <StyledLink to="flagged/comments">Flagged Comments</StyledLink>
        </Typography>
        <Typography variant="h4">
          <StyledLink to="approved/comments">
            Approved Flagged Comments
          </StyledLink>
        </Typography>
        <Typography variant="h4">
          <StyledLink to="removed/comments">
            Removed Flagged Comments
          </StyledLink>
        </Typography>
      </StyledBox>
    </Container>
  );
};
