import { TableView } from "@components/TableView";
import { getHelpCategories } from "@services/Network";

import React from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  {
    headerName: "Category",
    field: "name",
    minWidth: 150,
    flex: 1,
    sortable: false
  },
  {
    headerName: "FAQ items",
    field: "faqs",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row._count.faqs
  },
  {
    headerName: "Help Articles",
    field: "articles",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row._count.helpArticles
  }
];

export const HelpCategory = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/help-category/create");
  const onEdit = (row) => navigate(`/help-category/${row.helpCategoryId}/edit`);
  const onView = (row) => navigate(`/help-category/${row.helpCategoryId}`);

  return (
    <>
      <TableView
        title="Help Categories"
        useGet={getHelpCategories}
        columns={LIST_COLUMNS}
        hideFilter={true}
        hideLabelContainer={true}
        searchable={false}
        getRowId={(row) => row.helpCategoryId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
