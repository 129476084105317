import { Frequency, Weekday } from "rrule";

const getShortDayName = (day) => {
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  return daysOfWeek[day];
};
const getDaysOfWeekText = (selectedDays): string => {
  selectedDays.sort((a, b) => a - b);
  if (selectedDays.length === 2) {
    if (selectedDays.includes(5) && selectedDays.includes(6)) {
      return "on weekends";
    }
    return `on ${getShortDayName(selectedDays[0])} and ${getShortDayName(
      selectedDays[1]
    )}`;
  } else if (selectedDays.length >= 3 && selectedDays.length < 7) {
    if (
      selectedDays.length === 5 &&
      selectedDays.every((day) => day >= 0 && day <= 4)
    ) {
      return "on weekdays";
    } else {
      const dayNames = selectedDays.map((day) => getShortDayName(day));
      const lastDay = dayNames.pop();
      return `on ${dayNames.join(", ")} and ${lastDay}`;
    }
  } else if (selectedDays.length === 7) {
    return "on all days";
  }
  return "";
};
const getCustomMessage = (interval, timeFrame, days: Weekday[]) => {
  let customText = "Repeats Every";
  switch (timeFrame) {
    case "DAILY":
      customText =
        customText + (interval > 1 ? " " + interval + " days" : " day");
      break;
    case "WEEKLY":
      customText =
        customText + (interval > 1 ? " " + interval + " weeks" : " week");
      customText =
        customText +
        (days.length > 1
          ? " " + getDaysOfWeekText(days.map((day) => day.weekday))
          : "");
      break;
    case "MONTHLY":
      customText =
        customText + (interval > 1 ? " " + interval + " months" : " month");
      break;
  }
  return customText;
};
export default function convertRruleToMessage(rruleOptions) {
  console.log(rruleOptions);
  let freqName = "DNR";
  let isCustomText = false;
  let customText;
  if (!rruleOptions.interval) return { freqName, isCustomText, customText };
  switch (rruleOptions.freq) {
    case Frequency.DAILY:
      freqName = "DAILY";
      break;
    case Frequency.WEEKLY:
      freqName = "WEEKLY";
      break;
    case Frequency.MONTHLY:
      freqName = "MONTHLY";
      break;
  }
  if (rruleOptions.interval > 1 || rruleOptions.byweekday) {
    isCustomText = true;
    customText = getCustomMessage(
      rruleOptions.interval,
      freqName,
      rruleOptions.byweekday
    );
    freqName = "CUSTOM_TEXT";
    return { freqName, isCustomText, customText };
  }
  return { freqName, isCustomText, customText };
}
