import { CircularProgress, CircularProgressProps } from "@mui/material";

export const LoadingSpinner = (props: {
  size: number;
  color:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "inherit"
    | undefined;
  thickness: number;
  sx?: CircularProgressProps["sx"];
}) => {
  return (
    <CircularProgress
      size={props.size}
      color={props.color}
      thickness={props.thickness}
      sx={props.sx}
    />
  );
};

LoadingSpinner.defaultProps = {
  size: 20,
  color: "primary",
  thickness: 5,
};
