import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { inviteAtom, personAtom, signupTokenAtom } from "@recoil/signup";
import {
  ModelInvite,
  ModelPerson,
  useInviteInviteIdCompressedEmailGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useResetSignup } from "../../hooks/useResetSignup";

export const Invite = () => {
  const navigate = useNavigate();
  const setInvite = useSetRecoilState<ModelInvite | null>(inviteAtom);
  const setPerson = useSetRecoilState<ModelPerson | null>(personAtom);
  const setSignUpToken = useSetRecoilState<string>(signupTokenAtom);
  const resetSignup = useResetSignup();
  const { inviteId, compressedEmail } = useParams<{
    inviteId: string;
    compressedEmail: string;
  }>();

  const { data, isError, isFetching } = useInviteInviteIdCompressedEmailGet(
    inviteId as string,
    encodeURIComponent(encodeURIComponent(compressedEmail as string))
  );

  useEffect(() => {
    if (data) {
      resetSignup();
      setSignUpToken(data.headers["x-sportsgravy-token"]);
      setPerson(data.data.person!);
      setInvite(data.data);
      setTimeout(() => navigate("/sign-up/basic-info"), 1000);
    }
  }, [data, setInvite, navigate]);

  return (
    <Grid container spacing={2} direction={"column"}>
      <Grid>
        <Typography variant="h2">Activate Account</Typography>
      </Grid>
      {isFetching && (
        <Grid container spacing={2} direction={"row"}>
          <Grid>
            <CircularProgress size={24} />
          </Grid>
          <Grid>
            <Typography>Validating your invitation...</Typography>
          </Grid>
        </Grid>
      )}
      {data && (
        <Grid>
          <Typography>
            Your invitation is valid, directing you to sign up.
          </Typography>
        </Grid>
      )}
      {isError && (
        <Grid>
          <Typography>Your invitation is invalid or expired.</Typography>
        </Grid>
      )}
    </Grid>
  );
};
