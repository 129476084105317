import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelFeature,
  ModelPerson,
  useAdminDirectoryGet,
  useAdminFeatureGet,
  useAdminReleasePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { Backdrop, Typography } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormSelect } from "@components/FormSelect";
import { Form } from "@components/crud/Form";
import { FeatureBar } from "./FeatureBar";
import { LoadingSpinner } from "@components/LoadingSpinner";

const filterOptions = [
  { label: "All", value: "all" },
  { label: "Mobile", value: "MOB" },
  { label: "Web", value: "WEB" }
];

const renderTree = (
  control,
  testerOptions,
  allFeaturesSelected,
  node,
  level
) => {
  return (
    <div key={node.featureId}>
      <FeatureBar
        key={node.featureId}
        control={control}
        companyDirectory={testerOptions}
        level={level}
        node={node}
        allChecked={allFeaturesSelected}
      />
      {node.children &&
        node.children.length > 0 &&
        node.children.map((child) =>
          renderTree(
            control,
            testerOptions,
            allFeaturesSelected,
            child,
            level + 1
          )
        )}
    </div>
  );
};

export const ReleaseCreate = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState("all");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [options, setOptions] = useState<ModelFeature[]>([]);
  const [allFeaturesIds, setAllFeaturesIds] = useState<string[]>([]);
  const [allFeaturesSelected, setAllFeaturesSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      filter: "all",
      allFeatures: false,
      name: ""
    }
  });
  const { data: data, isLoading: isLoading } = useAdminFeatureGet({
    flatten: false,
    platform: filter,
    sortField: "name",
    sortDirection: "asc",
    pageSize: 1000
  });
  const { data: companyDirectory, isLoading: companyDirectoryLoading } =
    useAdminDirectoryGet();

  const testerOptions = useMemo(
    () =>
      (companyDirectory &&
        companyDirectory.data.persons &&
        companyDirectory.data.persons.map((person: ModelPerson) => {
          return {
            label: person.firstName + " " + person.lastName,
            value: person.personId
          };
        })) ||
      [],
    [companyDirectory?.data]
  );

  const getAllFeatureIds = (features) => {
    const result: string[] = [];

    const traverse = (feature: ModelFeature) => {
      result.push(feature.featureId as string);
      if (feature.children) feature.children.forEach(traverse);
    };

    features.forEach(traverse);
    return result;
  };
  useEffect(() => {
    if (data?.data?.features) {
      setOptions(data.data.features);
      setAllFeaturesIds(getAllFeatureIds(data.data.features));
    }
  }, [data]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const create = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "ADD"
    );
    create.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);

  const { mutate: save, isLoading: isSaving } = useAdminReleasePost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      const processedData = {
        name: values.name,
        description: values.description,
        features: Object.entries(values)
          .filter(
            ([key, value]) =>
              key !== "allFeatures" &&
              typeof value === "object" &&
              value !== null
          )
          .map(([featureId, testers]) => ({
            featureId,
            ...(testers &&
              //@ts-ignore
              testers.iosTester && { iosTesterId: testers.iosTester }),
            ...(testers &&
              //@ts-ignore
              testers.andTester && { andTesterId: testers.andTester }),
            ...(testers &&
              //@ts-ignore
              testers.webTester && { webTesterId: testers.webTester })
          }))
      };
      try {
        save(
          {
            data: processedData
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Release added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                window.history.back();
              }
            },
            onError: () => {
              enqueueSnackbar("Failed to add release!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to add release!", {
          variant: "error"
        });
      }
    };

  const renderedTree = useMemo(() => {
    return (
      <Grid sx={{ padding: "0px 0px" }} xs={6}>
        <Loader isLoading={isLoading}>
          {testerOptions &&
            options.map((feature) =>
              renderTree(
                control,
                testerOptions,
                allFeaturesSelected,
                feature,
                0
              )
            )}
        </Loader>
      </Grid>
    );
  }, [isLoading, testerOptions, options, allFeaturesSelected]);

  const handleAllFeatureSelect = (e) => {
    setLoading(true);
    setTimeout(() => {
      setAllFeaturesSelected(e.target.checked);
      const isChecked = e.target.checked;
      allFeaturesIds.forEach((id) => {
        //@ts-ignore
        setValue(id, isChecked);
      });
    }, 1000);
  };
  useEffect(() => {
    setLoading(false);
  }, [renderedTree]);
  return (
    <Container>
      <Backdrop
        sx={{
          overflow: "hidden",
          overflowY: "none",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={loading}
      >
        <LoadingSpinner />
      </Backdrop>
      <Toolbar title="Add QA Release" />
      <Loader isLoading={companyDirectoryLoading}>
        <Form sx={{ paddingBottom: "0 !important" }}>
          <Grid data-testid="release-add-form" container spacing={3}>
            <Grid data-testid="release-name" xs={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="release-description" xs={6}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              background: "#f3f4f7",
              marginTop: "24px",
              padding: "12px 24px",
              margin: "24px -24px 0px -24px"
            }}
          >
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "50px" }}>
                <FormCheckbox
                  control={control}
                  name="allFeatures"
                  onChange={(e) => {
                    handleAllFeatureSelect(e);
                  }}
                />
              </div>
              <Typography
                style={{
                  marginLeft: "20px",
                  marginRight: "24px",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Features
              </Typography>
              <FormSelect
                name="filter"
                options={filterOptions}
                onChange={(e) => setFilter(e.target.value)}
                sx={{ width: "200px", background: "#fff" }}
              />
            </Grid>
            <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#666666"
                }}
              >
                Platforms / Testers
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ padding: "0px 0px" }} xs={6}>
            <Loader isLoading={isLoading}>{renderedTree}</Loader>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/releases")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
