import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { FormLabel, Typography } from "@mui/material";
import { AddIcon, CloseIcon } from "@components/Icons";
import { UserOverrideForm } from "./UserOverrideForm";
import { useEffect, useState } from "react";
import { Button } from "@components/Button";
import { SearchAddPerson } from "../../../components/SearchAddPerson";
import { organizationAtom } from "../../../recoil/auth";
import { useRecoilValue } from "recoil";
import {
  ModelPerson,
  useAdminSettingsGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import colors from "theme/colors";

const AddBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  borderRadius: "4px",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.8
  }
}));

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  minHeight: 500,
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 800,
  height: 60,
  backgroundColor: colors.info.main,
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});
const SelectorContainer = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column"
});

const TypeContainer = styled("div")({
  width: 450,
  height: 40,
  backgroundColor: "#E0E0E1",
  display: "flex",
  flexDirection: "row",
  padding: 4,
  marginTop: 24,
  marginBottom: 16,
  alignSelf: "center"
});
const TypeText = styled("div")({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "700",
  cursor: "pointer"
});
export const UserOverride = (props: {
  title: string;
  masterList: string[];
  setMasterList: (arg0: string[]) => void;
  userOptions: ModelPerson[];
  setUserOptions: (arg0: ModelPerson[]) => void;
  organizationId?: string;
  roleId: string;
  parentRoleId?: string;
  type?: string;
  seasonId?: string;
  roleAlias?: string;
  sportId?: string;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ModelPerson | undefined>(
    undefined
  );
  const [selectType, setSelectType] = useState(0);
  const [selectMode, setSelectMode] = useState<unknown>("ALL");
  const organizationId = useRecoilValue(organizationAtom);

  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "org-teams-programs"
  });

  useEffect(() => {
    if (!showModal) {
      setSelectedUser(undefined);
      setSelectType(0);
    }
  }, [showModal]);

  useEffect(() => {
    if (props.roleAlias && props.roleAlias === "PLAYER" && settings?.data) {
      const settingsList = settings.data;
      const setting = settingsList.find(
        (s) =>
          s.settingId ===
          (props.type === "team"
            ? "org-teams-programs.add-override-teams"
            : "org-teams-programs.add-override-programs")
      );
      if (
        setting?.organizationSettings &&
        setting.organizationSettings.length > 0
      ) {
        setSelectMode(setting.organizationSettings[0].value);
      }
    }
  }, [settings]);
  return (
    <div data-testid="USER_OVERRIDE_COMPONENT">
      <AddBtn
        variant="admin-primary"
        onClick={() => {
          setShowModal(true);
        }}
        startIcon={<AddIcon sx={{ marginTop: "1.5px" }} />}
        type="button"
        isLoading={false}
        disabled={false}
      >
        {`Add ${props.title} Override`}
      </AddBtn>
      <Loader isLoading={isLoading}>
        <Modal
          open={showModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>{`Add ${props.title}`}</HeaderText>
              <CloseIcon onClick={() => setShowModal(false)} />
            </Header>

            {selectedUser ? (
              <UserOverrideForm
                setShowModal={setShowModal}
                selectedPerson={selectedUser}
                masterList={props.masterList}
                setMasterList={props.setMasterList}
                roleId={props.roleId}
                userOptions={props.userOptions}
                setUserOptions={props.setUserOptions}
                parentRoleId={props.parentRoleId}
                sportId={props.sportId}
              />
            ) : (
              <div
                style={
                  selectMode === "ALL"
                    ? {
                        display: "flex",
                        flexDirection: "column"
                      }
                    : {}
                }
              >
                {selectMode === "ALL" && props.roleAlias === "PLAYER" ? (
                  <TypeContainer data-testid="USER_OVERRIDE_TYPE_CONTAINER">
                    <TypeText
                      data-testid="USER_OVERRIDE_TYPE_0"
                      onClick={() => setSelectType(0)}
                      style={{
                        backgroundColor:
                          selectType === 0 ? "white" : "transparent"
                      }}
                    >
                      {"Search My Organization"}
                    </TypeText>
                    <TypeText
                      data-testid="USER_OVERRIDE_TYPE_1"
                      onClick={() => setSelectType(1)}
                      style={{
                        backgroundColor:
                          selectType === 1 ? "white" : "transparent"
                      }}
                    >
                      {"Search All SportsGravy Users"}
                    </TypeText>
                  </TypeContainer>
                ) : (
                  <div
                    data-testid="LABEL_CONTAINER"
                    style={{
                      paddingTop: 4,
                      paddingLeft: 4,
                      marginTop: 24,
                      marginLeft: 10
                    }}
                  >
                    <FormLabel>
                      <Typography variant="formLabel">
                        {props.roleAlias === "COACH" ||
                        props.roleAlias === "MANAGER"
                          ? "Search All SportsGravy Users"
                          : selectMode === "SEASON"
                          ? "Search Organization Athletes/Players by Season"
                          : "Search My Organization "}
                      </Typography>
                    </FormLabel>
                  </div>
                )}
                <SelectorContainer>
                  <SearchAddPerson
                    personSelected={(person) => {
                      setSelectedUser(person);
                    }}
                    organizationId={
                      (selectMode === "ALL" &&
                        selectType === 0 &&
                        props.roleAlias === "PLAYER") ||
                      selectMode === "ORG" ||
                      selectMode === "SEASON"
                        ? organizationId
                        : undefined
                    }
                    onlyOrg={
                      (selectMode === "ALL" && selectType === 0) ||
                      selectMode === "ORG" ||
                      selectMode === "SEASON"
                    }
                    seasonId={
                      selectMode === "SEASON" ? props.seasonId : undefined
                    }
                    showTeamOrProgram={
                      selectMode === "ORG" || selectMode === "SEASON"
                    }
                    disableBannedRole={props.roleAlias}
                  />
                </SelectorContainer>
              </div>
            )}
          </MainContainer>
        </Modal>
      </Loader>
    </div>
  );
};
