import IosAppStore from "@assets/images/app_store.png";
import GooglePlayStore from "@assets/images/google_play.png";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import sc from "styled-components";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const LinksContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: row;
  }
`;

const ImageContainer = styled(Box)`
  ${(props) => props.theme.breakpoints.up("xs")} {
    margin-bottom: 1rem;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 250px;
  }
`;

const StyledImage = sc.img`
  width: 100%;

`;

const IOS_APP_STORE_URL =
  "https://apps.apple.com/us/app/sportsgravy/id1509169282";
const GOOGLE_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.sportsgravy";

export const WhoopsyDaisy = () => {
  return (
    <Container>
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Whoopsy Daisy!
      </Typography>
      <Typography variant="body1">
        It looks like you do not have access to the administration console.
        Please download or sign in to our mobile app to begin using SportsGravy.
      </Typography>
      <LinksContainer>
        <ImageContainer className="right-spacing">
          <a href={IOS_APP_STORE_URL} target="_blank" rel="noreferrer">
            <StyledImage alt="iOS App Store" src={IosAppStore} />
          </a>
        </ImageContainer>

        <ImageContainer>
          <a href={GOOGLE_PLAY_STORE_URL} target="_blank" rel="noreferrer">
            <StyledImage alt="Google Play Store" src={GooglePlayStore} />
          </a>
        </ImageContainer>
      </LinksContainer>
    </Container>
  );
};
