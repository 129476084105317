import { VerticalListTooltip } from "@components/VerticalListTooltip";
import { objectPropertyRecursive } from "@utils/objectPropertyRecursive";

export const DataGridRenderCellTooltip =
  (column = "name", count = 1) =>
  // eslint-disable-next-line react/display-name
  (row) => {
    let value;
    if (row.row.teams != undefined) {
      if (row.row?.teams.length > 0) {
        value = row.row.teams;
      }
    }
    if (row.row.programs != undefined) {
      if (row.row?.programs.length > 0) {
        row.row?.programs.map((program) => {
          value.push(program);
        });
      }
    }
    if (row.row?.post?.teams != undefined) {
      if (row.row?.post?.teams.length > 0) {
        value = row.row.post.teams;
      }
    }
    if (row.row?.post?.programs != undefined) {
      if (row.row?.post?.programs.length > 0) {
        row.row?.post?.programs.map((program) => {
          value.push(program);
        });
      }
    }
    if (row.value != undefined) {
      value = row.value;
    }
    if (!value || !Array.isArray(value) || !value.length) return "-";
    const displayed = value
      ?.slice(0, count)
      ?.map((record) => objectPropertyRecursive(record, column))
      ?.join(" / ");
    const remaining =
      value
        ?.slice(count)
        ?.map((record) => objectPropertyRecursive(record, column)) || [];
    return <VerticalListTooltip text={displayed} tooltipList={remaining} />;
  };
