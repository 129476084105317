import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { FormLabel, Grid, Typography, styled } from "@mui/material";
import { selectedReleaseAtom } from "@recoil/auth";
import {
  AdminQAFeaturePutRequest,
  useAdminDirectoryGet,
  useAdminQAFeatureFeatureIdGet,
  useAdminQAFeatureFeatureIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { cleanObject } from "@utils/cleanObject";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import formatFullName from "@utils/formatFullName";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const QAFeatureEdit = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();
  console.log(featureId);
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const [releaseId, setReleaseId] = useState(selectedReleaseValue);
  const [releases, setReleases] = useState<
    { label: string; value: string; status?: string }[]
  >([]);

  const [platforms, setPlatforms] = useState<string[]>([]);
  const form = useForm({
    mode: "onBlur"
  });

  const { data: testersData } = useAdminDirectoryGet();
  const [testers, setTesters] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    if (testersData?.data) {
      setTesters(
        testersData.data.persons?.map((person) => ({
          label: formatFullName(person),
          value: person.personId!
        })) || []
      );
    }
  }, [testersData]);

  const { data: data, isLoading: isLoading } = useAdminQAFeatureFeatureIdGet(
    featureId!,
    {
      releaseId: releaseId as string
    }
  );
  const {
    control,
    getValues,
    setValue,
    formState: { isValid, isDirty },
    reset
  } = form;

  useEffect(() => {
    if (data) {
      const feature = data.data;
      const defaultValues = {
        name: feature.feature?.name,
        parentId: feature.feature?.parentId,
        iosTesterId: feature.iosTesterId,
        andTesterId: feature.andTesterId,
        webtesterId: feature.webTesterId
      };
      if (feature.feature?.releases) {
        setReleases(
          feature.feature.releases.map((release) => ({
            label: release.release.name,
            value: release.releaseId,
            status: release.release.releaseStatus
          }))
        );
      }
      setPlatforms(feature.feature!.platforms!);
      reset(defaultValues);
    }
  }, [data]);
  const { mutate: save, isLoading: isSaving } = useAdminQAFeatureFeatureIdPut();
  const onSave = () => {
    console.log("SUBMITTT", getValues());
    save(
      {
        data: cleanObject({
          iosTesterId: getValues().iosTesterId,
          andTesterId: getValues().andTesterId,
          webTesterId: getValues().webtesterId
        }) as AdminQAFeaturePutRequest,
        params: {
          releaseId: releaseId!
        },
        featureId: featureId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Feature edited successfully!", {
            variant: "success"
          });

          navigate(`/qa-features/${featureId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Feature!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <Loader isLoading={isLoading}>
      <Container data-testid="editFeatureForm">
        <Toolbar
          title="Edit QA Feature"
          showFilters={
            <Grid
              container
              flexDirection="row"
              alignItems="center"
              marginLeft="10px"
            >
              <Grid item marginRight="10px">
                <Typography variant="gridToolbarFilterLabel">
                  Release
                </Typography>
              </Grid>
              <Grid
                marginRight="10px"
                minWidth="85px"
                data-testid="releaseFilter"
              >
                <FormSelect
                  disabled
                  name="release"
                  isReleaseSelect
                  options={releases}
                  value={releaseId}
                  onChange={(e) => {
                    setReleaseId(e.target.value);
                  }}
                  sx={{
                    minWidth: 85
                  }}
                />
              </Grid>
            </Grid>
          }
        />
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="name">
                <FormInput
                  name="name"
                  disabled
                  control={control}
                  label="Feature Name"
                  required
                  rules={{
                    required: "Feature Name Is Required"
                  }}
                  type="text"
                />
              </Grid>
            </Grid>
            {data?.data?.feature?.parentId && (
              <Grid item container direction="row" spacing="24px">
                <Grid item xs={6} data-testid="parentFeature">
                  <FilterTreeSearchComponent
                    disabled
                    placeHolder=" "
                    currentFeatureId={featureId}
                    disableSelected
                    selectedValue={getValues().parentId}
                    label="Parent Feature"
                    onFilterChange={(val) => {
                      console.log(val);
                      setValue("parentId", val, { shouldDirty: true });
                    }}
                    onClearFilter={() => {
                      setValue("parentId", null, { shouldDirty: true });
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              direction="row"
              spacing="24px"
              marginBottom="-10px"
            >
              <Grid item xs={6}>
                <StyledFormLabel required>
                  <Typography variant="formLabel">Platforms</Typography>
                </StyledFormLabel>
              </Grid>
              <Grid item xs={6}>
                <StyledFormLabel required>
                  <Typography variant="formLabel">Tester</Typography>
                </StyledFormLabel>
              </Grid>
            </Grid>
            {platforms.map((platform) => {
              console.log(platform);
              let key = "";
              switch (platform) {
                case "IOS":
                  key = "iosTesterId";
                  break;
                case "AND":
                  key = "andTesterId";
                  break;
                case "WEB":
                  key = "webTesterId";
                  break;
              }
              return (
                <Grid
                  item
                  container
                  direction="row"
                  spacing="24px"
                  key={platform}
                >
                  <Grid item xs={6} data-testid={"platform_" + platform}>
                    <FormSelect
                      name={`platform_${platform}`}
                      disabled
                      options={FEATURE_PLATFORMS_OPTIONS}
                      value={platform}
                    />
                  </Grid>
                  <Grid item xs={6} data-testid={key}>
                    <FormSelect
                      name={key}
                      control={control}
                      options={testers}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={() => {
            onSave();
          }}
          isDisabled={!isDirty || !isValid}
          isLoading={isLoading || isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/qa-features")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
    </Loader>
  );
};
