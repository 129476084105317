import { Button } from "@components/Button";
import { FormSelect } from "@components/FormSelect";
import { SearchInput } from "@components/SearchInput";
import { Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AddIcon } from "./Icons";
import { Container } from "./crud/Container";
import { useRecoilValue } from "recoil";
import {
  organizationAtom,
  organizationsAtom,
  profileAtom
} from "../recoil/auth";
import { FeedPostView } from "./FeedPostView";
import { PostCommentAction } from "./PostCommentAction";
import { createPost, getFeed } from "../services/Network";
import { SkeletonFeedCommentLoader } from "./SkeletonFeedCommentLoader";
import {
  AdminFeedGetType,
  ModelPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useNavigate } from "react-router-dom";
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));
const AddBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));

interface FeedViewProps {
  filterConfig?: {
    label?: string;
    options?: { label: string; value: string }[];
    placeholderOption?: { label: string; value: string };
    defaultValue?: string;
  };
  sortConfig?: {
    label?: string;
    options?: { label: string; value: string }[];
    placeholderOption?: { label: string; value: string };
    defaultValue?: string;
  };
  onAdd?: () => void;
  onFilterChange?: (value: string) => void;
  onSortChange?: (value: string) => void;
  title: string;
  userId?: string;
  repostId?: string | undefined;
}

export const FeedView = ({
  filterConfig,
  sortConfig,
  onAdd,
  onFilterChange,
  onSortChange,
  title,
  userId,
  repostId
}: FeedViewProps) => {
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState(
    filterConfig?.defaultValue || filterConfig?.placeholderOption?.value || ""
  );
  const [sort, setSort] = React.useState(
    sortConfig?.defaultValue || sortConfig?.placeholderOption?.value || ""
  );
  const [textSearch, setTextSearch] = React.useState("");
  const organizationId = useRecoilValue(organizationAtom);

  const organizations = useRecoilValue(organizationsAtom);
  const userDetails = useRecoilValue(profileAtom);
  const [addPost, setAddPost] = useState(false);
  const [prevData, setPrevData] = useState<ModelPost[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const addClickHandler = () => {
    onAdd && onAdd();
    setAddPost(true);
  };
  const closeClickHandler = () => {
    setAddPost(false);
  };
  const onFilter = (e) => {
    setFilter(e.target.value);
    onFilterChange && onFilterChange(e.target.value);
  };
  const onSort = (e) => {
    setSort(e.target.value);
    onSortChange && onSortChange(e.target.value);
  };
  const mutation = createPost();
  const post = (message, sport) => {
    mutation.mutate(
      {
        data: {
          ...(organizationId && { organizationId: organizationId }),
          sports: sport,
          body: message,
          shareTo: []
        }
      },
      {
        onSuccess: (newPostData) => {
          setPrevData((prevData) => [newPostData.data, ...prevData]);
        }
      }
    );
    setAddPost(false);
  };
  const SelectFieldOptions = React.useMemo(() => {
    const placeholderOption = filterConfig?.placeholderOption
      ? [filterConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(filterConfig?.options || [])];
  }, [filterConfig]);

  const selectSortoptions = React.useMemo(() => {
    const placeholderOption = sortConfig?.placeholderOption
      ? [sortConfig?.placeholderOption]
      : [];
    return [...placeholderOption, ...(sortConfig?.options || [])];
  }, [sortConfig]);
  const [page, setPage] = useState(0);
  const feedGet = getFeed(
    {
      organizationId: organizationId,
      ...(filter && { type: filter as AdminFeedGetType }),
      ...(textSearch && { textSearch }),
      sortDirection: sort,
      ...(textSearch ? { pageSize: "10" } : { pageSize: "10" }),
      // ...(textSearch ? {} : { pageNo: String(page) }),
      pageNo: String(page)
    },
    {},
    repostId && { postId: repostId }
  );

  useEffect(() => {
    console.log(feedGet);
    if (feedGet?.error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [feedGet?.error]);
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);

  const { data, isLoading, queryKey } = feedGet;
  useEffect(() => {
    if (!isLoading) {
      if (repostId) {
        setPrevData([data]);
      } else if (
        queryKey[1]?.textSearch === undefined &&
        queryKey[1]?.pageNo == 0
      ) {
        setPrevData(data);
      } else if (
        queryKey[1]?.textSearch === undefined &&
        queryKey[1]?.pageNo != 0
      ) {
        setPrevData((prevData) => [...prevData, ...data]);
      } else if (queryKey[1]?.textSearch !== undefined) {
        if (queryKey[1]?.pageNo == 0) {
          setPrevData(data);
        }
        if (queryKey[1]?.pageNo > 0) {
          setPrevData((prevData) => [...prevData, ...data]);
        }
      }

      if (data.length < 10 || data.length === 0) {
        setHasMore(false);
        observer.current.disconnect();
      }
    }
  }, [data, isLoading, hasMore]);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMore && (!isLoading || page === 0)) {
          setPage((page) => page + 1);
        }
      },
      {
        rootMargin: "200px 0px", // load content when the user is 200px away from the bottom
        threshold: 1.0 // entire target element is visible
      }
    )
  );
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (isLoading) {
      currentObserver.disconnect();
    } else {
      if (currentElement) {
        currentObserver.observe(currentElement);
      }
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  const contentData = prevData || [];
  const displayFeed = useMemo(() => {
    if (contentData) {
      return contentData.map((content, index) => (
        <div
          style={{ margin: "0 auto" }}
          ref={
            index === contentData.length - 1 && !isLoading && hasMore
              ? setLastElement
              : null
          }
          key={content.postId}
        >
          <FeedPostView
            rePost={false}
            userId={userId}
            content={content}
            setPrevData={setPrevData}
          />
        </div>
      ));
    }
    return null;
  }, [contentData]);

  useEffect(() => {
    if (addPost) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [addPost]);
  const onTextSearchchange = (e) => {
    setTextSearch(e.target.value);
    setPage(0);
  };
  return (
    <Container>
      <StyledToolbar>
        <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
          <Grid xs={12} md={"auto"} sx={{ flexGrow: { md: "1 !important" } }}>
            <Typography variant="h2">{title}</Typography>
          </Grid>
          <Grid
            xs={12}
            md={"auto"}
            container
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid>
              <AddBtn
                variant="admin-primary"
                onClick={addClickHandler}
                startIcon={<AddIcon sx={{ marginTop: "1.5px" }} />}
              >
                Post
              </AddBtn>
            </Grid>
          </Grid>
        </Grid>
      </StyledToolbar>
      <StyledToolbar style={{ paddingTop: 0, marginTop: "-10px" }}>
        <Grid
          width="100%"
          xs={12}
          container
          alignItems={"center"}
          direction="row"
          spacing="20px"
        >
          <Grid style={{ marginRight: "-10px" }}>
            <Typography variant="gridToolbarFilterLabel">
              {filterConfig?.label || "View"}
            </Typography>
          </Grid>

          <Grid data-testid="feed-view-select">
            <FormSelect
              name="view"
              options={SelectFieldOptions}
              value={filter}
              onChange={onFilter}
              sx={{
                minWidth: 250
              }}
            />
          </Grid>
          <Grid style={{ marginRight: "-10px" }}>
            <Typography variant="gridToolbarFilterLabel">
              {sortConfig?.label || "Sort"}
            </Typography>
          </Grid>
          <Grid data-testid="feed-sort-select">
            <FormSelect
              name="Sort"
              options={selectSortoptions}
              value={sort}
              onChange={onSort}
              sx={{
                minWidth: "288px"
              }}
            />
          </Grid>
          <Grid flex={1}>
            <SearchInput
              placeholder="Search"
              onChange={onTextSearchchange}
              //@ts-ignore
              style={{
                minWidth: "30vw"
              }}
            />
          </Grid>
        </Grid>
      </StyledToolbar>

      {(queryKey[1]?.textSearch != undefined ||
        queryKey[1]?.sortDirection != "desc" ||
        queryKey[1]?.filter != "all") &&
        isLoading && (
          <div style={{ margin: "auto" }}>
            <SkeletonFeedCommentLoader type={"feed"} />
          </div>
        )}
      {mutation.isLoading && hasMore && (
        <div style={{ margin: "auto" }}>
          <SkeletonFeedCommentLoader type={"feed"} />
        </div>
      )}
      {displayFeed}
      {addPost && (
        <PostCommentAction
          onClick={post}
          title={`Post to ${
            !organizationId ? "SportsGravy" : "your Organization"
          }`}
          field={[
            {
              field: "From",
              value: `${
                !organizationId
                  ? "SportsGravy"
                  : userDetails?.person?.firstName +
                    " " +
                    userDetails?.person?.middleName +
                    " " +
                    userDetails?.person?.lastName
              }`
            },
            {
              field: "To",
              value: `${
                !organizationId
                  ? "All SportsGravy Users"
                  : organizations.find(
                      (org) => org.organizationId === organizationId
                    )?.name
              }`
            }
          ]}
          action={"create"}
          button={"Post"}
          onClose={closeClickHandler}
        />
      )}
      {isLoading && hasMore && (
        <div data-testid="skeletion-loader" style={{ margin: "auto" }}>
          <SkeletonFeedCommentLoader type={"feed"} />
        </div>
      )}
      {data.length === 0 && !isLoading && page == 0 && (
        <Typography
          data-testid="no-result"
          variant="h3"
          sx={{ textAlign: "center" }}
        >
          No results {filter === "flagged" && ` for "${filter}" posts!!`}{" "}
          {filter != "flagged" &&
            textSearch !== "" &&
            ` for search result "${textSearch}" `}
        </Typography>
      )}
    </Container>
  );
};

FeedView.defaultProps = {
  isDeleteDisabled: () => false,
  hasActionColumn: true,
  searchable: true,
  hideFilter: false,
  rowReordering: false,
  hideSortButton: true
};
