import { capitalize } from "@utils/capitalize";

export const DataGridRenderList =
  (separator: string, placeholder = "N/A") =>
  (row) => {
    let data = row.value;
    if (!data || data.length === 0) return placeholder;
    data = data.map(capitalize);

    if (data.length === 1) return capitalize(data[0]) + " Only";
    return data.join(separator);
  };
