import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import { PLATFORM_OPTIONS, TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import { Box, Chip, Typography } from "@mui/material";
import {
  ModelPerson,
  ModelTestRun,
  useAdminDirectoryGet,
  useAdminQAFeatureTestcaseTestcaseIdGet,
  useAdminQAFeatureTestcaseTestcaseIdTestRunsGet,
  useAdminReleaseAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { RenderTableView } from "@components/RenderTableView";
import { AddIcon, BugIcon } from "@components/Icons";
import InfoIcon from "../../../src/assets/icons/info.svg";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { organizationAtom, selectedReleaseAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import formatFullName from "@utils/formatFullName";
import { ToolTip } from "@components/ToolTip";

export const QATestcaseView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const setSelectedReleaseState = useSetRecoilState(selectedReleaseAtom);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get("platform");
  const { testcaseId } = useParams();
  const [filter, setFilter] = useState<string[]>([paramValue!]);
  const [rows, setRows] = useState<ModelTestRun[]>([]);
  const [permissions, setPermissions] = useState({
    edit: false,
    view: false,
    create: false
  });
  const [releaseId, setReleaseId] = useState(selectedReleaseValue);

  const [platforms, setPlatforms] = useState<string[]>([]);
  const { control, reset, setValue } = useForm({
    mode: "onBlur"
  });
  const formatExpectedTime = (date) => {
    if (!date) return "";

    const milliseconds = new Date(date).getTime();
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    let timeString = "";
    if (minutes > 0 || seconds > 0) {
      timeString += minutes > 0 ? `${minutes}m ` : "";
      timeString += seconds > 0 ? `${seconds}s` : "";
    }

    return timeString.trim();
  };

  const { data: testcase, isLoading: testcaseLoading } =
    useAdminQAFeatureTestcaseTestcaseIdGet(testcaseId!, {
      releaseId: releaseId!
    });
  const { data: companyDirectory, isLoading: companyDirectoryLoading } =
    useAdminDirectoryGet();
  const { data: testruns, isLoading: testrunLoading } =
    useAdminQAFeatureTestcaseTestcaseIdTestRunsGet(testcaseId!, {
      releaseId: releaseId!,
      filter: filter
    });

  const { data: releasesData, isLoading: releaseDataLoading } =
    useAdminReleaseAllGet();
  const releaseOptions = useMemo(() => {
    if (releasesData && testcase) {
      return testcase.data.release
        .map((releaseId) => {
          const release = releasesData.data.releases?.find(
            (r) => r.releaseId === releaseId
          );
          if (release) {
            return {
              label: release.name,
              value: releaseId,
              status: release.releaseStatus
            };
          }
        })
        .filter(Boolean);
    }
    return [];
  }, [releasesData, testcase]);

  const testerOptions = useMemo(() => {
    if (companyDirectory) {
      return companyDirectory.data!.persons!.map((tester: ModelPerson) => {
        return {
          label: formatFullName(tester),
          value: tester.personId
        };
      });
    }
    return [];
  }, [companyDirectory]);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("tech.test-cases", "VIEW");
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      setPermissions({
        edit,
        view,
        create
      });
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, [organizationId]);
  useEffect(() => {
    if (testruns?.data) {
      setRows(testruns.data.testruns);
    }
  }, [testruns]);
  useEffect(() => {
    if (testcase?.data) {
      setPlatforms([]);
      const tc = testcase.data;
      reset({
        selectedPlatform: paramValue,
        description: tc.testCase?.description,
        expectedResult: tc.testCase?.expectedResult,
        featureId: tc.feature?.feature?.name
      });
      if (tc.iosTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "ios"]);
        setValue("iosTesterId", tc.iosTesterId);
        setValue("ios", "ios");
      }
      if (tc.webTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "web"]);
        setValue("webTesterId", tc.webTesterId);
        setValue("web", "web");
      }
      if (tc.andTesterId) {
        setPlatforms((prevPlatforms) => [...prevPlatforms, "and"]);
        setValue("andTesterId", tc.andTesterId);
        setValue("and", "and");
      }
    }
  }, [testcase]);

  const renderChip = (params, status) => {
    const value = params.value;
    const option = TESTCASE_STATUS_OPTIONS.find(
      (option) => option.value === value
    );
    const label = option ? option.label.toLocaleUpperCase() : "";
    return (
      <>
        <Chip
          data-testid={"CHIP_" + value}
          style={{
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: 600
          }}
          label={label}
          sx={{
            background:
              value === "FAILED"
                ? "#FECACA"
                : value === "PASSED"
                ? "#BBF7D0"
                : value === "BLOCKED"
                ? "#FF9F0A"
                : value == "HOLD"
                ? "#FBF1BC"
                : "#E5E5E5",
            color:
              value === "FAILED"
                ? "#991B1B"
                : value === "PASSED"
                ? "#166534"
                : value === "BLOCKED"
                ? "#FFFFFF"
                : value == "HOLD"
                ? "#7B5B08"
                : "#666666"
          }}
        />
        {value == "HOLD" && (
          <ToolTip
            title={
              status == "uiStatus" ? params.row.uiReason : params.row.fnReason
            }
          >
            <img
              src={InfoIcon}
              style={{ marginLeft: "4px" }}
              data-testid="HOLD_TOOLTIP"
            />
          </ToolTip>
        )}
        {(value == "BLOCKED" || value == "FAILED") && (
          <Link
            data-testid="BUG_ICON"
            style={{ marginTop: "5px" }}
            to={
              status == "uiStatus"
                ? params.row.uiJiraLink
                : params.row.fnJiraLink
            }
            target="_blank"
          >
            <BugIcon style={{ marginLeft: "4px", cursor: "pointer" }} />
          </Link>
        )}
      </>
    );
  };
  const dateFormat = (date) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
      hour12: true
    };
    //@ts-ignore
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };

  const LIST_COLUMNS = [
    {
      headerName: "Platform",
      field: "platform",
      minWidth: 50,
      flex: 1
    },
    {
      headerName: "Tester",
      field: "tester",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }: { row: ModelTestRun }) => {
        return `${row.tester?.person?.firstName} ${row.tester?.person?.lastName}`;
      }
    },
    {
      headerName: "Executed On",
      field: "executedOn",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }: { row: ModelTestRun }) => {
        return dateFormat(row.executedOn);
      }
    },
    {
      headerName: "Time Logged",
      field: "timeLogged",
      minWidth: 50,
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: { row: ModelTestRun }) => {
        return formatExpectedTime(row.timeLogged);
      }
    },
    {
      headerName: "UI Status",
      field: "uiStatus",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return renderChip(params, "uiStatus");
      }
    },
    {
      headerName: "FN Status",
      field: "fnStatus",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return renderChip(params, "fnStatus");
      }
    }
  ];
  const TEST_RUN_FILTER_OPTIONS = [
    { label: "All", value: "ALL" },
    { label: "Web", value: "WEB" },
    { label: "iOS", value: "IOS" },
    { label: "AND", value: "AND" },
    { label: "UI Status = Passed", value: "UI_PASSED" },
    { label: "UI Status = Failed", value: "UI_FAILED" },
    { label: "UI Status = Blocked", value: "UI_BLOCKED" },
    { label: "UI Status = Hold", value: "UI_HOLD" },
    { label: "FN Status = Passed", value: "FS_PASSED" },
    { label: "FN Status = Failed", value: "FN_TESTED" },
    { label: "FN Status = Blocked", value: "FN_BLOCKED" },
    { label: "FN Status = Hold", value: "FN_HOLD" }
  ];

  return (
    <Container>
      <Toolbar
        title="View QA Test Case"
        backBtnClick={() => navigate("/qa-testcases")}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/qa-testcases/${testcaseId}/edit`)
        })}
        showFilters={
          <Grid container flexDirection="row" alignItems="center">
            <Grid marginRight="10px">
              <Typography variant="gridToolbarFilterLabel">Release</Typography>
            </Grid>
            <Grid
              marginRight="10px"
              minWidth="85px"
              data-testid="releaseFilter"
            >
              <FormSelect
                name="release"
                options={releaseOptions}
                isReleaseSelect
                value={releaseId}
                onChange={(e) => {
                  setReleaseId(e.target.value);
                  setSelectedReleaseState(e.target.value);
                }}
                optionStyle={{
                  color: "#3B6CF8"
                }}
                sx={{
                  minWidth: 85
                }}
              />
            </Grid>
          </Grid>
        }
      />
      <Loader
        isLoading={
          testcaseLoading || releaseDataLoading || companyDirectoryLoading
        }
      >
        <Form>
          <Grid data-testid="testcase-view-form" container spacing={3}>
            <Grid data-testid="testcase-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Test Case Description"
                disabled
                required={true}
                rules={{
                  required: "Test Case Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-expectedResult" xs={12}>
              <FormInput
                control={control}
                name="expectedResult"
                type="text"
                multiline
                disabled
                rows={7}
                label="Expected Result"
                required={true}
                rules={{
                  required: "Expected Result is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-featureId" xs={12}>
              <FormInput
                control={control}
                name="featureId"
                type="text"
                label="Feature"
                disabled
                required={true}
                rules={{
                  required: "Feature is required"
                }}
              />
            </Grid>
            {platforms.map((platform, index) => {
              return (
                <Grid container key={index} xs={12}>
                  <Grid data-testid={platform + "-testcase-platform"} xs={6}>
                    <FormSelect
                      options={PLATFORM_OPTIONS}
                      control={control}
                      name={platform}
                      disabled
                      label={index == 0 ? "Platforms" : ""}
                      required={true}
                    />
                  </Grid>
                  <Grid data-testid={platform + "TesterId"} xs={6}>
                    <FormSelect
                      control={control}
                      options={testerOptions}
                      name={platform + "TesterId"}
                      label={index == 0 ? "Testers" : ""}
                      disabled
                      required={true}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Form>
        <Box
          sx={{
            backgroundColor: "#E5E5E5",
            height: "65px",
            width: "100%",
            padding: "8px 32px 8px 32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: 600,
              color: "#666666",
              lineHeight: "28px",
              width: "350px"
            }}
          >
            Executed Tests
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{ fontSize: "18px", fontWeight: 300, color: "#1E293B" }}
            >
              View
            </Typography>
            <div
              data-testid="viewWidget"
              style={{
                width: "200px",
                marginLeft: "8px",
                background: "#fff",
                borderRadius: "7px"
              }}
            >
              <FormSelect
                control={control}
                name="selectedPlatform"
                options={TEST_RUN_FILTER_OPTIONS}
                onChange={(e) => {
                  if (
                    e.target.value == "IOS" ||
                    e.target.value == "WEB" ||
                    e.target.value == "AND"
                  ) {
                    setFilter([e.target.value]);
                    return;
                  }
                  if (e.target.value == "ALL") {
                    setFilter([]);
                    return;
                  }
                  setFilter((prevFilter) => {
                    if (prevFilter.length >= 2) {
                      return [...prevFilter.slice(0, -1), e.target.value];
                    } else {
                      return [...prevFilter, e.target.value];
                    }
                  });
                }}
              />
            </div>
            {permissions.create && (
              <div
                data-testid="add-testcase-btn"
                style={{
                  marginLeft: "36px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                onClick={() =>
                  navigate(
                    `/testcases/${testcase?.data.feature?.featureId}/create`
                  )
                }
              >
                <AddIcon
                  style={{ fill: "#2B337A", height: "24px", width: "24px" }}
                />
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#2B337A",
                    marginLeft: "3px"
                  }}
                >
                  Add Test Case
                </Typography>
              </div>
            )}
          </div>
        </Box>
        <RenderTableView
          title=""
          columns={LIST_COLUMNS}
          hideToolbar={true}
          isDeleteDisabled={() => true}
          getRowId={(row) => row.testRunId}
          rows={rows}
          hideFooter={true}
          hasActionColumn={false}
          isLoading={testrunLoading}
        />
      </Loader>
    </Container>
  );
};
