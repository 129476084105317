import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LinkButton = styled(Link)(({ theme }) => ({
  margin: "1rem 0 0 1rem",
  color: theme.palette.primary.main,
  "&:hover": {
    opacity: 0.8,
  },
}));
