import { TableView } from "@components/TableView";
import { getImportTemplateProviders } from "@services/Network";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  { headerName: "ERP Type", field: "erpType", minWidth: 100, flex: 1 },
  {
    headerName: "No. of Versions",
    field: "versions",
    valueGetter: ({ row }) => row?._count?.versions || 0,
    minWidth: 135,
    flex: 1
  },
  {
    headerName: "No. of Organizations",
    field: "organizations",
    valueGetter: ({ row }) => row?._count?.organizations || 0,
    minWidth: 175,
    flex: 1
  }
];

export const ImportTemplateProviders = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);

  const onAdd = () => navigate("/import-template-providers/create");
  const onEdit = (itp) =>
    navigate(`/import-template-providers/${itp.providerId}/edit`);
  const onView = (itp) =>
    navigate(`/import-template-providers/${itp.providerId}`);

  return (
    <>
      <TableView
        columns={LIST_COLUMNS}
        defaultSort={[{ field: "name", sort: "asc" }]}
        getRowId={(row) => row.providerId}
        hideFilter={true}
        hideLabelContainer={true}
        isDeleteDisabled={() => true}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
        title="Import Template Providers"
        useGet={getImportTemplateProviders}
      />
    </>
  );
};
