import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelDepartment,
  useAdminDepartmentGet,
  useAdminJobTitileTitleIdGet,
  useAdminJobTitleTitleIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";
import { JobTitleForm } from "./jobTitleForm";

export const JobTitleView = () => {
  const navigate = useNavigate();
  const { jobTitleId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset } = form;

  const { data: departmentsData, isLoading: isLoadingDepartments } =
    useAdminDepartmentGet();

  const { data: data, isLoading: isLoading } = useAdminJobTitileTitleIdGet(
    jobTitleId!
  );
  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  const [canSetDemo, setCanSetDemo] = useState(false);
  const [canSellAccount, setCanSellAccount] = useState(false);
  const [canSupportAccount, setCanSupportAccount] = useState(false);

  React.useEffect(() => {
    if (data?.data) {
      const jobTitle = data.data;
      reset({
        name: jobTitle.name,
        description: jobTitle.description,
        departmentId: jobTitle.departmentId,
        isActive: jobTitle.isActive,
        abbreviation: jobTitle.abbreviation,
        supportsCalls: jobTitle.supportsCalls,
        supportsCallCenterCalls: jobTitle.supportsCallCenterCalls,
        canSetDemo: jobTitle.canSetDemo,
        canSellAccount: jobTitle.canSellAccount,
        canSupportAccount: jobTitle.canSupportAccount,
        maxAccountsDemo: jobTitle.maxAccountsDemo,
        maxAccountsSupport: jobTitle.maxAccountsSupport,
        maxOpenOppSell: jobTitle.maxOpenOppSell,
        orgRatingDemo: jobTitle.orgRatingDemo || [],
        orgRatingSell: jobTitle.orgRatingSell || [],
        orgRatingSupport: jobTitle.orgRatingSupport || [],
        athletesThresholdDemo: jobTitle.athletesThresholdDemo,
        athletesThresholdSell: jobTitle.athletesThresholdSell,
        athletesThresholdSupport: jobTitle.athletesThresholdSupport
      });
      setCanSetDemo(jobTitle.canSetDemo as boolean);
      setCanSellAccount(jobTitle.canSellAccount as boolean);
      setCanSupportAccount(jobTitle.canSupportAccount as boolean);
    }
  }, [data]);
  React.useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminJobTitleTitleIdDelete();

  const onArticleDelete = async () => {
    try {
      await deleteAsync({ jobTitleId: jobTitleId as string });
      enqueueSnackbar("Job Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/job-titles");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Job.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Job"
        backBtnClick={() => {
          navigate("/job-titles");
        }}
        editBtnClick={() => {
          navigate(`/job-titles/${jobTitleId}/edit`);
        }}
        deleteBtnClick={() => {
          setOpenDeleteDialog(true);
        }}
      />
      <Loader isLoading={isLoading || isDeleting}>
        <JobTitleForm
          form={form}
          departments={departments}
          disabled
          isLoadingDepartments={isLoadingDepartments}
          canSetDemo={canSetDemo}
          canSellAccount={canSellAccount}
          canSupportAccount={canSupportAccount}
          setCanSellAccount={setCanSellAccount}
          setCanSetDemo={setCanSetDemo}
          setCanSupportAccount={setCanSupportAccount}
        />
      </Loader>
      <ConfirmationDialog
        title="Delete Job?"
        body="Are you sure you want to delete this job?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onArticleDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
