/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchInput } from "@components/SearchInput";
import { Autocomplete, Grid, MenuItem, Select } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

export const FSOFilter = ({
  data,
  setChartData
}: {
  data: any;
  setChartData: any;
}) => {
  const [seasonFilter, setSeasonFilter] = useState<string>("All Seasons");
  const [teamFilter, setTeamFilter] = useState<string>("All Teams");
  const [searchValue, setSearchValue] = useState<string>(
    "All Training Programs"
  );
  const [trainingProgramFilter, setTrainingProgramFilter] = useState<string>(
    "All Training Programs"
  );
  const seasonOptions = useMemo(() => {
    return (
      data[0]?.data[0]?.filters.map((filter) => ({
        label: filter,
        value: filter
      })) || []
    );
  }, [data]);

  const teamsOptions = useMemo(() => {
    let options: { label: string; value: string }[] = [];

    if (seasonFilter != "All Seasons") {
      options =
        (data[0]?.data[0]?.additionalFilterValues &&
          data[0]?.data[0]?.additionalFilterValues?.[seasonFilter] &&
          Object.keys(
            data[0]?.data[0]?.additionalFilterValues[seasonFilter]
          ).map((filter) => ({
            label: filter,
            value: filter
          }))) ||
        [];
    }

    options.unshift({ label: "All Teams", value: "All Teams" });

    return options;
  }, [seasonFilter]);

  const trainingProgramOptions = useMemo(() => {
    if (data[0]?.isFilterSearchable && data[0]?.data[0]?.filters.length > 0) {
      return (
        data[0]?.data[0]?.filters.map((filter) => ({
          label: filter,
          value: filter
        })) || []
      );
    }
  }, [data]);

  useEffect(() => {
    if (trainingProgramFilter.length > 0) {
      setChartData(data[0]?.data[0]?.filterValues?.[trainingProgramFilter]);
    }
  }, [trainingProgramOptions]);

  useEffect(() => {
    setTeamFilter(teamsOptions[0]?.value);
  }, [teamsOptions]);

  useEffect(() => {
    if (
      seasonOptions.length > 0 &&
      data[0]?.isFilterable &&
      !data[0]?.isFilterSearchable
    ) {
      setChartData(data[0]?.data[0]?.filterValues?.[seasonFilter]);
    }
  }, [seasonOptions]);

  return (
    <Grid paddingBottom="24px" container justifyContent="center" columnGap={3}>
      {data[0]?.isFilterable &&
        !data[0]?.isFilterSearchable &&
        data[0]?.data[0]?.filters.length > 0 && (
          <Grid xs={3}>
            <Select
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 5
                  }
                }
              }}
              value={seasonFilter}
              onChange={(e) => {
                if (e.target.value === "All Seasons") {
                  setTeamFilter("All Teams");
                }
                setSeasonFilter(e.target.value);
                if (setChartData) {
                  setChartData(data[0]?.data[0]?.filterValues[e.target.value]);
                }
              }}
            >
              {seasonOptions.map((s) => (
                <MenuItem key={s.value} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      {data[0]?.data[0]?.additionalFilters.length > 0 &&
        data[0]?.isFilterable &&
        !data[0]?.isFilterSearchable && (
          <Grid xs={3}>
            <Select
              disabled={
                seasonFilter === "All Seasons" || teamsOptions.length == 1
              }
              fullWidth
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: 5
                  }
                }
              }}
              onChange={(e) => {
                setTeamFilter(e.target.value);
                if (setChartData && e.target.value !== "All Teams") {
                  setChartData(
                    data[0]?.data[0]?.additionalFilterValues[seasonFilter][
                      e.target.value
                    ]
                  );
                }
                if (setChartData && e.target.value === "All Teams") {
                  setChartData(
                    data[0]?.data[0]?.additionalFilterValues["All Teams"]
                  );
                }
              }}
              value={teamFilter}
            >
              {teamsOptions.map((s) => (
                <MenuItem key={s.value} value={s.value}>
                  {s.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
      {data[0]?.isFilterSearchable && data[0]?.isFilterable && (
        <Autocomplete
          disableClearable
          value={trainingProgramFilter}
          onChange={(event: any, newValue: any) => {
            setTrainingProgramFilter(newValue.value);

            if (setChartData) {
              setChartData(data[0]?.data[0]?.filterValues[newValue.value]);
            }
          }}
          inputValue={searchValue}
          isOptionEqualToValue={(option, value) => {
            return option.value === value;
          }}
          onInputChange={(event, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          options={trainingProgramOptions}
          sx={{ width: "50%" }}
          renderInput={(params) => {
            return (
              <SearchInput
                {...params}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password"
                }}
              />
            );
          }}
        />
      )}
    </Grid>
  );
};
