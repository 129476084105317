import { Edit } from "@mui/icons-material";
import { Box, Divider, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import colors from "theme/colors";

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%"
}));

const Step = styled(Box)(() => ({
  display: "flex"
}));

const StepNumber = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  color: theme.palette.white.main,
  backgroundColor: "#64748B",
  marginRight: "0.5rem",

  "&.active-step": {
    backgroundColor: colors.info.main
  }
}));

const LineContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  padding: "0 1rem"
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: "1px",
  backgroundColor: theme.palette.divider,
  width: "100%"
}));

export const StepProgressLine = ({
  steps,
  activeStepNumber,
  onEditClick
}: {
  steps: string[];
  activeStepNumber: number;
  onEditClick?: (activeNumber: number) => void;
}) => {
  return (
    <Container>
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <Step>
            <StepNumber
              data-testid={"STEP_" + index}
              className={index <= activeStepNumber ? "active-step" : ""}
            >
              {index < activeStepNumber ? (
                <IconButton
                  onClick={() => {
                    onEditClick ? onEditClick(index) : {};
                  }}
                >
                  <Edit htmlColor="white" />
                </IconButton>
              ) : (
                index + 1
              )}
            </StepNumber>

            <Typography>{step}</Typography>
          </Step>
          {index !== steps.length - 1 && (
            <LineContainer>
              <StyledDivider />
            </LineContainer>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};
