/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useMemo } from "react";

type ApiFunction = (
  params?: any,
  options?: any
) => UseQueryResult<AxiosResponse | undefined, any>;
type UseApiSelectOptionsProps = {
  api: ApiFunction;
  params?: any;
  options?: any;
  dataField?: string;
  labelField?: string;
  valueField?: string;
};

export const useApiSelectOptions = ({
  api,
  params,
  options,
  dataField,
  labelField = "name",
  valueField
}: UseApiSelectOptionsProps): {
  isLoading: boolean;
  options: { label: string; value: string }[];
} => {
  const { data, isLoading } = api(params, options);

  const selectOptions = useMemo(() => {
    if (!data?.data) {
      return [];
    }

    if (!dataField) {
      dataField = findFirstKey(data.data);
    }

    const dataToUse = data.data[dataField] || [];
    if (!dataToUse.length) {
      return [];
    }

    if (!valueField) {
      valueField = findIdKey(dataToUse[0]);
    }

    return dataToUse.map((item: any) => {
      const option: { label: string; value: string } = {
        label: item?.[labelField],
        value: item?.[valueField as string]
      };

      return option;
    });
  }, [isLoading, data, dataField, labelField, valueField, options, params]);

  return { isLoading, options: selectOptions };
};

const findFirstKey = (obj: object): string => {
  for (const key in obj) {
    return key;
  }

  throw new Error("No key found");
};

const findIdKey = (obj: object): string => {
  for (const key in obj) {
    if (key.includes("Id")) {
      return key;
    }
  }

  throw new Error("No Id key found");
};
