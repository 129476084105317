/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from "@components/crud/Container";
import { Box, Grid, styled, Typography } from "@mui/material";
import { useState } from "react";
import InfoIcon from "@assets/icons/info.svg";
import { FormSelect } from "@components/FormSelect";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToolTip } from "@components/ToolTip";

const StyledDateCalendar = styled(DateCalendar)`
  margin: 0;
  .MuiPickersCalendarHeader-root {
    padding: 0 0 0 10px;
  }
  .MuiDayCalendar-header,
  .MuiDayCalendar-weekContainer {
    justify-content: space-between;
  }
  .MuiPickersArrowSwitcher-root {
    display: none;
  }
  .MuiPickersCalendarHeader-label {
    cursor: default;
  }
  .Mui-selected:not(.full-day):not(.half-day) {
    background-color: #fff !important;
    color: #000;
  }
  .MuiPickersDay-today {
    border: 2px solid #2b337a;
    color: #2b337a;
  }

  .MuiPickersDay-root.full-day,
  .MuiPickersDay-root:focus.full-day,
  .Mui-selected.full-day,
  .Mui-selected:hover.full-day,
  .full-day,
  .full-day:hover {
    background-color: #2b337a;
    color: #fff !important;
  }
  .MuiPickersDay-root.half-day,
  .MuiPickersDay-root:focus.half-day,
  .Mui-selected.half-day,
  .Mui-selected:hover.half-day,
  .half-day,
  half-day:hover {
    background-color: #b9bfde;
    color: #000 !important;
  }
`;

export const UserAvailability = ({
  disabled,
  setFullDays,
  setHalfDays,
  halfDays,
  fullDays
}: {
  disabled: boolean;
  setFullDays?: React.Dispatch<React.SetStateAction<Dayjs[]>>;
  setHalfDays?: React.Dispatch<React.SetStateAction<Dayjs[]>>;
  halfDays: Dayjs[];
  fullDays: Dayjs[];
}) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const leaves = ({
    fullDays,
    halfDays,
    outsideCurrentMonth,
    day,
    ...other
  }: {
    fullDays: Dayjs[];
    halfDays: Dayjs[];
    outsideCurrentMonth: boolean;
    day: Dayjs;
  }) => {
    const fullDay =
      !outsideCurrentMonth && fullDays.find((date) => date.isSame(day, "date"));

    const halfDay =
      !outsideCurrentMonth && halfDays.find((date) => date.isSame(day, "date"));
    return (
      <PickersDay
        className={fullDay ? "full-day" : halfDay ? "half-day" : ""}
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        onClick={() => {
          setHalfDays((prevHalfDays) => {
            let halfDayLeaves = [...prevHalfDays];
            let fullDayLeaves = [...fullDays];
            if (
              !halfDayLeaves.find((date) => date.isSame(day, "day")) &&
              !fullDayLeaves.find((date) => date.isSame(day, "day"))
            ) {
              halfDayLeaves.push(day);
            } else if (halfDayLeaves.find((date) => date.isSame(day, "day"))) {
              halfDayLeaves = halfDayLeaves.filter(
                (date) => !date.isSame(day, "day")
              );
              fullDayLeaves.push(day);
            } else if (fullDayLeaves.find((date) => date.isSame(day, "day"))) {
              fullDayLeaves = fullDayLeaves.filter(
                (date) => !date.isSame(day, "day")
              );
            }

            setFullDays(fullDayLeaves);
            return halfDayLeaves;
          });
        }}
      />
    );
  };
  return (
    <Container>
      <Box sx={{ padding: "0 32px" }}>
        <Grid container>
          <Grid container item xs={6} alignItems="center">
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: 300,
                lineHeight: "48px",
                color: "#1E293B"
              }}
            >
              {selectedYear}
            </Typography>
            <ToolTip
              title={
                <div
                  style={{
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <div
                      style={{
                        height: "20px",
                        width: "20px",
                        border: "2px solid #2B337A",
                        borderRadius: "10px"
                      }}
                    ></div>
                    <div>Current Day</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <div
                      style={{
                        height: "20px",
                        width: "20px",
                        border: "2px solid #B9BFDE",
                        backgroundColor: "#B9BFDE",
                        borderRadius: "10px"
                      }}
                    ></div>
                    <div>Half Day Leave</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <div
                      style={{
                        height: "20px",
                        width: "20px",
                        border: "2px solid #2B337A",
                        backgroundColor: "#2B337A",
                        borderRadius: "10px"
                      }}
                    ></div>
                    <div>Full Day Leave</div>
                  </div>
                </div>
              }
            >
              <img
                src={InfoIcon}
                style={{ paddingLeft: "8px", height: "24px" }}
              />
            </ToolTip>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 300,
                lineHeight: "22px",
                color: "#0F0F0F",
                paddingRight: "8px"
              }}
            >
              Year
            </Typography>
            <Box sx={{ width: "120px" }}>
              <FormSelect
                name="year"
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                }}
                options={[
                  {
                    label: "2024",
                    value: "2024"
                  },
                  {
                    label: "2023",
                    value: "2023"
                  }
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          data-testid="calendarView"
        >
          {(new Array(12).fill(0) as number[]).map((_, index) => {
            return (
              <LocalizationProvider key={index} dateAdapter={AdapterDayjs}>
                <StyledDateCalendar
                  key={`${selectedYear}-month-${index}`}
                  referenceDate={dayjs(`${selectedYear}-${index + 1}-01`)}
                  views={["day"]}
                  disablePast
                  disabled={disabled}
                  slots={{
                    day: leaves
                  }}
                  slotProps={{
                    day: {
                      fullDays: fullDays,
                      halfDays: halfDays
                    }
                  }}
                />
              </LocalizationProvider>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};
