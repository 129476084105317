import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminFeatureFeatureIdGet,
  useAdminFeatureTestcaseGet,
  useAdminFeatureTestcasePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { PLATFORM_OPTIONS, TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormHelperText, FormLabel } from "@mui/material";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";

export const TestcaseCreate = () => {
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const { testcaseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [featureId, setFeatureId] = useState(testcaseId ? testcaseId : "");
  const [showUiReasonField, setShowUiReasonField] = useState<string[]>([]);
  const [showUiJiraField, setShowUiJiraField] = useState<string[]>([]);
  const [showFnReasonField, setShowFnReasonField] = useState<string[]>([]);
  const [showFnJiraField, setShowFnJiraField] = useState<string[]>([]);
  const [platformOptions, setPlatformOptions] = useState(PLATFORM_OPTIONS);
  const [featureFieldFocused, setFeatureFieldFocused] = useState<boolean>();
  const { data: feature, isLoading: isFeatureLoading } =
    useAdminFeatureFeatureIdGet(testcaseId!);
  const { data: previousTestcase, isLoading: previousTestcaseLoading } =
    useAdminFeatureTestcaseGet({ featureId: "latest" });

  const { data: selectedFeature, isLoading: isSelectedFeatureLoading } =
    useAdminFeatureFeatureIdGet(featureId!);

  const [platformsAutomated, setPlatformAutomated] = useState({
    iosUI: false,
    iosFN: false,
    andUI: false,
    andFN: false,
    webUI: false,
    webFN: false,
    apiFN: false,
    devFN: false
  });
  useEffect(() => {
    //setPlatforms
    if (selectedFeature?.data) {
      setPlatformOptions([]);
      selectedFeature.data.platforms.map((platform) => {
        if (platform == "IOS") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "iOS", value: "ios" }
          ]);
        }
        if (platform == "WEB") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "Web", value: "web" }
          ]);
        }
        if (platform == "AND") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "AND", value: "and" }
          ]);
        }
        if (platform == "API") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "API", value: "api" }
          ]);
        }
        if (platform == "DEV") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "DEVops", value: "dev" }
          ]);
        }
      });
    }
  }, [selectedFeature]);

  const defaultValues = PLATFORM_OPTIONS.reduce((acc, platform) => {
    const platformValues = {
      description: "",
      expectedResult: "",
      featureId: "",
      platforms: [],
      isActive: true,
      [platform.value + "DefaultUIStatus"]: "NOT_TESTED",
      [platform.value + "DefaultFNStatus"]: "NOT_TESTED"
    };
    return { ...acc, ...platformValues };
  }, {});
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues
  });
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const create = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "ADD"
    );
    create.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);
  useEffect(() => {
    if (feature?.data) {
      //@ts-ignore
      setValue("featureId", feature?.data.name as string);
      setFeatureId(feature?.data.featureId as string);
    }
    if (feature?.data.testCases && feature?.data?.testCases?.length > 0) {
      //@ts-ignore
      const createdAt = new Date(feature.data.testCases[0].createdAt);
      const now = new Date();
      const differenceInMinutes =
        (now.getTime() - createdAt.getTime()) / (1000 * 60);
      if (testcaseId && differenceInMinutes <= 5) {
        if (feature.data.testCases[0].createdAt)
          setPlatforms(
            feature?.data?.testCases[0].platforms!.map((platform) =>
              platform.toLowerCase()
            )
          );
        setValue(
          //@ts-ignore
          "platforms",
          feature?.data?.testCases[0].platforms!.map((platform) =>
            platform.toLowerCase()
          )
        );
      }
    }
    if (!testcaseId && previousTestcase?.data) {
      const createdAt = new Date(previousTestcase.data.testcases![0].createdAt);
      const now = new Date();
      const differenceInMinutes =
        (now.getTime() - createdAt.getTime()) / (1000 * 60);
      if (differenceInMinutes <= 5) {
        setFeatureId(previousTestcase?.data.testcases![0].featureId);
        setValue(
          "featureId",
          previousTestcase?.data.testcases![0].feature.name
        );
        setValue(
          "platforms",
          previousTestcase?.data.testcases![0].platforms!.map((platform) =>
            platform.toLowerCase()
          )
        );
        setPlatforms(
          previousTestcase?.data.testcases![0].platforms!.map((platform) =>
            platform.toLowerCase()
          )
        );
      }
    }
  }, [feature, previousTestcase]);

  const { mutate: save, isLoading: isSaving } = useAdminFeatureTestcasePost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      //@ts-ignore
      const featureName = getValues("featureId");
      values["featureId"] = featureId;
      values["platforms"] = platforms.map((platform) =>
        platform.substring(0, 3).toUpperCase()
      );
      PLATFORM_OPTIONS.map((platform) => {
        if (platforms.includes(platform.value)) {
          values[platform.value + "DefaultUIStatus"] =
            formValues[platform.value + "DefaultUIStatus"];
          values[platform.value + "DefaultFNStatus"] =
            formValues[platform.value + "DefaultFNStatus"];
          if (
            formValues[platform.value + "DefaultUIStatus"] == "FAILED" ||
            formValues[platform.value + "DefaultUIStatus"] == "BLOCKED"
          ) {
            if (formValues[platform.value + "UiJiraLink"]) {
              values[platform.value + "UiJiraLink"] =
                formValues[platform.value + "UiJiraLink"];
            }
          }
          if (formValues[platform.value + "DefaultUIStatus"] == "HOLD") {
            if (formValues[platform.value + "UiReason"]) {
              values[platform.value + "UiReason"] =
                formValues[platform.value + "UiReason"];
            }
          }
          if (
            formValues[platform.value + "DefaultFNStatus"] == "FAILED" ||
            formValues[platform.value + "DefaultFNStatus"] == "BLOCKED"
          ) {
            if (formValues[platform.value + "FnJiraLink"]) {
              values[platform.value + "FnJiraLink"] =
                formValues[platform.value + "FnJiraLink"];
            }
          }
          if (formValues[platform.value + "DefaultFNStatus"] == "HOLD") {
            if (formValues[platform.value + "FnReason"]) {
              values[platform.value + "FnReason"] =
                formValues[platform.value + "FnReason"];
            }
          }
        } else {
          delete values[platform.value + "DefaultUIStatus"];
          delete values[platform.value + "DefaultFNStatus"];
        }
        delete values[`${platform.value}automatedUI`];
        delete values[`${platform.value}automatedFn`];
      });
      try {
        save(
          {
            data: values
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Testcase added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset({
                  featureId: featureName,
                  platforms: platforms
                });
                setShowFnJiraField([]);
                setShowFnReasonField([]);
                setShowUiJiraField([]);
                setShowUiReasonField([]);
              } else {
                window.history.back();
              }
            },
            onError: () => {
              enqueueSnackbar("Failed to add testcase!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to add testcase!", {
          variant: "error"
        });
      }
    };
  return (
    <Container>
      <Toolbar title="Add Test Case" />
      <Loader
        isLoading={testcaseId ? isFeatureLoading : previousTestcaseLoading}
      >
        <Form>
          <Grid data-testid="testcase-add-form" container spacing={3}>
            <Grid data-testid="testcase-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Test Case Description"
                required={true}
                rules={{
                  required: "Test Case Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-expectedResult" xs={12}>
              <FormInput
                control={control}
                name="expectedResult"
                type="text"
                multiline
                rows={7}
                label="Expected Result"
                required={true}
                rules={{
                  required: "Expected Result is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-featureId" xs={12}>
              <FilterTreeSearchComponent
                control={control}
                name="featureId"
                required={true}
                rules={{
                  required: "Feature is required"
                }}
                selectedValue={featureId}
                label="Feature"
                onFilterChange={(val) => {
                  setFeatureId(val);
                  setPlatforms([]);
                  setValue("platforms", []);
                  setShowFnJiraField([]);
                  setShowFnReasonField([]);
                  setShowUiJiraField([]);
                  setShowUiReasonField([]);
                }}
                onClearFilter={() => {
                  setFeatureId("");
                  setPlatforms([]);
                  setValue("platforms", []);
                  setShowFnJiraField([]);
                  setShowFnReasonField([]);
                  setShowUiJiraField([]);
                  setShowUiReasonField([]);
                }}
                setValue={setValue}
                TextProps={{
                  onBlur: () => {
                    setFeatureFieldFocused(false);
                  },
                  onFocus: () => {
                    setFeatureFieldFocused(true);
                  }
                }}
              />
              <FormHelperText error={true}>
                {featureFieldFocused == false &&
                  featureId == "" &&
                  "Feature is required"}
              </FormHelperText>
            </Grid>
            <Grid data-testid="testcase-platforms" xs={12}>
              <FormMultiSelect
                control={control}
                options={platformOptions}
                name="platforms"
                label="Platforms"
                disabled={featureId == ""}
                isLoading={isSelectedFeatureLoading && featureId != ""}
                required={true}
                rules={{
                  required: "Platforms is required"
                }}
                onChange={(e) => {
                  setPlatforms(e.target.value);
                  setValue(e.target.value + "DefaultUIStatus", "NOT_TESTED");
                  setValue(e.target.value + "DefaultFNStatus", "NOT_TESTED");
                }}
                onRemove={(value) =>
                  setPlatforms(
                    platforms.filter((platform) => platform !== value)
                  )
                }
              />
            </Grid>
            {platforms.map((platform, index) => {
              return (
                <Grid container key={index} xs={12}>
                  {platform !== "dev" && platform !== "api" && (
                    <Grid
                      data-testid={platform + "-testcase-DefaultUIStatus"}
                      container
                      direction="column"
                      spacing="10px"
                      xs={6}
                    >
                      <Grid>
                        <FormSelect
                          options={TESTCASE_STATUS_OPTIONS}
                          control={control}
                          disabled={platformsAutomated[`${platform}UI`]}
                          name={platform + "DefaultUIStatus"}
                          type="date"
                          label={
                            platform == "web"
                              ? "Web Default UI Status"
                              : platform == "ios"
                              ? "iOS Default UI Status"
                              : "AND Default UI Status"
                          }
                          required={true}
                          rules={{
                            required: "UI Status is required"
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value == "FAILED" ||
                              e.target.value == "BLOCKED"
                            ) {
                              if (showUiReasonField.includes(platform)) {
                                setShowUiReasonField(
                                  showUiReasonField.filter(
                                    (item) => item !== platform
                                  )
                                );
                              }
                              setShowUiJiraField([
                                ...showUiJiraField,
                                platform
                              ]);
                              return;
                            }
                            if (e.target.value == "HOLD") {
                              if (showUiJiraField.includes(platform)) {
                                setShowUiJiraField(
                                  showUiJiraField.filter(
                                    (item) => item !== platform
                                  )
                                );
                              }
                              setShowUiReasonField([
                                ...showUiReasonField,
                                platform
                              ]);
                              return;
                            }
                            if (showUiJiraField.includes(platform)) {
                              setShowUiJiraField(
                                showUiJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            if (showUiReasonField.includes(platform)) {
                              setShowUiReasonField(
                                showUiReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid>
                        <FormCheckbox
                          name={platform + "automatedUI"}
                          label="Automated UI Unit Test Complete"
                          control={control}
                          onChange={(e) => {
                            setValue(
                              platform + "DefaultUIStatus",
                              e.target.checked ? "AUTOMATED" : "NOT_TESTED"
                            );
                            const automated = platformsAutomated;
                            automated[`${platform}UI`] = e.target.checked;
                            setPlatformAutomated({ ...automated });
                            if (showUiJiraField.includes(platform)) {
                              setShowUiJiraField(
                                showUiJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            if (showUiReasonField.includes(platform)) {
                              setShowUiReasonField(
                                showUiReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    data-testid={platform + "-testcase-DefaultFNStatus"}
                    xs={6}
                    container
                    direction="column"
                    spacing="10px"
                  >
                    <Grid>
                      <FormSelect
                        control={control}
                        options={TESTCASE_STATUS_OPTIONS}
                        disabled={platformsAutomated[`${platform}FN`]}
                        name={platform + "DefaultFNStatus"}
                        label={
                          platform == "web"
                            ? "Web Default FN Status"
                            : platform == "ios"
                            ? "iOS Default FN Status"
                            : platform == "api"
                            ? "API Default FN Status"
                            : platform == "dev"
                            ? "DEVOps Default FN Status"
                            : "AND Default FN Status"
                        }
                        required={true}
                        rules={{
                          required: "FN Status is required"
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value == "FAILED" ||
                            e.target.value == "BLOCKED"
                          ) {
                            if (showFnReasonField.includes(platform)) {
                              setShowFnReasonField(
                                showFnReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            setShowFnJiraField([...showFnJiraField, platform]);
                            return;
                          }
                          if (e.target.value == "HOLD") {
                            if (showFnJiraField.includes(platform)) {
                              setShowFnJiraField(
                                showFnJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            setShowFnReasonField([
                              ...showFnReasonField,
                              platform
                            ]);
                            return;
                          }
                          if (showFnJiraField.includes(platform)) {
                            setShowFnJiraField(
                              showFnJiraField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                          if (showFnReasonField.includes(platform)) {
                            setShowFnReasonField(
                              showFnReasonField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                        }}
                      />
                    </Grid>

                    <Grid>
                      <FormCheckbox
                        name={platform + "automatedFn"}
                        label="Automated FN Unit Test Complete"
                        control={control}
                        onChange={(e) => {
                          setValue(
                            platform + "DefaultFNStatus",
                            e.target.checked ? "AUTOMATED" : "NOT_TESTED"
                          );
                          const automated = platformsAutomated;
                          automated[`${platform}FN`] = e.target.checked;
                          setPlatformAutomated({ ...automated });
                          if (showFnJiraField.includes(platform)) {
                            setShowFnJiraField(
                              showFnJiraField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                          if (showFnReasonField.includes(platform)) {
                            setShowFnReasonField(
                              showFnReasonField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  {(showUiJiraField.includes(platform) ||
                    showUiReasonField.includes(platform)) &&
                    (showUiJiraField.includes(platform) ? (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiJiraLink"}
                          control={control}
                          label={
                            platform == "web"
                              ? "Web Jira Link"
                              : platform == "ios"
                              ? "iOS Jira Link"
                              : platform == "api"
                              ? "API Jira Link"
                              : platform == "dev"
                              ? "DEVOps Jira Link"
                              : "AND Jira Link"
                          }
                          required
                          rules={{
                            required: "Jira Link is required"
                          }}
                          type="text"
                        />
                      </Grid>
                    ) : (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiReason"}
                          multiline
                          rows={4}
                          control={control}
                          label={
                            platform == "web"
                              ? "Web Reason"
                              : platform == "ios"
                              ? "iOS Reason"
                              : platform == "api"
                              ? "API Reason"
                              : platform == "dev"
                              ? "DEVOps Reason"
                              : "AND Reason"
                          }
                          required
                          rules={{
                            required: "Reason is required"
                          }}
                          type="text"
                        />
                      </Grid>
                    ))}

                  {(showFnJiraField.includes(platform) ||
                    showFnReasonField.includes(platform)) &&
                    (showFnJiraField.includes(platform) ? (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnJiraLink"}
                            control={control}
                            label={
                              platform == "web"
                                ? "Web Jira Link"
                                : platform == "ios"
                                ? "iOS Jira Link"
                                : platform == "api"
                                ? "API Jira Link"
                                : platform == "dev"
                                ? "DEVOps Jira Link"
                                : "AND Jira Link"
                            }
                            required
                            rules={{
                              required: "Jira Link is required"
                            }}
                            type="text"
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnReason"}
                            multiline
                            rows={4}
                            control={control}
                            label={
                              platform == "web"
                                ? "Web Reason"
                                : platform == "ios"
                                ? "iOS Reason"
                                : platform == "api"
                                ? "API Reason"
                                : platform == "dev"
                                ? "DEVOps Reason"
                                : "AND Reason"
                            }
                            required
                            rules={{
                              required: "Reason is required"
                            }}
                            type="text"
                          />
                        </Grid>
                      </>
                    ))}
                </Grid>
              );
            })}
            <Grid
              sx={{ display: "flex", alignItems: "center" }}
              data-testid="testcase-isActive"
            >
              <FormLabel
                sx={{ width: "125px", fontSize: "0.875rem", fontWeight: 700 }}
              >
                Is Active
              </FormLabel>
              <FormCheckbox control={control} name="isActive" />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => {
          window.history.back();
        }}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
