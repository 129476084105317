/* eslint-disable @typescript-eslint/no-explicit-any */
import { Loader } from "@components/crud/Loader";
import {
  Column,
  ColumnGrouping,
  DataGridTable
} from "@components/DataGridTable";
import { Grid, Typography, Divider, styled } from "@mui/material";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import { adminMetricPneFeatureCostOverviewGet } from "@sportsgravyengineering/sg-api-react-sdk/lib/api";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { formatCurrency } from "@utils/formatCurrenct";

const dateFormat = (isoString: string): string => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric"
  });
};

const StyledGrid = styled(Grid)`
  .pinned-header:nth-child(1),
  .pinned-body:nth-child(1) {
    border-right: 2px solid #000 !important;
  }
`;

export const FeatureCostOverview = ({
  platform,
  resourceId
}: {
  platform: "WEB" | "API" | "DEV" | "AND" | "IOS" | "ALL";
  resourceId: string;
}) => {
  const navigate = useNavigate();
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [refreshKey, setRefreshKey] = useState(0);
  const handleCellClick = (featureId: string) => {
    navigate(`?featureId=${featureId}`);
  };

  const query = useMemo(() => {
    const buildQuery = {} as {
      accountId?: string | undefined;
      platform: "WEB" | "API" | "DEV" | "AND" | "IOS";
      dtStart?: string | undefined;
      dtEnd?: string | undefined;
    };

    if (resourceId === "ALL") {
      delete buildQuery.accountId;
    } else {
      buildQuery.accountId = resourceId;
    }

    if (platform !== "ALL") {
      buildQuery.platform = platform;
    }

    if (selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.dtStart = selectedTimeRange[0];
      buildQuery.dtEnd = selectedTimeRange[1];
    }
    return buildQuery;
  }, [resourceId, platform, selectedTimeRange]);

  const { data: fco, isLoading: isLoading } = useQuery(
    [
      "adminMetricPneFeatureCostOverview",
      platform,
      resourceId,
      selectedTimeRange
    ],
    () => adminMetricPneFeatureCostOverviewGet(query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60,
      refetchOnWindowFocus: true
    }
  );

  const COLUMNS: Column[] = [
    {
      field: "feature",
      headerName: "Feature",
      width: 250,
      sortable: false,
      align: "left",
      onClick: (params) => handleCellClick(params.featureId),
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#007AFF",
              cursor: "pointer"
            }}
          >
            {params.featureName}
          </Typography>
        );
      }
    },
    {
      field: "sprints",
      headerName: "Sprints",
      width: 100,
      sortable: false,
      borderLeft: "1px solid #B3B3B3",
      renderCell: (params) => {
        return <Typography>{params.numberOfSprints}</Typography>;
      }
    },
    {
      headerName: "Start Date",
      field: "startDate",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        if (!params.startDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.startDate)}</Typography>;
      }
    },
    {
      headerName: "End Date",
      field: "endDate",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        if (!params.endDate) return <Typography>-</Typography>;
        return <Typography>{dateFormat(params.endDate)}</Typography>;
      }
    },
    {
      headerName: "Cards",
      field: "story.cards",
      width: 140,
      borderLeft: "1px solid #B3B3B3",
      sortable: false,
      renderCell: (params) => {
        return <Typography>{params.storyCards}</Typography>;
      }
    },
    {
      headerName: "Est Cost",
      field: "story.estCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {formatCurrency(params.storyEstimatedCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Act Cost",
      field: "story.actCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { storyActualCost, storyEstimatedCost } = params;
        return (
          <Typography
            style={{
              color:
                storyActualCost > storyEstimatedCost
                  ? "#E82C2C"
                  : storyActualCost == storyEstimatedCost
                  ? "#000"
                  : "#1ABC9C",
              fontWeight: storyActualCost == storyEstimatedCost ? 400 : 600
            }}
          >
            {formatCurrency(storyActualCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under $",
      field: "story.overUnder",
      width: 140,
      renderCell: (params) => {
        const { storyActualCost, storyEstimatedCost } = params;
        const difference = Math.abs(storyActualCost - storyEstimatedCost);
        const isNegative = storyActualCost < storyEstimatedCost;
        return (
          <Typography
            style={{
              color:
                difference === 0 ? "#000" : !isNegative ? "#E82C2C" : "#1ABC9C",
              fontWeight: difference === 0 ? 400 : 600
            }}
          >
            {difference === 0
              ? `$0`
              : isNegative
              ? `-${formatCurrency(difference)}`
              : `${formatCurrency(difference)}`}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under %",
      field: "story.overUnderPercentage",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { storyActualCost, storyEstimatedCost } = params;
        if (storyEstimatedCost === 0 || storyActualCost === 0) {
          return <Typography>0%</Typography>;
        }
        return (
          <Typography>
            {Math.round((storyActualCost / storyEstimatedCost) * 100)}%
          </Typography>
        );
      }
    },
    {
      headerName: "Cards",
      field: "task.cards",
      width: 140,
      sortable: false,
      borderLeft: "1px solid #B3B3B3",
      renderCell: (params) => {
        return <Typography>{params.taskCards}</Typography>;
      }
    },
    {
      headerName: "Est Cost",
      field: "task.estCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {formatCurrency(params.taskEstimatedCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Act Cost",
      field: "task.actCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { taskActualCost, taskEstimatedCost } = params;
        return (
          <Typography
            style={{
              color:
                taskActualCost > taskEstimatedCost
                  ? "#E82C2C"
                  : taskActualCost == taskEstimatedCost
                  ? "#000"
                  : "#1ABC9C",
              fontWeight: taskActualCost == taskEstimatedCost ? 400 : 600
            }}
          >
            {formatCurrency(taskActualCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under $",
      field: "task.overUnder",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { taskActualCost, taskEstimatedCost } = params;
        const difference = Math.abs(taskActualCost - taskEstimatedCost);
        const isNegative = taskActualCost < taskEstimatedCost;
        return (
          <Typography
            style={{
              color:
                difference === 0 ? "#000" : !isNegative ? "#E82C2C" : "#1ABC9C",
              fontWeight: difference === 0 ? 400 : 600
            }}
          >
            {difference === 0
              ? `$0`
              : isNegative
              ? `-${formatCurrency(difference)}`
              : `${formatCurrency(difference)}`}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under %",
      field: "task.overUnderPercentage",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { taskActualCost, taskEstimatedCost } = params;
        if (taskEstimatedCost === 0 || taskActualCost === 0)
          return <Typography>0%</Typography>;
        return (
          <Typography>
            {Math.round((taskActualCost / taskEstimatedCost) * 100)}%
          </Typography>
        );
      }
    },
    {
      headerName: "Cards",
      field: "bug.cards",
      width: 140,
      sortable: false,
      borderLeft: "1px solid #B3B3B3",
      renderCell: (params) => {
        return <Typography>{params.bugCards}</Typography>;
      }
    },
    {
      headerName: "Est Cost",
      field: "bug.estCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography>
            {formatCurrency(params.bugEstimatedCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Act Cost",
      field: "bug.actCost",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { bugActualCost, bugEstimatedCost } = params;
        return (
          <Typography
            style={{
              color:
                bugActualCost > bugEstimatedCost
                  ? "#E82C2C"
                  : bugActualCost == bugEstimatedCost
                  ? "#000"
                  : "#1ABC9C",
              fontWeight: bugActualCost == bugEstimatedCost ? 400 : 600
            }}
          >
            {formatCurrency(bugActualCost.toFixed(2))}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under $",
      field: "bug.overUnder",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { bugActualCost, bugEstimatedCost } = params;
        const difference = Math.abs(bugActualCost - bugEstimatedCost);
        const isNegative = bugActualCost < bugEstimatedCost;
        return (
          <Typography
            style={{
              color:
                difference === 0 ? "#000" : !isNegative ? "#E82C2C" : "#1ABC9C",
              fontWeight: difference === 0 ? 400 : 600
            }}
          >
            {difference === 0
              ? `$0`
              : isNegative
              ? `-${formatCurrency(difference)}`
              : `${formatCurrency(difference)}`}
          </Typography>
        );
      }
    },
    {
      headerName: "Over/Under %",
      field: "bug.overUnderPercentage",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const { bugActualCost, bugEstimatedCost } = params;
        if (bugEstimatedCost === 0 || bugActualCost === 0)
          return <Typography>0%</Typography>;
        return (
          <Typography>
            {Math.round((bugActualCost / bugEstimatedCost) * 100)}%
          </Typography>
        );
      }
    },
    {
      headerName: "TOTAL COST",
      field: "totalCost",
      width: 140,
      sortable: false,
      borderLeft: "1px solid #B3B3B3",
      renderCell: (params) => {
        return (
          <Typography>
            {formatCurrency(
              params.storyActualCost +
                params.taskActualCost +
                params.bugActualCost
            )}
          </Typography>
        );
      }
    }
  ];

  const COLUMN_GROUPING: ColumnGrouping[] = [
    {
      label: "Feature",
      pinned: true,
      width: 250,
      align: "left",
      columns: []
    },
    {
      label: "Overview",
      columns: ["sprints", "startDate", "endDate"],
      border: "1px solid #B3B3B3"
    },
    {
      label: "Story",
      columns: [
        "story.cards",
        "story.estCost",
        "story.actCost",
        "story.overUnder",
        "story.overUnderPercentage"
      ],
      border: "1px solid #B3B3B3"
    },
    {
      label: "Task",
      columns: [
        "task.cards",
        "task.estCost",
        "task.actCost",
        "task.overUnder",
        "task.overUnderPercentage"
      ],
      border: "1px solid #B3B3B3"
    },
    {
      label: "Bug",
      columns: [
        "bug.cards",
        "bug.estCost",
        "bug.actCost",
        "bug.overUnder",
        "bug.overUnderPercentage"
      ],
      border: "1px solid #B3B3B3"
    },
    {
      label: "TOTAL COST",
      width: 140,
      border: "1px solid #B3B3B3",
      columns: []
    }
  ];

  return (
    <StyledGrid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "1px",
        width: "calc(100vw - 340px)",
        minHeight: isLoading || !fco || !fco?.data ? "400px" : "0px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Feature Cost Overview
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            isQaMetric={false}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isLoading}>
        {(!fco || !fco?.data) && !isLoading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <DataGridTable
            rows={fco && (fco.data as any)}
            columns={COLUMNS}
            columnGrouping={COLUMN_GROUPING}
            pinnedColumns={["feature"]}
          />
        )}
      </Loader>
    </StyledGrid>
  );
};
