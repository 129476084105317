import { ReactNode } from "react";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const LoadingContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const Loader = ({
  children,
  isLoading
}: {
  children: ReactNode;
  isLoading: boolean;
}) => {
  return isLoading ? (
    <LoadingContainer className="loader">
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    children
  );
};
