import { Grid } from "@mui/material";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { RowOrderingIcon } from "./Icons";

export const SortableItem = (props: { label: string; value: string }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.value
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <Grid
      item
      container
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      direction={"row"}
      style={style}
      spacing="10px"
    >
      <Grid item>
        <RowOrderingIcon />
      </Grid>
      <Grid item>{props.label}</Grid>
    </Grid>
  );
};
