import { Container } from "@components/crud/Container";
import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import { InsightSummaryBox } from "@components/InsightSummaryBox";
import { Loader } from "@components/crud/Loader";
import { getAdminMetric } from "@services/Network";
import { CustomBarChart } from "../components/CustomBarChart";
import { CustomPieChart } from "../components/CustomPieChart";
import { MultiSelectFilter } from "../components/MultiSelectFilter";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { useQuery } from "@tanstack/react-query";
import {
  adminMetricKeysGet,
  ModelConfig,
  ModelSetting
} from "@sportsgravyengineering/sg-api-react-sdk";

export const Metric = ({
  chartKey,
  config,
  maxSelections
}: {
  chartKey: string;
  config: ModelConfig[] | ModelSetting[];
  maxSelections: number;
}) => {
  const [chartFilter, setChartFilter] = React.useState<string[]>([]);
  const organizationId = useRecoilValue(organizationAtom);
  const { data: data, isLoading: isLoading } = useQuery(
    [chartKey, organizationId],
    () =>
      adminMetricKeysGet({
        parentId: chartKey
      }),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60
    }
  );

  const getDefaultTimeFilter = (key: string) => {
    if (config) {
      if (config[0]["$kind"] == "Config") {
        return (config as ModelConfig[]).find(
          (c) => c.key === `${"dashboard.default-date"}-${key}`
        )?.value;
      }
      if (config[0]["$kind"] == "Setting") {
        return (config as ModelSetting[]).find(
          (c) => c.settingId === `${"org-dashboard.default-date"}.${key}`
        )?.value;
      }
    }
  };

  const filterOptions = useMemo(
    () =>
      data?.data.map((filter) => ({
        label: filter.title,
        description: filter.description,
        value: filter.key,
        chartType: filter.displayType,
        hasFilter: filter.isFilterable,
        defaultTimeFilter: getDefaultTimeFilter(filter.key as string)
      })) || [],
    [data?.data]
  );

  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Grid
          container
          paddingLeft="25px"
          paddingRight="40px"
          paddingBottom="40px"
        >
          <Grid item marginTop="16px" xs={12}>
            <MultiSelectFilter
              setChartFilter={setChartFilter}
              chartFilter={chartFilter}
              options={filterOptions}
              maxSelections={maxSelections}
            />
          </Grid>
          <Grid item xs={12}>
            {chartFilter.map((filter) => {
              const chart = filterOptions.find(
                (option) => option.value === filter
              );
              if (chart && chart.chartType == "H_BAR") {
                return (
                  <CustomBarChart
                    key={chart.value}
                    chartId={chart.value}
                    title={chart.label as string}
                    description={chart.description as string}
                    useGet={getAdminMetric}
                    hasFilter={chart.hasFilter}
                    defaultTimeFilter={
                      chart.defaultTimeFilter as string | undefined
                    }
                  />
                );
              }
              if (chart && chart?.chartType == "PIE") {
                return (
                  <CustomPieChart
                    key={chart.value}
                    chartId={chart.value}
                    title={chart.label as string}
                    description={chart.description as string}
                    useGet={getAdminMetric}
                    hasFilter={chart.hasFilter}
                    defaultTimeFilter={
                      chart.defaultTimeFilter as string | undefined
                    }
                  />
                );
              }

              if (chart && chart?.chartType == "INSIGHT_BOX") {
                return (
                  <InsightSummaryBox
                    chartId={chart.value}
                    key={chart.value}
                    title={chart.label as string}
                    description={chart.description as string}
                    useGet={getAdminMetric}
                    hasFilter={chart.hasFilter}
                    defaultTimeFilter={
                      chart.defaultTimeFilter as string | undefined
                    }
                  />
                );
              }
            })}
          </Grid>
        </Grid>
      </Container>
    </Loader>
  );
};
