/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Button as MuiButton,
  Typography,
  Divider,
  Popover,
  styled,
  Container
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Loader } from "./crud/Loader";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";

const StyledOption = styled("option")({
  fontSize: "13px",
  padding: "5px 10px",
  width: "100%",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#E5E5E5"
  }
});

export const InsightSummaryBox = ({
  title,
  description,
  releaseId,
  platform,
  testerId,
  featureId,
  useGet,
  setFeatureBreakDown,
  chartId,
  hasFilter,
  isQaInsights,
  defaultTimeFilter,
  handleInsightClick
}: {
  title: string;
  description: string;
  isLoading?: boolean;
  releaseId?: string;
  platform?: string;
  testerId?: string;
  featureId?: string;
  chartId?: string;
  useGet: (params, options) => any;
  setFeatureBreakDown?: (value) => void;
  hasFilter?: boolean;
  isQaInsights?: boolean;
  defaultTimeFilter?: string | undefined;
  handleInsightClick?: (string: "FAILED" | "BLOCKED") => void;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [refreshKey, setRefreshKey] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const secondsToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const timeComponents: string[] = [];
    if (hours > 0) {
      timeComponents.push(`${hours}H`);
    }
    if (
      (hours === 0 && minutes > 0) ||
      (hours > 0 && minutes > 0) ||
      (hours === 0 && minutes === 0 && remainingSeconds > 0)
    ) {
      timeComponents.push(`${minutes}M`);
    }
    if (
      (hours === 0 && remainingSeconds > 0) ||
      (hours > 0 && remainingSeconds > 0)
    ) {
      timeComponents.push(`${remainingSeconds}S`);
    }

    return timeComponents.join(" ");
  };

  const convertMBtoGB = (size: number) => {
    if (!size) return { value: "0", unit: "MB" };
    let value = size.toFixed(2);
    let unit = "MB";

    if (size > 1024) {
      value = (size / 1024).toFixed(2);
      unit = "GB";
    }

    return { value, unit };
  };
  const query = React.useMemo(() => {
    const buildQuery = {} as {
      releaseId?: string;
      testerId?: string;
      platform?: string;
      parentId?: string;
      dtStart?: string;
      dtEnd?: string;
      keys?: any;
      organizationId?: any;
    };

    if (releaseId !== "") {
      buildQuery.releaseId = releaseId;
    }

    if (testerId !== "") {
      buildQuery.testerId = testerId;
    }

    if (platform !== "") {
      buildQuery.platform = platform;
    }

    if (featureId !== "") {
      buildQuery.parentId = featureId;
    }

    if (chartId && chartId !== "") {
      buildQuery.keys = [{ key: chartId }];
    }

    if (!chartId && selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.dtStart = selectedTimeRange[0];
      buildQuery.dtEnd = selectedTimeRange[1];
    }
    if (chartId && selectedTimeRange[0] && selectedTimeRange[1]) {
      buildQuery.keys[0].dtStart = selectedTimeRange[0];
      buildQuery.keys[0].dtEnd = selectedTimeRange[1];
    }
    if (organizationId) {
      buildQuery.organizationId = organizationId;
    }
    return buildQuery;
  }, [
    releaseId,
    testerId,
    platform,
    featureId,
    selectedTimeRange,
    organizationId
  ]);

  const { isFetching, data } = useGet(
    !chartId
      ? query
      : {
          keys: JSON.stringify(query.keys),
          ...(query.organizationId && { organizationId: query.organizationId })
        },
    {
      query: {
        queryKey: [
          releaseId,
          testerId,
          platform,
          featureId,
          refreshKey,
          chartId
        ],
        refetchOnWindowFocus: false
      }
    }
  );

  useEffect(() => {
    if (data.featureBreakdown && setFeatureBreakDown)
      setFeatureBreakDown(data.featureBreakdown);
    if (hasFilter && data[0]?.data?.[0]?.filters.length > 0)
      setSelectedFilter(data[0]?.data?.[0]?.filters[0]);
  }, [data]);

  window.addEventListener("scroll", handleClose);
  const isFloat = (n) => {
    if (Number(n) === n && n % 1 !== 0) {
      return parseFloat(n.toFixed(2));
    }
    if (typeof n === "number") return n.toLocaleString();
    return n;
  };
  return (
    <Grid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "32px",
        minHeight:
          isFetching ||
          (!data[0]?.data?.[0]?.value?.insights && !data?.insights)
            ? "300px"
            : "auto"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          {hasFilter && data[0]?.data?.[0]?.filters.length > 0 && (
            <MuiButton
              endIcon={
                anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
              variant="outlined"
              style={{
                color: "#64748B",
                fontSize: "14px",
                fontWeight: 500,
                textTransform: "none",
                marginRight: "16px"
              }}
              onClick={(event) => {
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
            >
              {selectedFilter}
            </MuiButton>
          )}
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            isQaMetric={isQaInsights}
            defaultTimeFilter={defaultTimeFilter}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isFetching}>
        {(data?.insights || data?.[0]?.data?.[0]?.value?.insights) &&
          Array.from(
            {
              length: Math.ceil(
                data?.insights
                  ? data.insights.length / 4
                  : data?.[0]?.data?.[0].value?.insights.length / 4
              )
            },
            (_, index) => index
          ).map((row, index) => {
            const insights =
              data?.insights && !hasFilter
                ? data?.insights
                : !data?.insights && !hasFilter
                ? data?.[0]?.data?.[0].value?.insights
                : data?.[0]?.data?.[0]?.filterValues[
                    selectedFilter
                      ? selectedFilter
                      : data[0]?.data?.[0]?.filters[0]
                  ]?.insights;
            return (
              <Grid key={index} container direction="row" item>
                {insights &&
                  insights
                    .slice(
                      index * 4,
                      Math.min((index + 1) * 4, insights.length)
                    )
                    .map((con) => {
                      return (
                        <Grid
                          item
                          container
                          direction="column"
                          key={con.title}
                          xs={3}
                          minHeight="121px"
                          padding="24px"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            border: "1px solid rgba(229, 229, 229, 1)"
                          }}
                        >
                          <Grid item>
                            <Typography
                              style={{
                                color: "#64748B",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "21px"
                              }}
                            >
                              {con.title}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {(con.type === "text" || !con.type) && (
                              <Typography
                                style={{
                                  color: con?.link ? "#007AFF" : "#1E293B",
                                  fontWeight: 600,
                                  fontSize: "32px",
                                  lineHeight: "48px",
                                  cursor: con?.link ? "pointer" : "default"
                                }}
                                onClick={() => {
                                  if (con?.link && handleInsightClick) {
                                    handleInsightClick(con.link);
                                  }
                                }}
                              >
                                {isFloat(con.value)}
                              </Typography>
                            )}
                            {con.type === "time" && (
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontWeight: 600,
                                  fontSize: "32px",
                                  lineHeight: "48px"
                                }}
                              >
                                {secondsToHMS(con.value)
                                  .match(/\d+\D+/g)
                                  ?.map((part, index) => {
                                    const matchResult =
                                      part.match(/(\d+)(\D+)/);
                                    if (!matchResult) return null;
                                    const [numericPart, nonNumericPart] =
                                      matchResult.slice(1);
                                    return (
                                      <span key={index}>
                                        <span style={{ fontSize: "32px" }}>
                                          {numericPart}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "14px",
                                            color: "#64748B",
                                            marginLeft: "7px"
                                          }}
                                        >
                                          {nonNumericPart}
                                        </span>
                                      </span>
                                    );
                                  })}
                              </Typography>
                            )}
                            {con.type === "storage" && (
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontWeight: 600,
                                  fontSize: "32px",
                                  lineHeight: "48px"
                                }}
                              >
                                <span key={index}>
                                  <span style={{ fontSize: "32px" }}>
                                    {convertMBtoGB(con.value).value}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#64748B",
                                      marginLeft: "7px"
                                    }}
                                  >
                                    {convertMBtoGB(con.value).unit}
                                  </span>
                                </span>
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
              </Grid>
            );
          })}
        {!data[0]?.data?.[0]?.value?.insights && !data?.insights && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        )}
      </Loader>
      {hasFilter && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Container style={{ padding: 0 }}>
            {data[0]?.data?.[0]?.filters.map((option) => (
              <StyledOption
                onClick={(e) => {
                  //@ts-ignore
                  setSelectedFilter(e.target.value);
                  setAnchorEl(null);
                }}
                key={option}
                value={option}
              >
                {option}
              </StyledOption>
            ))}
          </Container>
        </Popover>
      )}
    </Grid>
  );
};
