import { CardProps, Card as MuiCard } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  ...theme.typography.body1,
  padding: ".75rem",
  borderRadius: "6px",
  inset: "0",
}));

export const NotificationCard = (props: {
  children: string | JSX.Element;
  variant: "success" | "error" | "info" | undefined;
  sx?: CardProps["sx"];
}) => {
  const className = !(
    props.variant === "success" ||
    props.variant === "error" ||
    props.variant === "info"
  )
    ? "success"
    : props.variant;
  return (
    <StyledBox className={className} variant="outlined" sx={props.sx}>
      {props.children}
    </StyledBox>
  );
};
