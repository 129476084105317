import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { InfoListCard } from "@components/InfoListCard";
import { LinkButton } from "@components/LinkButton";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { NotificationCard } from "@components/NotificationCard";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  ModelUser,
  useAuthSignUpResendPersonCodePost,
  useAuthSignUpVerifyAccountCodePost,
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export const DuplicateAccountAssociatedEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location?.state?.email;

  const [attempts, setAttempts] = useState<number>(0);
  const [user, setUser] = useState<ModelUser>();
  const [hasResent, setHasResent] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const formatAccountToInfoList = (account) => {
    return [
      {
        label: "Name",
        value: `${account?.person?.firstName} ${account?.person?.lastName}`,
      },
      { label: "Email", value: account?.email },
      { label: "Account Status", value: "Active" },
    ];
  };

  const { mutate: resendCode, isLoading: isResending } =
    useAuthSignUpResendPersonCodePost();

  const {
    mutateAsync: verifyAccountCodeAsync,
    isLoading: isVerifying,
    isError,
  } = useAuthSignUpVerifyAccountCodePost();

  const verifyCode = async (formValues) => {
    try {
      const user = await verifyAccountCodeAsync({
        data: { email, code: formValues.code },
      });
      setUser(user.data);
    } catch (error) {
      setAttempts(attempts + 1);
      throw error;
    }
  };

  return (
    <form onSubmit={handleSubmit(verifyCode)}>
      <Grid container spacing={3} justifyContent={"space-evenly"}>
        <Grid xs={12}>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 400 }}
          >
            Duplicate Account Warning
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1" color="text.general.secondary">
            You are attempting to sign up with an email that is already
            associated with an active user account.{" "}
            {!user &&
              `To verify your identity and be shown your
          existing account details please retrieve the 6 digit verification code
          sent to your email and enter it below.`}
          </Typography>
        </Grid>
        {attempts >= 3 && (
          <Grid xs={12}>
            <NotificationCard variant="error">
              {`You have entered the incorrect code too many times, please contact the SportsGravy Support Team`}
            </NotificationCard>
          </Grid>
        )}
        {user ? (
          <>
            <Grid xs={12} alignContent={"space-around"}>
              <Typography variant="h4">Existing Account Details</Typography>
              <InfoListCard infoList={formatAccountToInfoList(user)} />
            </Grid>
            <Grid xs={12} md={6}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password
              </Button>
            </Grid>
            <Grid xs={12} md={6}>
              <Button
                variant="primary"
                type="button"
                onClick={() => navigate("/")}
              >
                Sign In
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={7} md={9}>
              <FormInput
                type="text"
                required
                name="code"
                control={control}
                label="Email code"
                rules={{
                  required: "Email code is required",
                  validate: {
                    isError: () =>
                      !isError ||
                      "The code you entered was incorrect. Please try again.",
                  },
                }}
              />
            </Grid>
            <Grid
              xs={5}
              md={3}
              alignSelf={"center"}
              sx={{ paddingTop: "2.5rem" }}
            >
              {isResending ? (
                <LoadingSpinner />
              ) : !hasResent ? (
                <LinkButton
                  href="#"
                  onClick={() => {
                    resendCode({
                      data: {
                        email,
                        type: "EMAIL",
                      },
                    });
                    setHasResent(true);
                  }}
                >
                  Resend Code
                </LinkButton>
              ) : null}
            </Grid>
            <Grid xs={12}>
              <Button
                variant="primary"
                disabled={!isValid || isVerifying || attempts >= 3}
                type="submit"
                isLoading={isVerifying}
              >
                Continue
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};
