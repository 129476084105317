import { Close } from "@mui/icons-material";
import { Badge, Grid, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ModelNotification } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { Link } from "react-router-dom";
import {
  parseISO,
  differenceInSeconds,
  differenceInDays,
  formatDistanceToNowStrict
} from "date-fns";
import { useEffect, useState } from "react";
import colors from "theme/colors";

const StyledLink = styled(Typography)(() => ({
  ":hover": {
    textDecoration: "underline"
  }
}));
const StyledTitle = styled(Typography)(() => ({
  color: "#3B6CF8",
  fontSize: "16px",
  textDecoration: "underline",
  fontWeight: 600,
  ":hover": {
    cursor: "pointer"
  }
}));

export const NotificationItem = ({
  notification,
  onClear
}: {
  notification: ModelNotification;
  onClear: () => void;
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const formatDate = (date: string) => {
    const notificationDate = parseISO(date);
    const secondsDifference = differenceInSeconds(
      currentTime,
      notificationDate
    );
    if (secondsDifference < 60) {
      return "Just now";
    }
    const daysDifference = differenceInDays(currentTime, notificationDate);
    let distance = "";
    if (daysDifference >= 7 && daysDifference < 28) {
      const weeks = Math.floor(daysDifference / 7);
      distance = `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else {
      distance = formatDistanceToNowStrict(notificationDate, {
        addSuffix: true
      });
    }
    return distance;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Grid data-testid="notification-item" container padding={3}>
      <Grid
        item
        container
        direction={"row"}
        spacing="25px"
        key={notification.notificationId}
      >
        <Grid item xs={10.6}>
          {notification?.notificationObject?.actions?.includes("VIEW") ? (
            <Link
              target="_blank"
              to={notification?.notificationObject?.metadata?.url}
            >
              <StyledTitle>{notification.title}</StyledTitle>
            </Link>
          ) : (
            <Typography
              style={{
                color: "#3B6CF8",
                fontSize: "16px",
                textDecoration: "underline",
                fontWeight: 600
              }}
            >
              {notification.title}
            </Typography>
          )}
        </Grid>
        {notification.readAt === null && (
          <Grid data-testid="unread-notification" item xs={1}>
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  color: colors.info.main,
                  backgroundColor: colors.info.main
                }
              }}
              variant="dot"
            ></Badge>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        columnSpacing="25px"
        key={notification.notificationId + "1"}
      >
        <Grid item xs={10}>
          <Typography
            style={{ fontSize: "13px", color: "#1E293B", fontWeight: 300 }}
          >
            {notification.message}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onClear}>
            <Close fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent={"space-between"}
        direction={"row"}
        style={{
          marginTop: "8px"
        }}
      >
        <Grid item key={currentTime.toISOString()}>
          <Typography
            style={{ fontSize: "11px", color: "rgba(102, 102, 102, 0.8)" }}
          >
            {formatDate(notification.createdAt as string)}
          </Typography>
        </Grid>
        {notification?.notificationObject?.actions?.includes("VIEW") && (
          <Grid
            item
            style={{ paddingRight: "10px" }}
            alignContent={"left"}
            key={notification.notificationId + "2"}
          >
            <Link
              target="_blank"
              to={notification?.notificationObject?.metadata?.url}
            >
              <StyledLink
                style={{ fontSize: "12px", color: "#3B6CF8", fontWeight: 600 }}
              >
                View{" "}
                {capitalize(notification?.notificationObject?.metadata?.type)}
              </StyledLink>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
