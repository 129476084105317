/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Control, Controller } from "react-hook-form";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const FormToggleGroup = (props: {
  name: string;
  control: Control<any, any>;
  rules?: any | undefined;
  onChange?: (event: any) => void;
  disabled?: boolean;
  options: { value: string; label: string }[];
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field }) => (
        <FormInputContainer>
          <ToggleButtonGroup
            value={field.value}
            exclusive
            onChange={props.onChange || field.onChange}
            size="large"
            disabled={props.disabled}
          >
            {props.options.map((option) => (
              <ToggleButton
                key={option.value}
                value={option.value}
                style={{ textTransform: "none" }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormInputContainer>
      )}
    />
  );
};
