import { TableView } from "@components/TableView";
import { getSubroles } from "@services/Network";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { organizationAtom } from "../../recoil/auth";
import { getRecoil } from "recoil-nexus";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";

export const Subroles = () => {
  const [refreshKey] = React.useState(0);
  const navigate = useNavigate();
  const [permissions, setPermissions] = React.useState({
    edit: false,
    add: false
  });
  const [permissionLoading, setPermissionLoading] = React.useState(true);

  const [COLUMNS, setColumns] = React.useState([
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    {
      headerName: "Display Text",
      field: "displayText",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => row.display_text || "-"
    },
    {
      headerName: "Role",
      field: "parent.name",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => row.parent?.name || ""
    },
    {
      headerName: "Role Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueFormatter: ({ value }) =>
        value[0].toUpperCase() + value.slice(1).toLowerCase()
    },
    {
      headerName: "No. of Users",
      field: "noOfUsers",
      minWidth: 105,
      flex: 1,
      valueGetter: ({ row }) => row._count?.users || 0
    }
  ]);

  const organizationId = getRecoil(organizationAtom);

  if (organizationId && COLUMNS.length !== 3) {
    setColumns([
      { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
      {
        headerName: "Role",
        field: "parent.name",
        minWidth: 150,
        flex: 1,
        valueGetter: ({ row }) => row.parent?.name || ""
      },
      {
        headerName: "No. of Users",
        field: "noOfUsers",
        minWidth: 105,
        flex: 1,
        valueGetter: ({ row }) => row._count?.users || "-"
      }
    ]);
  }

  const onCreate = () => {
    navigate(`/subroles/create`);
  };

  const onEdit = (role) => {
    navigate(`/subroles/${role.roleId}/edit`);
  };

  const onView = (role) => {
    navigate(`/subroles/${role.roleId}`);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      try {
        const edit = await checkPermission("user.sub-roles", "EDIT");
        const add = await checkPermission("user.sub-roles", "ADD");
        setPermissions({
          edit,
          add
        });
      } catch {
        setPermissionLoading(false);
      } finally {
        setPermissionLoading(false);
      }
    };
    fetchPermissions();
  }, []);

  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Sub Roles"
        useGet={getSubroles}
        columns={COLUMNS}
        isDeleteDisabled={() => true}
        hideFilter={true}
        isEditDisabled={() => !permissions.edit}
        hideLabelContainer={true}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={permissions.add ? onCreate : undefined}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
        getRowId={(row) => row.roleId}
      />
    </Loader>
  );
};
