import { TransferList } from "@components/TransferList";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  Gender,
  ModelPerson,
  TeamTrainingProgramCreateUserInput,
  TeamTrainingProgramUserMetadata
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../../../recoil/auth";
import { UserOverride } from "./UserOverride";
import { capitalize } from "@utils/capitalize";
import { DataGridRenderCellTooltipPositions } from "@components/DataGridRenderCellPositionsTooltip";
import { RenderTableView } from "@components/RenderTableView";
import {
  ActiveUserIcon,
  ParentAcceptIcon,
  ParentAndKidAcceptIcon,
  PendingUserIcon
} from "@components/Icons";
import { Delete } from "@mui/icons-material";
import { ToolTip } from "@components/ToolTip";

export interface PlayerOption extends TeamTrainingProgramCreateUserInput {
  label: string;
  id: string;
  value: string;
  parent?: PlayerOption;
  gender?: Gender;
  status?: string;
}
interface PlayerSelectionFormProps {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any, undefined>;
  setPlayers: (value: string[]) => void;
  isEditing?: boolean;
  isLoadingUsers?: boolean;
  userOptions: ModelPerson[];
  players: string[];
  playersWithValues: PlayerOption[];
  setPlayersWithValues: (value: PlayerOption[]) => void;
  roleId: string;
  parentRoleId: string;
  positionOptions;
  type: "team" | "program";
  setUserOptions?: (value: ModelPerson[]) => void;
  seasonId?: string;
  sportId?: string;
}

export const PlayerSelectionForm = (props: PlayerSelectionFormProps) => {
  const organizationId = useRecoilValue(organizationAtom);

  const heightUnitValue =
    useRecoilValue(organizationsAtom).find(
      (org) => org.organizationId === organizationId
    )?.country === "US"
      ? "ft"
      : "cm";
  const weightUnitValue =
    useRecoilValue(organizationsAtom).find(
      (org) => org.organizationId === organizationId
    )?.country === "US"
      ? "lb"
      : "kg";

  const getUpdatedUserOptions = (
    userOptions: ModelPerson[],
    usersWithValues
  ) => {
    const includedUsers = usersWithValues.filter(
      (u) =>
        (u.user || u.person) &&
        userOptions.findIndex((option) => option?.personId === u?.id) === -1
    );
    if (includedUsers.length) {
      props.setUserOptions!([
        ...userOptions,
        ...includedUsers.map((u) => {
          return u.person || u.user.person;
        })
      ]);
      return [
        ...userOptions,
        ...includedUsers.map((u) => {
          return u.person || u.user.person;
        })
      ];
    } else return userOptions;
  };

  const playerOptions = useMemo(() => {
    if (!props.disabled && props.userOptions) {
      const updatedUserOptions = getUpdatedUserOptions(
        props.userOptions,
        props.playersWithValues
      );
      return updatedUserOptions.map((p) => ({
        id: p.personId!,
        value: p.personId!,
        label: `${p.firstName} ${p.lastName}`,
        ...p
      }));
    } else return [];
  }, [props.userOptions]);

  useEffect(() => {
    if (!props.disabled) {
      // do not reset the whole array
      const prevPlayerIds = props.playersWithValues.map((p) => p.personId);
      const newPlayerIds = props.players.filter(
        (p) => !prevPlayerIds.includes(p)
      );
      if (props.players.length < props.playersWithValues.length) {
        props.setPlayersWithValues([
          ...props.playersWithValues.filter((playerWithValue) =>
            props.players.includes(playerWithValue.id)
          )
        ]);
      } else {
        props.setPlayersWithValues([
          ...props.playersWithValues,
          ...newPlayerIds.map((id) => {
            const user =
              props.userOptions.find((u) => u.personId === id) ||
              playerOptions.find((u) => u.personId === id);
            return {
              id: user!.personId!,
              value: user!.personId!,
              personId: user!.personId!,
              label: `${user?.firstName} ${user?.lastName}`,
              ...user,
              gender: user?.gender,
              roleId: props.roleId,
              permissions: [],
              parent: user?.guardians?.length
                ? {
                    personId: user.guardians[0].personId!,
                    label: `${user.guardians[0].guardian?.firstName} ${user.guardians[0].guardian?.lastName}`,
                    roleId: props.parentRoleId,
                    permissions: [],
                    ...user.guardians[0].guardian,
                    id: user.guardians[0].personId!,
                    value: user.guardians[0].personId!
                  }
                : undefined
            };
          })
        ]);
      }
    }
  }, [props.players, props.userOptions]);

  const onDelete = (row) => {
    const index = props.playersWithValues.findIndex((pv) => pv.id === row.id);
    if (index !== -1) {
      props.setPlayers([
        ...props.players.slice(0, index),
        ...props.players.slice(index + 1)
      ]);
      props.setPlayersWithValues([
        ...props.playersWithValues.slice(0, index),
        ...props.playersWithValues.slice(index + 1)
      ]);
    }
  };

  const updateHeight = (personId, unit, value) => {
    // This function would update the player's height based on the unit
    props.setPlayersWithValues(
      props.playersWithValues.map((item) => {
        if (item.personId === personId) {
          return {
            ...item,
            metadata: {
              ...(item.metadata as TeamTrainingProgramUserMetadata),
              positions: item.metadata?.positions || [],
              height:
                unit === "feet"
                  ? `${value}'${item.metadata?.height?.split("'")[1] || ""}`
                  : unit === "inches"
                  ? `${item.metadata?.height?.split("'")[0] || ""}'${value}`
                  : value
            }
          };
        }
        return item;
      })
    );
  };

  const renderHeightInput = (params, heightUnit, disabled = false) => {
    if (heightUnit === "ft") {
      // Assuming height is stored as an object { feet: number, inches: number }
      const height = params.row.metadata?.height;
      const [feet, inches] = height ? height.split("'") : ["", ""];

      return (
        <>
          <TextField
            placeholder=""
            type="text"
            size="small"
            disabled={disabled}
            style={{ width: "50%" }}
            value={feet || ""}
            onChange={(event) =>
              updateHeight(params.row.personId, "feet", event.target.value)
            }
          />
          <Typography display="inline" variant="formLabel">
            ft
          </Typography>
          <TextField
            placeholder=""
            disabled={disabled}
            type="text"
            size="small"
            style={{ width: "50%" }}
            value={inches || ""}
            onChange={(event) =>
              updateHeight(params.row.personId, "inches", event.target.value)
            }
          />
          <Typography display="inline" variant="formLabel">
            in
          </Typography>
        </>
      );
    } else {
      // Assuming height is stored as a single number for centimeters
      const heightCm = params.row.metadata?.height || "";

      return (
        <>
          <TextField
            placeholder="Centimeters"
            type="text"
            size="small"
            style={{ width: "70%" }}
            value={heightCm}
            onChange={(event) =>
              updateHeight(params.row.personId, "cm", event.target.value)
            }
          />
          <Typography display="inline" variant="formLabel">
            {heightUnitValue}
          </Typography>
        </>
      );
    }
  };

  const LIST_COLUMNS_VIEW: GridColDef[] = [
    {
      headerName: "Name",
      field: "label",
      minWidth: 250,
      sortable: false,
      flex: 1
    },
    {
      headerName: "Gender",
      field: "gender",
      width: 120,
      sortable: false,
      flex: 1,
      renderCell: (params) => <div>{capitalize(params.row.gender)}</div>
    },
    ...(props.type === "team"
      ? [
          {
            headerName: "Positions",
            field: "positions",
            minWidth: 150,
            sortable: false,
            flex: 1,
            renderCell: (params) =>
              params.row.metadata &&
              params.row.metadata.positions &&
              DataGridRenderCellTooltipPositions(
                params.row.metadata.positions,
                props.positionOptions
              )
          }
        ]
      : []),
    {
      headerName: "Height",
      field: "height",
      minWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 1
          }}
        >
          {renderHeightInput(params, heightUnitValue, true)}
        </Box>
      )
    },
    {
      headerName: "Weight",
      field: "weight",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 1
          }}
        >
          <TextField
            placeholder=""
            disabled
            type={"text"}
            size="small"
            style={{ width: "70%" }}
            value={
              params.row.metadata
                ? (params.row.metadata as TeamTrainingProgramUserMetadata)[
                    "weight"
                  ]
                : ""
            }
          />
          <Typography display="inline" variant="formLabel">
            {weightUnitValue}
          </Typography>
        </Box>
      )
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        switch (params.row.status) {
          case "USER_ACCEPTED":
            return (
              <>
                <ToolTip title="User accepted" placement="right">
                  <div {...props} data-testid="PLAYER_USER_ACCEPTED">
                    <ActiveUserIcon />
                  </div>
                </ToolTip>
              </>
            );
          case "CHILD_ACCEPTED":
            return (
              <>
                <ToolTip title="Parent and child accepted" placement="right">
                  <div {...props} data-testid="PLAYER_CHILD_ACCEPTED">
                    <ParentAndKidAcceptIcon />
                  </div>
                </ToolTip>
              </>
            );
          case "PARENT_ACCEPTED":
            return (
              <>
                <ToolTip title="Parent accepted" placement="right">
                  <div {...props} data-testid="PLAYER_PARENT_ACCEPTED">
                    <ParentAcceptIcon />
                  </div>
                </ToolTip>
              </>
            );
          case "USER_PENDING":
            return (
              <>
                <ToolTip title="Invite Pending" placement="right">
                  <div {...props} data-testid="PLAYER_INVITE_PENDING">
                    <PendingUserIcon />
                  </div>
                </ToolTip>
              </>
            );
          default:
            return (
              <>
                <ToolTip title="Invite Pending" placement="right">
                  <div {...props} data-testid="PLAYER_INVITE_PENDING">
                    <PendingUserIcon />
                  </div>
                </ToolTip>
              </>
            );
        }
      }
    }
  ];

  const LIST_COLUMNS: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <IconButton onClick={() => onDelete(params.row)}>
          <Delete />
        </IconButton>
      )
    },
    {
      headerName: "Name",
      field: "label",
      minWidth: 250,
      flex: 1,
      sortable: false
    },
    {
      headerName: "Gender",
      field: "gender",
      width: 60,
      flex: 1,
      sortable: false,
      renderCell: (params) => <div>{params.row.gender}</div>
    },
    ...(props.type === "team"
      ? [
          {
            headerName: "Positions",
            field: "positions",
            minWidth: 350,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
              <Select
                value={
                  params.row.metadata && params.row.metadata.positions
                    ? (params.row.metadata as TeamTrainingProgramUserMetadata)[
                        "positions"
                      ]
                    : []
                }
                onChange={(event) => {
                  //update the positions in the playersWithValues
                  props.setPlayersWithValues(
                    props.playersWithValues.map((item) => {
                      if (item.personId === params.row.personId) {
                        return {
                          ...item,
                          metadata: {
                            ...(item.metadata as TeamTrainingProgramUserMetadata),
                            positions: event.target.value as string[]
                          }
                        };
                      }
                      return item;
                    })
                  );
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                style={{ width: "70%", height: 40 }}
                multiple
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          props.positionOptions.find(
                            (option) => option.value === value
                          )?.label || value
                        }
                      />
                    ))}
                  </div>
                )}
              >
                {props.positionOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )
          }
        ]
      : []),
    {
      headerName: "Height",
      field: "height",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 1
          }}
        >
          {renderHeightInput(params, heightUnitValue)}
        </Box>
      )
    },
    {
      headerName: "Weight",
      field: "weight",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            gap: 1
          }}
        >
          <TextField
            placeholder=""
            type={"text"}
            size="small"
            style={{ width: "70%" }}
            value={
              params.row.metadata
                ? (params.row.metadata as TeamTrainingProgramUserMetadata)[
                    "weight"
                  ]
                : ""
            }
            onChange={(event) => {
              //update the weight in the playersWithValues
              props.setPlayersWithValues(
                props.playersWithValues.map((item) => {
                  if (item.personId === params.row.personId) {
                    return {
                      ...item,
                      metadata: {
                        ...(item.metadata as TeamTrainingProgramUserMetadata),
                        positions: item.metadata?.positions || [],
                        weight: event.target.value
                      }
                    };
                  }
                  return item;
                })
              );
            }}
          />
          <Typography display="inline" variant="formLabel">
            {weightUnitValue}
          </Typography>
        </Box>
      )
    }
  ];
  return (
    <Grid container direction="column" spacing="25px">
      <Grid item container direction="row" spacing="24px">
        {!props.disabled && (
          <Grid item xs={12}>
            <TransferList
              name="players"
              label={props.type === "team" ? "Players" : "Athletes"}
              control={props.form.control}
              rules={{ required: "At least one Player is required" }}
              isLoading={props.isLoadingUsers}
              options={playerOptions}
              required={true}
              setValue={props.form.setValue}
              setState={props.setPlayers}
              state={props.players}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <RenderTableView
            title="Players"
            hideToolbar
            rows={props.playersWithValues}
            hasActionColumn={false}
            columns={props.disabled ? LIST_COLUMNS_VIEW : LIST_COLUMNS}
            hideFilter
            getRowId={(row) => row?.id}
            searchable={false}
          />
        </Grid>
        {!props.disabled && (
          <Grid item xs={12}>
            <UserOverride
              type={props.type}
              title={props.type === "team" ? "Player" : "Athlete"}
              roleId={props.roleId}
              parentRoleId={props.parentRoleId}
              seasonId={props.seasonId}
              masterList={props.players}
              setMasterList={props.setPlayers}
              userOptions={props.userOptions}
              setUserOptions={props.setUserOptions!}
              roleAlias="PLAYER"
              sportId={props.sportId}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PlayerSelectionForm.defaultProps = {
  type: "team"
};
