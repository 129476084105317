const formatAddress = (address?: {
  lines: string[];
  locality: string;
  province: string;
  postalCode: string;
  country: string;
}) => {
  if (!address) return "";
  const { lines, locality, province, postalCode, country } = address;
  const addressLines = lines?.filter((line) => line);
  const addressLinesString = addressLines?.join(" ");
  const addressComponents = [
    addressLinesString,
    locality,
    `${province} ${postalCode}`,
    country,
  ];
  const addressString = addressComponents
    .filter((component) => component)
    .join(", ");
  return addressString;
};

export default formatAddress;
