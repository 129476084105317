import styled from "styled-components";

const propToCssPropertyMap = {
  p: "padding",
  pt: "padding-top",
  pb: "padding-bottom",
  pr: "padding-right",
  pl: "padding-left",
  m: "margin",
  mt: "margin-top",
  mb: "margin-bottom",
  mr: "margin-right",
  ml: "margin-left",
};

function renderCssStringList(props: Spacing) {
  return Object.keys(props)
    .map((key) => {
      const cssProperty = propToCssPropertyMap[key];
      const value = props[key];
      if (!cssProperty || !value) return null;

      return `${cssProperty}: ${value}px;`;
    })
    .filter(Boolean);
}

const Spacer = styled.div<Spacing>`
  ${(props) => renderCssStringList(props)}
`;

type Spacing = {
  p?: number;
  pt?: number;
  pb?: number;
  pr?: number;
  pl?: number;
  m?: number;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
};

export default Spacer;
