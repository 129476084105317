import { VerticalListTooltip } from "@components/VerticalListTooltip";

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month} / ${day} / ${year}`;
  return formattedDate;
};

const formatTimeForDisplay = (timeStr) => {
  const [hours, minutes] = timeStr.split(":");
  const dummyDate = new Date(0);

  dummyDate.setHours(parseInt(hours));
  dummyDate.setMinutes(parseInt(minutes));

  // Format the time in "hh:mm A" format
  const formattedTime = dummyDate.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  });

  return formattedTime;
};
export const DataGridRenderCellTooltipTrainingDates =
  () =>
  // eslint-disable-next-line react/display-name
  (row) => {
    const value = row.value;
    if (!value || !Array.isArray(value) || !value.length) return null;
    const textToShow = value
      ?.slice(0, 1)
      ?.map(
        (date) =>
          formatDateForDisplay(date.startDate) +
          " to " +
          formatDateForDisplay(date.endDate) +
          "||" +
          formatTimeForDisplay(date.timings[0].startTime) +
          " - " +
          formatTimeForDisplay(date.timings[0].endTime)
      );
    const displayed = textToShow[0].split("||")[0];
    const secondLineText = textToShow[0].split("||")[1];
    const remaining = value
      ?.slice(1)
      ?.map(
        (date) =>
          formatDateForDisplay(date.startDate) +
          " to " +
          formatDateForDisplay(date.endDate) +
          " " +
          formatTimeForDisplay(date.timings[0].startTime) +
          " - " +
          formatTimeForDisplay(date.timings[0].endTime)
      );
    return (
      <VerticalListTooltip
        text={displayed}
        secondLineText={secondLineText}
        tooltipList={remaining}
      />
    );
  };
