import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import Sentry from "@services/Sentry";
import {
  ModelPerson,
  ModelRole,
  ModelRolePermission,
  RoleAlias,
  TrainingProgramWithOrgCreateInput,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminRoleRoleIdUserGet,
  useAdminSportGet,
  useAdminSubroleGet,
  useAdminTrainingProgramProgramIdGet,
  useAdminTrainingProgramProgramIdPatch
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  CoachOption,
  CoachSelectionForm
} from "@pages/teams-programs/components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "@pages/teams-programs/components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "@pages/teams-programs/components/PlayerSelectionForm";
import { ProgramDetailsForm } from "./ProgramDetailsForm";
import { PermissionSelectionForm } from "@pages/teams-programs/components/PermissionSelectionForm";
import { cleanObject } from "../../../utils/cleanObject";
import { formatDate, formatTime } from "@utils/formatDate";
// import { DevTool } from "@hookform/devtools";
const steps = [
  "Program Details",
  "Athlete Selection",
  "Coach Selection",
  "Manager Selection",
  "Permissions"
];

export const ProgramEdit = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { programId } = useParams();

  const organizationId = useRecoilValue(organizationAtom);

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Program Details");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const activeStepNumber = steps.findIndex((step) => step === tab);
  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;

  const {
    getValues,
    trigger,
    handleSubmit,
    formState: { isValid }
  } = form;
  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [levelId, setLevelId] = useState<string | undefined>(undefined);
  const [genders, setGenders] = useState<string[]>([]);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);

  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);

  const { data: permissionRequest } = useAdminPermissionGet();

  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "training-programs"
      )?.children;
      return (
        children?.filter(
          (permission) => permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const { data: playerUserOptions, isLoading: playerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.PLAYER, {
      sportId: sportId,
      type: "TRAINING_PROGRAM",
      organizationId: organizationId,
      genders: genders,
      ...(levelId && {
        levelId: levelId
      })
    });

  const [playerOptionsList, setPlayerOptionsList] = useState<ModelPerson[]>([]);
  useEffect(() => {
    if (playerUserOptions) {
      setPlayerOptionsList(playerUserOptions.data);
    }
  }, [playerUserOptions]);

  const { data: coachUserOptions, isLoading: coachUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.COACH, {
      organizationId: organizationId
    });
  const [coachOptionsList, setCoachOptionsList] = useState<ModelPerson[]>([]);

  useEffect(() => {
    if (coachUserOptions) {
      setCoachOptionsList(coachUserOptions.data);
    }
  }, [coachUserOptions]);
  const { data: managerUserOptions, isLoading: managerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.MANAGER, {
      organizationId: organizationId
    });
  const [managerOptionsList, setManagerOptionsList] = useState<ModelPerson[]>(
    []
  );

  useEffect(() => {
    if (managerUserOptions) {
      setManagerOptionsList(managerUserOptions.data);
    }
  }, [managerUserOptions]);
  const { data: subRoleOptions } = useAdminSubroleGet({
    pageSize: "100",
    organizationId: organizationId,
    includePermissions: true
  });

  const {
    data: programResponse,
    isLoading,
    error: error
  } = useAdminTrainingProgramProgramIdGet(programId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const program = useMemo(() => {
    if (programResponse) {
      return programResponse.data;
    }
    return null;
  }, [programResponse]);

  const programRoles = useMemo(() => {
    if (programResponse) {
      return programResponse.data.roles || [];
    }
    return [];
  }, [programResponse]);

  const getCoachesOrManagers = (alias) => {
    if (!program) return;
    const selectedPlayersInvite = program.invites?.filter(
      (item) => item.invitedFor![0]?.alias === alias
    );
    const addedPlayers = program.userRoles?.filter(
      (item) => item.role?.alias === alias
    );
    let invites: CoachOption[] = [],
      accepted: CoachOption[] = [];

    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          roleId: player?.userRoleId,
          ...player,
          role: alias,
          permissions: player?.permissionOverrides,
          status: "ACTIVE"
        };
        return formattedPerson;
      });
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId,
            value: player!.person!.personId,
            roleId: player?.invitedFor![0]?.roleId,
            ...player,
            role: alias,
            status: "PENDING"
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };
  const getPlayers = () => {
    if (!program) return;
    const selectedPlayersInvite = program.invites?.filter(
      (item) => item.invitedFor![0]?.alias === "PLAYER"
    );
    const addedPlayers = program.userRoles?.filter(
      (item) => item.role?.alias === "PLAYER"
    );

    const getParentObject = (parentId) => {
      const invitedParent = program.invites?.find(
        (invite) => invite?.personId === parentId
      );
      if (invitedParent)
        return {
          id: invitedParent.personId,
          personId: invitedParent.personId,
          label: `${invitedParent?.person?.firstName} ${invitedParent?.person?.lastName}`,
          ...invitedParent,
          roleId: invitedParent!.invitedFor![0].roleId!
        };
      const addedParent = program.userRoles?.find(
        (player) => player?.user?.personId === parentId
      );
      if (addedParent)
        return {
          id: addedParent.user?.person?.personId,
          personId: addedParent.user?.person?.personId,
          label: `${addedParent?.user?.person?.firstName} ${addedParent?.user?.person?.lastName}`,
          ...addedParent,
          roleId: addedParent?.userRoleId,
          permissions: addedParent?.permissionOverrides
        };
    };

    let invites: PlayerOption[] = [],
      accepted: PlayerOption[] = [];
    if (addedPlayers) {
      accepted = addedPlayers.map((player) => {
        const isChild = player?.user?.person?.guardians?.length;
        const formattedPerson = {
          label: `${player?.user?.person?.firstName} ${player?.user?.person?.lastName}`,
          id: player!.user!.personId!,
          personId: player!.user!.personId!,
          value: player!.user!.personId!,
          gender: player?.user?.person?.gender,
          roleId: player?.userRoleId,
          ...player,
          permissions: player?.permissionOverrides,
          parent: isChild
            ? getParentObject(
                player?.user?.person?.guardians![0].guardian?.personId
              )
            : undefined,
          status: isChild ? "CHILD_ACCEPTED" : "USER_ACCEPTED"
        };
        return formattedPerson;
      });
    }
    if (selectedPlayersInvite) {
      invites = selectedPlayersInvite
        .filter((iu) => !accepted.find((a) => a.id === iu!.person!.personId))
        .map((player) => {
          const checkIfParentAccepted = (parentId) => {
            if (!addedPlayers || !addedPlayers.length) return false;
            const accepted = addedPlayers.findIndex(
              (player) => player!.user!.personId === parentId
            );
            if (accepted !== -1) return true;
            return false;
          };
          const isChild = player?.person?.guardians?.length;
          const formattedPerson = {
            label: `${player?.person?.firstName} ${player?.person?.lastName}`,
            id: player!.person!.personId,
            value: player!.person!.personId,
            gender: player?.person?.gender,
            roleId: player?.invitedFor![0]?.roleId,
            ...player,
            status: isChild
              ? checkIfParentAccepted(
                  player?.person?.guardians![0].guardian?.personId
                )
                ? "PARENT_ACCEPTED"
                : "CHILD_PENDING"
              : "USER_PENDING",
            parent: isChild
              ? getParentObject(
                  player?.person?.guardians![0].guardian?.personId
                )
              : undefined
          };
          return formattedPerson;
        });
    }
    return [...invites, ...accepted];
  };
  const populateUsers = () => {
    if (!program) return;
    const playersSelected = getPlayers();
    if (playersSelected?.length) {
      setPlayers([...playersSelected.map((player) => player.id)]);
      setPlayersWithValues([...playersSelected]);
    }
    const coachesSelected = getCoachesOrManagers("COACH");
    if (coachesSelected?.length) {
      setCoaches([...coachesSelected.map((coach) => coach.id)]);
      setCoachesWithValues([...coachesSelected]);
    }
    const managersSelected = getCoachesOrManagers("MANAGER");
    if (managersSelected) {
      setManagers([...managersSelected.map((manager) => manager.id)]);
      setManagerWithValues([...managersSelected]);
    }
  };

  const convertTimetoDate = (time: string) => {
    const dateString = "2023-12-13"; //can be any date because we only show time in timePicker
    const dateTimeString = `${dateString}T${time}`;
    return new Date(dateTimeString);
  };
  const generateDateTimeComponents = (dates) => {
    const dateTimeComponents = dates.flatMap((date, index) => {
      const dateAndTimeObject = {
        clearable: index !== 0 ? true : false,
        onlyTime: false,
        startDate: new Date(date.startDate),
        endDate: new Date(date.endDate),
        startTime:
          date.timings.length > 0
            ? convertTimetoDate(date.timings[0].startTime)
            : undefined,
        endTime:
          date.timings.length > 0
            ? convertTimetoDate(date.timings[0].endTime)
            : undefined,
        customRepeat: date.timings[0].customRepeat,
        generatedObject: date.timings[0].customRepeat
      };
      const timingsObject =
        date.timings.length > 1
          ? date.timings.slice(1).map((timing) => ({
              clearable: false,
              onlyTime: true,
              startDate: undefined,
              endDate: undefined,
              startTime: convertTimetoDate(timing.startTime),
              endTime: convertTimetoDate(timing.endTime),
              customRepeat: timing.customRepeat
            }))
          : [];
      return [dateAndTimeObject, ...timingsObject];
    });
    return dateTimeComponents;
  };
  const [generatedComponents, setGeneratedComponents] = useState(
    getValues().dateTimeComponents
  );
  // useEffect(() => {
  //   if (getValues().dateTimeComponents)
  //     setGeneratedComponents(getValues().dateTimeComponents);
  // }, [reset]);
  useEffect(() => {
    if (!program) return;
    if (program.sportId) setSportId(program.sportId);
    populateUsers();
    const defaultValues = {
      name: program.name,
      sportId: program.sportId,
      gender: program.genders,
      level: program.levelId || "",
      type: program.type,
      dateTimeComponents: generateDateTimeComponents(program.dates)
    };
    setGenders(program.genders);
    setLevelId(defaultValues.level);
    setGeneratedComponents(defaultValues.dateTimeComponents);
    reset(defaultValues);
  }, [program]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );

  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const coachSubRoles = useMemo(() => {
    const existingSubRoles =
      programRoles.find((r) => r.alias === "COACH")?.children || [];
    const newOnes =
      subRoleOptions?.data?.roles?.filter(
        (subRole) =>
          !existingSubRoles.find((r) => r.inheritedFromId === subRole.roleId) &&
          subRole.alias === "COACH"
      ) || [];
    return [...existingSubRoles, ...(newOnes || [])];
  }, [subRoleOptions, programRoles]);

  const managerSubRoles = useMemo(() => {
    const existingSubRoles =
      programRoles.find((r) => r.alias === "MANAGER")?.children || [];
    const newOnes =
      subRoleOptions?.data?.roles?.filter(
        (subRole) =>
          !existingSubRoles.find((r) => r.inheritedFromId === subRole.roleId) &&
          subRole.alias === "MANAGER"
      ) || [];
    return [...existingSubRoles, ...(newOnes || [])];
  }, [subRoleOptions, programRoles]);

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1]);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/training-programs");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const convertDateAndTimeArray = (inputArray) => {
    const dates = [];

    inputArray.forEach((item) => {
      const { onlyTime, startDate, startTime, endDate, endTime, customRepeat } =
        item;
      if (startDate) {
        // Start a new date object when a non-empty startDate is encountered
        const currentDate = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          timings: []
        };
        if (!onlyTime) {
          currentDate.startDate = formatDate(startDate);
        }
        if (startTime && endTime && customRepeat) {
          //@ts-ignore
          currentDate.timings.push({
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
            customRepeat: customRepeat
          });
        }
        // @ts-ignore
        dates.push(currentDate);
      } else if (dates.length > 0) {
        // If a startDate doesn't exist but there are previous dates, add timings to the most recent date object
        // @ts-ignore
        const currentTimings = dates[dates.length - 1].timings;
        if (startTime && endTime && customRepeat) {
          currentTimings.push({
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
            customRepeat: customRepeat
          });
        }
      }
    });

    return dates;
  };
  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1]);
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };
  const { mutate: save, isLoading: isSaving } =
    useAdminTrainingProgramProgramIdPatch();
  const onSaveClick = async (formValues) => {
    try {
      const users = [
        ...playersWithValues,
        ...coachesWithValues,
        ...managerWithValues
      ];
      const cleanedUsers = users.map((user) => cleanObject(user));
      const cleanedUsersTrimmed = cleanedUsers.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ user, ...rest }) => rest
      );
      const cleanDefaultPermissions = defaultPermissions
        .map((permission) => {
          delete permission.children;
          delete permission.createdAt;
          return cleanObject(permission);
        })
        .filter(
          (role) =>
            // if parent role is selected, allow
            // but if it's a child role, only allow if it has users
            !role.parentId ||
            (role.parentId &&
              cleanedUsers.some((user) => user.roleId === role.roleId))
        );
      const data = {
        name: formValues.name,
        sportId: sportId,
        levelId: formValues.level,
        gender: formValues.gender,
        organizationId: organizationId,
        users: cleanedUsersTrimmed,
        defaultPermissions: cleanDefaultPermissions,
        type: formValues.type,
        dates: convertDateAndTimeArray(getValues().dateTimeComponents)
      };
      save(
        {
          programId: programId as string,
          data: data as TrainingProgramWithOrgCreateInput
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Training Program edited successfully!", {
              variant: "success"
            });
            navigate(`/training-programs/${programId}`);
          },
          onError: () => {
            enqueueSnackbar("Failed to edit Training Program!", {
              variant: "error"
            });
          }
        }
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };
  const getBadgeCounts = () => {
    return [
      0,
      playersWithValues.length,
      coachesWithValues.length,
      managerWithValues.length,
      0
    ];
  };

  return (
    <Container>
      <Toolbar
        title="Edit Training Program"
        tabs={{
          tabs: steps,
          onTabChange: (e, v) => {
            if (form.formState.isValid) setTab(v as string);
          },
          activeTab: tab
        }}
        badgeCounts={getBadgeCounts()}
      />
      <Form>
        <Loader isLoading={isSportLoading || isLoading}>
          <Grid container spacing={3}>
            {tab === "Program Details" && generatedComponents && (
              <ProgramDetailsForm
                disabled={false}
                isEditing={true}
                isEditMode={true}
                generatedComponents={generatedComponents}
                form={form}
                isLoadingOptions={isSportLoading && levelOptionsLoading}
                sportOptions={sportOptions}
                levelOptions={levelOptions}
                typeOptions={[
                  { label: "Individual Group", value: "INDIVIDUAL_TRAINING" },
                  {
                    label: "Small Group Training",
                    value: "SMALL_GROUP_TRAINING"
                  },
                  { label: "Large Group Training", value: "TRAINING_CAMP" }
                ]}
                setSportsId={setSportId}
                setGenders={setGenders}
                genders={genders}
                setLevelId={setLevelId}
              />
            )}
            {tab === "Athlete Selection" && !playerUserOptionsLoading && (
              <Grid xs={12} data-testid="players_tab">
                <PlayerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={true}
                  isLoadingUsers={playerUserOptionsLoading}
                  userOptions={playerOptionsList}
                  setUserOptions={setPlayerOptionsList}
                  positionOptions={[]}
                  players={players}
                  playersWithValues={playersWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  setPlayers={setPlayers}
                  type="program"
                  roleId={
                    programRoles.find((r) => r.alias === "PLAYER")
                      ?.roleId as string
                  }
                  parentRoleId={
                    programRoles.find((r) => r.alias === "PARENT")
                      ?.roleId as string
                  }
                  sportId={sportId}
                />
              </Grid>
            )}
            {tab === "Coach Selection" && !coachUserOptionsLoading && (
              <Grid xs={12}>
                <CoachSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={true}
                  isLoadingUsers={coachUserOptionsLoading}
                  userOptions={coachOptionsList}
                  setUserOptions={setCoachOptionsList}
                  subRoleOptions={coachSubRoles || []}
                  coaches={coaches}
                  coachesWithValues={coachesWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setCoaches={setCoaches}
                  roleId={
                    programRoles.find((r) => r.alias === "COACH")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Manager Selection" && !managerUserOptionsLoading && (
              <Grid xs={12}>
                <ManagerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={true}
                  isLoadingUsers={managerUserOptionsLoading}
                  userOptions={managerOptionsList}
                  setUserOptions={setManagerOptionsList}
                  subRoleOptions={managerSubRoles || []}
                  managers={managers}
                  managersWithValues={managerWithValues}
                  setManagersWithValues={setManagerWithValues}
                  setManagers={setManagers}
                  roleId={
                    programRoles.find((r) => r.alias === "MANAGER")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Permissions" && (
              <Grid xs={12}>
                <PermissionSelectionForm
                  form={form}
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  coachesWithValues={coachesWithValues}
                  managerWithValues={managerWithValues}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  players={players}
                  coaches={coaches}
                  managers={managers}
                  playerRole={programRoles.find((r) => r.alias === "PLAYER")!}
                  parentRole={programRoles.find((r) => r.alias === "PARENT")!}
                  coachRole={programRoles.find((r) => r.alias === "COACH")!}
                  coachSubRoles={coachSubRoles || []}
                  managerRole={programRoles.find((r) => r.alias === "MANAGER")!}
                  managerSubRoles={managerSubRoles || []}
                  permissions={permissionsList}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
      </Form>
      {/* <DevTool control={form.control} /> */}
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
              isLoading={isLoading || isSportLoading}
              key={Math.random().toString()}
            >
              Back
            </Button>
          ) : null,
          <Button
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            isLoading={isLoading || isSportLoading}
            key={Math.random().toString()}
          >
            Cancel
          </Button>,
          <Button
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={!isValid || !form.formState.isDirty || isSaving}
            isLoading={isSaving}
            key={Math.random().toString()}
          >
            {activeStepNumber < steps.length - 1
              ? "Save & Continue"
              : "Save & Send Invites"}
          </Button>
        ]}
        isDisabled={!isValid}
        isLoading={isLoading || isSportLoading || isSaving}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
