import { Permission } from "@components/Permission";
import { Grid } from "@mui/material";
import {
  ModelPermission,
  ModelRole,
  ModelRolePermission,
  PermissionAction
  // ModelRolePermission,
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo } from "react";
import { CoachOption } from "./CoachSelectionForm";
import { ManagerOption } from "./ManagerSelectionForm";
import { PlayerOption } from "./PlayerSelectionForm";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { isEqual } from "../../../utils/objectFunctions";
import { DevTool } from "@hookform/devtools";

export const PermissionSelectionForm = (props: {
  playersWithValues: PlayerOption[];
  players: string[];
  playerRole: ModelRole;
  parentRole: ModelRole;
  coachesWithValues: CoachOption[];
  coaches: string[];
  coachRole: ModelRole;
  coachSubRoles: ModelRole[];
  managerWithValues: ManagerOption[];
  managers: string[];
  managerRole: ModelRole;
  managerSubRoles: ModelRole[];
  disabled?: boolean;
  setManagerWithValues: (value: ManagerOption[]) => void;
  setCoachesWithValues: (value: CoachOption[]) => void;
  setPlayersWithValues: (value: PlayerOption[]) => void;
  permissions: ModelPermission[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any, undefined>;
  defaultPermissions: ModelRole &
    {
      permissions: ModelRolePermission[];
    }[];
  setDefaultPermissions: (
    value: ModelRole &
      {
        permissions: ModelRolePermission[];
      }[]
  ) => void;
  isEditing?: boolean;
}) => {
  const { setValue } = props.form;
  const coachesWithSubRole = useMemo(() => {
    const temp = props.coachesWithValues
      .filter(
        (coach) =>
          coach.roleId !== props.coachRole.roleId &&
          coach.roleId !== props.coachRole.inheritedFromId
      )
      .map((coach) => {
        const assignedPermissions = coach.permissions || [];
        return {
          ...coach,
          title: "Assigned Permissions",
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              assignedPermissions && assignedPermissions.length
                ? assignedPermissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || []
                : props.coachRole.children
                    ?.find((r) => r.roleId === coach.roleId)
                    ?.permissions?.find(
                      (rp) => rp.permissionId === permission.permissionId
                    )?.actions || [];

            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${coach.roleId}__${coach.id}__${idx}__${action}`,
                  permissionActions.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }

            if (permission.type === "BOOL") {
              setValue(
                `${coach.roleId}__${coach.id}__${idx}`,
                permissionActions.includes(PermissionAction.ON) || false
              );
            }
            return {
              userRoleId: coach.userRoleId,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });
    return temp;
  }, [props.coaches, props.permissions, props.coachesWithValues]);
  useEffect(() => {
    const updated = [
      ...coachesWithSubRole,
      ...props.coachesWithValues.filter(
        (coach) =>
          coach.roleId === props.coachRole.roleId ||
          coach.roleId === props.coachRole.inheritedFromId
      )
    ] as CoachOption[];
    if (!isEqual(updated, props.coachesWithValues)) {
      props.setCoachesWithValues(updated);
    }
  }, [coachesWithSubRole]);

  const coachesWithNoSubRole = useMemo(() => {
    const temp = props.coachesWithValues
      .filter(
        (coach) =>
          coach.roleId === props.coachRole.roleId ||
          coach.roleId === props.coachRole.inheritedFromId
      )
      .map((coach) => {
        return {
          ...coach,
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              coach.permissions && coach.permissions.length
                ? coach.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || []
                : props.coachRole.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];

            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${props.coachRole.roleId}__${coach.id}__${idx}__${action}`,
                  permissionActions.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }

            if (permission.type === "BOOL") {
              setValue(
                `${props.coachRole.roleId}__${coach.id}__${idx}`,
                permissionActions.includes(PermissionAction.ON) || false
              );
            }

            return {
              userRoleId: undefined,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });
    return temp;
  }, [props.coaches]);

  useEffect(() => {
    const updated = [
      ...props.coachesWithValues.filter(
        (coach) =>
          coach.roleId !== props.coachRole.roleId &&
          coach.roleId !== props.coachRole.inheritedFromId
      ),
      ...coachesWithNoSubRole
    ] as CoachOption[];
    if (!isEqual(updated, props.coachesWithValues)) {
      props.setCoachesWithValues(updated);
    }
  }, [coachesWithNoSubRole]);

  const subRoleToCoachMap = useMemo(() => {
    return props.coachSubRoles.reduce(
      (acc, subRole) => {
        acc[subRole.roleId!] = coachesWithSubRole.filter(
          (coach) =>
            coach.roleId === subRole.roleId ||
            coach.roleId === subRole.inheritedFromId
        );
        if (acc[subRole.roleId!].length === 0) {
          delete acc[subRole.roleId!];
        }
        return acc;
      },
      {} as Record<string, CoachOption[]>
    );
  }, [props.coachSubRoles, coachesWithSubRole]);

  const managersWithSubRole = useMemo(() => {
    const temp = props.managerWithValues
      .filter(
        (manager) =>
          manager.roleId !== props.managerRole.roleId &&
          manager.roleId !== props.managerRole.inheritedFromId
      )
      .map((manager) => {
        const assignedPermissions = manager.permissions || [];
        return {
          ...manager,
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              assignedPermissions && assignedPermissions.length
                ? assignedPermissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions
                : props.managerRole.children
                    ?.find((r) => r.roleId === manager.roleId)
                    ?.permissions?.find(
                      (rp) => rp.permissionId === permission.permissionId
                    )?.actions || [];
            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${manager.roleId}__${manager.id}__${idx}__${action}`,
                  permissionActions?.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }
            if (permission.type === "BOOL") {
              setValue(
                `${manager.roleId}__${manager.id}__${idx}`,
                permissionActions?.includes(PermissionAction.ON) || false
              );
            }
            return {
              userRoleId: undefined,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });
    return temp;
  }, [
    props.managers,
    props.managerRole,
    props.managerWithValues,
    props.permissions
  ]);

  useEffect(() => {
    const updated = [
      ...managersWithSubRole,
      ...props.managerWithValues.filter(
        (manager) =>
          manager.roleId === props.managerRole.roleId ||
          manager.roleId === props.managerRole.inheritedFromId
      )
    ] as ManagerOption[];
    if (!isEqual(updated, props.managerWithValues)) {
      props.setManagerWithValues(updated);
    }
  }, [managersWithSubRole]);

  const managersWithNoSubRole = useMemo(() => {
    const temp = props.managerWithValues
      .filter(
        (manager) =>
          manager.roleId === props.managerRole.roleId ||
          manager.roleId === props.managerRole.inheritedFromId
      )
      .map((manager) => {
        const assignedPermissions = manager.permissions || [];
        return {
          ...manager,
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              assignedPermissions && assignedPermissions.length
                ? assignedPermissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || []
                : props.managerRole.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];

            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${props.managerRole.roleId}__${manager.id}__${idx}__${action}`,
                  permissionActions.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }
            if (permission.type === "BOOL") {
              setValue(
                `${props.managerRole.roleId}__${manager.id}__${idx}`,
                permissionActions.includes(PermissionAction.ON) || false
              );
            }

            return {
              userRoleId: undefined,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });
    return temp;
  }, [props.managers, props.permissions]);

  useEffect(() => {
    const updated = [
      ...props.managerWithValues.filter(
        (manager) =>
          manager.roleId !== props.managerRole.roleId &&
          manager.roleId !== props.managerRole.inheritedFromId
      ),
      ...managersWithNoSubRole
    ] as ManagerOption[];
    if (!isEqual(updated, props.managerWithValues)) {
      props.setManagerWithValues(updated);
    }
  }, [managersWithNoSubRole, props.managerWithValues]);

  const subRoleToManagerMap = useMemo(() => {
    return props.managerSubRoles.reduce(
      (acc, subRole) => {
        acc[subRole.roleId!] = managersWithSubRole.filter(
          (manager) =>
            manager.roleId === subRole.roleId ||
            manager.roleId === subRole.inheritedFromId
        );
        if (acc[subRole.roleId!].length === 0) {
          delete acc[subRole.roleId!];
        }
        return acc;
      },
      {} as Record<string, ManagerOption[]>
    );
  }, [props.managerSubRoles, managersWithSubRole]);

  const parents = useMemo(() => {
    const temp = props.playersWithValues
      .map((player) => player.parent)
      .filter(Boolean)
      .map((parent) => {
        const parentAssignedPermissions = parent?.permissions || [];
        return {
          ...parent,
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              parentAssignedPermissions && parentAssignedPermissions.length
                ? parentAssignedPermissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || []
                : props.parentRole.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];

            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${props.parentRole.roleId}__${parent.id}__${idx}__${action}`,
                  permissionActions.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }

            if (permission.type === "BOOL") {
              setValue(
                `${props.parentRole.roleId}__${parent.id}__${idx}`,
                permissionActions.includes(PermissionAction.ON) || false
              );
            }
            return {
              userRoleId: undefined,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });

    return temp;
  }, [props.players]);

  const players = useMemo(() => {
    const pars = props.playersWithValues
      .map((player) => player.parent)
      .filter(Boolean)
      .map((parent) => {
        const parentAssignedPermissions = parent?.permissions || [];
        return {
          ...parent,
          permissions: props.permissions.map((permission, idx) => {
            const permissionActions =
              parentAssignedPermissions && parentAssignedPermissions.length
                ? parentAssignedPermissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || []
                : props.parentRole.permissions?.find(
                    (rp) => rp.permissionId === permission.permissionId
                  )?.actions || [];

            if (permission.type === "MULTI") {
              for (const action of Object.values(PermissionAction)) {
                setValue(
                  `${props.parentRole.roleId}__${parent.id}__${idx}__${action}`,
                  permissionActions.includes(action as PermissionAction) ||
                    undefined
                );
              }
            }

            if (permission.type === "BOOL") {
              setValue(
                `${props.parentRole.roleId}__${parent.id}__${idx}`,
                permissionActions.includes(PermissionAction.ON) || false
              );
            }
            return {
              userRoleId: undefined,
              permissionId: permission.permissionId,
              actions: permissionActions
            };
          })
        };
      });
    const temp = props.playersWithValues.map((player) => {
      const playerAssignedPermissions = player.permissions || [];
      const parent = pars.find((p) => p.id === player.parent?.id);
      return {
        ...player,
        ...(parent && {
          parent: parent
        }),
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            playerAssignedPermissions && playerAssignedPermissions.length
              ? playerAssignedPermissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || []
              : props.playerRole.permissions?.find(
                  (rp) => rp.permissionId === permission.permissionId
                )?.actions || [];

          if (permission.type === "MULTI") {
            for (const action of Object.values(PermissionAction)) {
              setValue(
                `${props.playerRole.roleId}__${player.id}__${idx}__${action}`,
                permissionActions?.includes(action as PermissionAction) || false
              );
            }
          }

          if (permission.type === "BOOL") {
            setValue(
              `${props.playerRole.roleId}__${player.id}__${idx}`,
              permissionActions?.includes(PermissionAction.ON) || false
            );
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions
          };
        })
      };
    });
    return temp;
  }, [props.players]);

  useEffect(() => {
    if (!isEqual(players, props.playersWithValues)) {
      props.setPlayersWithValues(players);
    }
  }, [players]);
  useMemo(() => {
    const rolePermission: ModelRole &
      {
        permissions: ModelRolePermission[];
      }[] = [];

    rolePermission.push({
      ...props.coachRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          props.coachRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        if (permission.type === "MULTI") {
          ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
            setValue(
              `${props.coachRole.roleId}__${idx}__${action}`,
              permissionActions?.includes(action as PermissionAction) || false
            );
          });
        }

        if (permission.type === "BOOL") {
          setValue(
            `${props.coachRole.roleId}__${idx}`,
            permissionActions?.includes(PermissionAction.ON) || false
          );
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions:
            props.coachRole.permissions?.find(
              (rp) => rp.permissionId === permission.permissionId
            )?.actions || []
        };
      })
    });

    rolePermission.push({
      ...props.managerRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          props.managerRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        if (permission.type === "MULTI") {
          ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
            setValue(
              `${props.managerRole.roleId}__${idx}__${action}`,
              permissionActions?.includes(action as PermissionAction) || false
            );
          });
        }

        if (permission.type === "BOOL") {
          setValue(
            `${props.managerRole.roleId}__${idx}`,
            permissionActions?.includes(PermissionAction.ON) || false
          );
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: props.managerRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions
        };
      })
    });

    rolePermission.push({
      ...props.playerRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          props.playerRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        if (permission.type === "MULTI") {
          ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
            setValue(
              `${props.playerRole.roleId}__${idx}__${action}`,
              permissionActions?.includes(action as PermissionAction) || false
            );
          });
        }

        if (permission.type === "BOOL") {
          setValue(
            `${props.playerRole.roleId}__${idx}`,
            permissionActions?.includes(PermissionAction.ON) || false
          );
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: permissionActions
        };
      })
    });

    rolePermission.push({
      ...props.parentRole,
      permissions: props.permissions.map((permission, idx) => {
        const permissionActions =
          props.parentRole.permissions?.find(
            (rp) => rp.permissionId === permission.permissionId
          )?.actions || [];

        if (permission.type === "MULTI") {
          ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
            setValue(
              `${props.parentRole.roleId}__${idx}__${action}`,
              permissionActions?.includes(action as PermissionAction) || false
            );
          });
        }

        if (permission.type === "BOOL") {
          setValue(
            `${props.parentRole.roleId}__${idx}`,
            permissionActions?.includes(PermissionAction.ON) || false
          );
        }

        return {
          userRoleId: undefined,
          permissionId: permission.permissionId,
          actions: permissionActions
        };
      })
    });
    // repeat for sub roles
    props.coachSubRoles.forEach((subRole) => {
      rolePermission.push({
        ...subRole,
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            subRole.permissions?.find(
              (rp) => rp.permissionId === permission.permissionId
            )?.actions || [];

          if (permission.type === "MULTI") {
            ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
              setValue(
                `${subRole.roleId}__${idx}__${action}`,
                permissionActions?.includes(action as PermissionAction) || false
              );
            });
          }

          if (permission.type === "BOOL") {
            setValue(
              `${subRole.roleId}__${idx}`,
              permissionActions?.includes(PermissionAction.ON) || false
            );
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions
          };
        })
      });
    });

    props.managerSubRoles.forEach((subRole) => {
      rolePermission.push({
        ...subRole,
        permissions: props.permissions.map((permission, idx) => {
          const permissionActions =
            subRole.permissions?.find(
              (rp) => rp.permissionId === permission.permissionId
            )?.actions || [];

          if (permission.type === "MULTI") {
            ["ALL", "VIEW", "ADD", "EDIT", "DELETE"].forEach((action) => {
              setValue(
                `${subRole.roleId}__${idx}__${action}`,
                permissionActions?.includes(action as PermissionAction) || false
              );
            });
          }

          if (permission.type === "BOOL") {
            setValue(
              `${subRole.roleId}__${idx}`,
              permissionActions?.includes(PermissionAction.ON) || false
            );
          }

          return {
            userRoleId: undefined,
            permissionId: permission.permissionId,
            actions: permissionActions
          };
        })
      });
    });
    props.setDefaultPermissions(rolePermission);
  }, [
    props.coachRole,
    props.managerRole,
    props.playerRole,
    props.parentRole,
    props.coachSubRoles,
    props.managerSubRoles,
    props.permissions
  ]);
  return (
    <Grid container direction="column" spacing="25px">
      <Permission
        disabled={props.disabled}
        defaultPermissions={props.defaultPermissions}
        role={props.coachRole}
        userList={coachesWithNoSubRole}
        masterList={props.coachesWithValues}
        setMasterList={props.setCoachesWithValues}
        permissions={props.permissions}
        setUserPermissions={props.setCoachesWithValues}
        form={props.form}
      />
      {Object.entries(subRoleToCoachMap).map(([roleId, coaches]) => (
        <Permission
          disabled={props.disabled}
          defaultPermissions={props.defaultPermissions}
          key={roleId}
          role={props.coachSubRoles.find((role) => role.roleId === roleId)!}
          userList={coaches}
          masterList={props.coachesWithValues}
          setMasterList={props.setCoachesWithValues}
          permissions={props.permissions}
          setUserPermissions={(value) => {
            props.setCoachesWithValues([
              ...coachesWithNoSubRole,
              ...value
            ] as CoachOption[]);
          }}
          form={props.form}
        />
      ))}
      <Permission
        disabled={props.disabled}
        defaultPermissions={props.defaultPermissions}
        role={props.managerRole}
        userList={managersWithNoSubRole}
        masterList={props.managerWithValues}
        setMasterList={props.setManagerWithValues}
        permissions={props.permissions}
        setUserPermissions={props.setManagerWithValues}
        form={props.form}
      />
      {Object.entries(subRoleToManagerMap).map(([roleId, managers]) => (
        <Permission
          disabled={props.disabled}
          defaultPermissions={props.defaultPermissions}
          key={roleId}
          role={props.managerSubRoles.find((role) => role.roleId === roleId)!}
          userList={managers}
          masterList={props.managerWithValues}
          setMasterList={props.setManagerWithValues}
          permissions={props.permissions}
          setUserPermissions={(value) => {
            props.setManagerWithValues([
              ...managersWithNoSubRole,
              ...value
            ] as ManagerOption[]);
          }}
          form={props.form}
        />
      ))}
      <Permission
        disabled={props.disabled}
        defaultPermissions={props.defaultPermissions}
        role={props.playerRole}
        userList={players}
        masterList={props.playersWithValues}
        setMasterList={(v) => {
          props.setPlayersWithValues(v);
        }}
        permissions={props.permissions}
        setUserPermissions={(v) => {
          props.setPlayersWithValues(v);
        }}
        form={props.form}
      />
      <Permission
        disabled={props.disabled}
        defaultPermissions={props.defaultPermissions}
        role={props.parentRole}
        userList={parents}
        masterList={props.playersWithValues}
        setMasterList={(v) => {
          props.setPlayersWithValues(v);
        }}
        permissions={props.permissions}
        setUserPermissions={(v) => {
          props.setPlayersWithValues(v);
        }}
        form={props.form}
      />
      <DevTool control={props.form.control} />
    </Grid>
  );
};
