/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MenuItem,
  Select,
  Box,
  Checkbox,
  ListItemText,
  Chip,
  styled
} from "@mui/material";

const StyledChip = styled(Chip)(() => ({
  "&.MuiChip-root.Mui-disabled": {
    pointerEvents: "none",
    opacity: 1
  }
}));

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "8px !important"
  }
}));
export const MultiSelectFilter = ({
  options,
  setChartFilter,
  chartFilter,
  maxSelections
}: {
  options: any;
  setChartFilter: (value) => void;
  chartFilter: string[];
  maxSelections?: number;
}) => {
  return (
    <StyledSelect
      multiple
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 350
          }
        }
      }}
      style={{
        width: "100%",
        minHeight: "40px"
      }}
      onChange={(e) => {
        //@ts-ignore
        setChartFilter([...e.target.value]);
      }}
      value={chartFilter}
      renderValue={(selected) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {/* @ts-ignore */}
            {selected.map((value, index) => {
              const label = options.find((option) => option.value === value)
                ?.label;

              const onDelete = () => {
                const newList = [
                  ...chartFilter.slice(0, index),
                  ...chartFilter.slice(index + 1)
                ];
                setChartFilter([...newList]);
              };

              const onMouseDown = (event) => {
                event.preventDefault();
                event.stopPropagation();
              };

              return (
                <StyledChip
                  data-testid={"CHIP_MULTI_SELECT_" + value}
                  key={value}
                  label={label}
                  onDelete={() => {
                    onDelete();
                  }}
                  onMouseDown={onMouseDown}
                />
              );
            })}
          </Box>
        );
      }}
    >
      {options.map((opt) => (
        <MenuItem
          disabled={
            chartFilter.length === maxSelections &&
            !chartFilter.includes(opt.value as string)
          }
          key={opt.value}
          value={opt.value}
        >
          <Checkbox checked={chartFilter.indexOf(opt.value as string) > -1} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
