import { Button } from "@components/Button";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { KidInvite, inviteAtom, kidInviteAtom } from "@recoil/signup";
import { ModelInvite } from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

const relationshipOptions = [
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
  { value: "Stepson", label: "Stepson" },
  { value: "Stepdaughter", label: "Stepdaughter" },
  { value: "Nephew", label: "Nephew" },
  { value: "Niece", label: "Niece" },
  { value: "Grandson", label: "Grandson" },
  { value: "Granddaughter", label: "Granddaughter" },
  { value: "Cousin", label: "Cousin" }
];

export const KidSelect = () => {
  const navigate = useNavigate();
  const [kidInvite, setKidInvite] = useRecoilState<KidInvite[]>(kidInviteAtom);
  const invite = useRecoilValue<ModelInvite | null>(inviteAtom);

  const childrenDefaultValues: {
    [key: string]: {
      selected?: boolean;
      email?: string;
      dob?: string;
      relationship?: string;
    };
  } = useMemo(() => {
    const values = {};
    kidInvite?.map((kid) => {
      values[kid.personId] = {
        selected: kid.selected,
        email: kid.email,
        dob: new Date(kid.dob),
        relationship: kid.relationship
      };
    });
    invite?.person?.children?.map((child) => {
      if (child.personId && !values[child.personId]) {
        values[child.personId] = {
          selected: true,
          // @ts-ignore
          email: child?.person?.email,
          dob: new Date(child?.person?.birthedAt || ""),
          relationship: ""
        };
      }
    });

    return values;
  }, [kidInvite]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
    unregister
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      children: childrenDefaultValues
    }
  });

  const watchChildren = watch("children");

  const submitForm = async (formValues: {
    children: {
      [key: string]: {
        selected?: boolean;
        email?: string;
        dob?: string;
        relationship?: string;
      };
    };
  }) => {
    const kidInvites: KidInvite[] = [];
    Object.keys(formValues.children).map((key) => {
      const kid = formValues.children[key];
      if (kid.selected) {
        console.log(kid);
        kidInvites.push({
          personId: key,
          email: kid.email as string,
          dob: new Date(kid.dob || "").toISOString().split("T")[0],
          relationship: kid.relationship as string,
          selected: kid.selected
        });
      }
    });
    setKidInvite(kidInvites);
    if (kidInvites.length) {
      // check each kids dob to see if they're under 13
      const under13 = kidInvites.some((kid) => {
        const dob = new Date(kid.dob || "");
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const m = today.getMonth() - dob.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--;
        }
        return age < 13;
      });
      if (under13) {
        navigate("/sign-up/kid-consent");
      } else {
        navigate("/sign-up/terms-of-use");
      }
    } else {
      navigate("/sign-up/terms-of-use");
    }
  };

  return (
    <Box
      sx={{
        marginLeft: {
          xs: "-.25rem",
          md: "-.25rem"
        },
        marginRight: {
          xs: "-.20rem",
          md: "-.20rem"
        },
        marginTop: "-.25rem"
      }}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid
          data-testid="kid-invite"
          container
          direction={"row"}
          spacing={1}
          sx={{ width: "100%", margin: 0 }}
        >
          <Grid xs={12}>
            <Typography
              variant="h2"
              color="text.general.primary"
              sx={{ fontWeight: 400 }}
            >
              Invite Kids
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="body1" color="text.general.secondary">
              Invite your kids to sign up for a SportsGravy user account to
              receive invaluable feedback from coaches.
            </Typography>
          </Grid>
          {invite?.person?.children?.map((child) => (
            <Grid data-testid="kid" xs={12} key={child.personId}>
              <Accordion
                expanded={!!watchChildren?.[child.personId as string]?.selected}
                onChange={(_, expanded) => {
                  setValue(`children.${child.personId}.selected`, expanded);
                  if (!expanded) {
                    unregister(
                      [
                        `children.${child.personId}.email`,
                        `children.${child.personId}.dob`,
                        `children.${child.personId}.relationship`
                      ],
                      {
                        keepValue: true,
                        keepDefaultValue: true
                      }
                    );
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ backgroundColor: "#F8FAFC" }}
                >
                  <FormCheckbox
                    name={`children.${child.personId}.selected`}
                    control={control}
                    label={`${child.person?.firstName} ${child.person?.lastName}`}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  {!!watchChildren?.[child.personId as string]?.selected && (
                    <Grid
                      container
                      direction={"row"}
                      spacing={3}
                      sx={{ width: "100%", marginBottom: "1rem" }}
                    >
                      <Grid data-testid="kid-email" xs={12}>
                        <FormInput
                          name={`children.${child.personId}.email`}
                          type="email"
                          label="Email"
                          required={
                            !!watchChildren?.[child.personId as string]
                              ?.selected
                          }
                          control={control}
                          rules={{ required: "Email is required" }}
                        />
                      </Grid>
                      <Grid data-testid="kid-dob" xs={12}>
                        <FormDatePicker
                          name={`children.${child.personId}.dob`}
                          label="Date of Birth"
                          control={control}
                          required={
                            !!watchChildren?.[child.personId as string]
                              ?.selected
                          }
                          rules={{ required: "Date of Birth is required" }}
                        />
                      </Grid>
                      <Grid data-testid="kid-relation" xs={12}>
                        <FormSelect
                          name={`children.${child.personId}.relationship`}
                          label="Relationship to You"
                          control={control}
                          options={relationshipOptions}
                          required={
                            !!watchChildren?.[child.personId as string]
                              ?.selected
                          }
                          rules={{ required: "Relationship is required" }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <Grid xs={6} sx={{ marginTop: "1rem" }}>
            <Button
              variant="cancel"
              type="button"
              onClick={() => navigate("/sign-up/account-info")}
            >
              Go Back
            </Button>
          </Grid>
          <Grid xs={6} sx={{ marginTop: "1rem" }}>
            <Button variant="primary" disabled={!isValid} type="submit">
              Continue
            </Button>
          </Grid>
          <Grid xs={12} sx={{ textAlign: "center", marginTop: ".5rem" }}>
            <Typography variant="body1">
              Already have an account? <Link to="/">Sign In</Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
