import { Loader } from "@components/crud/Loader";
import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getCannedMessages } from "@services/Network";
import { capitalize } from "@utils/capitalize";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  {
    headerName: "Title",
    field: "title",
    minWidth: 250,
    flex: 1,
    renderCell: (params) => {
      return (
        <span
          style={{
            padding: "5px 0",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {params.value}
        </span>
      );
    }
  },
  {
    headerName: "Message",
    field: "message",
    minWidth: 250,
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <span
          style={{
            padding: "5px 0",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {params.value}
        </span>
      );
    }
  },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Gender",
    field: "genders",
    minWidth: 150,
    flex: 1,
    renderCell: (params) =>
      params.value ? params.value.map((g) => capitalize(g)).join(", ") : "-"
  },
  {
    headerName: "Levels",
    field: "levels",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  },
  {
    headerName: "Positions",
    field: "positions",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  },
  {
    headerName: "Skills",
    field: "skills",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  },
  {
    headerName: "Game Concepts",
    field: "concepts",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  },
  {
    headerName: "Game Systems",
    field: "systems",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  },
  {
    headerName: "Roles",
    field: "roles",
    minWidth: 170,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("name", 1)
  }
];

export const CannedMessages = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    edit: false,
    add: false
  });
  const [permissionLoading, setPermissionLoading] = useState(false);

  useEffect(() => {
    setPermissionLoading(true);
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("feed.canned-messaged", "EDIT");
      const add = await checkPermission("feed.canned-messaged", "ADD");
      setPermissions({
        edit,
        add
      });
      setPermissionLoading(false);
    };
    fetchPermissions();
  }, []);

  const onAdd = () => navigate("/canned-messages/create");
  const onEdit = (cannedMessage) =>
    navigate(`/canned-messages/${cannedMessage.cannedMessageId}/edit`);
  const onView = (cannedMessage) =>
    navigate(`/canned-messages/${cannedMessage.cannedMessageId}`);

  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Canned Messages"
        useGet={getCannedMessages}
        columns={LIST_COLUMNS}
        hideFilter={true}
        defaultSort={[{ field: "title", sort: "asc" }]}
        {...(permissions.add && { onAdd })}
        isEditDisabled={() => permissions.edit != false}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
        hideLabelContainer
        getRowHeight={() => "auto"}
      />
    </Loader>
  );
};
