export const convertTimeToDate = (timeStr) => {
  const currentDate = new Date();
  const [hours, minutes] = timeStr.split(":").map(Number);
  currentDate.setHours(hours, minutes, 0, 0);
  return currentDate;
};

export const convertDateToTimeString = (dateObj) => {
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};
