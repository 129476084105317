/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@components/Button";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowOrderChangeParams,
  GridValidRowModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React from "react";
import { AddIcon } from "./Icons";
import { Container } from "./crud/Container";
import { Footer } from "./crud/Footer";

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));

const AddBtn = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 20px",
  gap: "8px",
  width: "100px",
  height: "40px",
  backgroundColor: theme.palette.info.main,
  borderRadius: "100px",
  color: theme.palette.white.main,
  textTransform: "none",

  "&:hover": {
    backgroundColor: theme.palette.info.main,
    opacity: 0.8
  }
}));

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "wrap",
    lineHeight: "normal"
  },
  "&.table-view": {
    borderRadius: "0px",
    fontSize: "14px",
    "& .MuiDataGrid-pinnedColumns": {
      backgroundColor: theme.palette.white.main,
      boxShadow: "inset 0px 2px 0px rgba(0, 0, 0, .08)",

      "& .MuiDataGrid-cell": {
        borderBottom: "none",
        ":first-of-type": {
          padding: "0 3px"
        },

        ":nth-of-type(2)": {
          "&.action-cell": {
            padding: "0 10px"
          },
          padding: "0 14px"
        },

        ":nth-of-type(3)": {
          padding: "0 14px 0 18px"
        }
      }
    },

    "& .MuiDataGrid-pinnedColumnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      fontWeight: 600,
      boxShadow: "none",

      "& .MuiDataGrid-columnHeader": {
        ":first-of-type": {
          padding: "0 7px"
        }
      }
    },

    "& .MuiDataGrid-columnHeaders": {
      ...theme.typography.body1,
      "& .MuiDataGrid-columnHeader--sortable": {
        color: theme.palette.blue.main
      },
      backgroundColor: theme.palette.white.secondary,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: "0px"
    },

    "& .MuiDataGrid-columnHeader": {
      textWrap: "wrap",
      lineHeight: "normal",
      "&. MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600
      },

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-row": {
      boxShadow: "inset 0px -1px 0px #E2E8F0"
    },

    "& .MuiDataGrid-rowReorderCellContainer": {
      ":first-of-type": {
        padding: "0 0 0 14px !important"
      }
    },

    "& .MuiDataGrid-cell": {
      borderBottom: "none",

      ":first-of-type": {
        padding: "0 10px 0 14px"
      },

      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },

    "& .MuiDataGrid-footerContainer": {
      justifyContent: "flex-start",
      backgroundColor: theme.palette.white.secondary
    },
    "& .multiple-line-cell": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center"
    }
  }
}));

const StyledGridOverlay = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff"
}));

interface TableViewProps extends Omit<DataGridProProps, "rows"> {
  columns: any[];
  customActions?: any[];
  getRowId?: GridRowIdGetter<GridValidRowModel>;
  hasActionColumn?: boolean;
  hideFooter?: boolean;
  hideSortButton?: boolean;
  hideSubmitFooter?: boolean;
  hideToolbar?: boolean;
  isDeleteDisabled?: (row: unknown) => boolean;
  isEditDisabled?: (row: never) => boolean;
  isSubmitting?: boolean;
  onAdd?: () => void;
  onCancel?: () => void;
  onDelete?: (row: GridRenderCellParams) => void;
  onEdit?: (row: GridRenderCellParams) => void;
  onRowOrderChange?: (params: GridRowOrderChangeParams) => void;
  onSortButtonClick?: () => void;
  onSubmit?: () => void;
  onView?: (row: GridRenderCellParams) => void;
  rowReordering?: boolean;
  rows: any[];
  title: string;
  isLoading?: boolean;
}

export const RenderTableView = ({
  columns,
  customActions,
  getRowId,
  hasActionColumn,
  hideFooter,
  hideSortButton,
  hideSubmitFooter,
  hideToolbar,
  isDeleteDisabled,
  isEditDisabled,
  isSubmitting,
  onAdd,
  onCancel,
  onDelete,
  onEdit,
  onRowOrderChange,
  onSortButtonClick,
  onSubmit,
  onView,
  rowReordering,
  rows,
  title,
  isLoading,
  ...dataGridProps
}: TableViewProps) => {
  const gridApiRef = useGridApiRef();

  React.useLayoutEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.resetRowHeights();
    }
  }, [gridApiRef]);

  if (!dataGridProps?.initialState) {
    dataGridProps.initialState = {};
  }

  const actionColumn: GridColDef = React.useMemo(
    () => ({
      field: "action",
      headerName: "Actions",
      headerClassName: "action-header",
      cellClassName: "action-cell",
      minWidth: 150,
      flex: 0.12,
      sortable: false,
      renderCell: (params) => {
        const deleteDisabled = isDeleteDisabled
          ? isDeleteDisabled(params.row)
          : true;
        const editDisabled = isEditDisabled
          ? isEditDisabled(params.row as never)
          : false;
        return (
          <Box>
            {onView && (
              <IconButton onClick={() => onView(params.row)}>
                <Visibility />
              </IconButton>
            )}
            {onEdit && !editDisabled && (
              <IconButton
                onClick={() => onEdit(params.row)}
                disabled={editDisabled}
              >
                <Edit />
              </IconButton>
            )}
            {customActions?.map((action, index) => action(params, index))}
            {onDelete && !deleteDisabled && (
              <IconButton
                onClick={() => onDelete(params.row)}
                disabled={deleteDisabled}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        );
      }
    }),
    []
  );

  const addClickHandler = () => {
    onAdd && onAdd();
  };

  const saveClickHandler = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const gridColumns = React.useMemo(() => {
    const newColumns: GridColDef[] = [];
    if (!rowReordering) {
      newColumns.push({
        field: "_",
        headerName: "",
        sortable: false,
        resizable: false,
        valueGetter: () => "",
        minWidth: 0,
        maxWidth: 0,
        width: 0
      });
    }
    if (hasActionColumn) {
      newColumns.push(actionColumn);
    }
    newColumns.push(...columns);

    return newColumns;
  }, [columns, hasActionColumn]);

  if (!dataGridProps?.initialState?.pinnedColumns && !rowReordering) {
    // automatically pin the first column or first two columns if the first column is an action column
    dataGridProps.initialState.pinnedColumns = {
      left: gridColumns
        .slice(0, hasActionColumn ? 3 : 2)
        .map((column) => column.field)
    };
  }
  function noRowsOverlay() {
    const overlay = document.querySelector(".MuiDataGrid-overlayWrapper");
    if (overlay) {
      //@ts-ignore
      overlay.style.height = "200px";
      //@ts-ignore
      overlay.style.marginTop = "20px";
    }

    return (
      <StyledGridOverlay data-testid={"noRecordExist"}>
        <Box>No records exist</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <Container>
      {!hideToolbar && (
        <StyledToolbar>
          <Grid container flexGrow={1} alignItems={"center"} rowSpacing={2}>
            <Grid xs={12} md={"auto"} sx={{ flexGrow: { md: "1 !important" } }}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid
              xs={12}
              md={"auto"}
              container
              justifyContent={"flex-end"}
              alignItems={"center"}
              spacing={2}
            >
              {onAdd && (
                <Grid>
                  <AddBtn
                    variant="admin-primary"
                    onClick={addClickHandler}
                    startIcon={<AddIcon sx={{ marginTop: "1.5px" }} />}
                  >
                    Add
                  </AddBtn>
                </Grid>
              )}
              {!hideSortButton && (
                <Grid>
                  <Button variant="admin-primary" onClick={onSortButtonClick}>
                    Sort
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </StyledToolbar>
      )}
      <StyledDataGrid
        data-testid={"offlineTableViewDataGrid"}
        apiRef={gridApiRef}
        className="table-view"
        rows={rows}
        columns={gridColumns}
        rowCount={rows.length}
        disableColumnMenu
        getRowId={getRowId}
        hideFooter={hideFooter}
        rowReordering={rowReordering}
        onRowOrderChange={onRowOrderChange}
        {...dataGridProps}
        loading={isLoading}
        {...(rows &&
          rows.length == 0 && {
            slots: {
              noRowsOverlay: noRowsOverlay,
              noResultsOverlay: noRowsOverlay
            }
          })}
        sx={{
          textWrap: "wrap"
        }}
        disableColumnReorder={true}
      />
      {!hideSubmitFooter && (
        <Footer
          cancelBtnClick={onCancel}
          saveBtnClick={saveClickHandler}
          isLoading={!!isSubmitting}
          isDisabled={!!isSubmitting}
        />
      )}
    </Container>
  );
};

RenderTableView.defaultProps = {
  isDeleteDisabled: () => true,
  hasActionColumn: true,
  searchable: false,
  hideFilter: true,
  hideFooter: false,
  hideSubmitFooter: true,
  rowReordering: false,
  isSubmitting: false,
  hideSortButton: true,
  isLoading: false
};
