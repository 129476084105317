import { Container } from "@components/crud/Container";
import { Grid, ButtonGroup, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import React from "react";
import { QATesting } from "./QA/QATesting";
import { ProductEngineering } from "./ProductEngineering/ProductEngineering";

export const TechnologyDashboard = () => {
  const [organizationId] = useRecoilState(organizationAtom);

  const buttonGroups = [
    "Product Roadmap",
    "Design",
    "Requirements",
    "Development",
    "Product & Engineering",
    "QA & Testing"
  ];

  const [activeButton, setActiveButton] = React.useState("Organizations");

  return (
    <Container>
      <Grid container direction="column" spacing="15px">
        {!organizationId && (
          <Grid item style={{ marginLeft: "25px", marginTop: "16px" }}>
            <ButtonGroup
              variant="outlined"
              style={{ border: "rgba(215, 221, 229, 1)" }}
            >
              {buttonGroups.map((bg) => {
                return (
                  <Button
                    key={bg}
                    variant="outlined"
                    style={
                      activeButton === bg
                        ? {
                            backgroundColor: "#E8EEFF",
                            color: "#2B337A",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "none"
                          }
                        : {
                            color: "#666666",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "none"
                          }
                    }
                    onClick={() => {
                      setActiveButton(bg);
                    }}
                  >
                    {bg}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
        )}
        {activeButton === "QA & Testing" && <QATesting />}
        {activeButton === "Product & Engineering" && <ProductEngineering />}
      </Grid>
    </Container>
  );
};
