import { TableView } from "@components/TableView";
import { Loader } from "@components/crud/Loader";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getTeams } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Sport",
    field: "sport",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Season",
    field: "season",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Level",
    field: "level",
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: ({ value }) => value?.name
  },
  {
    headerName: "Players",
    field: "players",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "PLAYER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "PLAYER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Coaches",
    field: "coaches",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "COACH"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "COACH"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  },
  {
    headerName: "Managers",
    field: "managers",
    minWidth: 150,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => {
      const selectedPlayersInvite = row.invites?.filter(
        (item) => item.invitedFor![0]?.alias === "MANAGER"
      );
      const addedPlayers = row.userRoles?.filter(
        (item) => item.role?.alias === "MANAGER"
      );
      return selectedPlayersInvite.length + addedPlayers.length;
    }
  }
];

export const Teams = () => {
  const navigate = useNavigate();
  const orgId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find((o) => o.organizationId === orgId);

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/teams/create");
  const onEdit = (row) => navigate(`/teams/${row.teamId}/edit`);
  const onView = (row) => navigate(`/teams/${row.teamId}`);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        orgId ? "ORGANIZATION" : "SYSTEM",
        orgId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const add = await checkPermission("team.teams", "ADD");
      const edit = await checkPermission("team.teams", "EDIT");
      setPermissions({
        add: add,
        edit: edit
      });
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const [permissions, setPermissions] = useState({
    add: false,
    edit: false
  });
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);

  return (
    <Loader isLoading={isLoadingPermissions}>
      <TableView
        title="Teams"
        useGet={getTeams}
        columns={LIST_COLUMNS}
        hideFilter
        hideLabelContainer
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={
          permissions.add && org?.offering?.includes("TEAMS")
            ? () => {
                onAdd();
              }
            : undefined
        }
        onEdit={permissions.edit ? onEdit : undefined}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </Loader>
  );
};
