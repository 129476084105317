import React, { useEffect, useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
import { styled } from "@mui/material";

window.Quill = Quill;
let quillObj: ReactQuill;

const BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

const StyledReactQuill = styled(ReactQuill)`
  .ql-toolbar {
    background: #f3f4f7;
    bottom: 3px;
    width: fit-content;
    border: none !important;
    border-radius: 5px;
    padding: 8px;
  }
  .ql-container {
    border-top: 1px solid #c4c4c4 !important;
    top: 10px;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 1px 2px 0px #0000001a;
    border-radius: 6px;
    padding: 14px 16px;
  }
`;

class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);

Quill.register("modules/imageResize", ImageResize);

export const Editor = ({
  style,
  disabled,
  html,
  onValueChange,
  onBlur
}: {
  style?;
  disabled?: boolean;
  html?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChange?: (deltaOps: any[] | undefined, hasMedia: boolean) => void;
  onBlur?: () => void;
}) => {
  const [value, setValue] = useState("");

  const onChange = (e) => {
    console.log(e);
    console.log("IMA:", quillObj.editor?.getContents().ops);
    const imgRegex = /<img.*?>/g; // Regular expression to match <img> tags
    const hasMedia = imgRegex.test(e);
    setValue(e);
    if (onValueChange)
      onValueChange(quillObj.editor?.getContents().ops, hasMedia);
  };
  const modules = useMemo(() => {
    return {
      toolbar: disabled
        ? false
        : {
            container: [
              [{ font: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              [
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "code-block"
              ],
              [{ color: [] }, { background: [] }],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
              ],
              ["link", "image"]
            ]
          },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      },
      ...(disabled
        ? {}
        : {
            imageResize: {
              parchment: Quill.import("parchment"),
              modules: ["Resize", "DisplaySize", "Toolbar"]
            }
          })
    };
  }, []);
  useEffect(() => {
    console.log("re");
    if (html) {
      setValue(html);
    }
  }, [html]);
  return (
    <StyledReactQuill
      ref={(el) => {
        quillObj = el;
      }}
      value={value}
      readOnly={disabled}
      style={style ? style : {}}
      onChange={(e) => {
        console.log("ON CHANGE");
        onChange(e);
      }}
      onBlur={() => {
        if (onBlur) onBlur();
      }}
      modules={modules}
    ></StyledReactQuill>
  );
};
