import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import { SearchAddPerson } from "@components/SearchAddPerson";
import { useNavigate } from "react-router-dom";
import { CreateUserForm } from "./CreateUserForm";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { adminUserInvitePost, adminUserPost } from "@services/Network";
import {
  AdminUserPostRequest,
  ModelRole,
  SGError
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { organizationAtom } from "@recoil/auth";
import { useRecoilState } from "recoil";

type Person = {
  personId: string;
  firstName: string;
  lastName: string;
  birthedAt: string;
  photo?: string;
  addressPrimary: {
    lines: string[];
    locality: string;
    province: string;
    postalCode: string;
    country: string;
  };
};
export const UserAdd = () => {
  const navigate = useNavigate();
  const [organizationId] = useRecoilState(organizationAtom);
  const defaultPerson: Person = {
    personId: "",
    firstName: "",
    lastName: "",
    birthedAt: "",
    photo: "",
    addressPrimary: {
      lines: [],
      locality: "",
      province: "",
      postalCode: "",
      country: ""
    }
  };
  const [selectedPerson, setSelectedPerson] = useState(defaultPerson);
  const [isPersonSelected, setisPersonSelected] = useState(false);
  const [isNewPersonSelected, setIsNewPersonSelected] = useState(false);
  const [newRolesSelected, setNewRolesSelected] = useState<ModelRole[]>([]);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const form = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      email: "",
      country: "",
      dialCode: "",
      province: "",
      locality: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      birthedAt: "",
      guardians: [],
      children: [],
      isUnderAge: false,
      parentDetails: {},
      parentId: ""
    }
  });

  const {
    reset,
    getValues,
    formState: { isValid }
  } = form;
  const { mutate, isLoading } = adminUserInvitePost();
  const { mutate: mutateNew, isLoading: isLoadingNew } = adminUserPost();
  const sendInvite = async (type) => {
    if (isPersonSelected) {
      const data: {
        roles: ModelRole[];
        organizationId?: string | undefined;
      } = {
        roles: newRolesSelected
      };
      if (organizationId) data.organizationId = organizationId;
      mutate(
        {
          userId: selectedPerson.personId,
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Invite sent successfully", {
              variant: "success"
            });
            if (type === "NEW") {
              setNewRolesSelected([]);
              setIsNewPersonSelected(false);
              setisPersonSelected(false);
              setSelectedPerson(defaultPerson);
              resetForm();
              navigate("/users/create");
            } else navigate("/users");
          },
          onError: (e: unknown) => {
            const error = e as { response: { data: SGError } };
            const errorCode = error?.response?.data?.code;
            enqueueSnackbar("Failed to sent Invite-" + errorCode, {
              variant: "error"
            });
          }
        }
      );
    } else {
      const currentValues = getValues();
      const data: {
        firstName: string;
        lastName: string;
        middleName: string;
        suffix: string;
        email: string;
        roles: ModelRole[];
        isMinor: boolean;
        parentId?: string;
        parent?: AdminUserPostRequest;
        organizationId?: string;
      } = {
        firstName: currentValues.firstName,
        lastName: currentValues.lastName,
        middleName: currentValues.middleName,
        suffix: currentValues.suffix,
        email: currentValues.email,
        roles: newRolesSelected,
        isMinor: currentValues.isUnderAge
      };
      if (data.isMinor) {
        if (currentValues.parentId && currentValues.parentId !== "")
          data.parentId = currentValues.parentId;
        else if (currentValues.parentDetails.email !== "")
          data.parent = currentValues.parentDetails;
      }
      if (organizationId) data.organizationId = organizationId;

      mutateNew(
        {
          data: data
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Invite sent successfully", {
              variant: "success"
            });
            if (type === "NEW") {
              setNewRolesSelected([]);
              setIsNewPersonSelected(false);
              setisPersonSelected(false);
              setSelectedPerson(defaultPerson);
              resetForm();
              navigate("/users/create");
            } else navigate("/users");
          },
          onError: (e: unknown) => {
            const error = e as { response: { data: SGError } };
            const errorCode = error?.response?.data?.code;
            const errorDetails = error?.response?.data?.message;
            enqueueSnackbar(
              "Failed to sent Invite-" + errorCode + ": " + errorDetails,
              {
                variant: "error"
              }
            );
          }
        }
      );
    }
  };

  const onChangeRolesSelected = (roleId, checked) => {
    const rolesSelected = newRolesSelected.slice();
    const index = rolesSelected.findIndex((role) => role.roleId === roleId);
    if (checked) {
      if (index === -1) {
        rolesSelected.push({ roleId: roleId });
      }
    } else {
      rolesSelected.splice(index, 1);
    }
    setNewRolesSelected(rolesSelected);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };
  const resetForm = () => {
    reset(
      {
        firstName: "",
        middleName: "",
        lastName: "",
        suffix: "",
        email: "",
        country: "",
        dialCode: "",
        province: "",
        locality: "",
        address1: "",
        address2: "",
        postalCode: "",
        phone: "",
        birthedAt: "",
        guardians: [],
        children: [],
        isUnderAge: false,
        parentDetails: {},
        parentId: ""
      },
      {
        keepValues: false
      }
    );
  };
  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    setNewRolesSelected([]);
    if (isNewPersonSelected || isPersonSelected) {
      setIsNewPersonSelected(false);
      setisPersonSelected(false);
      setSelectedPerson(defaultPerson);
      resetForm();
      navigate("/users/create");
    } else navigate("/users");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };
  return (
    <Container>
      <Toolbar title="Add User" />
      {!isPersonSelected && !isNewPersonSelected ? (
        <SearchAddPerson
          personSelected={(person, personSelected, newPersonSelected) => {
            setSelectedPerson(person);
            setisPersonSelected(personSelected);
            setIsNewPersonSelected(newPersonSelected);
          }}
          organizationId={organizationId}
        />
      ) : isPersonSelected ? (
        <CreateUserForm
          isEditing={false}
          form={form}
          userId={selectedPerson.personId}
          onChangeRolesSelected={(roleId, checked) => {
            onChangeRolesSelected(roleId, checked);
          }}
        />
      ) : (
        <CreateUserForm
          isEditing={true}
          form={form}
          userId={selectedPerson.personId}
          onChangeRolesSelected={(roleId, checked) => {
            onChangeRolesSelected(roleId, checked);
          }}
          setIsFormValid={setIsFormValid}
        />
      )}
      <Footer
        cancelBtnClick={onCancelClick}
        saveBtnClick={() => sendInvite("INV")}
        saveAndNewBtnClick={() => sendInvite("NEW")}
        saveBtnLabel="Send Invite"
        saveAndNewBtnLabel="Send Invite & New"
        isDisabled={
          !(isPersonSelected || isNewPersonSelected) ||
          newRolesSelected.length === 0 ||
          !isFormValid ||
          !isValid ||
          isLoading ||
          isLoadingNew
        }
        isLoading={isLoading || isLoadingNew}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel adding a user to the system?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
