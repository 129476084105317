import { LinkButton } from "@components/LinkButton";
import { Box, Container, Typography } from "@mui/material";

export default function NotFound(): JSX.Element {
  return (
    <section>
      <Container maxWidth="sm">
        <Box pt={6} pb={10} textAlign="center">
          <img src="/images/error.svg" alt="Error" />
          <Typography variant="h3" color="primary">
            Whoops!
          </Typography>
          <Typography variant="h3" component="h2" gutterBottom={true}>
            Something went wrong!
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            We're sorry, our wires must have been crossed.
          </Typography>
          <Box mt={3}>
            <LinkButton href="/">Return to the homepage</LinkButton>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
