import { FormCheckbox } from "@components/FormCheckbox";
import { FormLabel, Grid, Link, Typography, styled } from "@mui/material";
import { ModelUserRoleAliasBan } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import React from "react";
import { Control } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const RenderRolesWithAliases = ({
  orgRoles,
  control,
  isEditable,
  isNewUser,
  organizationId,
  sgBannedRoles,
  orgBannedRoles,
  disabled,
  showUnderline,
  onChangeRolesSelected,
  openRoleDesc,
  teamProgramRoles,
  setShowTPErrorModal
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orgRoles: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  isEditable?: boolean;
  isNewUser?: boolean;
  organizationId: string | undefined;
  sgBannedRoles?: string[] | ModelUserRoleAliasBan[] | undefined;
  orgBannedRoles?: string[] | undefined;
  disabled?: boolean;
  showUnderline?: boolean;
  onChangeRolesSelected?: (roleId, boolean) => void;
  openRoleDesc?: (roleId) => void;
  teamProgramRoles?: string[];
  setShowTPErrorModal?: (val: boolean) => void;
}) => {
  const renderRoles = (rolesArr, isBannedAlias: boolean = false) => {
    const roleStyle =
      isEditable || showUnderline
        ? {
            color: "#3B6CF8",
            fontWeight: 400,
            textDecoration: "underline"
          }
        : {
            color: "#1E293B"
          };
    return (
      <Grid
        item
        container
        direction="row"
        spacing="10px"
        data-testid="roleRow"
        style={{ marginLeft: "-26px" }}
      >
        {rolesArr.map((role) => {
          if (organizationId || isEditable) {
            return (
              <Grid
                item
                container
                direction="row"
                key={role.value}
                data-testid={"ROLE_VALUE_" + role.value}
              >
                {/* <div
                  style={{
                    display: "flex",
                    marginLeft: "3px",
                    marginTop: "10px"
                  }}
                > */}
                <Grid item>
                  <FormCheckbox
                    // key={role.value}
                    control={control}
                    name={role.value.replace(".", "_") + "_SELECTED"}
                    label={role.label || role.role.name}
                    disabled={disabled || isBannedAlias}
                    labelPadding="4px 8px"
                    onClickLabel={() => openRoleDesc!(role.value)}
                    notUnCheckable={teamProgramRoles?.includes(role.value)}
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        teamProgramRoles?.includes(role.value) &&
                        setShowTPErrorModal
                      ) {
                        setShowTPErrorModal(true);
                        return;
                      }
                      onChangeRolesSelected!(role.value, e.target.checked);
                    }}
                    labelTypographyProps={{
                      variant: "body1",
                      style: roleStyle
                    }}
                  />
                </Grid>
                {role.children?.length ? (
                  role.children.map((subRoles) => {
                    return (
                      <Grid
                        item
                        key={subRoles.roleId}
                        data-testid={"ROLE_VALUE_" + subRoles.roleId}
                      >
                        <FormCheckbox
                          control={control}
                          name={subRoles.roleId.replace(".", "_") + "_SELECTED"}
                          label={subRoles.displayText || subRoles.name}
                          labelPadding="4px 8px"
                          onClickLabel={() => openRoleDesc!(subRoles.roleId)}
                          disabled={disabled || isBannedAlias}
                          onChange={(e) =>
                            onChangeRolesSelected!(
                              subRoles.roleId,
                              e.target.checked
                            )
                          }
                          labelTypographyProps={{
                            variant: "body1",
                            style: roleStyle
                          }}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <></>
                )}
                {/* </div> */}
              </Grid>
            );
          } else {
            return (
              <Grid
                item
                key={role.role.roleId}
                data-testid={"ROLE_VALUE_" + role.role.roleId}
              >
                <FormCheckbox
                  // key={role.role.roleId}
                  control={control}
                  disabled={!isEditable}
                  name={role.role.roleId.replace(".", "_") + "_SELECTED"}
                  label={role.role.name}
                  labelPadding="4px 8px"
                  labelTypographyProps={{
                    variant: "body1"
                  }}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    );
  };

  const rolesAliasesMap = orgRoles.reduce((map, role) => {
    const alias = role.role.alias;
    if (!map[alias]) {
      map[alias] = [];
    }
    map[alias].push(role);
    return map;
  }, {});
  return (
    <Grid item container direction="column" spacing="10px">
      {Object.keys(rolesAliasesMap).map((alias) => {
        const bannedAlias =
          isEditable || !isNewUser
            ? organizationId
              ? orgBannedRoles?.indexOf(alias) !== -1 ||
                (sgBannedRoles && sgBannedRoles?.indexOf(alias) !== -1) ||
                alias === "PARENT" ||
                alias === "PLAYER"
              : sgBannedRoles && sgBannedRoles?.indexOf(alias) !== -1
            : false;
        return (
          alias !== "SPORTSFAN" && (
            <Grid
              item
              container
              direction="column"
              spacing="10px"
              key={alias}
              data-testid={"ROLE_ALIAS_" + alias}
            >
              <Grid item>
                {isEditable || isNewUser ? (
                  <StyledFormLabel required>
                    <Typography display="inline" variant="formLabel">
                      For {" " + capitalize(alias)}
                      {(alias === "PARENT" || alias === "PLAYER") &&
                        " (Assign this role through "}
                      {(alias === "PARENT" || alias === "PLAYER") && (
                        <>
                          <Link
                            href="/import-players"
                            style={{
                              color: "#3B6CF8",
                              fontWeight: "normal",
                              textDecoration: "underline"
                            }}
                          >
                            Upload Athletes/Players
                          </Link>
                        </>
                      )}
                      {(alias === "PARENT" || alias === "PLAYER") && " screen)"}
                    </Typography>
                  </StyledFormLabel>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    data-testid={"ROLE_ALIAS_" + alias}
                  >
                    For {" " + capitalize(alias)}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                direction="row"
                spacing="15px"
                style={{ marginLeft: "-1px" }}
              >
                {renderRoles(rolesAliasesMap[alias], bannedAlias)}
              </Grid>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};
