import { Button } from "@components/Button";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

export const Success = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    return navigate("/");
  };

  return (
    <FormContainer>
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Congratulations!
      </Typography>
      <Typography variant="body1">
        You’ve successfully created your account. You can now Sign In and start
        using SportsGravy.
      </Typography>
      <ButtonContainer>
        <Button variant="primary" type="button" onClick={goToLogin}>
          Go to Sign In
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};
