import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { getHelpArticles } from "@services/Network";
import {
  ModelHelpCategory,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import {
  HELP_ARTICLE_DISPLAY_CHANNELS,
  HELP_ARTICLE_RELATED_TO
} from "@utils/constants";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

export const HelpArticles = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  console.log(organizationId);

  const onAdd = () => navigate("/help-articles/create");
  const onEdit = (article) =>
    navigate(`/help-articles/${article.articleId}/edit`);
  const onView = (article) => navigate(`/help-articles/${article.articleId}`);

  const { data: categoriesData } = useAdminHelpCategoryGet();
  const [categories, setCategories] = React.useState<ModelHelpCategory[]>([]);

  useEffect(() => {
    if (categoriesData?.data) {
      setCategories(categoriesData.data.helpCategory!);
    }
  }, [categoriesData]);
  const LIST_COLUMNS = [
    {
      headerName: "Headline",
      field: "headline",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.headline
    },
    {
      headerName: "Category",
      field: "category",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        if (categories) {
          return (
            categories.find((cat) => cat.helpCategoryId === row.categoryId)
              ?.name || ""
          );
        }
      }
    },
    {
      headerName: "Related To",
      field: "relatedTo",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        HELP_ARTICLE_RELATED_TO.find((opt) => opt.value === row.relatedTo)
          ?.label || ""
    },
    {
      headerName: "Display Channel",
      field: "displayChannel",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        row.displayChannel
          .map((dc) => {
            return (
              HELP_ARTICLE_DISPLAY_CHANNELS.find((opt) => opt.value === dc)
                ?.label || ""
            );
          })
          .join(",")
    },
    {
      headerName: "Media",
      field: "hasMedia",
      minWidth: 100,
      flex: 0.5,
      valueGetter: ({ row }) => (row.hasMedia ? "Yes" : "No"),
      sortable: false
    },
    {
      headerName: "Created On",
      field: "createdOn",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => dateFormat(row.createdAt)
    }
  ];
  const filterConfig = React.useMemo(() => {
    const options =
      categories?.map((category: ModelHelpCategory) => ({
        label: category.name as string,
        value: category.helpCategoryId as string
      })) || [];

    return {
      field: "categoryId",
      placeholderOption: { label: "All", value: "" },
      options: options
    };
  }, [categories]);
  return (
    <>
      <TableView
        title="Help Articles"
        useGet={getHelpArticles}
        columns={LIST_COLUMNS}
        defaultSort={[{ field: "headline", sort: "asc" }]}
        filterConfig={filterConfig}
        getRowId={(row) => row.articleId}
        hideLabelContainer={true}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
      />
    </>
  );
};
