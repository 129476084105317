export const cleanObject = <T>(obj: T): Partial<T> => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined) {
      return acc;
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value = cleanObject(value as any);
    }
    return { ...acc, [key]: value };
  }, {} as T);
};
