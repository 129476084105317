type AnyObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const omitProperties = <T extends object, K extends [...(keyof T)[]]>(
  obj: T,
  keys: K
): {
  [K2 in Exclude<keyof T, K[number]>]: T[K2];
} => {
  const ret = {} as {
    [K in keyof typeof obj]: (typeof obj)[K];
  };
  let key: keyof typeof obj;
  for (key in obj) {
    if (!keys.includes(key)) {
      ret[key] = obj[key];
    }
  }
  return ret;
};

export const onlyProperties = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  const result = {} as Pick<T, K>;
  const objKeys = Object.keys(obj) as K[];

  for (const key of objKeys) {
    if (keys.includes(key)) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const removePropertiesRecursive = (
  input: AnyObject | AnyObject[],
  keysToRemove: string[]
): void => {
  if (Array.isArray(input)) {
    for (const obj of input) {
      removePropertiesRecursive(obj, keysToRemove);
    }
  } else {
    for (const key in input) {
      if (keysToRemove.includes(key)) {
        delete input[key];
      }
    }
  }
};

export const setPropertyRecursive = (
  obj: AnyObject,
  path: string,
  value: unknown
): void => {
  const pathArray = path.split(".");
  const lastKey = pathArray.pop() as string;
  const lastObj = pathArray.reduce((o, i) => {
    if (!o[i]) {
      o[i] = {};
    }
    return o[i];
  }, obj);
  lastObj[lastKey] = value;
};

// isEqual from lodash
export const isEqual = (value: unknown, other: unknown): boolean => {
  if (value === other) {
    return true;
  }
  if (
    value === null ||
    value === undefined ||
    other === null ||
    other === undefined
  ) {
    return false;
  }
  if (typeof value !== typeof other) {
    return false;
  }
  if (typeof value !== "object") {
    return false;
  }

  const valueKeys = Object.keys(value);
  const otherKeys = Object.keys(other);

  if (valueKeys.length !== otherKeys.length) {
    return false;
  }

  for (const key of valueKeys) {
    if (!isEqual(value[key], other[key])) {
      return false;
    }
  }

  return true;
};
