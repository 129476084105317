import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { AddIcon } from "@components/Icons";
import { TableView } from "@components/TableView";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { hasPermission } from "@services/Casbin";
import { getImportTemplateProviderVersionsFactory } from "@services/Network";
import {
  ModelImportTemplateProvider,
  useAdminImportTmplProviderIdDelete,
  useAdminImportTmplProviderIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ERP_TYPE_OPTIONS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const StyledForm = styled(Form)`
  flex-grow: 0 !important;
`;

const LIST_COLUMNS = [
  { headerName: "Version Name", field: "version", minWidth: 150, flex: 1 },
  {
    headerName: "Description",
    field: "description",
    sortable: false,
    minWidth: 200,
    flex: 1
  },
  {
    headerName: "Template File",
    field: "fileName",
    sortable: false,
    minWidth: 150,
    flex: 1
  }
];

export const ImportTemplateProviderView = () => {
  const navigate = useNavigate();
  const { providerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [toDelete, setToDelete] = useState<ModelImportTemplateProvider | null>(
    null
  );

  const [refreshKey, setRefreshKey] = useState(0);
  const getVersions = getImportTemplateProviderVersionsFactory(
    providerId as string
  );
  const [permissions, setPermissions] = useState({
    delete: false,
    edit: false
  });
  const {
    data: provider,
    isFetching: isProviderFetching,
    error: error
  } = useAdminImportTmplProviderIdGet(providerId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const onView = (version) =>
    navigate(`/import-template-providers/${providerId}/${version.versionId}`);

  const onDownload = (row) => {
    const file = new Blob(['"' + row.csvTemplate?.join('","') + '"'], {
      type: "text/csv"
    });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download =
      row.fileName ||
      row.version.toLowerCase().replace(/ /g, "_") + "-" + Date.now() + ".csv";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminImportTmplProviderIdDelete();

  const onConfirmDelete = async () => {
    if (!toDelete?.providerId) return;
    try {
      await deleteAsync({ providerId: toDelete.providerId });
      enqueueSnackbar("Import Template Provider deleted successfully", {
        variant: "success"
      });
      setToDelete(null);
      navigate("/import-template-providers");
      setRefreshKey(new Date().getTime());
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete import template provider.",
        {
          variant: "error"
        }
      );
      setToDelete(null);
    }
  };
  const isDeleteDisabled = (row) => {
    return row?._count?.organizations > 0;
  };
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("user.import-providers", "DELETE");
      const edit = await checkPermission("user.import-providers", "EDIT");
      setPermissions({
        delete: del,
        edit: edit
      });
    };
    fetchPermissions();
  }, []);

  const { control, reset } = useForm();
  useEffect(
    () =>
      reset(
        {
          name: provider?.data?.name,
          description: provider?.data?.description,
          erpType: provider?.data?.erpType
        },
        {
          keepDefaultValues: true
        }
      ),
    [provider]
  );
  return (
    <Container>
      <Toolbar
        title="View Import Template Provider"
        deleteBtnClick={
          !isDeleteDisabled(provider?.data) && permissions.delete
            ? () => setToDelete(provider!.data!)
            : undefined
        }
        editBtnClick={
          permissions.edit
            ? () => {
                navigate(`/import-template-providers/${providerId}/edit`);
              }
            : undefined
        }
        backBtnClick={() => navigate("/import-template-providers")}
      />
      <Loader isLoading={isProviderFetching}>
        <StyledForm>
          <Grid container spacing={3}>
            <Grid xs={12} md={6} data-testid="ImportTpl_view_name">
              <FormInput
                control={control}
                disabled={true}
                label="Name"
                name="name"
                required={true}
                type="text"
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="ImportTpl_view_erpType">
              <FormSelect
                control={control}
                disabled={true}
                label="ERP Type"
                name="erpType"
                options={ERP_TYPE_OPTIONS}
                required={true}
              />
            </Grid>
            <Grid xs={12} data-testid="ImportTpl_view_desc">
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                multiline={true}
                TextProps={{
                  minRows: 3
                }}
                disabled={true}
              />
            </Grid>
          </Grid>
        </StyledForm>
        <TableView
          columns={LIST_COLUMNS}
          customActions={[
            (params) => (
              <IconButton onClick={() => onDownload(params.row)}>
                <Download />
              </IconButton>
            )
          ]}
          defaultSort={[{ field: "version", sort: "asc" }]}
          getRowId={(row) => row.versionId}
          hideToolbar={true}
          isDeleteDisabled={() => true}
          onView={onView}
          refreshKey={refreshKey}
          title="Import Template Providers"
          useGet={getVersions}
        />
        <Footer
          additionalBtns={[
            <Button
              key={1}
              variant="admin-primary"
              type="button"
              onClick={() =>
                navigate(`/import-template-providers/${providerId}/create`)
              }
              startIcon={<AddIcon />}
            >
              Add Version
            </Button>
          ]}
        />
      </Loader>
      <ConfirmationDialog
        body={`Are you sure you want to delete ${toDelete?.name}?`}
        close={() => setToDelete(null)}
        confirmBtnVariant="admin-error"
        icon="error"
        isConfirming={isDeleting}
        onCancel={() => setToDelete(null)}
        onConfirm={onConfirmDelete}
        open={!!toDelete}
        title="Delete Template Provider?"
      />
    </Container>
  );
};
