import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  KidInvite,
  basicFormInputAtom,
  kidInviteAtom,
  passwordAtom,
  personAtom,
  signatureAtom
} from "@recoil/signup";
import { accountPost } from "@services/Network";
import {
  AuthResendCodeRequestType,
  ModelPerson,
  useAuthSignUpResendPersonCodePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { formatPhone } from "@utils/phoneFormatters";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { BasicFormInput } from "types/basicFormInput";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
`;

const CodesButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const ResendContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 100%;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled(Link)(({ theme }) => ({
  margin: "1rem 0 0 1rem",
  color: theme.palette.primary.main,
  "&:hover": {
    opacity: 0.8
  }
}));

export const VerifyAccount = () => {
  const [isFirstSend, setIsFirstSend] = useState<boolean>(true);
  const [person, setPerson] = useRecoilState<ModelPerson | null>(personAtom);
  const [basicInfo, setBasicInfo] = useRecoilState<BasicFormInput | null>(
    basicFormInputAtom
  );
  const password = useRecoilValue<string>(passwordAtom);
  const signature = useRecoilValue<string>(signatureAtom);
  const kidInvite = useRecoilValue<KidInvite[]>(kidInviteAtom);
  const navigate = useNavigate();

  // Vericication Codes form
  const {
    control,
    handleSubmit: handleSubmitCodes,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      emailCode: "",
      smsCode: ""
    }
  });

  // Resend codes
  const resendMutation = useAuthSignUpResendPersonCodePost();

  const isResendingEmailCode =
    !isFirstSend &&
    resendMutation.isLoading &&
    resendMutation?.variables?.data?.type === AuthResendCodeRequestType.EMAIL;

  const isResendingSMSCode =
    !isFirstSend &&
    resendMutation.isLoading &&
    resendMutation?.variables?.data?.type === AuthResendCodeRequestType.PHONE;

  const resendCode = async (type: AuthResendCodeRequestType) => {
    await resendMutation.mutateAsync({
      data: { type, email: person?.emailPrimary?.email as string }
    });
  };

  useEffect(() => {
    if (!person?.emailPrimary?.verifiedAt) {
      resendCode(AuthResendCodeRequestType.EMAIL);
    }
    if (!person?.phonePrimary?.verifiedAt) {
      resendCode(AuthResendCodeRequestType.PHONE);
    }
    setIsFirstSend(false);
  }, []);

  const submitCodesMutation = accountPost();
  const submitCodes = async (data) => {
    await submitCodesMutation.mutateAsync({
      data: {
        emailCode: data.emailCode,
        // @ts-ignore
        kids: kidInvite
          ?.filter((k) => k.selected)
          ?.map((kid) => ({
            personId: kid.personId,
            email: kid.email,
            dob: new Date(kid.dob).toISOString().split("T")[0] + "T00:00:00Z",
            relationship: kid.relationship,
            consented: true
          })),
        password,
        phoneCode: data.smsCode,
        signature
      }
    });

    navigate("/sign-up/success");
  };

  return (
    <FormContainer data-testid="signup-verifyAccount">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Verify your Account
      </Typography>
      <Typography variant="body2" color="text.general.primary">
        {`In order to complete the Sign Up process, we need to verify both your
        email (${
          person?.emailPrimary?.email as string
        }) and mobile phone (${formatPhone(
          person?.phonePrimary?.phone
        )}). Please retrieve the 6 digit code sent to your
        email and phone and enter them below.`}
      </Typography>
      {submitCodesMutation.isError && (
        <NotificationCard
          variant="error"
          sx={{
            mt: "1rem"
          }}
        >
          Invalid Codes. Please try again.
        </NotificationCard>
      )}
      {!person?.emailPrimary?.verifiedAt && (
        <Fragment>
          <FormRow>
            <FormFieldContainer data-testid="signup-emailCode">
              <FormInput
                name="emailCode"
                type="text"
                required
                label="Email Code"
                control={control}
                rules={{
                  required: "Email Code is required"
                }}
              />
            </FormFieldContainer>
            <ResendContainer>
              {isResendingEmailCode ? (
                <LoadingSpinner />
              ) : (
                <LinkButton
                  to="#"
                  onClick={() => resendCode(AuthResendCodeRequestType.EMAIL)}
                >
                  Resend Code
                </LinkButton>
              )}
            </ResendContainer>
          </FormRow>
          <FormRow>
            <Typography variant="body1">
              <Link
                to="/sign-up/basic-info"
                onClick={(e) => {
                  e.preventDefault();

                  setBasicInfo({
                    ...basicInfo,
                    email: undefined
                  });

                  setPerson({
                    ...person,
                    emailPrimary: undefined,
                    // @ts-ignore
                    email: null
                  });

                  navigate("/sign-up/basic-info");
                }}
              >
                Enter a new Email and try again
              </Link>
            </Typography>
          </FormRow>
        </Fragment>
      )}
      {!person?.phonePrimary?.verifiedAt && (
        <Fragment>
          <FormRow>
            <FormFieldContainer data-testid="signup-mobileCode">
              <FormInput
                name="smsCode"
                type="text"
                label="Mobile Phone Code"
                required
                control={control}
                rules={{
                  required: "Mobile Phone Code is required"
                }}
              />
            </FormFieldContainer>
            <ResendContainer>
              {isResendingSMSCode ? (
                <LoadingSpinner />
              ) : (
                <LinkButton
                  to="#"
                  onClick={() => resendCode(AuthResendCodeRequestType.PHONE)}
                >
                  Resend Code
                </LinkButton>
              )}
            </ResendContainer>
          </FormRow>
          <FormRow>
            <Typography variant="body1">
              <Link
                to="/sign-up/personal-info"
                onClick={(e) => {
                  e.preventDefault();

                  setPerson({
                    ...person,
                    phonePrimary: undefined,
                    // @ts-ignore
                    phone: null
                  });

                  navigate("/sign-up/personal-info");
                }}
              >
                Enter a new Mobile Phone and try again
              </Link>
            </Typography>
          </FormRow>
        </Fragment>
      )}
      <CodesButtonContainer>
        <FormFieldContainer>
          <Button
            variant="primary"
            disabled={!isValid}
            type="button"
            isLoading={submitCodesMutation.isLoading}
            onClick={handleSubmitCodes(submitCodes)}
          >
            Submit
          </Button>
        </FormFieldContainer>
      </CodesButtonContainer>
      <SignInContainer>
        <Typography variant="body1">
          Back to <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
    </FormContainer>
  );
};
