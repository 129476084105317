import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { TableView } from "@components/TableView";
import { GridColDef, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { getSportsByCountry } from "@services/Network";
import {
  ModelSport,
  useSportCountryCountryIdOrderPut,
} from "@sportsgravyengineering/sg-api-react-sdk";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const ORG_COLUMNS: GridColDef[] = [
  {
    headerName: "Name",
    field: "sport",
    valueGetter: ({ row }) => row?.sport.name,
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Alternate Name",
    field: "name",
    valueGetter: ({ value }) => value || "N/A",
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Country",
    field: "country",
    valueGetter: ({ row }) => row.country.name,
    minWidth: 150,
    flex: 1,
    sortable: false,
  },
  {
    headerName: "Created Date",
    field: "createdAt",
    minWidth: 115,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) =>
      format(parseISO(row.sport.createdAt), "MMM d, yyyy"),
  },
];

export const SportCountrySort = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = React.useState<ModelSport[]>([]);
  const [isCancel, setIsCancel] = React.useState(false);
  const { countryId } = useParams();

  const filterConfig = React.useMemo(() => {
    return {
      field: "country",
      options: [
        { label: "United States", value: "US" },
        { label: "Canada", value: "CA" },
      ],
      defaultValue: countryId,
    };
  }, [countryId]);

  const useGet = (params, options) =>
    getSportsByCountry(countryId, {
      ...options,
      query: {
        ...options?.query,
        onSuccess: (data) => {
          const sportsWithRowData = data?.data?.map((sport) => {
            return {
              ...sport,
              id: sport.sportId,
              __reorder__: sport.sport.name,
            };
          });
          setRows(sportsWithRowData);
        },
        queryKey: [countryId],
      },
    });

  const orderMutation = useSportCountryCountryIdOrderPut();

  const onRowOrderChange = (params: GridRowOrderChangeParams) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(params.oldIndex, 1)[0];
    rowsClone.splice(params.targetIndex, 0, row);
    setRows(rowsClone);
  };

  const onFilterChange = (value) => {
    navigate(`/sports/country/${value}`);
  };

  const onSubmit = () => {
    if (!countryId) return;

    const sports = rows.map((row, index) => {
      return {
        sportId: row.sportId,
        order: index,
      };
    });

    orderMutation.mutate(
      {
        countryId,
        data: { sports },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Successfully sorted sports!", {
            variant: "success",
          });
          navigate("/sports");
        },
        onError: () => {
          enqueueSnackbar("Something went wrong! Failed to sort sports.", {
            variant: "error",
          });
        },
      },
    );
  };

  const onCancel = () => {
    setIsCancel(true);
  };

  return (
    <>
      <TableView
        title="Sort Sports by Country"
        useGet={useGet}
        columns={ORG_COLUMNS}
        rows={rows}
        filterConfig={filterConfig}
        hasActionColumn={false}
        searchable={false}
        hideFooter
        rowReordering
        isSubmitting={orderMutation.isLoading}
        onSubmit={onSubmit}
        onCancel={onCancel}
        hideSubmitFooter={false}
        onRowOrderChange={onRowOrderChange}
        onFilterChange={onFilterChange}
      />
      <ConfirmationDialog
        open={isCancel}
        title="Cancel Sorting Sports?"
        body={`Are you sure you want to cancel? All changes will be lost.`}
        close={() => setIsCancel(false)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
        onConfirm={() => navigate("/sports")}
        onCancel={() => setIsCancel(false)}
      />
    </>
  );
};
