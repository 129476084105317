import { FeedView } from "@components/FeedView";
import { useRecoilValue } from "recoil";
import { profileAtom } from "@recoil/auth";
import { useParams } from "react-router-dom";

export const Feed = () => {
  const { feedId } = useParams();
  const userId = useRecoilValue(profileAtom)?.userId;
  const filterConfig = {
    field: "type",
    placeholderOption: {
      label: "All Posts",
      value: "all"
    },
    options: [
      {
        label: "Flagged Posts",
        value: "flagged"
      }
    ]
  };
  const sortConfig = {
    field: "type",
    placeholderOption: {
      label: "Newest to oldest",
      value: "desc"
    },
    options: [
      {
        label: "Oldest to newest",
        value: "asc"
      }
    ]
  };

  return (
    <>
      <FeedView
        title="Feed"
        userId={userId}
        filterConfig={filterConfig}
        sortConfig={sortConfig}
        isDeleteDisabled={() => true}
        repostId={feedId}
        defaultSort={[{ field: "name", sort: "desc" }]}
      />
    </>
  );
};
