import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { withDataGridRenderLocation } from "@components/DataGridRenderLocation";
import { TableView } from "@components/TableView";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { getCountries, getOrganizations } from "@services/Network";
import {
  ModelSport,
  useSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const Organizations = () => {
  const navigate = useNavigate();
  const loggedInOrganizationId = useRecoilValue(organizationAtom);
  useEffect(() => {
    if (loggedInOrganizationId) {
      navigate(`/organizations/${loggedInOrganizationId}`);
    }
  }, [loggedInOrganizationId, navigate]);
  const [refreshKey] = useState(0);

  const countries = getCountries();

  const ORG_COLUMNS: GridColDef[] = [
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    { headerName: "Abbr", field: "abbreviation", minWidth: 100, flex: 1 },
    {
      headerName: "Type",
      field: "type",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const type = params.value;
        const typeString = type
          .split("_")
          .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(" ");
        return <>{typeString}</>;
      }
    },
    {
      headerName: "Sports",
      field: "sports",
      minWidth: 150,
      flex: 1,
      renderCell: DataGridRenderCellTooltip("sport.name", 1)
    },
    {
      headerName: "Location",
      field: "location",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: withDataGridRenderLocation({
        countries: countries?.data,
        displayFullCountry: true
      })
    }
  ];

  const { data: sports } = useSportGet({ pageSize: 1000 });
  const filterConfig = useMemo(
    () => ({
      field: "sportId",
      placeholderOption: { label: "All Organizations", value: "" },
      options: [
        {
          label: "Filter by Sport",
          children:
            sports?.data?.sports?.map((sport: ModelSport) => ({
              label: sport.name,
              value: sport.sportId
            })) || []
        }
      ]
    }),
    [sports]
  );

  const onAdd = () => {
    navigate("/organizations/create");
  };
  const onEdit = (organization) => {
    navigate(`/organizations/${organization.id}/edit`);
  };
  const onView = (organization) => {
    navigate(`/organizations/${organization.id}`);
  };

  return (
    <>
      {!loggedInOrganizationId && (
        <TableView
          title="Organizations"
          useGet={getOrganizations}
          columns={ORG_COLUMNS}
          isDeleteDisabled={() => true}
          filterConfig={filterConfig}
          defaultSort={
            { field: "name", sort: "asc" } as unknown as GridSortModel
          }
          onAdd={onAdd}
          onEdit={onEdit}
          onView={onView}
          refreshKey={refreshKey}
        />
      )}
    </>
  );
};
