import { TableView } from "@components/TableView";
import { getDirectory } from "@services/Network";
import {
  ModelDepartment,
  useAdminDepartmentGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { formatPhone } from "@utils/phoneFormatters";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Directory = () => {
  const navigate = useNavigate();
  const onView = (resource) => navigate(`/directory/${resource.personId}`);

  const { data: departmentsData } = useAdminDepartmentGet();
  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);
  const LIST_COLUMNS = [
    {
      headerName: "Name",
      field: "name",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => formatFullName(row)
    },

    {
      headerName: "Job",
      field: "jobTitle",
      minWidth: 150,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row.sportsgravyUser?.jobTitle?.name || ""
    },
    {
      headerName: "Work Email",
      field: "email",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row.sportsgravyUser?.workEmail || ""
    },
    {
      headerName: "SportsGravy Number",
      field: "sgNumber",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        row.sportsgravyUser?.sgNumber
          ? formatPhone(row.sportsgravyUser?.sgNumber)
          : ""
    },
    {
      headerName: "Mobile Phone",
      field: "mobile",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => (row.phone ? formatPhone(row.phone) : ""),
      sortable: false
    }
  ];
  const filterConfig = React.useMemo(() => {
    const options =
      departments?.map((department: ModelDepartment) => ({
        label: department.name as string,
        value: department.departmentId as string
      })) || [];

    return {
      field: "departmentId",
      placeholderOption: { label: "All Staff", value: "" },
      options: options
    };
  }, [departments]);
  return (
    <>
      <TableView
        title="Company Directory"
        useGet={getDirectory}
        columns={LIST_COLUMNS}
        defaultSort={[{ field: "name", sort: "asc" }]}
        filterConfig={filterConfig}
        getRowId={(row) => row.personId}
        onView={onView}
      />
    </>
  );
};
