import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
} from "@mui/material";
import styled from "styled-components";

export const Accordion = styled(MUIAccordion)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const AccordionSummary = styled(MUIAccordionSummary)(() => ({
  backgroundColor: "#EDEDED",
  padding: "0 32px !important",

  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: "0",
  },
}));

export const AccordionDetails = styled(MUIAccordionDetails)(() => ({
  padding: "0",
}));
