/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingSpinner } from "@components/LoadingSpinner";
import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography
} from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { SortableItem } from "./SortableItem";

const FormSelectContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  min-height: 44px;
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const LoadingContainer = styled(Box)`
  min-height: 44px;
  display: flex;
  align-items: center;
`;

const StyledSelect = styled(Select)(() => ({
  height: 53,
  fieldset: {
    "&.MuiOutlinedInput-notchedOutline": {
      // @ts-ignore
      borderColor: "rgba(0, 0, 0, 0.26)"
    }
  }
}));

const ErrorContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  position: "absolute",
  top: "100%",
  left: 0,
  margin: 0,
  fontFamily: "Inter var, sans-serif",
  fontSize: "12px",
  fontWeight: 500,
  height: "30px"
}));

type SelectOption = {
  label: string;
  value?: string;
  children?: SelectOption[];
};

interface FormSelectProps<T> extends SelectProps<T> {
  name: string;
  options: SelectOption[];
  control?: any;
  rules?: any;
  label?: string;
  required?: boolean;
  isLoading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (event: SelectChangeEvent<any>) => void;
  menuItems?: any;
  onOrderChange: (newList: string[]) => void;
}

export const FormOrderSelect: <T>(props: FormSelectProps<T>) => JSX.Element = (
  props
) => {
  const [sortableOptions, setSortableOptions] = useState<string[]>([]);

  useEffect(() => {
    setSortableOptions([
      ...props.options
        .filter((opt) => opt.value !== "SORT_ORDER_TEXT")
        .map((opt) => opt.value!)
    ]);
  }, [props.options]);
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const activeIndex = sortableOptions.indexOf(active.id);
      const overIndex = sortableOptions.indexOf(over.id);
      const newOrder = arrayMove(sortableOptions, activeIndex, overIndex);
      // setSortableOptions((items) => {
      //   const activeIndex = items.indexOf(active.id);
      //   const overIndex = items.indexOf(over.id);
      //   return arrayMove(items, activeIndex, overIndex);
      // });
      props.onOrderChange(newOrder);
    }
  };
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={Object.assign(
        props.required ? { required: !!props.required } : {},
        props.rules || {}
      )}
      render={({ field, fieldState }) => (
        <FormSelectContainer>
          {props.label && (
            <StyledFormLabel required={props.required}>
              <Typography display="inline" variant="formLabel">
                {props.label}
              </Typography>
            </StyledFormLabel>
          )}
          {props.isLoading ? (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          ) : (
            <StyledSelect
              error={!!fieldState.error}
              required={!!props.required}
              value={field.value || "SORT_ORDER_TEXT" || ""}
              onBlur={field.onBlur}
              inputProps={{ shrink: "true" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              placeholder={props.placeholder}
              disabled={props.disabled}
              onClose={() => {
                field.onBlur();
              }}
              sx={props.sx}
            >
              {props.options[0]?.value !== "" && (
                <option disabled value="">
                  {props.placeholder}
                </option>
              )}
              {props.options.map((opt) => (
                <option key={opt.value} value={opt.value} hidden>
                  {opt.label}
                </option>
              ))}
              {/* {props.options.map((item, index) => renderMenuItem(item, index))} */}
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={sortableOptions}
                  strategy={verticalListSortingStrategy}
                >
                  {/* We need components that use the useSortable hook */}
                  {sortableOptions.map((opt) => (
                    <MenuItem key={opt}>
                      <SortableItem
                        label={
                          props.options.find((op) => op.value === opt)?.label ||
                          ""
                        }
                        value={opt}
                      />
                    </MenuItem>
                  ))}
                </SortableContext>
              </DndContext>
            </StyledSelect>
          )}
          {!!fieldState.error && (
            <ErrorContainer className="helper-text">
              <Typography variant="body2">
                {fieldState.error.message}
              </Typography>
            </ErrorContainer>
          )}
        </FormSelectContainer>
      )}
    />
  );
};
