import { BugIcon } from "@components/Icons";
import { TableViewInfinite } from "@components/TableViewInfinite";
import { Chip } from "@mui/material";
import { getQATestcases } from "@services/Network";
import { TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InfoIcon from "../../../src/assets/icons/info.svg";
import {
  ModelPerson,
  ModelRelease,
  useAdminReleaseAllGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useRecoilValue } from "recoil";
import {
  organizationAtom,
  previousPathAtom,
  profileAtom,
  selectedFilterAtom,
  selectedPlatformAtom,
  selectedReleaseAtom
} from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";
import formatFullName from "@utils/formatFullName";
import { ToolTip } from "@components/ToolTip";

const renderNewlines = (text) => {
  return text.split("\n").map((line, index) => {
    return (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
};

export const QATestcases = () => {
  const navigate = useNavigate();
  const selectedPlatformValue = useRecoilValue(selectedPlatformAtom);
  const previousPathValue = useRecoilValue(previousPathAtom);
  const loggedInUser = useRecoilValue(profileAtom);
  const selectedReleaseValue = useRecoilValue(selectedReleaseAtom);
  const selectedFilterValue = useRecoilValue(selectedFilterAtom);
  const organizationId = useRecoilValue(organizationAtom);
  const [featureId, setFeatureId] = useState(selectedFilterValue);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [selectedPlatform, setSelectedPlatform] = useState(
    selectedPlatformValue === "MOB" || selectedPlatformValue === "ALL"
      ? "IOS"
      : selectedPlatformValue || "IOS"
  );
  const [testcaseId, setTestcaseId] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [previousPath, setPreviousPath] = useState<string>("");
  const [releaseId, setReleaseId] = useState<string>();
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  console.log(featureId);
  const onEdit = (testcase) => {
    navigate(`/qa-testcases/${testcase.testCase.testCaseId}/edit`);
  };
  const onView = (testcase) => {
    setTestcaseId(testcase.testCase.testCaseId);
    setViewOpen(true);
  };
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);
  useEffect(() => {
    if (previousPathValue) setPreviousPath(previousPathValue);
  }, []);
  useEffect(() => {
    if (viewOpen) {
      setViewOpen(false);
      const queryString = selectedPlatform
        ? `platform=${selectedPlatform}`
        : "";
      navigate(
        `/qa-testcases/${testcaseId}${queryString ? `?${queryString}` : ""}`
      );
    }
  }, [viewOpen]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      const del = await checkPermission("tech.test-cases", "VIEW");
      setPermissions({
        create,
        edit,
        view: del
      });
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);
  const { data: releasesData, isLoading: releasedLoading } =
    useAdminReleaseAllGet();
  const [releases, setReleases] = React.useState<ModelRelease[]>([]);
  const [selectedTester, setSelectedTester] = useState<string>("");
  const [testers, setTesters] = React.useState<ModelPerson[]>([]);

  const filterConfig = {
    field: "featureId",
    defaultValue: selectedFilterValue,
    placeholderOption: { label: "All", value: "" },
    options: []
  };
  const platformConfig = {
    field: "platform",
    defaultValue:
      selectedPlatformValue &&
      selectedPlatformValue !== "MOB" &&
      selectedPlatformValue !== "ALL"
        ? selectedPlatformValue
        : "IOS",
    label: "Platform",
    options: [
      { label: "iOS", value: "IOS" },
      { label: "Web", value: "WEB" },
      { label: "AND", value: "AND" },
      { label: "API", value: "API" },
      { label: "DEVOps", value: "DEV" }
    ]
  };
  const releaseConfig = React.useMemo(() => {
    const options =
      releases?.map((release: ModelRelease) => ({
        label: release.name as string,
        value: release.releaseId as string,
        status: release.releaseStatus
      })) || [];

    let defaultValue = "";
    if (previousPath && previousPath.includes("qa-testcases")) {
      defaultValue =
        selectedReleaseValue || (options.length > 0 ? options[0].value : "");
    } else {
      defaultValue = options.length > 0 ? options[0].value : "";
    }

    return {
      field: "release",
      defaultValue: defaultValue,
      options: options,
      label: "Release"
    };
  }, [releases]);

  const testerConfig = React.useMemo(() => {
    const options =
      testers?.map((tester: ModelPerson) => ({
        label: formatFullName(tester) as string,
        value: tester.personId as string
      })) || [];
    if (options.find((opt) => opt.value === loggedInUser!.personId)) {
      setSelectedTester(loggedInUser!.personId!);
    } else {
      setSelectedTester("");
    }
    return {
      field: "testerId",
      placeholderOption: { label: "All", value: "" },
      options: options,
      label: "Tester"
    };
  }, [testers]);

  useEffect(() => {
    if (releasesData?.data) {
      const releases = releasesData.data.releases;
      setReleases(releases);
      let testersOfAllReleases = [] as ModelPerson[];
      testersOfAllReleases = releases?.[0]?.testers.map((t) => t.person) || [];
      setTesters([...testersOfAllReleases]);
    }
  }, [releasesData]);

  useEffect(() => {
    if (releasesData?.data) {
      setTesters([
        ...(releasesData.data.releases
          ?.find((r) => r.releaseId === releaseId)
          ?.testers.map((t) => t.person) || [])
      ]);
    }
  }, [releaseId]);

  const isDefaultStatusAutomated = (platform, type, testCase) => {
    return testCase[`${platform}Default${type}Status`] === "AUTOMATED";
  };

  const renderChip = (params, status, isAutomated = false) => {
    const statusWithAutomated = [
      ...TESTCASE_STATUS_OPTIONS,
      { label: "Automated", value: "AUTOMATED" }
    ];
    let value;
    let isDefaultValue = false;
    if (params.testRuns.length > 0) value = params.testRuns![0][status];
    else {
      isDefaultValue = true;
      value = !isAutomated ? "NOT_TESTED" : "AUTOMATED";
    }
    const option = statusWithAutomated.find((option) => option.value === value);
    const label = option ? option.label.toLocaleUpperCase() : "";
    return (
      <>
        <Chip
          data-testid={"CHIP_" + value}
          style={{
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: 600
          }}
          label={label}
          sx={{
            background:
              value === "FAILED"
                ? "#FECACA"
                : value === "PASSED"
                ? "#BBF7D0"
                : value === "BLOCKED"
                ? "#FF9F0A"
                : value == "HOLD"
                ? "#FBF1BC"
                : "#E5E5E5",
            color:
              value === "FAILED"
                ? "#991B1B"
                : value === "PASSED"
                ? "#166534"
                : value === "BLOCKED"
                ? "#FFFFFF"
                : value == "HOLD"
                ? "#7B5B08"
                : "#666666"
          }}
        />
        {value == "HOLD" && params.testRuns.length > 0 && (
          <ToolTip
            title={
              status == "uiStatus"
                ? params.testRuns![0].uiReason
                : params.testRuns![0].fnReason
            }
          >
            <img
              src={InfoIcon}
              style={{ marginLeft: "4px" }}
              data-testid="HOLD_TOOLTIP"
            />
          </ToolTip>
        )}
        {value == "HOLD" && isDefaultValue && (
          <ToolTip
            title={
              status === "uiStatus"
                ? params[`${selectedPlatform.toLowerCase()}UiReason`]
                : params[`${selectedPlatform.toLowerCase()}FnReason`]
            }
          >
            <img
              src={InfoIcon}
              style={{ marginLeft: "4px" }}
              data-testid="HOLD_TOOLTIP"
            />
          </ToolTip>
        )}
        {(value == "BLOCKED" || value == "FAILED") &&
          params.testRuns.length > 0 && (
            <Link
              data-testid="BUG_ICON"
              style={{ marginTop: "7px" }}
              to={
                status == "uiStatus"
                  ? params.testRuns![0].uiJiraLink
                  : params.testRuns![0].fnJiraLink
              }
              target="_blank"
            >
              <BugIcon style={{ marginLeft: "4px", cursor: "pointer" }} />
            </Link>
          )}
        {(value == "BLOCKED" || value == "FAILED") && isDefaultValue && (
          <Link
            style={{ marginTop: "7px" }}
            to={
              status === "uiStatus"
                ? params[`${selectedPlatform.toLowerCase()}UiJiraLink`]
                : params[`${selectedPlatform.toLowerCase()}FnJiraLink`]
            }
            target="_blank"
          >
            <BugIcon style={{ marginLeft: "4px", cursor: "pointer" }} />
          </Link>
        )}
      </>
    );
  };
  const LIST_COLUMNS = [
    {
      headerName: "Test Case Description",
      field: "testCase.description",
      minWidth: 250,
      cellClassName: "multiple-line-cell",
      renderCell: ({ row }) => (
        <div
          style={{
            padding: "20px 0",
            wordWrap: "break-word",
            wordBreak: "break-all"
          }}
        >
          {renderNewlines(row.testCase.description)}
        </div>
      )
    },
    {
      headerName: "Expected Result",
      field: "expectedResult",
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <div
          style={{
            padding: "20px 0",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap"
          }}
        >
          {renderNewlines(row.testCase.expectedResult || "")}
        </div>
      )
    },
    {
      headerName: "Feature",
      field: "feature.name",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => row.feature.feature.name
    },
    {
      headerName: "UI Status",
      field: "uiStatus",
      value: "date",
      minWidth: 115,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isAutomated = isDefaultStatusAutomated(
          selectedPlatform.toLowerCase(),
          "UI",
          params.row.testCase
        );
        return renderChip(params.row, "uiStatus", isAutomated);
      }
    },
    {
      headerName: "FN Status",
      field: "fnStatus",
      value: "date",
      minWidth: 115,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const isAutomated = isDefaultStatusAutomated(
          selectedPlatform.toLowerCase(),
          "FN",
          params.row.testCase
        );
        return renderChip(params.row, "fnStatus", isAutomated);
      }
    }
  ];
  const isEditEnabled = () => {
    return permissions.edit;
  };
  return (
    <>
      <Loader isLoading={isLoadingPermissions || releasedLoading}>
        <TableViewInfinite
          title="QA Test Cases"
          hideFilter
          useGet={getQATestcases}
          columns={LIST_COLUMNS}
          searchable={false}
          customFilter="FEATURE"
          getRowId={(row) => row.testCaseReleaseId}
          setId={setFeatureId}
          hideFooter
          filterConfig={filterConfig}
          hideLabelContainer
          hidePlatformFilter={false}
          platformConfig={platformConfig}
          releaseConfig={releaseConfig}
          testerConfig={testerConfig}
          addBtnText="Add Feature"
          getRowHeight={() => "auto"}
          defaultSort={[{ field: "name", sort: "asc" }]}
          setSelectedRelease={setReleaseId}
          testerId={selectedTester}
          setSelectedTester={setSelectedTester}
          onEdit={isEditEnabled() ? onEdit : undefined}
          onView={onView}
          setSelectedPlatform={setSelectedPlatform}
        />
      </Loader>
    </>
  );
};
