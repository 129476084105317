import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import {
  ModelHelpCategory,
  useAdminHelpArticleArticleIdDelete,
  useAdminHelpArticleArticleIdGet,
  useAdminHelpCategoryGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { HelpArticleForm } from "./helpArticleForm";
import { Loader } from "@components/crud/Loader";
import { enqueueSnackbar } from "notistack";

export const HelpArticleView = () => {
  const navigate = useNavigate();
  const { articleId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });

  const { reset } = form;

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useAdminHelpCategoryGet();

  const { data: data, isLoading: isLoading } = useAdminHelpArticleArticleIdGet(
    articleId!
  );
  const [categories, setCategories] = React.useState<ModelHelpCategory[]>([]);

  React.useEffect(() => {
    if (data?.data) {
      const article = data.data;
      reset({
        headline: article.headline,
        content: article.content,
        displayChannel: article.displayChannel,
        relatedTo: article.relatedTo,
        categoryId: article.categoryId
      });
    }
  }, [data]);
  React.useEffect(() => {
    if (categoriesData?.data) {
      setCategories(categoriesData.data.helpCategory!);
    }
  }, [categoriesData]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminHelpArticleArticleIdDelete();

  const onArticleDelete = async () => {
    try {
      await deleteAsync({ articleId: articleId as string });
      enqueueSnackbar("Article Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/help-articles");
    } catch (e) {
      enqueueSnackbar("Something went wrong! Unable to delete the Article.", {
        variant: "error"
      });
      setOpenDeleteDialog(false);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Help Articles"
        backBtnClick={() => {
          navigate("/help-articles");
        }}
        editBtnClick={() => {
          navigate(`/help-articles/${articleId}/edit`);
        }}
        deleteBtnClick={() => {
          setOpenDeleteDialog(true);
        }}
      />
      <Loader isLoading={isLoading || isDeleting}>
        <HelpArticleForm
          form={form}
          categories={categories}
          disabled
          isLoadingCategories={isLoadingCategories}
        />
      </Loader>
      <ConfirmationDialog
        title="Delete Article?"
        body="Are you sure you want to delete this article?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onArticleDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
