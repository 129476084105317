import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /https:\/\/([\w-]+\.)?api\.sportsgravy\.com\/?.*/
      ]
    })
  ],
  tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.1
});

export default Sentry;
