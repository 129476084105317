import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ConsentForm,
  KidInvite,
  consentFormAtom,
  inviteAtom,
  kidInviteAtom,
  personAtom
} from "@recoil/signup";
import {
  ModelInvite,
  ModelPerson
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  marginbottom: 1em;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
  display: flex;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const FullName = styled(Typography)`
  font-weight: bold;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TermsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  inset: 0;
  // overflow-y: auto;
  // height: 16rem;
`;

const relationshipOptions = [
  { value: "SON", label: "Son" },
  { value: "DAUGHTER", label: "Daughter" }
];

export const KidConsent = () => {
  const person = useRecoilValue<ModelPerson | null>(personAtom);
  const setConsentForm = useSetRecoilState<ConsentForm | null>(consentFormAtom);
  const kidInvite = useRecoilValue<KidInvite[]>(kidInviteAtom);
  const invite = useRecoilValue<ModelInvite | null>(inviteAtom);
  const navigate = useNavigate();

  const under13 = useMemo(() => {
    return kidInvite.filter((kid) => {
      const dob = typeof kid.dob === "string" ? new Date(kid.dob) : kid.dob;
      const age = Math.floor(
        (new Date().getTime() - dob.getTime()) / 31556952000
      );
      return age < 13;
    });
  }, [kidInvite]);

  const personAddress = useMemo(() => {
    return `${person?.addressPrimary?.lines?.join(" ")} ${person?.addressPrimary
      ?.locality} ${person?.addressPrimary?.province} ${person?.addressPrimary
      ?.postalCode} ${person?.addressPrimary?.country}`;
  }, []);

  const defaultValues = useMemo(
    () => ({
      agreeToTerms: false,
      signature: "",
      kid: under13.map((kid) => {
        const kidPerson = invite?.person?.children?.find(
          (child) => child.personId === kid.personId
        )?.person;
        return {
          name: `${kidPerson?.firstName} ${kidPerson?.lastName}`,
          email: kid.email,
          dob: typeof kid.dob === "string" ? new Date(kid.dob) : kid.dob,
          address: personAddress,
          relationship: kid.relationship,
          parentName: `${person?.firstName} ${person?.lastName}`,
          parentDob:
            typeof person?.birthedAt === "string"
              ? new Date(person?.birthedAt)
              : person?.birthedAt,
          parentAddress: personAddress
        };
      })
    }),
    [kidInvite, invite, personAddress]
  );

  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues
  });

  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);

  const signature = watch("signature");
  const fullName = useMemo(() => {
    return `${person?.firstName} ${person?.lastName}`;
  }, []);

  const acceptTerms = async (formValues) => {
    setConsentForm(formValues);
    navigate("/sign-up/terms-of-use");
  };

  const declineTerms = async () => {
    navigate("/");
  };

  return (
    <FormContainer data-testid="kid-consent">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Parents Consent
      </Typography>
      <form onSubmit={handleSubmit(acceptTerms)}>
        <TermsContainer>
          <div>
            <p className="mt-1 text-dark">COPPA PARENTAL CONSENT FORM</p>
            <p className="card-text">
              In compliance with the Children's Online Privacy Protection Act
              (COPPA), parents or legal guardians (collectively, “guardians”) of
              children under 13 years of age must consent to collections, uses,
              and disclosures of the personal information of their children
              collected by Sports Gravy LLC (“SportsGravy”).
            </p>
            <p className="card-text">
              SportsGravy may have already collected your contact information
              from your child or your child's sports organization, as well as
              the name of your child or other guardians, in order to obtain this
              consent.
            </p>
            <p className="card-text">
              Your permission is required for the collection, use, or disclosure
              of your child's personal information. We will not grant your child
              access to the SportsGravy service unless you provide us with
              permission. SportsGravy accounts provide access to content,
              materials, and resources of the service as described in our{" "}
              <a href="/">Children's Privacy Notice</a>.
            </p>
            {under13.map((kid, index) => (
              <div data-testid="kid-consent-details" key={kid.personId}>
                <FormRow>
                  <FormInput
                    name={`kid[${index}].name`}
                    type="text"
                    required
                    control={control}
                    label="Child's Full Name"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormInput
                    name={`kid[${index}].email`}
                    type="email"
                    required
                    control={control}
                    label="Child's Email"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormDatePicker
                    name={`kid[${index}].dob`}
                    required
                    control={control}
                    label="Child's Date of Birth"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormInput
                    name={`kid[${index}].address`}
                    type="text"
                    required
                    control={control}
                    label="Child's Address"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormInput
                    name={`kid[${index}].parentName`}
                    type="text"
                    required
                    control={control}
                    label="Parent's / Guardian's Full Name"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormSelect
                    name={`kid[${index}].relationship`}
                    options={relationshipOptions}
                    required
                    control={control}
                    label="Parent's / Guardian's Relationship to Child"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormDatePicker
                    name={`kid[${index}].parentDob`}
                    required
                    control={control}
                    label="Parent's / Guardian's Date of Birth"
                    disabled
                  />
                </FormRow>
                <FormRow>
                  <FormInput
                    name={`kid[${index}].parentAddress`}
                    type="text"
                    required
                    control={control}
                    label="Parent's / Guardian's Mailing Address"
                    disabled
                  />
                </FormRow>
              </div>
            ))}
            <p className="card-text">
              By completing this form and checking the box, you certify that you
              consent to the collection, use and/or disclosure of your child's
              personal information as described in the{" "}
              <a href="">SportsGravy's Children's Privacy Notice</a>.
            </p>
            <p className="card-text">
              You may revoke your consent at any time to refuse further
              collection and use of your child's information. If you desire to
              revoke this consent, please communicate that revocation of consent
              by contacting us as described in the{" "}
              <a href="">Children's Privacy Notice</a>.
            </p>
          </div>
        </TermsContainer>
        <FormRow>
          <FormFieldContainer data-testid="kid-consent-sign">
            <FormInput
              name="signature"
              type="text"
              required
              label={`Electronic Signature ( ${fullName} )`}
              control={control}
              rules={{
                required: "Electronic Signature is required",
                validate: {
                  matchesFullName: (value: string) => {
                    return (
                      value === fullName ||
                      "Electronic Signature must match your full name as displayed above"
                    );
                  }
                }
              }}
            />
            <FullName />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-acceptTerms">
            <FormCheckbox
              control={control}
              name="agreeToTerms"
              disabled={fullName !== signature}
              label="I have read, understand and agree to the COPPA PARENTAL CONSENT FORM above."
              rules={{
                required: true
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer>
          <FormFieldContainer sx={{ marginRight: "1.5rem" }}>
            <Button
              variant="cancel"
              type="button"
              onClick={() => setIsDeclineDialogOpen(true)}
            >
              Decline
            </Button>
          </FormFieldContainer>
          <FormFieldContainer>
            <Button variant="primary" disabled={!isValid} type="submit">
              Accept
            </Button>
          </FormFieldContainer>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Already have an account? <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
      <ConfirmationDialog
        open={isDeclineDialogOpen}
        title="Decline Terms of Use?"
        body="By clicking “Decline” below you will cancel the registration process and your new SportsGravy account will not be created."
        close={() => setIsDeclineDialogOpen(false)}
        onCancel={() => setIsDeclineDialogOpen(false)}
        onConfirm={declineTerms}
        cancelBtnText="Cancel"
        confirmBtnText="Decline"
      />
    </FormContainer>
  );
};
