/* eslint-disable @typescript-eslint/no-explicit-any */
export const objectPropertyRecursive = (
  obj: Record<string, any>,
  path: string,
): any => {
  const pathParts = path.split(".");
  const length = pathParts.length;

  let currentObj = obj;
  let i = 0;

  while (i < length && currentObj !== undefined) {
    currentObj = currentObj?.[pathParts[i++]];
  }
  return currentObj;
};
