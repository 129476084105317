/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, FormLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers-pro";
import React from "react";
import { Control, Controller } from "react-hook-form";
const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FormDatePicker = (props: {
  name: string;
  control: Control<any, any>;
  rules: any | undefined;
  label: string;
  required: boolean | undefined;
  onChange?: (event: React.ChangeEvent) => void;
  disabled?: boolean;
  disableFuture?: boolean;
  selectedValue?: Date;
  minDate?: Date;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ fieldState, field }) => (
        <FormInputContainer data-testid={"DATE_PICKER_" + props.name}>
          <StyledFormLabel required={props.required}>
            <Typography display="inline" variant="formLabel">
              {props.label}
            </Typography>
          </StyledFormLabel>
          <DatePicker
            value={props.selectedValue || field.value || undefined}
            format="MM/dd/yyyy"
            disabled={props.disabled}
            disableFuture={props.disableFuture}
            onChange={(e: unknown) => {
              const event = e as React.ChangeEvent;
              field.onChange(event);
              props.onChange && props.onChange(event);
            }}
            onClose={field.onBlur}
            slotProps={{
              textField: {
                helperText: fieldState.error?.message,
                onBlur: field.onBlur,
                error: !!fieldState.error
              }
            }}
            minDate={props.minDate}
          />
        </FormInputContainer>
      )}
    />
  );
};

FormDatePicker.defaultProps = {
  rules: undefined,
  required: false,
  disabled: false,
  disableFuture: false
};
