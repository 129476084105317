import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 0;
  margin: 0;
`;
