import colors from "./colors";
import { pxToRem } from "./functions";

const baseProperties = {
  fontFamily: '"inter var", sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(26),
  fontSize3XL: pxToRem(32),
};

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: colors.text.general.primary,
  fontWeight: baseProperties.fontWeightBold,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  h1: {
    fontSize: baseProperties.fontSize3XL,
    lineHeight: "40px",
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: baseProperties.fontSize2XL,
    lineHeight: "32px",
    ...baseHeadingProperties,
  },

  h2Thin: {
    fontSize: baseProperties.fontSize2XL,
    lineHeight: "32px",
    ...baseHeadingProperties,
    fontWeight: baseProperties.fontWeightLight,
  },

  h3: {
    fontSize: baseProperties.fontSizeXL,
    lineHeight: "28px",
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: baseProperties.fontSizeLG,
    lineHeight: "24px",
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: baseProperties.fontSizeMD,
    lineHeight: "22px",
    ...baseHeadingProperties,
  },

  gridToolbarFilterLabel: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    color: colors.text.general.primary,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.8,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    color: colors.text.general.primary,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.625,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.6,
  },

  body3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
  },

  body4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.5,
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: 500,
    lineHeight: 1.25,
    color: "#64748b",
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    "2xl": baseProperties.fontSize2XL,
    "3xl": baseProperties.fontSize3XL,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },

  formLabel: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    color: colors.text.general.primary,
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },

  tooltipText: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    color: colors.white.main,
    fontWeight: baseProperties.fontWeightMedium,
  },

  tooltipValue: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    color: colors.white.main,
    fontWeight: baseProperties.fontWeightBold,
  },

  tableHeader: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(13),
    color: "#64748B",
    fontWeight: 600,
    lineHeight: 1.625,
  },

  permissionNames: {
    fontFamily: baseProperties.fontFamily,
    fontSize: pxToRem(13),
    color: "#1E2941",
    fontWeight: baseProperties.fontWeightBold,
    lineHeight: 1.625,
  },

  navigationMenuHeader: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    color: "#CACACA",
    fontWeight: baseProperties.fontWeightBold,
    textTransform: "uppercase",
  },
};

export default typography;
