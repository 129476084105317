import { GlobalStyles, Tooltip, TooltipProps } from "@mui/material";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";

import { styled } from "@mui/material/styles";
import { CSSProperties, ReactElement } from "react";

const StyledTooltip = styled(Tooltip)`
  white-space: pre-line;
`;

interface ToolTipProps extends TooltipProps {
  children: ReactElement;
}

const tooltipStyles: CSSProperties = {
  backgroundColor: "#fff",
  border: "1px solid #E5E5E5",
  boxShadow: "0px 6px 16px 0px #00000029",
  padding: "12px 16px",
  borderRadius: "2px",
  color: "#0F0F0F",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  zIndex: 9999
};
export const ToolTip = ({
  children,
  componentsProps,
  ...props
}: ToolTipProps) => {
  return (
    <>
      <GlobalStyles
        styles={{
          ".custom-tooltip-arrow::before": {
            border: "1px solid #E5E5E5"
          }
        }}
      />
      <StyledTooltip
        componentsProps={{
          tooltip: {
            style: {
              ...tooltipStyles,
              ...componentsProps?.tooltip?.style
            },
            sx: {
              ...tooltipStyles,
              ...componentsProps?.tooltip?.sx
            }
          },
          arrow: {
            className: "custom-tooltip-arrow",
            style: {
              color: "#fff"
            }
          }
        }}
        arrow
        {...props}
      >
        {children}
      </StyledTooltip>
    </>
  );
};

const { border, ...stylesWithoutBorder } = tooltipStyles;

export const ReactToolTip = ({
  children,
  ...props
}: ITooltip & { children?: React.ReactNode }) => (
  <ReactTooltip
    border={border}
    opacity={1}
    style={{
      ...stylesWithoutBorder
    }}
    {...props}
  >
    {children}
  </ReactTooltip>
);
