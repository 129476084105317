/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const StyledFormLabel = styled(FormControlLabel)`
  margin-bottom: 0.25rem;
`;

const ErrorContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
  position: "absolute",
  top: "100%",
  left: 0,
  margin: 0,
  fontFamily: "Inter var, sans-serif",
  fontSize: "12px",
  fontWeight: 500,
  height: "30px"
}));

export const FormRadioGroup = (props: {
  name: string;
  options: { value: string; label: string }[];
  control: any;
  rules: any | undefined;
  horizontal?: boolean;
  onChange?: (event: any) => void;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field, fieldState }) => (
        <>
          <RadioGroup
            value={field.value || ""}
            onChange={props.onChange ? props.onChange : field.onChange}
            onBlur={field.onBlur}
            data-test-id="FORM_RADIO_GROUP"
          >
            {props.horizontal ? (
              <Grid item container direction={"row"} marginTop="-10px">
                {props.options.map(({ value, label }) => (
                  <Grid item key={value}>
                    <StyledFormLabel
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              props.options.map(({ value, label }) => (
                <StyledFormLabel
                  key={value}
                  value={value}
                  control={<Radio />}
                  label={label}
                />
              ))
            )}
          </RadioGroup>
          {!!fieldState.error && (
            <ErrorContainer>
              <Typography variant="body2">
                {fieldState.error.message}
              </Typography>
            </ErrorContainer>
          )}
        </>
      )}
    />
  );
};

FormRadioGroup.defaultProps = {
  rules: undefined
};
