import { TableView } from "@components/TableView";
import { organizationAtom } from "@recoil/auth";
import { getSportLocations } from "@services/Network";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row }) => row.name
  },
  {
    headerName: "Address1",
    field: "lines",
    minWidth: 400,
    flex: 1,
    sortable: false,
    valueGetter: ({ row }) => row?.lines.join(",")
  },
  {
    headerName: "City",
    field: "city",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row?.locality
  },
  {
    headerName: "State/Province",
    field: "province",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row?.province
  },
  {
    headerName: "Postal Code",
    field: "postalCode",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.postalCode,
    sortable: false
  }
];

export const SportLocations = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  console.log(organizationId);
  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/sport-locations/create");
  const onEdit = (location) =>
    navigate(`/sport-locations/${location.locationId}/edit`);
  const onView = (location) =>
    navigate(`/sport-locations/${location.locationId}`);

  return (
    <>
      <TableView
        title="Sports Locations"
        useGet={getSportLocations}
        columns={LIST_COLUMNS}
        defaultSort={[{ field: "name", sort: "asc" }]}
        hideFilter={true}
        getRowId={(row) => row.locationId}
        hideLabelContainer={true}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
