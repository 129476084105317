export const base64ImageToFile = (base64String, fileName) => {
  const base64 = base64String.replace(
    /^data:image\/(png|jpeg|jpg);base64,/,
    ""
  );

  // Convert the base64 string to a byte array
  const byteCharacters = atob(base64);
  const byteArray = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  // Create a Blob from the byte array
  const blob = new Blob([byteArray], { type: "image/png" });

  // Create a File object from the Blob
  return new File([blob], fileName, { type: "image/png" });
};
