import { Button } from "@components/Button";
import { FormRadioGroup } from "@components/FormRadioGroup";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { personAtom, signupTokenAtom } from "@recoil/signup";
import { useAuthSignUpPrimaryEmailPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  margin-top: 3rem;
`;

export const SelectPrimaryAccount = () => {
  const navigate = useNavigate();
  const person = useRecoilValue(personAtom);
  const emailOptions = useMemo(() => {
    return (
      person?.emails?.map((email) => {
        return {
          value: email.email as string,
          label: email.email as string
        };
      }) || []
    );
  }, [person?.emails]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: person?.emailPrimary?.email as string
    }
  });

  const email = watch("email");

  const { mutate, isLoading, isError } = useAuthSignUpPrimaryEmailPut();

  const token = getRecoil(signupTokenAtom);

  const submitForm = async () => {
    mutate(
      {
        data: { email },
        headers: { "x-sportsgravy-token": token }
      },
      {
        onSuccess: (res) => {
          navigate("/sign-up/account-info");
          setRecoil(personAtom, res.data);
        }
      }
    );
  };
  return (
    <FormContainer>
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Select Account Email
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        We have successfully merged your pending accounts into one account.
        Please select the email that you would like to use to sign into your
        SportsGravy account.
      </Typography>
      {isError && (
        <NotificationCard
          variant="error"
          sx={{
            mt: "1rem"
          }}
        >
          Error: Something went wrong. Try submitting again.
        </NotificationCard>
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormRadioGroup
            name="email"
            control={control}
            options={emailOptions}
            rules={{ required: true }}
          />
        </FormRow>
        <ButtonContainer>
          <Button
            variant="primary"
            disabled={!isValid || !!isLoading}
            type="submit"
            isLoading={!!isLoading}
          >
            Continue
          </Button>
        </ButtonContainer>
      </form>
    </FormContainer>
  );
};
