import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getJobTitles } from "@services/Network";
import {
  useAdminDepartmentGet,
  ModelDepartment,
  ModelJobTitle
} from "@sportsgravyengineering/sg-api-react-sdk";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LIST_COLUMNS: GridColDef<ModelJobTitle>[] = [
  {
    headerName: "Name",
    field: "name",
    minWidth: 150,
    flex: 1
  },
  {
    headerName: "Abbreviation",
    field: "abbreviation",
    minWidth: 150,
    flex: 1
  },
  {
    headerName: "Department",
    field: "department",
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) => row.department?.name
  },
  {
    headerName: "Sets Demo",
    field: "setsDemo",
    minWidth: 120,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) =>
      row.canSetDemo ? `Yes - ${row.maxAccountsDemo}` : "No"
  },
  {
    headerName: "Sells Accounts",
    field: "sellsAccounts",
    minWidth: 130,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) =>
      row.canSellAccount ? `Yes - ${row.maxOpenOppSell}` : "No"
  },
  {
    headerName: "Supports Accounts",
    field: "supportsAccounts",
    minWidth: 150,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) =>
      row.canSupportAccount ? `Yes - ${row.maxAccountsSupport}` : "No"
  },
  {
    headerName: "Accept Calls",
    field: "acceptCalls",
    minWidth: 120,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) => (row.supportsCalls ? `Yes` : "No")
  },
  {
    headerName: "Call Center",
    field: "supportsCallCenterCalls",
    minWidth: 120,
    flex: 1,
    sortable: true,
    valueGetter: ({ row }) => (row.supportsCallCenterCalls ? `Yes` : "No")
  },
  {
    headerName: "Is Active",
    field: "isActive",
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => (row.isActive ? "Yes" : "No")
  }
];

export const JobTitles = () => {
  const navigate = useNavigate();

  const [refreshKey] = React.useState(0);

  const onAdd = () => navigate("/job-titles/create");
  const onEdit = (row) => navigate(`/job-titles/${row.jobtitleId}/edit`);
  const onView = (row) => navigate(`/job-titles/${row.jobtitleId}`);

  const { data: departmentsData } = useAdminDepartmentGet();
  const [departments, setDepartments] = React.useState<ModelDepartment[]>([]);

  useEffect(() => {
    if (departmentsData?.data) {
      setDepartments(departmentsData.data.departments!);
    }
  }, [departmentsData]);

  const filterConfig = React.useMemo(() => {
    const options =
      departments?.map((department: ModelDepartment) => ({
        label: department.name as string,
        value: department.departmentId as string
      })) || [];

    return {
      field: "departmentId",
      placeholderOption: { label: "All", value: "" },
      options: options
    };
  }, [departments]);

  return (
    <>
      <TableView
        title="Jobs"
        useGet={getJobTitles}
        columns={LIST_COLUMNS}
        hideLabelContainer={true}
        searchable
        getRowId={(row) => row.jobtitleId}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        filterConfig={filterConfig}
        isDeleteDisabled={() => true}
        refreshKey={refreshKey}
      />
    </>
  );
};
