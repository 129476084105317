import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import FeedPostMobileTopNav from "@assets/images/feedPostMobileTop.svg";
import MobileTopNav from "@assets/images/mobileTopNav.svg";
import MobileBottom from "@assets/images/mobileBottom.svg";
import RedirectIcon from "@assets/icons/redirectIcon.svg";
import FullScreenIcon from "@assets/icons/fullscreenIcon.svg";
import OutStreamAdPreview from "@assets/images/OutStreamAd.svg";
import InStreamAdPreview from "@assets/images/InStreamAd.svg";
import FeedPostEmailLayout from "@assets/images/feedPostEmailLayout.svg";
import SponsoredByEmailLayout from "@assets/images/sponsoredByEmailLayout.svg";
import InstreamAdEmailLayout from "@assets/images/instreamAdEmailLayout.svg";
import OutStreamEmailLayout from "@assets/images/outStreamAdEmailLayout.svg";
import MobileBottomNavEmailLayout from "@assets/images/mobileBottomNavEmailLayout.svg";
import { Avatar, Typography } from "@mui/material";
import { AD_LAYOUT_OPTIONS, CALL_TO_ACTION_OPTIONS } from "@utils/constants";
import { useMemo, useState } from "react";
import { SponsorshipLevelExample } from "./SponsorshipLevelExample";
import ReactPlayer from "react-player";
import MoreVerIcon from "@mui/icons-material/MoreVert";
const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};
const StyledBox = styled(Grid)(() => ({
  marginTop: "10px",
  border: "1px solid #bdbdbd",
  borderRadius: "4px",
  padding: "32px 60px",
  "@media (max-width: 600px)": {
    padding: "0px",
    ".date": {
      fontSize: "9px"
    },
    ".title": {
      fontSize: "9px"
    },
    ".linkText": {
      fontSize: "9px"
    },
    ".actionButton": {
      fontSize: "9px",
      padding: "4px 12px"
    }
  }
}));
const StyledOuterBox = styled(Grid)(() => ({
  background: "#e7e7e7",
  textAlign: "center",
  borderRadius: "22px",
  borderTop: "20px solid #E7E7E7",
  borderRight: "10px solid #E7E7E7",
  borderBottom: "20px solid #E7E7E7",
  borderLeft: "10px solid #E7E7E7",
  "@media (max-width: 600px)": {
    padding: "0px",
    margin: "0px",
    borderLeft: "1px solid #E7E7E7",
    borderRight: "1px solid #E7E7E7"
  }
}));
const StyledInnerBox = styled(Box)(() => ({
  textAlign: "center",
  borderRadius: "20px 20px 20px 20px"
}));
const CenteredDiv = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "500px"
}));
const SponsoredByText = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 600,
  textAlign: "left",
  color: "#B3B3B3",
  padding: "2px 8px 8px 8px"
}));
const SponsoreBox = styled(Box)(() => ({
  backgroundColor: "#dadada",
  margin: "2px 8px 8px 8px"
}));
const StyledDimensions = styled("div")(() => ({
  color: "#bababa",
  fontWeight: 600,
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));
const StyledReactPlayer = styled(ReactPlayer)(() => ({
  "& video": {
    objectFit: "contain",
    width: "100%",
    height: "100%"
  }
}));
const StyledInstreamDiv = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    paddingBottom: "80% !important",
    "& div": {
      height: "360px !important"
    }
  }
}));
export const LivePreview = (props: {
  layout;
  placement;
  adDetails;
  type?;
  metaData?;
}) => {
  const [AdViewer, setAdViewer] = useState(false);
  const adProperties = AD_LAYOUT_OPTIONS.find(
    (option) => option.value == props.layout
  );
  const closeAdViewer = () => {
    setAdViewer(false);
  };

  const memoizedReactPlayer = useMemo(() => {
    return (
      <StyledReactPlayer
        key={1}
        data-testid="ad-media-video"
        playing={true}
        height={"100%"}
        width={"100%"}
        url={
          props.type == "email"
            ? props.adDetails.media
            : !(props.adDetails.media instanceof File)
            ? props.adDetails.media
            : URL.createObjectURL(props.adDetails.media)
        }
      />
    );
  }, [props.adDetails.media]);

  const AdMedia = (borderRadius) => {
    if (!props.adDetails.media) {
      return (
        <StyledDimensions
          data-testid="dimension"
          style={{ height: adProperties?.size.height + "px" }}
        >
          {" "}
          {adProperties?.size.width} x {adProperties?.size.height}
          <img
            src={RedirectIcon}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "30px",
              height: "30px",
              zIndex: 1000,
              cursor: "pointer"
            }}
            onClick={() => {
              if (
                props.adDetails.redirect.includes("http://") ||
                props.adDetails.redirect.includes("https://")
              )
                window.open(props.adDetails.redirect, "_blank");
              else window.open("https://" + props.adDetails.redirect, "_blank");
            }}
          />
        </StyledDimensions>
      );
    }
    if (props.adDetails.media) {
      return (
        <>
          <img
            data-testid="ad-media"
            src={
              props.type == "email"
                ? props.adDetails.media
                : !(props.adDetails.media instanceof File)
                ? props.adDetails.media
                : URL.createObjectURL(props.adDetails.media)
            }
            style={{
              objectFit: "fill",
              height: adProperties?.size.height + "px",
              width: "100%",
              borderRadius: borderRadius ? "0px 0px 20px 20px" : "0"
            }}
          />
          <img
            src={RedirectIcon}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "30px",
              height: "30px",
              zIndex: 1,
              cursor: "pointer"
            }}
          />
        </>
      );
    }
  };
  const outStreamAdRender = () => {
    return (
      <Grid container>
        {adProperties?.fields.includes("logo") && (
          <Grid sx={{ margin: "0 0 0 10px" }}>
            <Avatar
              data-testid="ad-logo"
              sx={{
                bgcolor: "#000",
                color: "#fff !important",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "left"
              }}
              src={
                props.adDetails.logo instanceof File
                  ? URL.createObjectURL(props.adDetails.logo)
                  : props.adDetails.logo
              }
              variant="square"
            ></Avatar>
          </Grid>
        )}
        <Grid xs container direction="column">
          {adProperties?.Imageposition.includes("top") && (
            <SponsoreBox
              data-testid="ad-media-top"
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0px ",
                borderRadius:
                  adProperties?.size.height < 100
                    ? "0"
                    : adProperties.Imageposition.length > 0 &&
                      adProperties.label.includes("Contextual")
                    ? "0"
                    : "0px 0px 20px 20px"
              }}
            >
              {AdMedia(false)}
            </SponsoreBox>
          )}
          {adProperties?.fields.includes("title") && (
            <Typography
              data-testid="ad-title"
              style={{
                maxWidth: "200px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                margin: "0 0 0 10px",
                textTransform: "capitalize",
                ...(adProperties.label.includes("Contextual") && {
                  maxWidth: "90%",
                  height: "18px",
                  color: "#3B6CF8",
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  margin: "7px 0 7px 10px",
                  textWrap: "nowrap"
                })
              }}
              onClick={() => {
                if (
                  props.adDetails.redirect.includes("http://") ||
                  props.adDetails.redirect.includes("https://")
                )
                  window.open(props.adDetails.redirect, "_blank");
                else
                  window.open("https://" + props.adDetails.redirect, "_blank");
              }}
            >
              {props.adDetails.title ? props.adDetails.title : "Ad Title"}
            </Typography>
          )}
          {adProperties?.Imageposition.includes("middle") && (
            <SponsoreBox
              data-testid="ad-media-middle"
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0px ",
                borderRadius:
                  adProperties?.size.height < 100
                    ? "0"
                    : adProperties.Imageposition.length > 0 &&
                      adProperties.label.includes("Contextual")
                    ? "0"
                    : "0px 0px 20px 20px"
              }}
            >
              {AdMedia(false)}
            </SponsoreBox>
          )}
          {!adProperties?.fields.includes("image") && (
            <img
              src={RedirectIcon}
              style={{
                position: "absolute",
                top: "1px",
                right: "10px",
                width: "25px",
                height: "25px",
                zIndex: 1,
                cursor: "pointer",
                margin: "0 10px 0 0"
              }}
              onClick={() => {
                if (
                  props.adDetails.redirect.includes("http://") ||
                  props.adDetails.redirect.includes("https://")
                )
                  window.open(props.adDetails.redirect, "_blank");
                else
                  window.open("https://" + props.adDetails.redirect, "_blank");
              }}
            />
          )}
          {adProperties?.fields.includes("description") && (
            <Typography
              data-testid="ad-description"
              style={{
                textAlign: "left",
                fontSize: "12px",
                maxWidth: "250px",
                fontWeight: 400,
                lineHeight: "18px",
                margin: "0 0 0 10px",
                ...(adProperties.label.includes("Contextual") && {
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  height: "40px",
                  maxWidth: "90%",
                  margin: "7px 0 7px 10px"
                })
              }}
            >
              {props.adDetails.description
                ? props.adDetails.description
                : "Ad Description"}
            </Typography>
          )}
          {adProperties?.Imageposition.includes("bottom") && (
            <SponsoreBox
              data-testid="ad-media-bottom"
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0px ",
                bottom: "0x",
                borderRadius: "0px 0px 20px 20px"
              }}
            >
              {AdMedia(true)}
            </SponsoreBox>
          )}
        </Grid>
      </Grid>
    );
  };
  const FeedPostAdMedia = (borderRadius) => {
    if (!props.adDetails.media && !props?.metaData?.image) {
      return (
        <StyledDimensions data-testid="dimension" style={{ height: "290px" }}>
          {" "}
          {adProperties?.size.width} x {adProperties?.size.height}
        </StyledDimensions>
      );
    }
    if (props.adDetails.media || props?.metaData?.image) {
      return (
        <>
          {adProperties?.fields.includes("image") && (
            <img
              data-testid="ad-media-image"
              src={
                props.type == "email"
                  ? props.adDetails.media
                  : props.metaData?.image
                  ? props.metaData?.image
                  : !(props.adDetails.media instanceof File)
                  ? props.adDetails.media
                  : URL.createObjectURL(props.adDetails.media)
              }
              style={{
                objectFit: "fill",
                height: "290px",
                width: "100%",
                borderRadius: borderRadius ? "0px 0px 20px 20px" : "0"
              }}
            />
          )}
          {adProperties?.fields.includes("video") && (
            <div style={{ objectFit: "fill", height: "290px", width: "100%" }}>
              {memoizedReactPlayer}
            </div>
          )}
        </>
      );
    }
  };
  return (
    <>
      <StyledBox>
        <StyledOuterBox xs={12}>
          <StyledInnerBox
            data-testid="live-preview"
            style={{
              backgroundColor:
                props.layout === "" || props.placement === ""
                  ? "#D4D4D4"
                  : "#fff"
            }}
          >
            {props.placement == "" && props.layout == "" && (
              <CenteredDiv>Select an AD Placement</CenteredDiv>
            )}
            {props.layout == "" &&
              props.placement != "INSTREAM" &&
              props.placement != "" && (
                <CenteredDiv>Select AD Layout</CenteredDiv>
              )}
            {props.layout != "" && props.placement == "FEED_POST" && (
              <>
                <img
                  data-testid="feed-post-ad"
                  src={
                    props.type == "email"
                      ? FeedPostEmailLayout
                      : FeedPostMobileTopNav
                  }
                  style={{ width: "100%", borderRadius: "20px 20px 0px 0px" }}
                />
                <div
                  style={{
                    height: "fit-content",
                    margin: "0px 15px 0px 15px"
                  }}
                >
                  <Grid container sx={{ margin: "0 0 0 10px" }}>
                    <Grid>
                      <Avatar
                        data-testid="ad-logo"
                        sx={{
                          bgcolor: "#000",
                          color: "#fff !important",
                          fontSize: "14px",
                          fontWeight: 700,
                          lineHeight: "20px",
                          textAlign: "left"
                        }}
                        src={
                          props.adDetails.logo instanceof File
                            ? URL.createObjectURL(props.adDetails.logo)
                            : props.adDetails.logo
                        }
                        variant="square"
                      ></Avatar>
                    </Grid>

                    <Grid
                      marginLeft={"11px"}
                      xs
                      container
                      direction="column"
                      alignItems={"start"}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%"
                        }}
                      >
                        <Typography fontSize={"12px"} fontWeight={600}>
                          {`${props.adDetails.businessName}`}
                        </Typography>
                        <Typography fontSize={"10px"} sx={{ color: "#939393" }}>
                          Promoted
                        </Typography>
                      </div>
                      <Typography
                        className="date"
                        fontSize={"12px"}
                        fontWeight={400}
                      >
                        {dateFormat(new Date())}
                      </Typography>
                    </Grid>
                    <MoreVerIcon style={{ fill: "#939393" }} />
                  </Grid>
                  <div>
                    <Typography
                      data-testid="livepreview-description"
                      sx={{
                        textAlign: "left",
                        marginLeft: "11px",
                        marginTop: "8px",
                        overflow: "hidden",
                        maxWidth: "fit-content"
                      }}
                    >
                      {props.adDetails.description.substring(0, 75)}
                      {props.adDetails.description.length > 75 && (
                        <span style={{ color: "#666666", fontSize: "12px" }}>
                          ...see more
                        </span>
                      )}
                    </Typography>
                  </div>
                  <div style={{ minHeight: "100px", position: "relative" }}>
                    <SponsoreBox
                      style={{
                        width: "95%",
                        marginTop: "11px",
                        height: "290px",
                        position: "relative",
                        marginBottom: "0px"
                      }}
                    >
                      {FeedPostAdMedia(false)}
                    </SponsoreBox>
                    {adProperties?.fields.includes("button") && (
                      <div
                        style={{
                          height: "44px",
                          backgroundColor: "#F3F4F7",
                          margin: "0 8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <div>
                          <Typography
                            data-testid="livepreview-callToActionTitle"
                            className="title"
                            variant="subtitle1"
                            sx={{
                              textAlign: "left",
                              marginLeft: "9px",
                              fontSize: "11px"
                            }}
                          >
                            &nbsp;{props.adDetails.title}
                          </Typography>
                          <Typography
                            data-testid="livepreview-linkText"
                            className="linkText"
                            variant="body1"
                            sx={{
                              textAlign: "left",
                              marginLeft: "11px",
                              fontSize: "9px",
                              color: "#666666",
                              maxWidth: "150px",
                              height: "12px",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {props.adDetails.linkText
                              ? props.adDetails.linkText
                              : props.adDetails.redirect}
                          </Typography>
                        </div>
                        {props.adDetails.actionButton && (
                          <div
                            data-testid="livepreview-actionButton"
                            className="actionButton"
                            style={{
                              borderRadius: "99px",
                              border: "1px solid #2B337A",
                              color: "#2B337A",
                              padding: "2px 12px",
                              marginRight: "11px",
                              height: "26px",
                              cursor: "pointer"
                            }}
                          >
                            <a
                              href={props.adDetails.redirect}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#2B337A" }}
                            >
                              {CALL_TO_ACTION_OPTIONS.find(
                                (b) => props.adDetails.actionButton == b.value
                              )
                                ? CALL_TO_ACTION_OPTIONS.find(
                                    (b) =>
                                      props.adDetails.actionButton == b.value
                                  )!.label
                                : ""}
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <img
                  src={
                    props.type == "email"
                      ? MobileBottomNavEmailLayout
                      : MobileBottom
                  }
                  style={{
                    width: "100%",
                    borderRadius: "0px 0px 20px 20px",
                    position: "relative",
                    bottom: "-8px"
                  }}
                />
              </>
            )}
            {props.layout != "" && props.placement == "SPONSORED_BY" && (
              <>
                <img
                  data-testid="sponsored-by-ad"
                  src={
                    props.type == "email"
                      ? SponsoredByEmailLayout
                      : MobileTopNav
                  }
                  style={{ width: "100%", borderRadius: "20px 20px 0px 0px" }}
                />
                <div
                  style={{
                    backgroundColor: "#F7F9FB",
                    height: "fit-content",
                    margin: "-8px 15px 0px 15px"
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                      textAlign: "left",
                      padding: "8px 8px 0px 8px",
                      fontSize: "12px"
                    }}
                  >
                    Team A vs Team B 🏒 Ice Hockey - Men's Bronze Medal Match |
                    Full match with pre...
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      textAlign: "left",
                      padding: "2px 8px 8px 8px",
                      fontSize: "10px"
                    }}
                  >
                    09/17/2023 5:55 PM - 7:55 PM IST
                  </div>
                  <SponsoredByText>Sponsored By</SponsoredByText>
                  <div style={{ minHeight: "100px", position: "relative" }}>
                    <SponsoreBox
                      style={{
                        width: "95%",
                        height: adProperties?.size.height + "px",
                        position: "relative"
                      }}
                    >
                      {AdMedia(false)}
                    </SponsoreBox>
                  </div>
                </div>
                <img
                  src={
                    props.type == "email"
                      ? MobileBottomNavEmailLayout
                      : MobileBottom
                  }
                  style={{
                    width: "100%",
                    borderRadius: "0px 0px 20px 20px",
                    position: "relative",
                    bottom: "-8px"
                  }}
                />
              </>
            )}
            {props.layout != "" && props.placement == "OUTSTREAM" && (
              <>
                {(adProperties?.size.height > 190 ||
                  adProperties?.label.includes("Extra")) && (
                  <StyledInstreamDiv
                    data-testid="outstream-ad-extraLarge"
                    style={{
                      width: "100%",
                      height: 0,
                      paddingBottom: "78%",
                      overflow: "hidden",
                      position: "relative"
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        borderRadius: "20px 20px 0px 0px",
                        height: "270px",
                        backgroundImage: `url(${
                          props.type === "email"
                            ? OutStreamEmailLayout
                            : OutStreamAdPreview
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                      }}
                    ></div>
                  </StyledInstreamDiv>
                )}
                <img
                  data-testid="outstream-ad"
                  src={
                    props.type == "email"
                      ? OutStreamEmailLayout
                      : OutStreamAdPreview
                  }
                  style={{
                    width: "100%",
                    borderRadius: "20px 20px 0px 0px",
                    display: adProperties?.label.includes("Extra")
                      ? "none"
                      : adProperties?.size.height < 190
                      ? "block"
                      : !adProperties?.size.height
                      ? "block"
                      : "none"
                  }}
                />
                <div
                  style={{
                    height:
                      adProperties?.size.height < 200 &&
                      !adProperties?.label.includes("Contextual")
                        ? "200px"
                        : !adProperties?.size.height
                        ? "200px"
                        : "fit-content",
                    margin: "-8px 0px 0px 0px"
                  }}
                >
                  <SponsoredByText>Sponsored By</SponsoredByText>
                  <div style={{ minHeight: "100px", position: "relative" }}>
                    {outStreamAdRender()}
                  </div>
                </div>
              </>
            )}
            {props.placement == "INSTREAM" && (
              <>
                {!props.adDetails.media && (
                  <StyledDimensions
                    data-testid="dimension"
                    style={{ height: "190px", fontSize: "33px" }}
                  >
                    {" "}
                    1920 x 1080
                  </StyledDimensions>
                )}

                {props.adDetails.media && (
                  <div
                    data-testid="ad-media-video"
                    key={1}
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "190px",
                      borderRadius: "20px 20px 0 0",
                      overflow: "hidden"
                    }}
                  >
                    <div
                      style={{
                        objectFit: "fill",
                        height: "190px",
                        width: "100%"
                      }}
                    >
                      {memoizedReactPlayer}
                    </div>
                    <img
                      src={FullScreenIcon}
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        width: "px",
                        height: "15px",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setAdViewer(true);
                      }}
                      alt="Fullscreen Icon"
                    />
                  </div>
                )}
                <img
                  data-tessid="instream-ad"
                  src={
                    props.type == "email"
                      ? InstreamAdEmailLayout
                      : InStreamAdPreview
                  }
                  style={{
                    width: "100%",
                    borderRadius: "0px 0px 20px 20px",
                    position: "relative",
                    top: "-8px"
                  }}
                />
              </>
            )}
          </StyledInnerBox>
        </StyledOuterBox>
      </StyledBox>
      {AdViewer && (
        <SponsorshipLevelExample
          onClose={closeAdViewer}
          title={"Live Preview - In-Stream Ad Landscape"}
          src={
            props.type == "email"
              ? props.adDetails.media
              : !(props.adDetails.media instanceof File)
              ? props.adDetails.media
              : URL.createObjectURL(props.adDetails.media)
          }
          type={"video"}
          email={props.type == "email"}
        />
      )}
    </>
  );
};
