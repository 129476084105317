import { FormInput } from "@components/FormInput";
import { FormSwitch } from "@components/FormSwitch";
import { FormToggleGroup } from "@components/FormToggleGroup";
import {
  Box,
  Divider,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
  styled
} from "@mui/material";
import { ModelSubscriptionPlan } from "@sportsgravyengineering/sg-api-react-sdk";
// import { useAdminSubscriptionPlanPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
// import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const SubscriptionPlan = (props: {
  subscriptionPlan: ModelSubscriptionPlan;
  isEditing: boolean;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<FieldValues, any, any>;
}) => {
  // const form = useForm({
  //   mode: "onBlur"
  // });

  const { control, setValue, getValues } = props.form;
  // const {
  //   reset,
  //   control
  //   // getValues,
  //   // formState: { isDirty }
  // } = form;
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [limitRecruitingFeedViews, setLimitRecruitingFeedViews] =
    useState(false);
  const [limitRecruitingFeedPosts, setLimitRecruitingFeedPosts] =
    useState(false);
  const hasCloudStorageLimit = props.subscriptionPlan.planId === "plan.free";
  useEffect(() => {
    const recPostIdx = props.subscriptionPlan.features!.findIndex(
      (feat) => feat.featureKey === "recruting-feed-post-limit"
    );
    const isRecPostLimited =
      props.subscriptionPlan.features![recPostIdx]?.isLimited || false;
    const isRecViewLimited =
      props.subscriptionPlan.features![
        props.subscriptionPlan.features!.findIndex(
          (feat) => feat.featureKey === "recruting-feed-view-limit"
        )
      ]?.isLimited || false;
    setLimitRecruitingFeedPosts(!!isRecPostLimited);
    setLimitRecruitingFeedViews(!!isRecViewLimited);
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    setCurrencySymbol(formatter.formatToParts(1)[0].value);
  }, [props.subscriptionPlan]);

  const getFormName = (featureKey: string) => {
    const featIdx = props.subscriptionPlan.features?.findIndex(
      (feat) => feat.featureKey === featureKey
    );
    if (featIdx != -1)
      return `subscriptionPlans[${props.index}].features[${featIdx}]`;
  };
  return (
    <Box
      data-testid={props.subscriptionPlan.planId}
      style={{
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
        padding: "10px",
        borderRadius: "16px",
        border: "1px solid rgba(196, 196, 196, 1)"
      }}
    >
      <Grid item container direction="column" padding="20px" spacing={"24px"}>
        <Grid item container direction={"row"} justifyContent="space-between">
          <Grid item>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "500",
                color: "rgba(30, 41, 59, 1)"
              }}
            >
              {capitalize(props.subscriptionPlan.type!) + " Subscription Plan"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container direction={"row"} spacing={"24px"}>
          <Grid
            item
            xs={6}
            data-testid={`${props.subscriptionPlan.planId}.planName`}
          >
            <FormInput
              name={`subscriptionPlans[${props.index}].name`}
              control={control}
              rules={{ required: "Plan Name is required" }}
              label="Plan Name"
              type="text"
              required={true}
              disabled={!props.isEditing}
            />
          </Grid>
          <Grid
            item
            data-testid={`${props.subscriptionPlan.planId}.monthlyPrice`}
          >
            <FormInput
              name={`subscriptionPlans[${props.index}].monthlyPrice`}
              control={control}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                )
              }}
              rules={{ required: "Monthly price is required" }}
              label="Monthly Price"
              type="text"
              required={true}
              disabled={!props.isEditing}
            />
          </Grid>
          <Grid
            item
            data-testid={`${props.subscriptionPlan.planId}.yearlyPrice`}
          >
            <FormInput
              name={`subscriptionPlans[${props.index}].yearlyPrice`}
              control={control}
              rules={{ required: "Yearly price is required" }}
              label="Yearly Price"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                )
              }}
              required={true}
              disabled={!props.isEditing}
            />
          </Grid>
        </Grid>
        <Grid item container direction={"row"} spacing="24px">
          <Grid
            item
            container
            xs={6}
            flexDirection={"row"}
            spacing="10px"
            alignItems={"center"}
          >
            <Grid item xs={6}>
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Limit Recruiting Feed Views
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid
              item
              data-testid={`${props.subscriptionPlan.planId}.recrutingFeed`}
            >
              <FormSwitch
                control={control}
                name={getFormName("recruting-feed-view-limit") + ".isLimited"}
                disabled={!props.isEditing}
                onChange={(e) => {
                  setLimitRecruitingFeedViews(e.target.checked);
                  if (!e.target.checked) {
                    setValue(
                      getFormName("recruting-feed-view-limit") + ".description",
                      "Unlimited Recruiting Feed Views"
                    );
                    setValue(
                      getFormName("recruting-feed-view-limit") + ".comparison",
                      null
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
          {limitRecruitingFeedViews && (
            <Grid
              item
              data-testid={`${props.subscriptionPlan.planId}.recrutingFeedLimit`}
            >
              <FormInput
                name={getFormName("recruting-feed-view-limit") + ".limitValue"}
                control={control}
                rules={{ required: "Limit allowed on feed views is required" }}
                label="Limit Allowed On Feed Views"
                type="text"
                required={true}
                disabled={!props.isEditing}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>)?.target
                    .value;
                  setValue(
                    getFormName("recruting-feed-view-limit") + ".description",
                    `${value} Recruiting Feed ${
                      value == "1" ? "View" : "Views"
                    }`
                  );
                  setValue(
                    getFormName("recruting-feed-view-limit") + ".comparison",
                    `${value} ${value == "1" ? "View" : "Views"}`
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container direction={"row"} spacing="24px">
          <Grid
            item
            xs={6}
            container
            flexDirection={"row"}
            spacing="10px"
            alignItems={"center"}
          >
            <Grid item xs={6}>
              <StyledFormLabel required>
                <Typography variant="formLabel">
                  Limit Recruiting Feed Posts
                </Typography>
              </StyledFormLabel>
            </Grid>
            <Grid
              item
              data-testid={`${props.subscriptionPlan.planId}.recrutingPost`}
            >
              <FormSwitch
                control={control}
                name={getFormName("recruting-feed-post-limit") + ".isLimited"}
                disabled={!props.isEditing}
                onChange={(e) => {
                  setLimitRecruitingFeedPosts(e.target.checked);
                  if (!e.target.checked) {
                    setValue(
                      getFormName("recruting-feed-post-limit") + ".description",
                      "Unlimited Recruiting Feed Posts"
                    );
                    setValue(
                      getFormName("recruting-feed-post-limit") + ".comparison",
                      null
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
          {limitRecruitingFeedPosts && (
            <Grid
              item
              data-testid={`${props.subscriptionPlan.planId}.recrutingPostLimit`}
            >
              <FormInput
                name={getFormName("recruting-feed-post-limit") + ".limitValue"}
                control={control}
                rules={{ required: "Limit allowed on feed posts is required" }}
                label="Limit allowed On Recruiting Feed Posts"
                type="text"
                required={true}
                disabled={!props.isEditing}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>)?.target
                    .value;
                  setValue(
                    getFormName("recruting-feed-post-limit") + ".description",
                    `${value} Recruiting Feed ${
                      value == "1" ? "Post" : "Posts"
                    }`
                  );
                  setValue(
                    getFormName("recruting-feed-post-limit") + ".comparison",
                    `${value} ${value == "1" ? "Post" : "Posts"}`
                  );
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid item container direction={"row"} spacing={"24px"}>
          {hasCloudStorageLimit && (
            <Grid
              item
              xs={6}
              data-testid={`${props.subscriptionPlan.planId}.cloudStorage`}
            >
              <FormInput
                name={getFormName("cloud-storage") + ".limitValue"}
                control={control}
                rules={{
                  required: "Cloud Storage & Processing is Required"
                }}
                label="Limit Allowed On Cloud Storage"
                type="text"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">GB</InputAdornment>
                  )
                }}
                required={true}
                disabled={!props.isEditing}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>)?.target
                    .value;
                  setValue(
                    getFormName("cloud-storage") + ".description",
                    `${value} GB Cloud Storage & Processing`
                  );
                  setValue(
                    getFormName("cloud-storage") + ".comparison",
                    `${value} GB`
                  );
                }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={6}
            data-testid={`${props.subscriptionPlan.planId}.noOfUsers`}
          >
            <FormInput
              name={getFormName("user-accounts") + ".limitValue"}
              control={control}
              rules={{ required: "No Of Users Covered is required " }}
              label="No Of Users Covered"
              type="text"
              required={true}
              disabled={!props.isEditing}
              onChange={(e) => {
                const value = (e as ChangeEvent<HTMLInputElement>)?.target
                  .value;
                setValue(
                  getFormName("user-accounts") + ".description",
                  `Up to ${value} active ${value == "1" ? "User" : "Users"}`
                );
                setValue(
                  getFormName("user-accounts") + ".comparison",
                  `${value} ${value == "1" ? "user" : "Users"}`
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            item
            container
            xs={6}
            flexDirection={"row"}
            spacing="10px"
            alignItems={"self-end"}
          >
            <Grid
              item
              xs={10}
              data-testid={`${props.subscriptionPlan.planId}.videoLimit`}
            >
              <FormInput
                name={getFormName("video-curation") + ".limitValue"}
                control={control}
                rules={{
                  required: "Max Video Length is Required"
                }}
                label="Max Video Length"
                type="text"
                required={true}
                disabled={!props.isEditing}
                onChange={(e) => {
                  const value = (e as ChangeEvent<HTMLInputElement>)?.target
                    .value;
                  setValue(
                    getFormName("video-curation") + ".description",
                    `Curate videos up to ${value} ${getValues(
                      getFormName("video-curation") + ".limitUnit"
                    ).toLowerCase()} long`
                  );
                  setValue(
                    getFormName("video-curation") + ".comparison",
                    `Up to ${value} ${getValues(
                      getFormName("video-curation") + ".limitUnit"
                    ).toLowerCase()}`
                  );
                }}
              />
            </Grid>
            <Grid
              item
              marginTop="15px"
              xs={2}
              data-testid={`${props.subscriptionPlan.planId}.videoLimitUnit`}
            >
              <FormToggleGroup
                name={getFormName("video-curation") + ".limitUnit"}
                control={control}
                disabled={!props.isEditing}
                options={
                  props.subscriptionPlan.features
                    ? props.subscriptionPlan.features[
                        props.subscriptionPlan.features.findIndex(
                          (feat) => feat.featureKey === "video-curation"
                        )
                      ].limitUnitOptions?.map((opt) => {
                        return {
                          label: opt,
                          value: opt
                        };
                      }) || []
                    : []
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
