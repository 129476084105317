import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ModelMedia,
  useAdminGameConceptGet,
  useAdminGameSystemPost,
  useAdminPositionGet,
  useAdminSkillGet,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MediaSelector } from "@components/MediaSelector";
import GamePickerImage from "@assets/images/gameSystemPicker.png";
import { organizationAtom } from "../../recoil/auth";
import { useRecoilState } from "recoil";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { capitalizeEveryWord } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const GameSystemCreate = () => {
  const [filestoUpload, setFilesToUpload] = useState<File | ModelMedia | null>(
    null
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [resetMedia, setResetMedia] = useState(false);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const [positions, setPositions] = useState<
    {
      positionId: string;
    }[]
  >([]);
  const [skills, setSkills] = useState<
    {
      skillId: string;
    }[]
  >([]);
  const [gameConcepts, setGameConcepts] = useState<
    {
      gameConceptId: string;
    }[]
  >([]);
  const [organizationId] = useRecoilState(organizationAtom);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions, isLoading: positionOptionsLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: positions
      },
      options: {
        query: {
          enabled: !!positions
        }
      }
    });

  const { options: skillOptions, isLoading: skillOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSkillGet,
      dataField: "skills",
      labelField: "name",
      valueField: "skillId",
      params: {
        sportId: skills,
        organizationId
      },
      options: {
        query: {
          enabled: !!skills
        }
      }
    });

  const { options: gameConceptOptions, isLoading: gameConceptOptionsLoading } =
    useApiSelectOptions({
      api: useAdminGameConceptGet,
      dataField: "gameConcepts",
      labelField: "name",
      valueField: "conceptId",
      params: {
        sportId: gameConcepts,
        organizationId
      },
      options: {
        query: {
          enabled: !!gameConcepts
        }
      }
    });

  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
    reset
  } = useForm({
    mode: "onBlur"
  });
  useEffect(() => {
    if (sportOptions.length == 1) {
      setValue("sportId", sportOptions[0].value);
      setValue("positions", []);
      setPositions(sportOptions[0].value);
      setValue("skills", []);
      setSkills(sportOptions[0].value);
      setValue("gameConcepts", []);
      setGameConcepts(sportOptions[0].value);
    }
  }, [sportOptions]);
  const { mutate: save, isLoading: isSaving } = useAdminGameSystemPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      setMediaUploadLoading(true);
      const filesPromises = await Promise.all(
        filestoUpload.map((file) => {
          if (file instanceof File) {
            const promise = uploadMediaUsingPresignedUrl(file);
            console.log("PROMISE", promise);
            return promise;
          }
        })
      );
      const values = {
        ...formValues,
        ...(organizationId && { organizationId: organizationId })
      };
      if (values.positions.length) {
        values.positions = values.positions.map((position) => ({
          positionId: position
        }));
      }
      if (values.skills.length) {
        values.skills = values.skills.map((skill) => ({
          skillId: skill
        }));
      }
      if (values.gameConcepts.length) {
        values.gameConcepts = values.gameConcepts.map((gameConcept) => ({
          gameConceptId: gameConcept
        }));
      }

      values["mediaIds"] = filesPromises;
      setMediaUploadLoading(false);
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Game system added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
              setResetMedia(!resetMedia);
              setFilesToUpload(null);
            } else {
              navigate("/game-systems");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add game system!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Game System" />
      <Form>
        <Grid data-testid="gameSystem-add-form" container spacing={3}>
          <Grid data-testid="gameSystem-name" xs={12} md={6}>
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              required={true}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid data-testid="gameSystem-sport" xs={12} md={6}>
            <FormSelect
              control={control}
              name="sportId"
              label="Sport"
              required={true}
              isLoading={isSportLoading}
              options={sportOptions}
              disabled={sportOptions.length === 1}
              onChange={(e) => {
                setValue("positions", []);
                setPositions(e.target.value);
                setValue("skills", []);
                setSkills(e.target.value);
                setValue("gameConcepts", []);
                setGameConcepts(e.target.value);
              }}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          <Grid data-testid="gameSystem-description" xs={12} md={6}>
            <FormInput
              control={control}
              name="description"
              type="text"
              label="Description"
              multiline={true}
            />
          </Grid>
          <Grid data-testid="gameSystem-position" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="positions"
              label="Positions"
              required={false}
              disabled={positionOptionsLoading}
              options={positionOptions}
            />
          </Grid>
          <Grid data-testid="gameSystem-skill" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="skills"
              label="Skills"
              required={false}
              disabled={skillOptionsLoading}
              options={skillOptions}
            />
          </Grid>
          <Grid data-testid="gameSystem-gameConcept" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="gameConcepts"
              label="Game Concepts"
              required={false}
              disabled={gameConceptOptionsLoading}
              options={gameConceptOptions}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <StyledFormLabel>
              <Typography display="inline" variant="formLabel">
                {"Photo/Video Content"}
              </Typography>
            </StyledFormLabel>
          </Grid>
          <MediaSelector
            setFilesToUpload={(files) => {
              //@ts-ignore
              setFilesToUpload(files);
            }}
            uploadedFiles={[]}
            imagePlaceHolder={GamePickerImage}
            resetMedia={resetMedia}
          />
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving || mediaUploadLoading}
        isLoading={isSaving || mediaUploadLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/game-systems")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
