import { TableViewInfinite } from "@components/TableViewInfinite";
import { Loader } from "@components/crud/Loader";
import { organizationAtom, selectedFilterAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getFeatures } from "@services/Network";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const LIST_COLUMNS = [
  {
    headerName: "Feature Name",
    field: "name",
    sortable: false,
    minWidth: 300,
    flex: 1,
    valueGetter: ({ row }) => row.name
  },
  {
    headerName: "Parent Feature",
    field: "parentFeature",
    sortable: false,
    minWidth: 300,
    flex: 1,
    valueGetter: ({ row }) => (row?.parent ? row.parent.name : "")
  },
  {
    headerName: "Platforms",
    field: "platforms",
    minWidth: 100,
    flex: 1,
    sortable: false,
    valueGetter: ({ value }) =>
      value
        .map(
          (val) =>
            FEATURE_PLATFORMS_OPTIONS.find((option) => option.value === val)
              ?.label
        )
        .join(", ")
  },
  {
    headerName: "Is Active",
    field: "isActive",
    sortable: false,
    minWidth: 100,
    flex: 1,
    valueGetter: ({ row }) => (row?.isActive ? "Yes" : "No")
  }
];

export const Features = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const selectedFilterValue = useRecoilValue(selectedFilterAtom);
  const [featureId, setFeatureId] = useState(selectedFilterValue);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  useEffect(() => {
    if (organizationId) navigate("/not-found");
  }, [organizationId]);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("tech.features", "ADD");
      const edit = await checkPermission("tech.features", "EDIT");
      const del = await checkPermission("tech.features", "VIEW");
      const permission = {
        create,
        edit,
        view: del
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, [organizationId]);

  const onAdd = () => {
    if (!featureId || featureId === "") navigate("/features/create");
    else navigate(`/features/${featureId}/create`);
  };

  const onEdit = (feature) => {
    navigate(`/features/${feature.featureId}/edit`);
  };

  const onView = (feature) => {
    navigate(`/features/${feature.featureId}`);
  };

  const filterConfig = {
    field: "parentId",
    defaultValue: selectedFilterValue,
    placeholderOption: { label: "All", value: "" },
    options: []
  };

  const platformConfig = {
    field: "platform",
    defaultValue: "MOB",
    placeHolderOption: { label: "Mobile", value: "MOB" },
    options: [
      {
        label: "Mobile",
        value: "MOB"
      },
      {
        label: "Web",
        value: "WEB"
      }
    ]
  };

  const isEditEnabled = () => {
    return permissions.edit;
  };
  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableViewInfinite
          title="Features"
          hideFilter
          useGet={getFeatures}
          columns={LIST_COLUMNS}
          searchable={false}
          customFilter="FEATURE"
          getRowId={(row) => row.featureId}
          setId={setFeatureId}
          hideFooter
          filterConfig={filterConfig}
          hideLabelContainer
          hidePlatformFilter={false}
          platformConfig={platformConfig}
          addBtnText="Add Feature"
          defaultSort={[{ field: "name", sort: "asc" }]}
          onEdit={isEditEnabled() ? onEdit : undefined}
          onAdd={permissions?.create ? onAdd || undefined : undefined}
          onView={onView}
        />
      </Loader>
    </>
  );
};
