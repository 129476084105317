import { Skeleton, Paper, Toolbar } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Container } from "./crud/Container";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "696px",
  marginTop: "-2rem",
  display: "flex",
  flexWrap: "wrap",
  fontFamily: "Open Sans",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  background: "#FFF",
  padding: "15px",
  boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.13)",
  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  },
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));
const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2rem 0",

  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));
export const SkeletonFeedCommentLoader = (props: { type }) => {
  return (
    <Container>
      {props.type === "feed" && (
        <StyledToolbar>
          <StyledPaper>
            <Container>
              <Grid container alignItems="center">
                <Grid>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                </Grid>
                <Grid>
                  <Skeleton
                    animation="wave"
                    style={{ marginLeft: "11px" }}
                    variant="text"
                    width={200}
                    height={20}
                  />
                  <Skeleton
                    animation="wave"
                    style={{ marginLeft: "11px" }}
                    variant="text"
                    width={200}
                    height={20}
                  />
                </Grid>
              </Grid>
              <Grid>
                <Skeleton
                  animation="wave"
                  style={{ marginTop: "11px", width: "100%" }}
                  variant="rectangular"
                  height={400}
                />
              </Grid>
            </Container>
          </StyledPaper>
        </StyledToolbar>
      )}
      {props.type === "comment" && (
        <Box>
          <Container>
            <Grid container alignItems="center">
              <Grid>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={40}
                  height={40}
                />
              </Grid>
              <Grid>
                <Skeleton
                  animation="wave"
                  style={{ marginLeft: "11px" }}
                  variant="text"
                  width={300}
                  height={20}
                />
                <Skeleton
                  animation="wave"
                  style={{ marginLeft: "11px" }}
                  variant="text"
                  width={300}
                  height={20}
                />
              </Grid>
            </Grid>
            <Grid>
              <Skeleton
                animation="wave"
                style={{ marginTop: "11px", width: "100%" }}
                variant="rectangular"
                height={50}
              />
            </Grid>
          </Container>
        </Box>
      )}
    </Container>
  );
};
