import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { getOrganizationUploads } from "@services/Network";
import {
  ModelOrganizationUpload,
  ModelOrganizationUploadStatus
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const ImportPlayer = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({
    add: false
  });
  const [permissionLoading, setPermissionLoading] = useState(false);
  const dateFormat = (date) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true
    };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };
  const IMPORT_PLAYER_COLUMNS: GridColDef<
    ModelOrganizationUpload & {
      id: string;
      createdAt: string;
    }
  >[] = [
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => {
        const status = params.value as string as ModelOrganizationUploadStatus;
        return (
          <Chip
            style={{
              fontSize: "10px",
              padding: "4px 8px",
              marginLeft: "-14px",
              fontWeight: 600
            }}
            label={status}
            sx={{
              background: ["PROCESSING", "PENDING"].includes(status)
                ? "#FBF1BC"
                : status === "COMPLETED"
                ? "#BBF7D0"
                : "#FECACA",
              color: ["PROCESSING", "PENDING"].includes(status)
                ? "#7B5B08"
                : status === "COMPLETED"
                ? "#166534"
                : "#991B1B"
            }}
          />
        );
      },
      sortable: false
    },
    {
      headerName: "For",
      field: "for",
      minWidth: 100,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      sortable: false,
      valueFormatter: (params) => capitalize(params.value)
    },
    {
      headerName: "Sport",
      field: "sport",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.sport?.name
    },
    {
      headerName: "Season",
      field: "season",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.season?.name
    },
    {
      headerName: "Template File",
      field: "templateFile",
      minWidth: 120,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.fileName || "N/A"
    },
    {
      headerName: "Total Rows",
      field: "totalRows",
      minWidth: 100,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.rowCount
    },
    {
      headerName: "Imported Rows",
      field: "importedRows",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row._count.importedRecords || 0
    },
    {
      headerName: "Failed Rows",
      field: "failedRows",
      minWidth: 110,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row._count.failedRecords || 0
    },
    {
      headerName: "Total Person Records",
      field: "totalPersonRecords",
      minWidth: 170,
      flex: 1,
      sortable: false,
      valueGetter: (value) =>
        value.row.personRecordCount + value.row.failedPersonRecordCount || 0
    },
    {
      headerName: "Imported Person Records",
      field: "importedPersonRecords",
      minWidth: 200,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.personRecordCount || 0
    },
    {
      headerName: "Failed Person Records",
      field: "failedPersonRecords",
      minWidth: 180,
      flex: 1,
      sortable: false,
      valueGetter: (value) => value.row.failedPersonRecordCount || 0
    },
    {
      headerName: "Uploaded By",
      field: "uploadedBy",
      minWidth: 130,
      flex: 1,
      sortable: false,
      valueGetter: (value) =>
        value.row.uploadedBy?.person?.firstName +
        " " +
        value.row.uploadedBy?.person?.lastName
    },
    {
      headerName: "Uploaded Date/Time",
      field: "createdAt",
      minWidth: 230,
      flex: 1,
      sortable: false,
      valueGetter: (value) => dateFormat(value.row.createdAt)
    }
  ];

  useEffect(() => {
    setPermissionLoading(true);
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId as string,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const add = await checkPermission("user.upload", "ADD");
      setPermissions({
        add
      });
      setPermissionLoading(false);
    };
    fetchPermissions();
  }, []);

  const onAdd = () => {
    navigate("create");
  };

  const onView = (upload) => {
    navigate(`${upload.id}`);
  };

  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Upload Athletes / Players"
        useGet={getOrganizationUploads}
        columns={IMPORT_PLAYER_COLUMNS}
        isEditDisabled={() => true}
        onAdd={permissions.add ? onAdd : undefined}
        onView={onView}
        refreshKey={refreshKey}
        hideFilter={true}
        hideLabelContainer={true}
        pinnedColumns={{ left: ["action", "status", "for", "sport"] }}
      />
    </Loader>
  );
};
