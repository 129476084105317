// Required when using TypeScript 4.x and above
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

import { createTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import colors from "./colors";
import components from "./components";
import { pxToRem } from "./functions";
import typography from "./typography";

const theme = createTheme({
  breakpoints: breakpoints,
  // @ts-ignore
  palette: colors,
  // @ts-ignore
  typography: typography,
  functions: {
    pxToRem,
  },
  // @ts-ignore
  components,
});

export default theme;
