import { Container } from "@components/crud/Container";
import { Grid, ButtonGroup, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import React, { useEffect, useState } from "react";
import { Metric } from "../Metric/Metric";
import {
  ModelConfig,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";

export const SGAppDashboard = () => {
  const [organizationId] = useRecoilState(organizationAtom);
  const [maxSelections, setMaxSelections] = useState(5);
  const buttonGroups = [
    "Organizations",
    "Users",
    "Feed",
    "Live Streams",
    "Media"
  ];

  const [activeButton, setActiveButton] = React.useState("Organizations");
  const { data: config, isLoading: isLoading } = useConfigGet();

  useEffect(() => {
    if (config?.data) {
      const maxSelection = (config.data as ModelConfig[]).find(
        (c) => c.key === "dashboard.reporting-widgets-count"
      );
      if (maxSelection) {
        setMaxSelections(parseInt(maxSelection.value as string));
      }
    }
  }, [config]);

  return (
    <Container>
      <Grid container direction="column" spacing="15px">
        {!organizationId && (
          <Grid item style={{ marginLeft: "25px", marginTop: "16px" }}>
            <ButtonGroup
              variant="outlined"
              style={{ border: "rgba(215, 221, 229, 1)" }}
            >
              {buttonGroups.map((bg) => {
                return (
                  <Button
                    key={bg}
                    variant="outlined"
                    style={
                      activeButton === bg
                        ? {
                            backgroundColor: "#E8EEFF",
                            color: "#2B337A",
                            fontSize: "14px",
                            fontWeight: 600,
                            textTransform: "none"
                          }
                        : {
                            color: "#666666",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "none"
                          }
                    }
                    onClick={() => {
                      setActiveButton(bg);
                    }}
                  >
                    {bg}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
      <Loader isLoading={isLoading}>
        {activeButton == "Organizations" && (
          <Metric
            chartKey="org."
            config={config?.data as ModelConfig[]}
            maxSelections={maxSelections}
          />
        )}
        {activeButton === "Users" && (
          <Metric
            chartKey="user"
            config={config?.data as ModelConfig[]}
            maxSelections={maxSelections}
          />
        )}
        {activeButton === "Feed" && (
          <Metric
            chartKey="feed"
            config={config?.data as ModelConfig[]}
            maxSelections={maxSelections}
          />
        )}
        {activeButton === "Live Streams" && (
          <Metric
            chartKey="live-streams"
            config={config?.data as ModelConfig[]}
            maxSelections={maxSelections}
          />
        )}
        {activeButton === "Media" && (
          <Metric
            chartKey="media"
            config={config?.data as ModelConfig[]}
            maxSelections={maxSelections}
          />
        )}
      </Loader>
    </Container>
  );
};
