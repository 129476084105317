import { Box, Grid, styled } from "@mui/material";
import formatFullName from "@utils/formatFullName";

const ProfileThumbnail = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main
}));

const StyledPersonOption = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "12px 16px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#F5F5F5"
  }
}));
type Person = {
  personId: string;
  firstName: string;
  lastName: string;
  birthedAt: string;
  photo?: string;
  email: string;
  addressPrimary: {
    lines: string[];
    locality: string;
    province: string;
    postalCode: string;
    country: string;
  };
};
export const CalendarAttendee = ({
  person,
  onClick,
  ...props
}: {
  person: Person | null;
  onClick: () => void;
}) => {
  const fullName = formatFullName(person);
  const optionComponents = [fullName];
  const optionText = optionComponents
    .filter((component) => !!component)
    .join(" | ");
  const initialsText = person
    ? `${person?.firstName?.[0]}${person?.lastName?.[0]}`
    : "";
  const emailText = person?.email;
  return (
    <StyledPersonOption
      container
      {...props}
      direction="row"
      data-testid="shareWith-option"
      alignItems="center"
      onClick={onClick}
    >
      <Grid item xs={1}>
        <ProfileThumbnail>
          {person?.photo ? (
            <img
              src={person.photo}
              alt={`${person.firstName} ${person.lastName}`}
            />
          ) : (
            <span>{initialsText}</span>
          )}
        </ProfileThumbnail>
      </Grid>
      <Grid item xs={11}>
        <span>{optionText}</span>
        <br />
        <span style={{ color: "#64748B" }}>{emailText}</span>
      </Grid>
    </StyledPersonOption>
  );
};
