import { FormInput } from "@components/FormInput";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import styled from "styled-components";
import { LoadingSpinner } from "./LoadingSpinner";
import { Control } from "react-hook-form";

const StyledAutocomplete = styled(Autocomplete)`
  &.address-autocomplete {
    width: 100%;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const autocompleteService = { current: null } as any;
const libraries = ["places"] as "places"[];

export const AddressAutocomplete = ({
  name,
  control,
  rules,
  label,
  required,
  selectSuggestion,
  disabled,
  onChange
}: {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  label: string;
  required: boolean;
  selectSuggestion: (place: google.maps.places.PlaceResult) => void;
  disabled?: boolean;
  onChange?: (value: string) => void;
}) => {
  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
      libraries={libraries}
      loadingElement={<LoadingSpinner />}
    >
      <StyledAutocomplete
        className="address-autocomplete"
        onLoad={(autocomplete) => {
          autocompleteService.current = autocomplete;
        }}
        onPlaceChanged={() => {
          if (autocompleteService.current) {
            const place = autocompleteService.current.getPlace();
            selectSuggestion(place);
          }
        }}
      >
        <FormInput
          name={name}
          control={control}
          rules={rules}
          label={label}
          required={required}
          type="text"
          disabled={disabled}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
        />
      </StyledAutocomplete>
    </LoadScript>
  );
};

AddressAutocomplete.defaultProps = {
  disabled: false
};
