/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useAdminSettingsGet,
  useAdminTeamGet,
  useAdminTrainingProgramGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import ShareWithIcon from "../../../../src/assets/icons/shareWithIcon.svg";
import VersusIcon from "../../../../src/assets/icons/versusIcon.svg";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { FormControl } from "@mui/base";
import { capitalize } from "@utils/capitalize";
import { hasPermission } from "@services/Casbin";
const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px 0px 0px 14px"
  }
}));
export const LiveStreamShareWith = (props: {
  form: UseFormReturn<any>;
  required: boolean | undefined;
  disabled?: boolean;
  action?: string;
  selectedContest?: string;
  setOpposingTeamName?: any;
  setHomeTeamName?: any;
  setConnections?: any;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [shareWithPublicEnabled, setShareWithPublicEnabled] = useState(false);
  const { data: setting } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "org-live-stream"
  });

  useEffect(() => {
    if (
      //@ts-ignore
      setting?.data.filter(
        (s) => s.settingId === "org-live-stream.allow-public-sharing"
      )[0].organizationSettings?.length > 0
    ) {
      setShareWithPublicEnabled(
        //@ts-ignore
        setting?.data.filter(
          (s) => s.settingId === "org-live-stream.allow-public-sharing"
        )[0].organizationSettings[0].value
      );
    } else {
      setShareWithPublicEnabled(
        setting?.data.filter(
          (s) => s.settingId === "org-live-stream.allow-public-sharing"
        )[0].default as boolean
      );
    }
  }, [setting]);

  const checkPermissionForTeamProgram = async (teamOrProgram, id) => {
    const res = await hasPermission(
      teamOrProgram,
      id!,
      "organization.post",
      "ON"
    );
    return res;
  };

  const { data: team } = useAdminTeamGet({
    organizationId: organizationId!
  });
  const { data: trainingProgram } = useAdminTrainingProgramGet({
    organizationId: organizationId!
  });
  const shareToOptions = useMemo(() => {
    const sportId = props.form.getValues("sportId");
    if (props.selectedContest !== "TRAINING_SESSION") {
      const teamOptions =
        (team?.data.teams &&
          team?.data?.teams
            .filter(
              async (team) =>
                team.sportId === sportId ||
                (sportId === "" &&
                  (await checkPermissionForTeamProgram("TEAM", team.teamId)))
            )
            .map((team) => ({
              label: team.name!,
              value: team.teamId,
              data: team,
              kind: "Team"
            }))) ||
        [];
      return [...teamOptions];
    } else {
      const trainingProgramOptions =
        (trainingProgram?.data.trainingPrograms &&
          trainingProgram?.data?.trainingPrograms
            .filter(
              async (trainingProgram) =>
                trainingProgram.sportId === sportId ||
                (sportId === "" &&
                  (await checkPermissionForTeamProgram(
                    "TRAINING_PROGRAM",
                    trainingProgram!.programId
                  )))
            )
            .map((trainingProgram) => ({
              label: trainingProgram.name!,
              value: trainingProgram.programId,
              kind: "TrainingProgram",
              data: trainingProgram
            }))) ||
        [];

      return [...trainingProgramOptions];
    }
  }, [
    team,
    trainingProgram,
    props.form.getValues("sportId"),
    props.selectedContest
  ]);

  useEffect(() => {
    if (shareToOptions.length == 1) {
      props.form.setValue("shareTo", shareToOptions[0].value);
      props.setHomeTeamName(shareToOptions[0].label);
      props.setConnections([shareToOptions[0]]);
    }
  }, [shareToOptions]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "18px"
        }}
      >
        <img src={ShareWithIcon} />
        <Controller
          control={props.form.control}
          name={"shareTo"}
          render={({ field }) => (
            <StyledSelect
              className="sport"
              value={field.value}
              data-testid="event-shareTo"
              displayEmpty
              disabled={shareToOptions.length == 1}
              onChange={(e) => {
                props.form.setValue("shareTo", "");
                props.form.setValue("shareWithTeam_Program", "shareWithTeam");
                props.setHomeTeamName(
                  shareToOptions.find(
                    (option) => option.value === e.target.value
                  )?.label
                );
                props.setConnections([
                  shareToOptions.find(
                    (option) => option.value === e.target.value
                  )
                ]);
                props.setOpposingTeamName(props.form.getValues("opponent"));
                field.onChange(e.target.value);
              }}
            >
              <MenuItem value="" selected style={{ display: "none" }}>
                {props.selectedContest === "TRAINING_SESSION"
                  ? "Training Program"
                  : "Team"}
              </MenuItem>
              {shareToOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
          )}
        />
        {(props.selectedContest == "GAME" ||
          props.selectedContest == "SCRIMMAGE") && (
          <>
            <Typography
              style={{
                marginLeft: "14px",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#2B337A"
              }}
            >
              Are you the Home Team?
            </Typography>
            <Controller
              name="isHomeTeam"
              control={props.form.control}
              render={({ field }) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px"
                    }}
                  >
                    <Typography
                      style={{ color: !field.value ? "#64748B" : "#B3B3B3" }}
                    >
                      No
                    </Typography>
                    <Switch
                      checked={field.value}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                    <Typography
                      style={{ color: field.value ? "#64748B" : "#B3B3B3" }}
                    >
                      Yes
                    </Typography>
                  </div>
                </>
              )}
            />
          </>
        )}
        {(props.selectedContest == "GAME" ||
          props.selectedContest == "SCRIMMAGE" ||
          props.selectedContest == "EVENT") && (
          <div
            style={{
              marginLeft: "16px",
              marginTop: "5px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img src={VersusIcon} />
            <Controller
              name="opponent"
              control={props.form.control}
              rules={{ required: "Please enter a value" }}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    data-testid="event-opponent"
                    id="standard-basic"
                    value={field.value}
                    hiddenLabel
                    style={{ marginLeft: "14px" }}
                    variant="standard"
                    placeholder={
                      props.selectedContest == "EVENT"
                        ? "Opposing Organization"
                        : "Opposing Team"
                    }
                    onChange={(e) => {
                      field.onChange(capitalize(e.target.value));
                      props.setOpposingTeamName(capitalize(e.target.value));
                      if (!e.target.value)
                        props.form.setError("opponent", {
                          message: "Opponent is required"
                        });
                      else if (e.target.value.length > 50) {
                        props.form.setError("opponent", {
                          message:
                            "Opposing Team should be less than 50 characters"
                        });
                      } else props.form.clearErrors("opponent");
                    }}
                    onBlur={() => {
                      if (!field.value)
                        props.form.setError("opponent", {
                          message: "Opponent is required"
                        });
                      else props.form.clearErrors("opponent");
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{
                      inputProps: {
                        maxLength: 50
                      }
                    }}
                  />
                </>
              )}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "18px"
        }}
      >
        <img src={ShareWithIcon} style={{ marginRight: "15px" }} />
        <Controller
          name="shareWithTeam_Program"
          control={props.form.control}
          render={({ field }) => (
            <>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={field.value}
                  data-testid="sharewith-radioGroup"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                >
                  {props.form.getValues("shareTo") != "" && (
                    <FormControlLabel
                      control={<Radio />}
                      value="shareWithTeam"
                      label={
                        props.selectedContest === "TRAINING_SESSION"
                          ? "Share with Training Program"
                          : "Share with Team"
                      }
                    />
                  )}
                  <FormControlLabel
                    control={<Radio />}
                    value="isSharedWithOrg"
                    label="Share with Organization"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />
        {shareWithPublicEnabled && (
          <>
            <Controller
              control={props.form.control}
              name="isPublic"
              render={({ field }) => (
                <Checkbox
                  data-testid="checkBox-isPublic"
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  style={{ padding: "0 9px" }}
                />
              )}
            />
            <Typography style={{ color: "#2B337A", fontSize: "16px" }}>
              Make available to public
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

LiveStreamShareWith.defaultProps = {
  required: false,
  disabled: false
};
