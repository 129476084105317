import colors from "./colors";

const { transparent, error, primary } = colors;

const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,

      "& fieldset": {
        border: `1px solid rgb(0, 0, 0, 0.23)`,
        borderRadius: "6px",
        verticalAlign: "middle",
      },

      "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: "Inter var, sans-serif",
        fontSize: "12px",
        fontWeight: 500,
        color: error.main,
      },

      "& .Mui-focused": {
        "& > fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: primary.main,
          borderWidth: "1px",
        },
      },

      "&:hover .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error)": {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: "rgb(0, 0, 0, 0.23)",
          borderWidth: "1px",
        },
      },
    },
  },
};

export default textField;
