import Debug from "debug";
import { AtomEffect } from "recoil";

const debug = Debug("recoil-persist");

export interface PersistStorage {
  setItem(key: string, value: string): void | Promise<void>;
  getItem(key: string): null | string | Promise<string>;
  removeItem(key: string): void | Promise<void>;
}

export interface PersistConfiguration {
  key?: string;
  storage?: PersistStorage;
}

export type StateType<T> = T;
export type PromisibleStateType<T> = StateType<T> | Promise<StateType<T>>;

export interface StateSerializer<T> {
  serialize(state: StateType<T>): string;
  deserialize(stateString: string): StateType<T>;
}

const createJSONStateSerializer = <T>(): StateSerializer<T> => ({
  serialize: (state: StateType<T>): string => JSON.stringify(state),
  deserialize: (stateString: string): StateType<T> => {
    try {
      return JSON.parse(stateString);
    } catch (e) {
      debug(e);
      return undefined as T;
    }
  },
});

const getFullKey = (key: string, itemKey: string): string =>
  `${key}.${itemKey}`;

const createStateManager = <T>(
  config: PersistConfiguration,
  serializer: StateSerializer<T>,
): {
  getState: (itemKey: string) => StateType<T>;
  setState: (itemKey: string, state: StateType<T>) => void;
  removeState: (itemKey: string) => void;
} => {
  const storage = config.storage ?? localStorage;

  const getState = (key: string): StateType<T> => {
    const toParse = storage.getItem(key);
    if (typeof toParse === "string") {
      debug("getState", {
        key,
        toParse,
        result: serializer.deserialize(toParse),
      });
      return serializer.deserialize(toParse);
    }

    debug("getState", { key, toParse, result: null });
    return null as T;
  };

  const setState = (key: string, state: StateType<T>): void => {
    try {
      if (state === null || state === undefined || state === "") {
        return removeState(key);
      }

      const serializedState = serializer.serialize(state);
      debug("setState", { key, serializedState });
      storage.setItem(key, serializedState);
    } catch (e) {
      debug(e);
    }
  };

  const removeState = (key: string): void => {
    try {
      debug("removeState", { key });
      storage.removeItem(key);
    } catch (e) {
      debug(e);
    }
  };

  return {
    getState,
    setState,
    removeState,
  };
};

export const handleStateUpdate = async <T>(
  statePromise: PromisibleStateType<T>,
  nodeKey: string,
  setSelf: (newValue: T) => void,
) => {
  const state = await statePromise;
  debug("handleStateUpdate", { nodeKey, state });
  setSelf(state[nodeKey]);
};

export const updateState = <T>(
  key: string,
  newValue: T,
  isReset: boolean,
  setState: (key: string, state: StateType<T>) => void,
  removeState: (key: string) => void,
) => {
  debug("updateState", { key, newValue, isReset });
  if (isReset) {
    debug("removeState", { key });
    removeState(key);
  } else {
    debug("setState", { key, newValue });
    setState(key, newValue);
  }
};

export const useRecoilPersist = (config: PersistConfiguration = {}) => {
  const serializer = createJSONStateSerializer<unknown>();
  const { getState, setState, removeState } = createStateManager(
    config,
    serializer,
  );

  // Higher-order function to create AtomEffect with a specific type
  return <T>(): AtomEffect<T> => {
    const persistAtom: AtomEffect<T> = ({ onSet, node, setSelf }) => {
      const key = getFullKey(config.key ?? "recoil-persist", node.key);
      debug("persistAtom", { key, node });

      const savedValue = getState(key);
      setSelf(savedValue as T);

      onSet((newValue, _, isReset) =>
        updateState(key, newValue, isReset, setState, removeState),
      );
    };

    return persistAtom;
  };
};
