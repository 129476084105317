import { SearchInput } from "@components/SearchInput";
import { Close } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  // CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { organizationAtom } from "@recoil/auth";
import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import liveStreamIcon from "@assets/icons/liveIconRed.svg";
import { useAdminCalendarGet } from "@sportsgravyengineering/sg-api-react-sdk";
import { CalendarEventView } from "./CalendarEventView";
import { CalendarEventCreateUpdate } from "./CalendarEventCreateUpdate";
interface RecurrenceEvent {
  name: string;
  start: string;
  end: string;
  streamId: string | null;
  recurrenceId: string;
  eventId: string;
}

interface Event {
  id: string;
  date: string;
  month: string;
  year: string;
  day: string;
  events: RecurrenceEvent[];
}

const StyledBoxHeader = styled(Box)({
  height: "168px",
  backgroundColor: "#F3F4F7",
  boxShadow: "0px 1px 3px 0px #00000026",
  padding: "15px 15px 15px 15px"
});
const StyledSearchTitle = styled(Typography)({
  display: "flex",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "26px",
  fontFamily: "Inter",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

export const CalendarSearch = ({
  isSearchOpen,
  toggleSearchOpen,
  reLoadCalendar
}: {
  isSearchOpen: boolean;
  toggleSearchOpen: (isOpen: boolean) => void;
  reLoadCalendar;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [textSearch, setTextSearch] = useState("");
  const [openEventView, setOpenEventView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [eventDialogDetails, setEventDialogDetails] = useState({
    action: "edit",
    id: "",
    recurrenceId: "",
    title: "Edit Event",
    isLiveStream: false,
    startDate: "",
    endDate: ""
  });
  const eventsByStartDate = (searchResults) =>
    searchResults.reduce((acc, event) => {
      const startDate = new Date(event.start);
      const dayOfWeek = new Intl.DateTimeFormat("en-US", {
        weekday: "short"
      }).format(startDate);
      const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
        startDate
      );
      const day = startDate.getDate();
      const year = startDate.getFullYear();

      const startDateKey = `${year}-${month}-${day}`;

      if (!acc[startDateKey]) {
        acc[startDateKey] = {
          date: day,
          year,
          month,
          day: dayOfWeek,
          events: []
        };
      }

      acc[startDateKey].events.push(event);

      return acc;
    }, {});

  const { data: search, isLoading: isSearching } = useAdminCalendarGet({
    organizationId: organizationId!,
    textSearch: textSearch
  });
  const displaySearchResults = useMemo(() => {
    if (search?.data) {
      const recurrencesData: RecurrenceEvent[][] = [].concat(
        //@ts-ignore
        ...search.data.map((s) =>
          s.recurrences && s.recurrences.length > 0
            ? s.recurrences.map((recurrence) => ({
                name: s.name,
                start: recurrence.start,
                end: recurrence.end,
                streamId: recurrence.streamId,
                recurrenceId: recurrence.recurrenceId,
                eventId: s.eventId
              }))
            : []
        )
      );
      //@ts-ignore
      recurrencesData.sort((a, b) => new Date(a.start) - new Date(b.start));
      return Object.values(eventsByStartDate(recurrencesData)).map((event) => (
        <Box
          data-testid={`EventContainer`}
          sx={{
            marginTop: "8px",
            padding: "16px 24px",
            borderBottom: "1px solid #E5E5E5"
          }}
          key={(event as Event).id}
        >
          <Box>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                display: "inline"
              }}
            >
              {(event as Event).date}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                display: "inline"
              }}
            >
              &nbsp;&nbsp;&nbsp;{(event as Event).month}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                display: "inline"
              }}
            >
              &nbsp;{(event as Event).year},
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                display: "inline"
              }}
            >
              &nbsp;{(event as Event).day}
            </Typography>
            <hr style={{ border: "1px solid #E5E5E5" }} />
          </Box>
          {(event as Event).events.map((event) => (
            //@ts-ignore
            <Box key={event.id} sx={{ marginTop: "24px" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    height: "14px",
                    width: "14px",
                    backgroundColor: "#1ABC9C",
                    borderRadius: "2px",
                    marginTop: "6px"
                  }}
                ></div>
                <div
                  style={{ marginLeft: "8px" }}
                  data-testid={`EVENT_item_${event.eventId}`}
                >
                  <StyledSearchTitle
                    onClick={() => {
                      console.log("event", event);
                      setEventDialogDetails({
                        action: "view",
                        id: event.eventId,
                        recurrenceId: event.recurrenceId,
                        title: "View Event",
                        isLiveStream: event.streamId ? true : false,
                        startDate: event.start,
                        endDate: event.end
                      });
                      setOpenEventView(true);
                    }}
                  >
                    {event.streamId && (
                      <img
                        style={{ marginRight: "8px" }}
                        src={liveStreamIcon}
                      />
                    )}
                    {event.name}
                  </StyledSearchTitle>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      color: "#666666"
                    }}
                  >
                    {new Date(event.start).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true
                    })}{" "}
                    -{" "}
                    {new Date(event.end).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true
                    })}
                  </Typography>
                </div>
              </div>
            </Box>
          ))}
        </Box>
      ));
    }
  }, [search?.data]);

  const setViewClose = () => {
    setOpenEventView(false);
    setOpenEdit(false);
  };
  return (
    <Drawer
      data-testid="notification-drawer"
      anchor="right"
      open={isSearchOpen}
      PaperProps={{
        sx: {
          backgroundColor: "#F3F4F7",
          width: "40%"
        }
      }}
      onClose={() => {
        toggleSearchOpen(false);
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F3F4F7"
        }}
      >
        <StyledBoxHeader>
          <Grid
            flexDirection={"row"}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ marginBottom: "12px" }}
          >
            <Grid item xs={9} marginBottom={"5px"}>
              <Typography
                style={{
                  color: "#0F0F0F",
                  fontSize: "32px",
                  fontStyle: "normal",
                  lineHeight: "48px",
                  fontWeight: 300,
                  margin: "12px 0 0 12px"
                }}
              >
                Search
              </Typography>
            </Grid>
            <Grid item xs={1} marginBottom={"5px"}>
              <IconButton
                onClick={() => {
                  toggleSearchOpen(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Box sx={{ margin: "0 12px" }}>
            <SearchInput
              data-testid="SearchInput"
              placeholder="Search"
              required
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </Box>
        </StyledBoxHeader>

        {displaySearchResults}
        {isSearching && (
          <Box marginTop={"10px"} textAlign={"center"}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      {openEventView && (
        <CalendarEventView
          details={eventDialogDetails}
          button={`Save`}
          onClose={setViewClose}
          hasPermissionToEdit={true}
          hasPermissionToDelete={true}
          openEdit={() => {
            setEventDialogDetails({
              action: "edit",
              id: eventDialogDetails.id,
              recurrenceId: eventDialogDetails.recurrenceId,
              title: "Edit Event",
              isLiveStream: eventDialogDetails.isLiveStream,
              startDate: eventDialogDetails.startDate,
              endDate: eventDialogDetails.endDate
            });
            setOpenEventView(false);
            setOpenEdit(true);
          }}
        />
      )}
      {openEdit && (
        <CalendarEventCreateUpdate
          details={eventDialogDetails}
          button={`Save`}
          onClose={setViewClose}
          reLoadCalendar={reLoadCalendar}
        />
      )}
    </Drawer>
  );
};
