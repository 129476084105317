import type { Options } from "@toast-ui/calendar";

export const theme: Options["theme"] = {
  common: {
    border: "1px solid #ddd",
    backgroundColor: "white",
    holiday: { color: "#333" },
    saturday: { color: "#333" },
    dayName: { color: "#333" },
    today: { color: "#009688" },
    gridSelection: {
      backgroundColor: "rgba(19, 93, 230, 0.1)",
      border: "1px solid #135de6"
    }
  },
  month: {
    dayName: {
      borderLeft: "none",
      backgroundColor: "inherit"
    },
    holidayExceptThisMonth: { color: "#bbbbbb" },
    dayExceptThisMonth: { color: "#bbb" },
    moreView: { boxShadow: "none" },
    moreViewTitle: { backgroundColor: "#f4f4f4" },
    weekend: { backgroundColor: "#E2E8F0" }
  },
  week: {
    dayName: {
      borderTop: "none",
      borderBottom: "none",
      borderLeft: "none",
      backgroundColor: "inherit"
    },
    today: {
      color: "#000",
      backgroundColor: "inherit"
    },
    pastDay: { color: "#000" },
    futureDay: { color: "#000" },
    panelResizer: { border: "1px solid #ddd" },
    dayGrid: { borderRight: "1px solid #ddd" },
    dayGridLeft: {
      width: "100px",
      backgroundColor: "",
      borderRight: "1px solid #ddd"
    },
    weekend: { backgroundColor: "#E2E8F0" },
    timeGridLeft: {
      width: "100px",
      backgroundColor: "#fafafa",
      borderRight: "1px solid #ddd"
    },
    timeGridLeftAdditionalTimezone: { backgroundColor: "#fdfdfd" },
    timeGridHourLine: { borderBottom: "1px solid #eee" },
    timeGridHalfHourLine: { borderBottom: "1px dotted #f9f9f9" },
    timeGrid: { borderRight: "1px solid #ddd" },
    nowIndicatorLabel: { color: "#E82C2C", fontSize: "14px", fontWeight: 500 },
    nowIndicatorPast: { border: "1px solid #E9E9E9" },
    nowIndicatorBullet: { backgroundColor: "#E82C2C" },
    nowIndicatorToday: { border: "1px solid #E82C2C" },
    nowIndicatorFuture: { border: "1px solid #E9E9E9" },
    pastTime: { color: "#B3B3B3" },
    futureTime: { color: "#333" },
    gridSelection: { color: "#135de6" }
  }
};
