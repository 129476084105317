import { SearchInput } from "@components/SearchInput";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  FormLabel,
  Grid,
  List,
  ListItemButton,
  ListItemText
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",
  fontWeight: "bold",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledList = styled(List)(() => ({
  "&": {
    border: "1px solid rgba(0, 0, 0, 0.24)",
    borderRadius: "6px",
    overflowY: "scroll"
  }
}));

export const TransferList = (props: {
  name;
  options;
  control;
  rules?;
  label;
  required;
  isLoading;
  value?;
  setValue?;
  setState?;
  state?;
  placeholder?;
  disabled?;
}) => {
  const emptyOption = {
    label: `Choose ${props.label} from the left`,
    value: "empty",
    highlighted: false
  };
  console.log(emptyOption);

  useEffect(() => {
    props.setValue(props.name, props.state, {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [props.state]);

  const [searchValue, setSearchValue] = useState("");
  const updateSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const [highlightedOption, setHighlightedOption] = useState();
  const highlightOption = (option, selected = false) => {
    if (selected) {
      for (const key in props.options) {
        if (
          props.options[key].highlighted &&
          availableOptions.includes(props.options[key])
        ) {
          props.options[key].highlighted = false;
        }
      }
    } else {
      for (const key in props.options) {
        if (
          props.options[key].highlighted &&
          props.state.includes(props.options[key].value)
        ) {
          props.options[key].highlighted = false;
        }
      }
    }
    option.highlighted = !option.highlighted;
    setHighlightedOption(option);
  };

  const availableOptions = useMemo(() => {
    const searchValueLowerCase = searchValue.toLowerCase();
    return props.options.filter((option) => {
      if (searchValue.length > 2) {
        return (
          !props.state.includes(option.value) &&
          option.label.toLowerCase().match(searchValueLowerCase)
        );
      } else {
        return !props.state.includes(option.value);
      }
    });
  }, [props.options, props.state, searchValue, highlightedOption]);

  const selectOption = (field, option, remove = false) => {
    if (!remove) {
      props.setState(() => {
        return [...field.value, option.value];
      });
    } else {
      props.setState(
        field.value.filter((value) => {
          return value !== option.value;
        })
      );
    }
  };

  const selectOptions = () => {
    let newOptions: string[] = [];
    for (const key in props.options) {
      if (props.options[key].highlighted) {
        props.options[key].highlighted = false;
        newOptions = [...newOptions, props.options[key].value];
      }
    }
    props.setState((prevOptions) => {
      return [...prevOptions, ...newOptions];
    });
  };

  const deselectOptions = () => {
    const prevOptions = [...props.options]
      .filter((obj) => {
        const isHighlighted = obj.highlighted;
        obj.highlighted = false;
        return isHighlighted;
      })
      .map(({ value }) => value);
    const difference = props.state.filter(
      (item) => prevOptions.indexOf(item) < 0
    );
    props.setState(difference);
  };

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field }) => (
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={5.5} data-testid="TRANSFER_LIST_SEARCH">
            <StyledFormLabel>{props.label} Available</StyledFormLabel>
            <SearchInput
              sx={{
                borderBottom: 0,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0
              }}
              name="search"
              placeholder={`${props.label} Search`}
              onChange={updateSearchValue}
            />
            <StyledList
              data-testid="TRANSFER_LIST_AVAILABLE_OPTIONS"
              sx={{ height: 250, borderRadius: "0px 0px 6px 6px !important" }}
            >
              {availableOptions.map((option, key) => {
                return (
                  <ListItemButton
                    data-testid={"TRANSFER_LIST_OPTION_" + key}
                    key={key}
                    onClick={() => highlightOption(option)}
                    onDoubleClick={() => selectOption(field, option)}
                    selected={option.highlighted}
                  >
                    <ListItemText>{option.label}</ListItemText>
                  </ListItemButton>
                );
              })}
            </StyledList>
          </Grid>
          <Grid
            container
            item
            xs={1}
            direction="column"
            justifyContent="center"
            alignItems="center"
            data-testid="TRANSFER_LIST_SELECT_BUTTONS"
          >
            <Button
              sx={{ my: 0.5, border: "none !important" }}
              variant="outlined"
              size="small"
              onClick={deselectOptions}
              disabled={
                typeof field.value == "undefined" ||
                (field.value instanceof Array && !field.value.length)
              }
              aria-label={`Remove selected ${props.label}`}
            >
              <ArrowBackIcon fontSize="medium" />
            </Button>
            <Button
              sx={{ my: 0.5, border: "none !important" }}
              variant="outlined"
              size="small"
              onClick={selectOptions}
              disabled={availableOptions.length === 0}
              aria-label={`Add selected ${props.label}`}
            >
              <ArrowForwardIcon fontSize="medium" />
            </Button>
          </Grid>
          <Grid item xs={5.5}>
            <StyledFormLabel required={props.required || false}>
              {props.label} Selected
            </StyledFormLabel>
            <StyledList
              sx={{ height: 300 }}
              data-testid="TRANSFER_LIST_SELECTED_OPTIONS"
            >
              {props.options
                .filter((item) => {
                  return field?.value?.includes(item.value);
                })
                .map((option, key) => {
                  return (
                    <ListItemButton
                      key={key}
                      data-testid={"TRANSFER_LIST_SELECTED_OPTION_" + key}
                      onClick={() => highlightOption(option, true)}
                      onDoubleClick={() => selectOption(field, option, true)}
                      selected={option.highlighted}
                    >
                      <ListItemText>{option.label}</ListItemText>
                    </ListItemButton>
                  );
                })}
            </StyledList>
          </Grid>
        </Grid>
      )}
    />
  );
};

TransferList.defaultProps = {
  disabled: false
};
