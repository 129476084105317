import { Avatar, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ModelPerson } from "@sportsgravyengineering/sg-api-react-sdk";
import { AppStoreUrl, PlayStoreUrl } from "@utils/constants";

const StyledContainer = styled(Box)`
  position: fixed;
  bottom: 10px;
  width: 100vw;
  padding: 15px;
  display: flex;
  justify-content: center;
  touch-action: none;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  height: 52px;
  justify-content: space-between;
  max-width: 300px;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  max-width: 150px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  padding: 4px 12px !important;
  text-transform: none;
  color: #fff;
  background-color: #2b337a;
  border-radius: 6px;
  font-weight: 500;
  height: 28px;
  width: 100px;
`;

export const SharedByPopup = ({
  user,
  mobileUrl
}: {
  user: ModelPerson;
  mobileUrl: string;
}) => {
  const appStoreFallback = () => {
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.location.href =
        PlayStoreUrl +
        `&referrer=utm_source%3Dgoogle%26utm_medium%3Dorganic%26utm_term%3Ddeep_link%253D${mobileUrl}`;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      if (window.confirm(`Open in "App Store"?`)) {
        window.location.href = AppStoreUrl;
      }
    }
  };
  const handleClick = () => {
    appStoreFallback();
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {user?.avatarId ? (
            <Avatar
              sx={{ width: 28, height: 28 }}
              src={
                user.avatar?.baseUrl && user.avatar.path
                  ? user.avatar?.baseUrl + user.avatar?.path
                  : ""
              }
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: "#" + user?.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center",
                width: 28,
                height: 28
              }}
              variant="square"
            >
              {`${user?.firstName} ${user?.lastName}`
                .split(" ")
                .map((name) => name[0])
                .join("")
                .toUpperCase()}
            </Avatar>
          )}
          <StyledTypography>{`${user?.firstName} ${user?.lastName} is on SportsGravy`}</StyledTypography>
        </Box>
        <StyledButton variant="contained" onClick={handleClick}>
          Open App
        </StyledButton>
      </StyledBox>
    </StyledContainer>
  );
};
