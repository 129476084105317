/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Container } from "./crud/Container";
import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Typography
} from "@mui/material";
import MoreVerIcon from "@mui/icons-material/MoreVert";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { VisibilityOff } from "@mui/icons-material";
import { getPostComment, removePost } from "@services/Network";
import { SkeletonFeedCommentLoader } from "./SkeletonFeedCommentLoader";
import FeedIndividualMedia from "./FeedIndividualMedia";
import { PostCommentAction } from "./PostCommentAction";
import { FeedMediaViewer } from "./FeedMediaViewer";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "../recoil/auth";
import {
  ModelPostComment,
  PostPostIdCommentGetSortOrder
} from "@sportsgravyengineering/sg-api-react-sdk";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const FeedComment = (props: {
  commentCount;
  formatBody;
  formatDate;
  comment;
  postId;
}) => {
  const [commentSort, setCommentSort] =
    useState<PostPostIdCommentGetSortOrder>("recent");
  const [onDelete, setOnDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [comments, setComments] = useState<ModelPostComment[]>([]);
  const [deleteExplanation, setDeleteExplanation] = useState({ message: "" });
  const [deleteCommentDetails, setdeleteCommentDetails] = useState({
    id: "",
    author: ""
  });
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const StyledCommentSortGrid = styled(Grid)(() => ({
    borderRadius: "4px",
    width: "205px",
    padding: "7px 10px 7px 10px",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#EBEBEB"
    }
  }));
  const mutation = removePost();
  const deleteComment = (commentId) => {
    mutation.mutate(
      {
        data: {
          ...(organizationId && { organizationId: organizationId }),
          postId: props.postId,
          commentId: commentId,
          explanation: deleteExplanation.message,
          action: "REMOVE"
        }
      },
      {
        onSuccess: () => {
          setComments(
            comments.filter((comment) => comment.commentId !== commentId)
          );
        }
      }
    );
  };
  const [showCommentsCount, setshowCommentsCount] = useState(
    props.commentCount
  );
  const [AnchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [AnchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
  };
  const handleDeleteClick = () => {
    setAnchorEl(null);
    setAnchorEl1(null);
    setOnDelete(true);
  };
  const handleDeleteConfirmation = () => {
    setOnDelete(false);
    setDeleteConfirmation(true);
  };
  const handleDeleteClose = () => {
    setOnDelete(false);
  };

  const handlePopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    id,
    author
  ) => {
    setAnchorEl(event.currentTarget);
    setdeleteCommentDetails({ id: id, author: author });
  };
  window.addEventListener("scroll", handleClose);
  const open = Boolean(AnchorEl);
  const id = open ? "simple-popover" : undefined;
  const commentSortOpen = Boolean(AnchorEl1);
  const commentSortId = commentSortOpen ? "simple-popover" : undefined;
  const handleCommentSort = (sort) => {
    setCommentSort(sort);
    handleClose();
  };
  const handleCommentSortPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl1(event.currentTarget);
  };
  useEffect(() => {
    if (onDelete || deleteConfirmation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [onDelete, deleteConfirmation]);
  const loadMoreComments = (id) => {
    setshowCommentsCount((prevshowCommentsCount) => ({
      ...prevshowCommentsCount,
      [id]: (prevshowCommentsCount[id] || 0) + 3
    }));
  };

  const postCommentGet = getPostComment(
    props.postId,
    {
      sortOrder: commentSort
    },
    {}
  );
  useEffect(() => {
    if (
      postCommentGet.isSuccess &&
      postCommentGet.data.length > 0 &&
      !postCommentGet.isLoading
    ) {
      setComments(postCommentGet.data);
    }
  }, [postCommentGet.data]);
  const [mediaViewer, setMediaViewer] = useState(Boolean);
  const [currentIndex, setCurrentIndex] = useState();
  const [media, setMedia] = useState(Boolean);
  const toggleMediaViewer = (media, index) => {
    setMediaViewer(true);
    setCurrentIndex(index);
    setMedia(media);
  };
  const closeMediaViewer = () => {
    setMediaViewer(false);
  };
  return (
    <>
      <Container style={{ marginTop: "11px" }} data-testid="post-comment-title">
        <Divider />
        <Grid
          sx={{ marginTop: "11px" }}
          container
          justifyContent="space-between"
        >
          <Grid>
            <Typography sx={{ fontSize: "12px" }}>Comments</Typography>
          </Grid>
          <Grid
            data-testid="comment-sort"
            onClick={handleCommentSortPopover}
            container
            sx={{ alignItems: "center", cursor: "pointer" }}
          >
            <Typography sx={{ fontSize: "10px", color: "#838383" }}>
              Most {commentSort}
            </Typography>
            <UnfoldMoreIcon
              sx={{ color: "#838383", width: "14px", height: "14px" }}
            />
          </Grid>
        </Grid>
      </Container>

      <Container data-testid="post-comment-body" style={{ marginTop: "13px" }}>
        <div>
          {comments.reduce((rendered, comment) => {
            if (rendered.length == showCommentsCount[props.postId]) {
              return rendered;
            }
            if (rendered.length < showCommentsCount[props.postId]) {
              const commentElement = (
                <Box
                  key={comment.commentId}
                  sx={{
                    borderRadius: "3px",
                    bgcolor: "#D9D9D94D",
                    padding: "12px",
                    marginBottom: "12px"
                  }}
                >
                  <Grid container alignItems="center">
                    <Grid>
                      {comment.user?.person?.avatarId ? (
                        <Avatar
                          sx={{ width: "36px", height: "36px" }}
                          variant="square"
                          src={
                            comment?.user?.person?.avatar?.baseUrl &&
                            comment?.user?.person?.avatar?.path
                              ? comment.user.person.avatar.baseUrl +
                                comment.user.person.avatar.path
                              : undefined
                          }
                        />
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor: "#" + comment.user?.person?.colorCode,
                            color: "#fff"
                          }}
                          variant="square"
                        >
                          {`${comment.user?.person?.firstName} ${comment.user?.person?.lastName}`
                            .split(" ")
                            .map((name) => name[0])
                            .join("")
                            .toUpperCase()}
                        </Avatar>
                      )}
                    </Grid>
                    <Grid marginLeft={"11px"} xs container direction="column">
                      <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                        {`${comment.user?.person?.firstName} ${comment.user?.person?.middleName} ${comment.user?.person?.lastName}`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "10px",
                          color: "#666",
                          fontWeight: 400
                        }}
                      >
                        {props.formatDate(comment?.createdAt)}
                      </Typography>
                    </Grid>
                    <IconButton
                      onClick={(event) =>
                        handlePopover(
                          event,
                          comment.commentId,
                          `${comment.user?.person?.firstName} ${comment.user?.person?.middleName} ${comment.user?.person?.lastName}`
                        )
                      }
                    >
                      <MoreVerIcon />
                    </IconButton>
                    <Container style={{ background: "none" }}>
                      <Typography
                        style={{
                          width: "100%",
                          marginTop: "7px",
                          fontSize: "12px",
                          fontWeight: 400
                        }}
                      >
                        {props.formatBody(
                          comment?.comment,
                          comment?.mentions,
                          []
                        )}
                      </Typography>
                      <Box sx={{ width: "50%" }}>
                        {comment.media && comment?.media?.length > 0 && (
                          <FeedIndividualMedia
                            onClick={() => {
                              toggleMediaViewer(
                                comment?.media,
                                comment?.media?.[0].mediaId
                              );
                            }}
                            src={
                              //@ts-ignore
                              comment?.media?.[0]?.baseUrl +
                              comment?.media?.[0]?.path
                            }
                            style={{
                              width: "100%",
                              height: "200px",
                              marginTop: "7px",
                              fontSize: "12px",
                              fontWeight: 400,
                              cursor: "pointer"
                            }}
                          />
                        )}
                      </Box>
                    </Container>
                  </Grid>
                </Box>
              );
              //@ts-ignore
              rendered.push(commentElement);
            }

            if (
              comment.replies &&
              comment.replies.length > 0 &&
              rendered.length < showCommentsCount[props.postId]
            ) {
              comment.replies.forEach((reply) => {
                if (rendered.length < showCommentsCount[props.postId]) {
                  const replyElement = (
                    <Box
                      key={reply.commentId}
                      sx={{
                        marginLeft: "70px",
                        borderRadius: "3px",
                        bgcolor: "#D9D9D94D",
                        padding: "12px",
                        marginBottom: "12px"
                      }}
                    >
                      <Grid container alignItems="center">
                        <Grid>
                          {reply.user?.person?.avatarId ? (
                            <Avatar
                              sx={{ width: "36px", height: "36px" }}
                              variant="square"
                              src={
                                //@ts-ignore
                                reply.user?.person?.avatar?.baseUrl +
                                reply.user?.person?.avatar?.path
                              }
                            />
                          ) : (
                            <Avatar
                              sx={{
                                bgcolor: "#" + reply.user?.person?.colorCode,
                                color: "#fff"
                              }}
                              variant="square"
                            >
                              {`${reply.user?.person?.firstName} ${reply.user?.person?.lastName}`
                                .split(" ")
                                .map((name) => name[0])
                                .join("")
                                .toUpperCase()}
                            </Avatar>
                          )}
                        </Grid>
                        <Grid
                          marginLeft={"11px"}
                          xs
                          container
                          direction="column"
                        >
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: 400 }}
                          >
                            {`${reply.user?.person?.firstName} ${reply.user?.person?.middleName} ${reply.user?.person?.lastName}`}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              color: "#666",
                              fontWeight: 400
                            }}
                          >
                            {props.formatDate(reply?.createdAt)}
                          </Typography>
                        </Grid>
                        <IconButton
                          onClick={(event) =>
                            handlePopover(
                              event,
                              reply.commentId,
                              `${reply.user?.person?.firstName} ${reply.user?.person?.middleName} ${reply.user?.person?.lastName}`
                            )
                          }
                        >
                          <MoreVerIcon />
                        </IconButton>
                        <Container style={{ background: "none" }}>
                          <Typography
                            style={{
                              width: "100%",
                              marginTop: "7px",
                              fontSize: "12px",
                              fontWeight: 400
                            }}
                          >
                            {props.formatBody(
                              reply?.comment,
                              reply?.mentions,
                              []
                            )}
                          </Typography>
                        </Container>
                      </Grid>
                    </Box>
                  );
                  //@ts-ignore
                  rendered.push(replyElement);
                }
              });
            }

            return rendered;
          }, [])}
        </div>
        {comments.reduce(
          (acc, comment) => acc + 1 + (comment?.replies?.length || 0),
          0
        ) > (showCommentsCount[props.postId] || 3) && (
          <Typography
            data-testid="load-more-comments"
            style={{
              fontWeight: 300,
              fontSize: "12px",
              cursor: "pointer"
            }}
            onClick={() => loadMoreComments(props.postId)}
          >
            Load More Comments
          </Typography>
        )}
        <Popover
          style={{ marginLeft: "25px" }}
          id={id}
          open={open}
          anchorEl={AnchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Container>
            <Grid
              sx={{
                borderRadius: "4px",
                padding: "12px",
                alignItems: "center",
                ":hover": {
                  backgroundColor: "#EBEBEB"
                }
              }}
              container
            >
              <Grid>
                <VisibilityOff />
              </Grid>

              <Grid
                style={{ cursor: "pointer" }}
                onClick={handleDeleteClick}
                marginLeft={"11px"}
                xs
                container
                direction="column"
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  Remove comment from{" "}
                  {!organizationId ? "from SportsGravy" : "post"}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  Control this comment's visibility within{" "}
                  {!organizationId ? "SportsGravy" : "your organization "}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Popover>
        <Popover
          data-testid="comment-sort-popover"
          style={{ marginLeft: "70px" }}
          id={commentSortId}
          open={commentSortOpen}
          anchorEl={AnchorEl1}
          disableScrollLock={true}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Container>
            <StyledCommentSortGrid
              container
              onClick={() => {
                handleCommentSort("relevant");
              }}
            >
              <Grid>
                <RocketLaunchIcon fontSize="small" sx={{ color: "#B3B3B3" }} />
              </Grid>

              <Grid marginLeft={"11px"} xs container direction="column">
                <Typography
                  sx={{ fontSize: "11px", fontWeight: 600, color: "#5E5E5E" }}
                >
                  Most relevant{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "10px", fontWeight: 400, color: "#707070" }}
                >
                  See the most relevant comments
                </Typography>
              </Grid>
            </StyledCommentSortGrid>
            <StyledCommentSortGrid
              container
              onClick={() => {
                handleCommentSort("recent");
              }}
            >
              <Grid>
                <AccessTimeIcon fontSize="small" sx={{ color: "#B3B3B3" }} />
              </Grid>

              <Grid marginLeft={"11px"} xs container direction="column">
                <Typography
                  sx={{ fontSize: "11px", fontWeight: 600, color: "#5E5E5E" }}
                >
                  Most recent{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: "10px", fontWeight: 400, color: "#707070" }}
                >
                  See the most recent comments
                </Typography>
              </Grid>
            </StyledCommentSortGrid>
          </Container>
        </Popover>
      </Container>
      {postCommentGet.isLoading && (
        <SkeletonFeedCommentLoader type={"comment"} />
      )}
      {onDelete && (
        <PostCommentAction
          onClick={handleDeleteConfirmation}
          setMessage={setDeleteExplanation}
          id={deleteCommentDetails.id}
          displayMessage={
            !organizationId
              ? "Removing this comment will delete it from this post in SportsGravy. Please provide an explanation to the author of this comment."
              : "Removing this comment will delete it from the feed of users within your organization who view this post. Please provide an explanation to the commenter."
          }
          action={"delete"}
          button={"Remove Comment"}
          onClose={handleDeleteClose}
          title={`Remove Comment ${
            !organizationId ? "from SportsGravy" : "within Organization"
          }`}
          field={[
            {
              field: "Comment Author",
              value: deleteCommentDetails.author
            },
            {
              field: "Organization Removed From",
              value: `${
                !organizationId
                  ? "All SportsGravy Users"
                  : organizations.find(
                      (org) => org.organizationId === organizationId
                    )?.name
              }`
            }
          ]}
        />
      )}
      <ConfirmationDialog
        open={deleteConfirmation}
        title="Delete Comment"
        body={`Are you sure you want to delete this comment ${
          !organizationId ? "from SportsGravy" : "within your organization"
        }? This change cannot be undone.`}
        close={() => setDeleteConfirmation(false)}
        onConfirm={() => {
          setDeleteConfirmation(false);
          deleteComment(deleteCommentDetails.id);
        }}
        onCancel={() => setDeleteConfirmation(false)}
        confirmBtnVariant="admin-error"
        icon="error"
      />
      {mediaViewer && (
        <FeedMediaViewer
          close={closeMediaViewer}
          mediaFiles={media}
          currentIndex={currentIndex}
        />
      )}
      {mutation.isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={mutation.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
