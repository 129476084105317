import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";
import { InfoListCard } from "@components/InfoListCard";
import { LinkButton } from "@components/LinkButton";
import { LoadingSpinner } from "@components/LoadingSpinner";
import { NotificationCard } from "@components/NotificationCard";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { basicFormInputAtom, pendingAccountAtom } from "@recoil/signup";
import { resendPersonCode, verifyPersonCode } from "@services/Network";
import { PERSON_STATUS } from "@utils/constants";
import { formatPhone } from "@utils/phoneFormatters";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const PendingAccountWarning = () => {
  const navigate = useNavigate();
  const basicFormInput = useRecoilValue(basicFormInputAtom);
  const pendingAccount = useRecoilValue(pendingAccountAtom);
  const [attempts, setAttempts] = useState<number>(0);
  const [hasResent, setHasResent] = useState<boolean>(false);

  const codeType = useMemo(() => {
    return pendingAccount?.status === PERSON_STATUS.SIGNUP_BASIC ||
      pendingAccount?.status === PERSON_STATUS.NEW
      ? "EMAIL"
      : "PHONE";
  }, [pendingAccount]);
  const codeTypeCopy = useMemo(() => {
    return codeType === "EMAIL" ? "email" : "mobile phone";
  }, [codeType]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    trigger
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      code: ""
    }
  });

  const formatAccountToInfoList = (account) => {
    return [
      {
        label: "Person Record",
        value: `${account?.firstName} ${account?.lastName}`
      },
      { label: "Email Handle", value: account?.email },
      {
        label: "Mobile Phone",
        value: formatPhone(account?.phone) || "N/A"
      },
      { label: "Status", value: "Pending" }
    ];
  };

  const resendMutation = resendPersonCode();

  const resendCode = () => {
    resendMutation.mutate({
      data: { type: codeType, email: basicFormInput?.email as string }
    });
  };

  const verifyMutation = verifyPersonCode();

  const submitForm = async (formValues) => {
    verifyMutation.mutate(
      {
        data: {
          type: codeType,
          email: basicFormInput?.email as string,
          code: formValues.code
        }
      },
      {
        onSuccess: () => {
          if (
            pendingAccount?.status === PERSON_STATUS.SIGNUP_BASIC ||
            pendingAccount?.status === PERSON_STATUS.NEW
          ) {
            navigate("/sign-up/personal-info", { replace: true });
          } else {
            navigate("/sign-up/account-info", { replace: true });
          }
        },

        onError: () => {
          setAttempts(attempts + 1);
        }
      }
    );
  };

  useEffect(() => {
    if (verifyMutation.isError) {
      trigger("code");
      verifyMutation.reset();
    }
  }, [verifyMutation.isError]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={3} justifyContent={"space-evenly"}>
        <Grid xs={12}>
          <Typography
            variant="h2"
            color="text.general.primary"
            sx={{ fontWeight: 400 }}
          >
            Pending Account Warning
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1" color="text.general.secondary">
            {`You have a pending user account already associated with this email.
              To verify your identity and continue setting up your pending user
              account please retrieve the 6 digit verification code sent to your
              ${codeTypeCopy} and enter it below.`}
          </Typography>
        </Grid>
        {attempts >= 3 && (
          <Grid xs={12}>
            <NotificationCard variant="error">
              {`You have entered the incorrect code too many times, please contact the SportsGravy Support Team`}
            </NotificationCard>
          </Grid>
        )}
        <Grid xs={12}>
          <>
            <Typography variant="h4">Existing Account Details</Typography>
            <InfoListCard infoList={formatAccountToInfoList(pendingAccount)} />
          </>
        </Grid>
        <Grid xs={7} md={9}>
          <FormInput
            type="text"
            required
            name="code"
            control={control}
            label={`${codeType === "EMAIL" ? "Email" : "Mobile Phone"} code`}
            rules={{
              required: `${
                codeType === "EMAIL" ? "Email" : "Mobile Phone"
              } code is required`,
              validate: {
                isVerifyError: () => !verifyMutation.isError || "Invalid Code"
              }
            }}
          />
        </Grid>
        <Grid xs={5} md={3} alignSelf={"center"} sx={{ paddingTop: "2.5rem" }}>
          {resendMutation.isLoading ? (
            <LoadingSpinner />
          ) : !hasResent ? (
            <LinkButton
              href="#"
              onClick={() => {
                resendCode();
                setHasResent(true);
              }}
            >
              Resend Code
            </LinkButton>
          ) : null}
        </Grid>
        <Grid xs={12}>
          <Button
            variant="primary"
            disabled={!isValid || verifyMutation.isLoading || attempts >= 3}
            type="submit"
            isLoading={verifyMutation.isLoading}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
