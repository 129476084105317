import { Box } from "@mui/material";
import styled from "styled-components";

export const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem;
  border-top: 1px solid #e2e8f0;
`;
