/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, styled, Divider, Typography, Box } from "@mui/material";
import { Loader } from "@components/crud/Loader";
import {
  Column,
  ColumnGrouping,
  DataGridTable
} from "@components/DataGridTable";
import { TimeFilter } from "@pages/dashboard/components/TimeFilter";
import React, { useMemo, useState } from "react";
import InfoIcon from "@assets/icons/info.svg";
import {
  adminMetricPneSprintCfGet,
  ModelSprintSfUserData
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQuery } from "@tanstack/react-query";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import { ToolTip } from "@components/ToolTip";

const StyledBoxValue = styled(Typography)`
  color: #1e293b;
  font-weight: 600;
  font-size: 2vw;
  line-height: 48px;

  @media (min-width: 1300px) {
    font-size: 32px;
  }
`;

const StyledFlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
`;

const StyledHeader = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
  color: #1e293b;
`;

const StyledGrid = styled(Grid)`
  .pinned-header:nth-child(1),
  .pinned-body:nth-child(1) {
    border-right: 2px solid #000 !important;
  }
`;

const dateFormat = (iosDate: string): string => {
  const date = new Date(iosDate);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${month} ${day}, ${year}'`;
};

export const SprintCarryForwardTrend = () => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<[any, any]>([
    null,
    null
  ]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [sprintCount, setSprintCount] = useState<string>("5");

  const query = useMemo(() => {
    const buildQuery = {} as {
      sprintCount: string;
    };
    buildQuery.sprintCount = sprintCount;
    return buildQuery;
  }, [sprintCount]);

  const { data: scf, isLoading: isLoading } = useQuery(
    ["adminMetricPneFeatureCostOverview", sprintCount],
    () => adminMetricPneSprintCfGet(query),
    {
      staleTime: 1000 * 60 * 10 * 60,
      cacheTime: 1000 * 60 * 10 * 60,
      refetchOnWindowFocus: true
    }
  );

  const StyledToolTip = ({
    title,
    children,
    position
  }: {
    title: string | React.ReactNode;
    children: React.ReactElement;
    position?: "top" | "bottom" | "left" | "right";
  }) => {
    return (
      <ToolTip
        arrow={false}
        title={title}
        placement={position || "top"}
        componentsProps={{
          tooltip: {
            style: {
              maxHeight: "300px",
              overflow: "auto"
            }
          }
        }}
      >
        {children}
      </ToolTip>
    );
  };

  const generateColumns = (data) => {
    const dynamicColumns: any = [];
    if (data && data?.userData) {
      data.userData.forEach((row) => {
        row.userSprintData.map((sprint, index) => {
          const existingColumnIndex = dynamicColumns.findIndex(
            (column) =>
              column.field === `sprint_${sprint.sprintId}_planned` ||
              column.field === `sprint_${sprint.sprintId}_completed` ||
              column.field === `sprint_${sprint.sprintId}_carryForward`
          );
          if (existingColumnIndex === -1) {
            dynamicColumns.push({
              field: `sprint_${sprint.sprintId}_planned`,
              headerName: "Planned",
              width: 60,
              renderHeader: () => {
                return (
                  <StyledFlexBox>
                    <StyledHeader>Planned</StyledHeader>
                    <StyledHeader>Original</StyledHeader>
                  </StyledFlexBox>
                );
              },
              align: "center",
              borderLeft: "1px solid #B3B3B3",
              renderCell: (params) => {
                const { plannedCards, plannedHours } =
                  params.userSprintData[index];
                return (
                  <StyledFlexBox>
                    <Typography>{plannedCards}</Typography>
                    <Typography>{plannedHours.toFixed(2)}</Typography>
                  </StyledFlexBox>
                );
              }
            });
            dynamicColumns.push({
              field: `sprint_${sprint.sprintId}_completed`,
              headerName: "Completed",
              width: 60,
              align: "center",
              renderHeader: () => {
                return (
                  <StyledFlexBox>
                    <StyledHeader>Completed</StyledHeader>
                    <StyledHeader>Actual</StyledHeader>
                  </StyledFlexBox>
                );
              },

              renderCell: (params) => {
                const { cardsCompletedInSprint, actualHours } =
                  params.userSprintData[index];
                return (
                  <StyledFlexBox>
                    <Typography>{cardsCompletedInSprint}</Typography>
                    <Typography>{actualHours.toFixed(2)}</Typography>
                  </StyledFlexBox>
                );
              }
            });
            dynamicColumns.push({
              field: `sprint_${sprint.sprintId}_carryForward`,
              headerName: "C/F",
              width: 50,
              align: "center",
              renderHeader: () => {
                return (
                  <StyledFlexBox>
                    <StyledHeader>C/F</StyledHeader>
                    <StyledHeader>C/F</StyledHeader>
                  </StyledFlexBox>
                );
              },

              renderCell: (params) => {
                const { carryOverHours, carryOver } =
                  params.userSprintData[index];
                return (
                  <StyledFlexBox>
                    <StyledFlexBox
                      style={{
                        flexDirection: "row",
                        gap: 4,
                        alignItems: "center",
                        marginLeft: carryOver.length > 0 ? "15px" : ""
                      }}
                    >
                      <Typography
                        style={{
                          color: carryOver.length > 0 ? "#E82C2C" : "#000000",
                          fontWeight: carryOver.length > 0 ? 600 : 500
                        }}
                      >
                        {carryOver.length}
                      </Typography>
                      <div style={{ height: "15px" }}>
                        {carryOver.length > 0 ? (
                          <StyledToolTip
                            title={
                              <>
                                {carryOver.map((pcs, index) => (
                                  <div key={index}>{pcs.key}</div>
                                ))}
                              </>
                            }
                          >
                            <img height="13px" src={InfoIcon} />
                          </StyledToolTip>
                        ) : null}
                      </div>
                    </StyledFlexBox>

                    <StyledFlexBox
                      style={{
                        flexDirection: "row",
                        gap: 4,
                        alignItems: "center",
                        marginLeft: carryOverHours > 0 ? "15px" : ""
                      }}
                    >
                      <Typography
                        style={{
                          color: carryOverHours > 0 ? "#E82C2C" : "#000000",
                          fontWeight: carryOverHours > 0 ? 600 : 500
                        }}
                      >
                        {carryOverHours.toFixed(2)}
                      </Typography>
                    </StyledFlexBox>
                  </StyledFlexBox>
                );
              }
            });
          }
        });
      });
    }
    return dynamicColumns;
  };

  const COLUMNS: Column[] = [
    {
      field: "resource",
      headerName: "Resource",
      width: 200,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        if (
          !params.user?.person?.firstName ||
          !params.user?.person?.lastName ||
          !params.user
        )
          return <Typography>-</Typography>;
        return (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#000"
            }}
          >
            {params.user?.person?.firstName} {params.user?.person?.lastName}
          </Typography>
        );
      }
    },
    ...generateColumns(scf?.data)
  ];

  const generateColumnGrouping = () => {
    const columnGrouping: ColumnGrouping[] = [];
    if (scf?.data.sprints) {
      const resourceColumn: ColumnGrouping = {
        label: "Resource",
        pinned: true,
        width: 250,
        align: "left",
        columns: []
      };
      columnGrouping.push(resourceColumn);
      (scf?.data.sprints as Array<any>).forEach((sprint) => {
        const existingColumnIndex = columnGrouping.findIndex(
          (column) => column.label === sprint.sprint
        );
        if (existingColumnIndex == -1) {
          const sprintColumn: ColumnGrouping = {
            label: sprint.name,
            columns: [
              `sprint_${sprint.sprintId}_planned`,
              `sprint_${sprint.sprintId}_completed`,
              `sprint_${sprint.sprintId}_carryForward`
            ],
            border: "1px solid #B3B3B3"
          };
          columnGrouping.push(sprintColumn);
        }
      });
    }
    return columnGrouping;
  };

  const COLUMN_GROUPING = generateColumnGrouping();

  return (
    <StyledGrid
      container
      direction="column"
      padding="0px"
      style={{
        border: "1px solid rgba(0, 0, 0, 0.23)",
        boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        marginTop: "1px",
        width: "calc(100vw - 340px)",
        minHeight: isLoading || !scf || !scf?.data ? "400px" : "0px"
      }}
    >
      <Grid
        item
        padding="16px 24px 16px 24px"
        container
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Grid item container direction="column" spacing="2px" xs={6}>
          <Grid item>
            <Typography
              style={{ color: "#1E293B", fontWeight: 700, fontSize: "16px" }}
            >
              Sprint Carry Forward Trend
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px"
              }}
            >
              Quick Insights related to SportGravy
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignSelf="center">
          <TimeFilter
            selectedTimeRange={selectedTimeRange}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            setSelectedTimeRange={setSelectedTimeRange}
            isQaMetric={false}
            defaultTimeFilter="5"
            setSelectedTimeFilter={setSprintCount}
            customTimeFilterOptions={[
              { label: "Last 5 Sprints", value: "5" },
              { label: "Last 10 Sprints", value: "10" }
            ]}
          />
        </Grid>
      </Grid>

      <Grid item marginTop="-5px">
        <Divider />
      </Grid>
      <Loader isLoading={isLoading}>
        {(!scf || !scf?.data) && !isLoading ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto"
              }}
            >
              <img src={NoDataIcon} style={{ width: "64px" }} />
              <Typography
                style={{
                  color: "#64748b",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "16px 24px"
                }}
              >
                No data available
              </Typography>
            </div>
          </>
        ) : (
          <Grid container direction="row" item>
            {scf &&
              scf.data.insights &&
              (
                scf.data.insights as Array<{
                  title: string;
                  value: string;
                  type?: string;
                }>
              ).map((con, index) => {
                return (
                  <Grid
                    item
                    container
                    direction="column"
                    key={con.title}
                    xs={index < 4 ? 3 : 2}
                    minHeight="121px"
                    padding="24px"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      border: "1px solid rgba(229, 229, 229, 1)"
                    }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          color: "#64748B",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "21px"
                        }}
                      >
                        {con.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {(con.type === "text" || !con.type) && (
                        <StyledBoxValue>{con.value}</StyledBoxValue>
                      )}
                      {con.type === "date" && (
                        <StyledBoxValue>{dateFormat(con.value)}</StyledBoxValue>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}

        {scf &&
        scf?.data &&
        scf?.data.userData &&
        (scf?.data.userData as Array<ModelSprintSfUserData>).length > 0 ? (
          <DataGridTable
            rows={scf?.data.userData as Array<any>}
            columns={COLUMNS}
            columnGrouping={COLUMN_GROUPING}
            pinnedColumns={["resource"]}
          />
        ) : (
          <></>
        )}
      </Loader>
    </StyledGrid>
  );
};
