import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid-pro";
import { getRoles } from "@services/Network";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { organizationAtom } from "../../recoil/auth";
import { ModelRole } from "@sportsgravyengineering/sg-api-react-sdk";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";

export const Roles = () => {
  const [refreshKey] = React.useState(0);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState({
    edit: false
  });
  const [permissionLoading, setPermissionLoading] = useState(true);

  const [COLUMNS, setColumns] = React.useState<GridColDef[]>([
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    {
      headerName: "Display Text",
      field: "displayText",
      minWidth: 110,
      flex: 1,
      valueGetter: ({ row }) => row.display_text || "-"
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 90,
      flex: 1,
      valueGetter: ({ row }) =>
        row.type[0].toUpperCase() + row.type.slice(1).toLowerCase()
    },
    {
      headerName: "No. of Sub Roles",
      field: "noOfSubRoles",
      minWidth: 135,
      flex: 1,
      valueGetter: ({ row }) =>
        row.isSubclassable ? row?._count?.children : "-"
    },
    {
      headerName: "No. of Users",
      field: "noOfUsers",
      minWidth: 105,
      flex: 1,
      valueGetter: ({ row }) => row?._count?.users || 0
    }
  ]);

  const [organizationId] = useRecoilState(organizationAtom);
  if (organizationId && COLUMNS.length !== 3) {
    setColumns([
      { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
      {
        headerName: "No. of Sub Roles",
        field: "noOfSubRoles",
        minWidth: 135,
        flex: 1,
        valueGetter: ({ row }) =>
          row?.isSubclassable ? row?._count?.children : "-"
      },
      {
        headerName: "No. of Users",
        field: "noOfUsers",
        minWidth: 105,
        flex: 1,
        valueGetter: ({ row }) => row?._count?.users || 0
      }
    ]);
  }

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      try {
        const edit = await checkPermission("user.roles", "EDIT");
        setPermissions({
          edit
        });
      } catch {
        setPermissionLoading(false);
      } finally {
        setPermissionLoading(false);
      }
    };
    fetchPermissions();
  }, [organizationId]);

  const onEdit = (role) => {
    navigate(`/roles/${role.roleId}/edit`);
  };

  const onView = (role) => {
    navigate(`/roles/${role.roleId}`);
  };

  const filterConfig = {
    field: "type",
    placeholderOption: {
      label: "All Roles",
      value: ""
    },
    options: [
      {
        label: "System Roles",
        value: "SYSTEM"
      },
      {
        label: "Organization Roles",
        value: "ORGANIZATION"
      },
      {
        label: "Personal Roles",
        value: "PERSON"
      }
    ]
  };
  if (permissionLoading)
    return (
      <Loader isLoading={permissionLoading}>
        <></>
      </Loader>
    );
  return (
    <Loader isLoading={permissionLoading}>
      <TableView
        title="Roles"
        useGet={getRoles}
        columns={COLUMNS}
        filterConfig={filterConfig}
        hideFilter={!!organizationId}
        hideLabelContainer={!!organizationId}
        isDeleteDisabled={() => true}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onEdit={onEdit}
        isEditDisabled={(row: ModelRole) =>
          row.alias === "ADMIN" || !permissions.edit
        }
        onView={onView}
        refreshKey={refreshKey}
        getRowId={(row) => row.roleId}
      />
    </Loader>
  );
};
