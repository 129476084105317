import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminSkillPost,
  useAdminPositionGet,
  useAdminSkillGet,
  ModelMedia,
  useAdminSportGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MediaSelector } from "@components/MediaSelector";
import GamePickerImage from "@assets/images/gameSkillPicker.png";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import { organizationAtom } from "../../recoil/auth";
import { useRecoilState } from "recoil";
import { uploadMediaUsingPresignedUrl } from "@services/customNetworkCalls";
import { capitalizeEveryWord } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const SkillsCreate = () => {
  const [filestoUpload, setFilesToUpload] = useState<File | ModelMedia | null>(
    null
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [resetMedia, setResetMedia] = useState(false);
  const [sportId, setSportId] = useState<
    {
      sportId: string;
    }[]
  >([]);
  const [organizationId] = useRecoilState(organizationAtom);
  const [mediaUploadLoading, setMediaUploadLoading] = useState(false);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions, isLoading: postionsLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: sportId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });
  const { options: parentSkillsOptions, isLoading: parentSkillsLoading } =
    useApiSelectOptions({
      api: useAdminSkillGet,
      dataField: "skills",
      labelField: "name",
      valueField: "skillId",
      params: {
        sportId: sportId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const {
    handleSubmit,
    control,
    formState: { isValid },
    setValue,
    reset
  } = useForm({
    mode: "onBlur"
  });
  useEffect(() => {
    if (sportOptions.length == 1) {
      setValue("sportId", sportOptions[0].value);
      setValue("positions", []);
      setValue("parentId", "");
      setSportId(sportOptions[0].value);
    }
  }, [sportOptions]);
  const { mutate: save, isLoading: isSaving } = useAdminSkillPost();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      setMediaUploadLoading(true);
      const filesPromises = await Promise.all(
        filestoUpload.map((file) => {
          if (file instanceof File) {
            const promise = uploadMediaUsingPresignedUrl(file);
            console.log("PROMISE", promise);
            return promise;
          }
        })
      );
      const values = {
        ...formValues,
        organizationId
      };
      if (values.positions) {
        values.positions = values.positions.map((position) => ({
          positionId: position
        }));
      }
      values["mediaIds"] = filesPromises || [];
      setMediaUploadLoading(false);
      save(
        {
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Skill added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
              setResetMedia(!resetMedia);
              setFilesToUpload(null);
            } else {
              navigate("/skills");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add skill!", { variant: "error" });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Skill" />
      <Form>
        <Grid data-testid="skill-add-form" container spacing={3}>
          <Grid data-testid="skill-name" xs={12} md={6}>
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              required={true}
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid data-testid="skill-sport" xs={12} md={6}>
            <FormSelect
              control={control}
              name="sportId"
              label="Sport"
              required={true}
              options={sportOptions}
              disabled={sportOptions.length === 1}
              isLoading={isSportLoading}
              onChange={(e) => {
                setValue("positions", []);
                setValue("parentId", "");
                setSportId(e.target.value);
              }}
              rules={{
                required: "Sport is required"
              }}
            />
          </Grid>
          <Grid data-testid="skill-parentSkill" xs={12} md={6}>
            <FormSelect
              control={control}
              name="parentId"
              label="Parent Skill"
              required={false}
              isLoading={parentSkillsLoading}
              options={parentSkillsOptions}
            />
          </Grid>
          <Grid data-testid="skill-position" xs={12} md={6}>
            <FormMultiSelect
              control={control}
              name="positions"
              label="Positions"
              required={false}
              isLoading={postionsLoading}
              options={positionOptions}
            />
          </Grid>
          <Grid data-testid="skill-description" xs={12} md={12}>
            <FormInput
              control={control}
              name="description"
              type="text"
              label="Description"
              multiline={true}
            />
          </Grid>
          <Grid xs={12} md={12}>
            <StyledFormLabel>
              <Typography display="inline" variant="formLabel">
                {"Photo/Video Content"}
              </Typography>
            </StyledFormLabel>
          </Grid>
          <MediaSelector
            setFilesToUpload={(files) => {
              //@ts-ignore
              setFilesToUpload(files);
            }}
            uploadedFiles={[]}
            imagePlaceHolder={GamePickerImage}
            resetMedia={resetMedia}
          />
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        saveAndNewBtnClick={handleSubmit(saveHandler(true))}
        isDisabled={!isValid || isSaving || mediaUploadLoading}
        isLoading={isSaving || mediaUploadLoading}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/skills")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
