export const calculateAge = (birthedAt: Date | string): number => {
  const today = new Date();
  if (typeof birthedAt === "string") {
    birthedAt = new Date(birthedAt);
    if (isNaN(birthedAt.getTime())) {
      throw new Error("Invalid date");
    }
  }
  let age = today.getFullYear() - birthedAt.getFullYear();
  const m = today.getMonth() - birthedAt.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthedAt.getDate())) {
    age--;
  }
  return age;
};
