import { AddressAutocomplete } from "@components/AddressAutocomplete";
import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormDatePicker } from "@components/FormDatePicker";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { NotificationCard } from "@components/NotificationCard";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { inviteAtom, personAtom, signupTokenAtom } from "@recoil/signup";
import { getCountries, personalPost } from "@services/Network";
import {
  ModelInvite,
  ModelPerson,
  authSignUpAbandonDelete,
  responseCodes,
  useLookupCountryCountryIdSubdivisionGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalize } from "@utils/capitalize";
import convertUTCDateToLocalDate from "@utils/convertUtcToLocal";
import { stringJoin } from "@utils/stringJoin";
import { differenceInYears } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const FormRow = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  ${(props) => props.theme.breakpoints.up("xs")} {
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  margin-top: 3rem;
`;

const FormFieldContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
`;

const GoBackBtn = styled(Button)`
  margin-right: 1rem;
`;

const SignInContainer = styled(Box)`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const genderOptions = [
  { value: "MALE", label: "Male" },
  { value: "FEMALE", label: "Female" }
];

export const PersonalInfo = () => {
  const signupToken = useRecoilValue<string>(signupTokenAtom);
  const personalInfo = useRecoilValue<ModelPerson | null>(personAtom);
  const invite = useRecoilValue<ModelInvite | null>(inviteAtom);
  const address = useMemo(() => personalInfo?.addressPrimary, [personalInfo]);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const {
    watch,
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    formState: { errors },
    trigger,
    reset,
    clearErrors
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      country: address?.country || "",
      birthedAt: personalInfo?.birthedAt
        ? convertUTCDateToLocalDate(new Date(personalInfo?.birthedAt))
        : "",
      gender: personalInfo?.gender || "",
      address1: address?.lines?.[0] || "",
      address2: address?.lines?.[1] || "",
      city: address?.locality || "",
      state: address?.province || "",
      zip: address?.postalCode || "",
      phone: personalInfo?.phonePrimary?.phone || ""
    }
  });

  useEffect(() => {
    reset(
      {
        country: address?.country || "",
        birthedAt: personalInfo?.birthedAt
          ? convertUTCDateToLocalDate(new Date(personalInfo?.birthedAt))
          : "",
        gender: personalInfo?.gender || "",
        address1: address?.lines?.[0] || "",
        address2: address?.lines?.[1] || "",
        city: address?.locality || "",
        state: address?.province || "",
        zip: address?.postalCode || "",
        phone: personalInfo?.phonePrimary?.phone || ""
      },
      {
        keepDirty: true
      }
    );
  }, [personalInfo]);

  const country = watch("country");
  const birthedAt = watch("birthedAt");

  useEffect(() => {
    if (birthedAt) {
      trigger("birthedAt");
    }
  }, [country]);

  const { data: countryData, isLoading: isCountryDataLoading } = getCountries({
    query: {
      staleTime: Infinity,
      cacheTime: 0
    }
  });

  const { data: stateData, isLoading: isStateDataLoading } =
    useLookupCountryCountryIdSubdivisionGet(country, {
      query: {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!country
      }
    });

  const countryOptions = useMemo(() => {
    if (countryData?.length) {
      return countryData
        .map((country) => ({
          value: country.countryId as string,
          label: country.name as string
        }))
        .sort((c1, c2) => {
          if (c1.value === "US") return -1;
          if (c2.value === "US") return 1;
          return 0;
        });
    }
    return [];
  }, [countryData]);

  const selectedCountry = useMemo(() => {
    if (countryData?.length) {
      return countryData?.find((c) => c.countryId === country);
    }
    return null;
  }, [country, countryData]);

  const stateOptions = useMemo(() => {
    if (stateData?.data?.length) {
      return stateData.data.map((state) => ({
        value: state.subdivisionId as string,
        label: state.name as string
      }));
    }
    return [];
  }, [stateData]);

  const stateLabel = useMemo(() => {
    if (!stateData || !stateData.data || stateData.data.length === 0) {
      return "";
    }

    const categoryCounts = stateData.data.reduce((counts, item) => {
      const category = item.category as string;
      counts[category] = (counts[category] || 0) + 1;
      return counts;
    }, {});

    const maxCategory = Object.keys(categoryCounts).reduce((max, category) => {
      return categoryCounts[category] > categoryCounts[max] ? category : max;
    });
    return maxCategory || "";
  }, [stateData, selectedCountry]);

  const isAgeOfConsent = (birthDate: string) => {
    if (!birthDate) return false;
    if (invite) return true;
    if (!selectedCountry?.ageConsent) return true;

    const today = new Date();
    const birthDateObj = new Date(birthDate);
    const age = differenceInYears(today, birthDateObj);
    return age > selectedCountry?.ageConsent;
  };

  const selectAddressSuggestion = (place) => {
    const addressComponents = place?.address_components || [];
    const streetNumber = addressComponents.find((c) =>
      c.types.includes("street_number")
    );
    const route = addressComponents.find((c) => c.types.includes("route"));
    const address1 = stringJoin(" ", streetNumber?.long_name, route?.long_name);
    const subpremise = addressComponents.find((c) =>
      c.types.includes("subpremise")
    );
    const country = addressComponents.find((c) => c.types.includes("country"));
    const state = addressComponents.find((c) =>
      c.types.includes("administrative_area_level_1")
    );
    const city = addressComponents.find(
      (c) => c.types.includes("locality") || c.types.includes("sublocality")
    );
    const zip = addressComponents.find((c) => c.types.includes("postal_code"));
    setValue("address1", address1);
    if (subpremise) setValue("address2", subpremise?.long_name);
    setValue("country", country?.short_name);
    setValue("state", state?.short_name);
    setValue("city", city?.long_name);
    setValue("zip", zip?.long_name);
    trigger("address1");
    trigger("country");
    trigger("state");
    trigger("city");
    trigger("zip");
    if (country) trigger("birthedAt");
  };

  const mutation = personalPost();

  const submitForm = async (formValues) => {
    const lines = [formValues.address1];
    if (formValues.address2) {
      lines.push(formValues.address2);
    }
    const body = {
      gender: formValues.gender,
      birthedAt: `${formValues.birthedAt.getFullYear()}-${(
        formValues.birthedAt.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${formValues.birthedAt
        .getDate()
        .toString()
        .padStart(2, "0")}`,
      phone: `${formValues.phone}`,
      addresses: [
        {
          country: formValues.country,
          postalCode: formValues.zip,
          province: formValues.state,
          locality: formValues.city,
          lines: lines
        }
      ]
    };

    mutation.mutate(
      {
        data: body,
        headers: {
          "X-SportsGravy-Token": signupToken as string
        }
      },
      {
        onSuccess: () => {
          navigate("/sign-up/account-info");
        },
        onError: (e) => {
          const error = e as unknown as {
            response: { data: { code: string } };
          };
          const errorCode = error?.response?.data?.code;
          if (
            [
              responseCodes.AUTH_SIGNUP_DUPLICATE_ASSOCIATED_PHONE,
              responseCodes.AUTH_SIGNUP_DUPLICATE_PRIMARY_PHONE
            ].includes(errorCode)
          ) {
            return navigate("/sign-up/duplicate-account-associated-phone");
          }

          // user can resume the sign up process if they validate their phone number
          if (errorCode === responseCodes.AUTH_SIGNUP_PERSONAL_DUPLICATE) {
            return navigate("/sign-up/duplicate-pending-account-warning");
          }

          // found a duplicate active account, they cannot sign up
          if (errorCode === responseCodes.AUTH_SIGNUP_DUPLICATE) {
            return navigate("/sign-up/duplicate-account-warning");
          }

          setError(errorCode);
        }
      }
    );
  };

  const goBack = () => {
    navigate("/sign-up/basic-info");
  };

  return (
    <FormContainer data-testid="signup-personalInfo">
      <Typography
        variant="h2"
        color="text.general.primary"
        sx={{ fontWeight: 400, mb: ".5rem" }}
      >
        Personal Information
      </Typography>
      <Typography variant="body1" color="text.general.secondary">
        Enter your personal Information below to continue creating your account
      </Typography>
      {mutation.isError && (
        <NotificationCard
          variant="error"
          sx={{
            mt: "1rem"
          }}
        >
          {error === responseCodes.VALIDATE_PHONE_TWILIO_EXCEPTION
            ? `Error: The phone number was unable to be validated, please utilize a different phone number.`
            : `Error (${error}): Something went wrong. Try submitting again.`}
        </NotificationCard>
      )}
      <form onSubmit={handleSubmit(submitForm)}>
        <FormRow>
          <FormFieldContainer data-testid="signup-personalInfo-country">
            <FormSelect
              name="country"
              required
              label="Country"
              control={control}
              options={countryOptions}
              rules={{ required: "Country is required" }}
              isLoading={isCountryDataLoading}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer
            data-testid="signup-personalInfo-dob"
            className="static-error"
            sx={{ mr: "1.5rem" }}
          >
            <FormDatePicker
              name="birthedAt"
              required
              disableFuture
              label="Date of Birth"
              control={control}
              rules={{
                required: "Date of Birth is required",
                validate: {
                  ageOfConsent: (value: string) =>
                    !!invite || isAgeOfConsent(value)
                }
              }}
            />
          </FormFieldContainer>
          <FormFieldContainer data-testid="signup-personalInfo-gender">
            <FormSelect
              name="gender"
              label="Gender"
              required
              control={control}
              options={genderOptions}
              rules={{ required: "Gender is required" }}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-personalInfo-address1">
            <AddressAutocomplete
              name="address1"
              control={control}
              selectSuggestion={selectAddressSuggestion}
              rules={{
                required: "Address is required"
              }}
              label="Address 1"
              required
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer>
            <FormInput
              name="address2"
              type="text"
              label="Address 2"
              control={control}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-personalInfo-city">
            <FormInput
              name="city"
              type="text"
              required
              label="City"
              control={control}
              rules={{
                required: "City is required"
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer
            data-testid="signup-personalInfo-state"
            sx={{ mr: "1.5rem" }}
          >
            <FormSelect
              name="state"
              options={stateOptions}
              required
              label={capitalize(stateLabel) || "State"}
              control={control}
              rules={{
                required: `${capitalize(stateLabel) || "State"} is required`
              }}
              isLoading={!!country && isStateDataLoading}
            />
          </FormFieldContainer>
          <FormFieldContainer data-testid="signup-personalInfo-zipcode">
            <FormInput
              name="zip"
              type="text"
              required
              label="Zip Code"
              control={control}
              rules={{
                required: "Zip Code is required"
              }}
            />
          </FormFieldContainer>
        </FormRow>
        <FormRow>
          <FormFieldContainer data-testid="signup-personalInfo-phone">
            <FormInput
              name="phone"
              type="tel"
              required
              label="Mobile Phone"
              control={control}
              rules={{
                required: "Mobile Phone is required"
              }}
              country={selectedCountry}
            />
          </FormFieldContainer>
        </FormRow>
        <ButtonContainer>
          <FormFieldContainer sx={{ marginRight: "1.5rem" }}>
            <GoBackBtn variant="cancel" type="button" onClick={goBack}>
              Go Back
            </GoBackBtn>
          </FormFieldContainer>
          <FormFieldContainer data-testid="SIGNUP_PERSONAL_CONTINUE_BTN">
            <Button
              variant="primary"
              disabled={!isValid || !!mutation.isLoading}
              type="submit"
              isLoading={mutation.isLoading}
            >
              Continue
            </Button>
          </FormFieldContainer>
        </ButtonContainer>
      </form>
      <SignInContainer>
        <Typography variant="body1">
          Already have an account? <Link to="/">Sign In</Link>
        </Typography>
      </SignInContainer>
      <ConfirmationDialog
        open={errors?.birthedAt?.type === "ageOfConsent"}
        title="Date of Birth Error"
        body={`Because you are below ${selectedCountry?.ageMajority} years of age, you need to be invited by your Parent / Legal Guardian to create an account. Providing accurate age information is essential to organizations and recruiters. Your honesty ensures a smooth and successful experience for everyone involved.`}
        hideCloseIcon
        confirmBtnText="I made a mistake"
        cancelBtnText="I am below 18"
        onCancel={() => {
          authSignUpAbandonDelete();
          navigate("/");
        }}
        onConfirm={() => clearErrors("birthedAt")}
        icon="warning"
        confirmBtnVariant="admin-warning"
      />
    </FormContainer>
  );
};
