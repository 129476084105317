import { Close, NotificationsOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { organizationAtom } from "@recoil/auth";
import { deleteNotification, getNotification } from "@services/Network";
import { ModelNotification } from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { NotificationItem } from "./NotificationItem";

const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: "rgba(229, 229, 229, 1)",
  boxShadow: "0px 1px 3px 0px #00000026",
  padding: "15px 15px 15px 15px"
});

export const NotificationDrawer = ({
  isNotificationDrawerOpen,
  toggleNotificationDrawer
}: {
  isNotificationDrawerOpen: boolean;
  toggleNotificationDrawer: (isOpen: boolean) => void;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const [unReadNotifications, setUnreadNotifications] = useState<
    ModelNotification[]
  >([]);
  const notificationGet = getNotification(
    {
      ...(organizationId && { organizationId: organizationId }),
      pageNo: page
    },
    {}
  );
  const { data, fetchStatus, isLoading } = notificationGet;
  useEffect(() => {
    if (!isLoading) {
      if (fetchStatus == "idle" && !isLoading && page === 1) {
        setUnreadNotifications(data);
      }
      if (fetchStatus == "idle" && !isLoading && page > 1) {
        setUnreadNotifications((prevNotifications) => [
          ...prevNotifications,
          ...data
        ]);
      }
      if (data.length < 10 || data.length === 0) {
        setHasMore(false);
        observer.current.disconnect();
      }
    }
  }, [fetchStatus, hasMore, isLoading]);
  const mutation = deleteNotification();
  const removeNotification = (notificationId) => {
    const updatedNotifications = unReadNotifications.filter(
      (notif) => notif.notificationId !== notificationId
    );
    setUnreadNotifications([...updatedNotifications]);
    mutation.mutate({
      notificationId: notificationId
    });
  };
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasMore && (!isLoading || page === 1)) {
          setPage((page) => page + 1);
        }
      },
      {
        rootMargin: "200px 0px", // load content when the user is 200px away from the bottom
        threshold: 1.0 // entire target element is visible
      }
    )
  );
  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (isLoading) {
      currentObserver.disconnect();
    } else {
      if (currentElement) {
        currentObserver.observe(currentElement);
      }
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);
  const notification = unReadNotifications || [];
  const displayNotification = useMemo(() => {
    if (notification) {
      return notification.map((notification, index) => (
        <Box
          key={notification.notificationId}
          boxShadow={"0px 1px 3px 0px #00000026"}
          marginBottom="10px"
          ref={
            index === unReadNotifications.length - 1 && !isLoading && hasMore
              ? setLastElement
              : null
          }
        >
          <NotificationItem
            notification={notification}
            key={notification.notificationId}
            onClear={() => {
              removeNotification(notification?.notificationId);
            }}
          />
        </Box>
      ));
    }
    return null;
  }, [notification]);
  return (
    <Drawer
      data-testid="notification-drawer"
      anchor="right"
      open={isNotificationDrawerOpen}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(229, 229, 229, 1)"
        }
      }}
      onClose={() => {
        toggleNotificationDrawer(false);
      }}
    >
      <Box
        sx={{
          width: "400px",
          backgroundColor: "rgba(229, 229, 229, 1)"
        }}
      >
        <StyledBoxHeader>
          <Grid flexDirection={"row"} container alignItems={"center"}>
            <Grid item xs={1.3}>
              <NotificationsOutlined color="secondary" />
            </Grid>
            <Grid item xs={9} marginBottom={"5px"}>
              <Typography
                style={{
                  color: "#1E293B",
                  fontSize: "16px",
                  fontStyle: "normal",
                  lineHeight: "18px",
                  fontWeight: "600"
                }}
              >
                Notifications
              </Typography>
            </Grid>
            <Grid item xs={1} marginBottom={"5px"}>
              <IconButton
                onClick={() => {
                  toggleNotificationDrawer(false);
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </StyledBoxHeader>

        {displayNotification}
        {isLoading && (
          <Box marginTop={"10px"} textAlign={"center"}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
