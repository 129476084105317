/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { FormCheckbox } from "@components/FormCheckbox";
import { SPORT_CONTEST_TYPE_OPTIONS } from "@utils/constants";

const CountryList = ({
  countryList,
  control,
  search,
  disabled
}: {
  countryList: any;
  control: any;
  search: string;
  disabled: boolean;
}) => {
  const batchSize = 50;
  const [batchIndex, setBatchIndex] = useState(0);
  const [preProcessedcountryList, setPreProcessedcountryList] = useState({});
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (countryList) {
      const processedFields = Object.keys(countryList)
        .filter((key) =>
          countryList[key].countryName
            .toLowerCase()
            .includes(search.toLowerCase())
        )
        .reduce((acc, key) => {
          acc[key] = countryList[key];
          return acc;
        }, {});
      setPreProcessedcountryList(processedFields);
      setBatchIndex(0);
    }
  }, [countryList, search]);

  const loadNextBatch = () => {
    setBatchIndex((prevIndex) => prevIndex + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadNextBatch();
    }, 2000);

    if (
      Object.keys(preProcessedcountryList).length <=
      (batchIndex + 1) * batchSize
    ) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [batchIndex, preProcessedcountryList]);

  const currentBatch = Object.keys(preProcessedcountryList)
    .slice(0, (batchIndex + 1) * batchSize)
    .reduce((acc, key) => {
      acc[key] = preProcessedcountryList[key];
      return acc;
    }, {});

  const renderCountry = (field) => {
    return (
      <Grid
        data-testid="sport-country-input"
        container
        direction="column"
        spacing={1}
        key={field.countryId}
        xs={12}
        marginBottom={"24px"}
      >
        <Grid xs={8} md={4}>
          <FormCheckbox
            name={`countries.${field.countryId}.selected`}
            label={field.countryName}
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid style={{ display: "flex", gap: "24px" }}>
          <Grid xs={6}>
            <FormInput
              name={`countries.${field.countryId}.name`}
              type="text"
              label="Alternate Name"
              control={control}
              disabled={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <FormSelect
              name={`countries.${field.countryId}.contestType`}
              label="Contest Type"
              options={SPORT_CONTEST_TYPE_OPTIONS}
              control={control}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const containerHeight = Object.keys(preProcessedcountryList).length * 70;

  return (
    <div
      ref={containerRef}
      style={{ height: "600px", overflowY: "auto", width: "100%" }}
    >
      <div style={{ height: containerHeight, marginLeft: "25px" }}>
        {Object.keys(currentBatch).map((key) =>
          renderCountry(currentBatch[key])
        )}
      </div>
    </div>
  );
};

export default React.memo(CountryList, (prevProps, nextProps) => {
  return (
    prevProps.countryList === nextProps.countryList &&
    prevProps.search === nextProps.search
  );
});
