/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  text-align: center;
`;
const AutoComplete = ({ map, mapApi, addplace }) => {
  const searchInputRef = useRef(null);
  useEffect(() => {
    if (!map || !mapApi) return;
    const autoComplete = new mapApi.places.Autocomplete(searchInputRef.current);
    const onPlaceChanged = () => {
      const place = autoComplete.getPlace();

      if (!place.geometry) return;
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      addplace(place);
      searchInputRef.current.blur();
    };

    autoComplete.addListener("place_changed", onPlaceChanged);
    autoComplete.bindTo("bounds", map);
  }, [map, mapApi, addplace]);

  const clearSearchBox = () => {
    searchInputRef.current.value = "";
  };

  return (
    <Wrapper>
      <input
        style={{
          padding: "12px 16px 12px 16px",
          borderRadius: "10px",
          border: "0.5px solid #0006",
          width: "75%"
        }}
        className="search-input"
        ref={searchInputRef}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Search"
      />
    </Wrapper>
  );
};

export default AutoComplete;
