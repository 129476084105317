import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { PLATFORM_OPTIONS, TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import { FormHelperText, FormLabel } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import {
  useAdminFeatureFeatureIdGet,
  useAdminFeatureTestcaseTestcaseIdGet,
  useAdminFeatureTestcaseTestcaseIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import { DevTool } from "@hookform/devtools";

export const TestcaseEdit = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { enqueueSnackbar } = useSnackbar();
  const { testcaseId } = useParams();
  const [featureId, setFeatureId] = useState("");
  const [featureFieldFocused, setFeatureFieldFocused] = useState<boolean>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [showUiReasonField, setShowUiReasonField] = useState<string[]>([]);
  const [showUiJiraField, setShowUiJiraField] = useState<string[]>([]);
  const [showFnReasonField, setShowFnReasonField] = useState<string[]>([]);
  const [showFnJiraField, setShowFnJiraField] = useState<string[]>([]);
  const [platformOptions, setPlatformOptions] = useState(PLATFORM_OPTIONS);
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue
  } = useForm({
    mode: "onBlur"
  });
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const edit = hasPermission(
      "ORGANIZATION",
      organizationId!,
      "tech.test-cases",
      "EDIT"
    );
    edit.then((res) => {
      if (!res) navigate("/not-found");
    });
  }, [organizationId]);
  const { data: testcase, isLoading: testcaseLoading } =
    useAdminFeatureTestcaseTestcaseIdGet(testcaseId!);

  const { data: feature, isLoading: featureLoading } =
    useAdminFeatureFeatureIdGet(featureId!);

  const [platformsAutomated, setPlatformAutomated] = useState({
    iosUI: false,
    iosFN: false,
    andUI: false,
    andFN: false,
    webUI: false,
    webFN: false,
    apiFN: false,
    devFN: false
  });

  useEffect(() => {
    //setPlatforms
    if (feature?.data && testcase.data.featureId !== featureId) {
      setPlatformOptions([]);
      setPlatforms([]);
      setValue("platforms", []);
      feature.data.platforms.map((platform) => {
        if (platform == "IOS") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "iOS", value: "ios" }
          ]);
        }
        if (platform == "WEB") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "Web", value: "web" }
          ]);
        }
        if (platform == "AND") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "AND", value: "and" }
          ]);
        }

        if (platform == "API") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "API", value: "api" }
          ]);
        }
        if (platform == "DEV") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "DEVops", value: "dev" }
          ]);
        }
      });
    }
  }, [feature, testcase]);

  const setAutomatedValue = (platform, type) => {
    const automated = platformsAutomated;
    console.log("PLTFORM:", platform, type);
    automated[`${platform}${type}`] = true;
    setValue(`${platform}automated${type}`, true);
    setPlatformAutomated({ ...automated });
  };
  console.log("IMAZ:::", platformsAutomated);
  useEffect(() => {
    setPlatformOptions([]);
    if (testcase?.data) {
      const tc = testcase.data;
      reset({
        description: tc.description,
        expectedResult: tc.expectedResult,
        featureId: tc.feature?.name,
        platforms: tc.platforms!.map((platform) => platform.toLowerCase()),
        webDefaultUIStatus: tc.webDefaultUIStatus,
        webDefaultFNStatus: tc.webDefaultFNStatus,
        iosDefaultUIStatus: tc.iosDefaultUIStatus,
        iosDefaultFNStatus: tc.iosDefaultFNStatus,
        andDefaultUIStatus: tc.andDefaultUIStatus,
        andDefaultFNStatus: tc.andDefaultFNStatus,
        apiDefaultFNStatus: tc.apiDefaultFNStatus,
        devDefaultFNStatus: tc.devDefaultFNStatus,
        isActive: tc.isActive,
        webUiJiraLink: tc.webUiJiraLink || "",
        webUiReason: tc.webUiReason || "",
        iosUiJiraLink: tc.iosUiJiraLink || "",
        iosUiReason: tc.iosUiReason || "",
        andUiJiraLink: tc.andUiJiraLink || "",
        andUiReason: tc.andUiReason || "",
        webFnJiraLink: tc.webFnJiraLink || "",
        webFnReason: tc.webFnReason || "",
        iosFnJiraLink: tc.iosFnJiraLink || "",
        iosFnReason: tc.iosFnReason || "",
        andFnJiraLink: tc.andFnJiraLink || "",
        andFnReason: tc.andFnReason || "",
        devFnJiraLink: tc.devFnJiraLink || "",
        devFnReason: tc.devFnReason || "",
        apiFnJiraLink: tc.apiFnJiraLink || "",
        apiFnReason: tc.apiFnReason || ""
      });

      testcase.data.feature?.platforms.map((platform) => {
        if (platform == "IOS") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "iOS", value: "ios" }
          ]);
          if (tc.iosDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("ios", "FN");
          if (tc.iosDefaultUIStatus === "AUTOMATED")
            setAutomatedValue("ios", "UI");
        }
        if (platform == "WEB") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "Web", value: "web" }
          ]);
          if (tc.webDefaultFNStatus === "AUTOMATED") {
            setAutomatedValue("web", "FN");
          }
          if (tc.webDefaultUIStatus === "AUTOMATED") {
            setAutomatedValue("web", "UI");
          }
        }
        if (platform == "AND") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "AND", value: "and" }
          ]);
          if (tc.andDefaultFNStatus === "AUTOMATED") {
            setAutomatedValue("and", "FN");
          }
          if (tc.andDefaultUIStatus === "AUTOMATED") {
            setAutomatedValue("and", "UI");
          }
        }
        if (platform == "API") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "API", value: "api" }
          ]);
          if (tc.apiDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("api", "FN");
        }
        if (platform == "DEV") {
          setPlatformOptions((prev) => [
            ...prev,
            { label: "DEVops", value: "dev" }
          ]);
          if (tc.devDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("dev", "FN");
        }
      });
      setPlatforms(tc.platforms!.map((platform) => platform.toLowerCase()));
      setFeatureId(tc.featureId!);
      if (!tc.platforms?.includes("WEB")) {
        setValue("webDefaultUIStatus", "NOT_TESTED");
        setValue("webDefaultFNStatus", "NOT_TESTED");
      }
      if (!tc.platforms?.includes("IOS")) {
        setValue("iosDefaultUIStatus", "NOT_TESTED");
        setValue("iosDefaultFNStatus", "NOT_TESTED");
      }
      if (!tc.platforms?.includes("AND")) {
        setValue("andDefaultUIStatus", "NOT_TESTED");
        setValue("andDefaultFNStatus", "NOT_TESTED");
      }

      if (!tc.platforms?.includes("API")) {
        setValue("apiDefaultFNStatus", "NOT_TESTED");
      }
      if (!tc.platforms?.includes("DEV")) {
        setValue("devDefaultFNStatus", "NOT_TESTED");
      }

      PLATFORM_OPTIONS.forEach((platform) => {
        if (
          tc[platform.value + "DefaultUIStatus"] === "FAILED" ||
          tc[platform.value + "DefaultUIStatus"] === "BLOCKED"
        ) {
          setShowUiJiraField((prev) => [...prev, platform.value]);
        }
        if (tc[platform.value + "DefaultUIStatus"] === "HOLD") {
          setShowUiReasonField((prev) => [...prev, platform.value]);
        }
        if (
          tc[platform.value + "DefaultFNStatus"] === "FAILED" ||
          tc[platform.value + "DefaultFNStatus"] === "BLOCKED"
        ) {
          setShowFnJiraField((prev) => [...prev, platform.value]);
        }
        if (tc[platform.value + "DefaultFNStatus"] === "HOLD") {
          setShowFnReasonField((prev) => [...prev, platform.value]);
        }
      });
    }
  }, [testcase]);
  const { mutate: save, isLoading: isSaving } =
    useAdminFeatureTestcaseTestcaseIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues
      };
      Object.keys(values).forEach(
        (key) => values[key] == null && delete values[key]
      );
      values["featureId"] = featureId;
      values["platforms"] = platforms.map((platform) =>
        platform.substring(0, 3).toUpperCase()
      );
      PLATFORM_OPTIONS.map((platform) => {
        if (platforms.includes(platform.value)) {
          values[platform.value + "DefaultUIStatus"] =
            formValues[platform.value + "DefaultUIStatus"];
          values[platform.value + "DefaultFNStatus"] =
            formValues[platform.value + "DefaultFNStatus"];
        } else {
          values[platform.value + "DefaultUIStatus"] == "NOT_TESTED";
          values[platform.value + "DefaultFNStatus"] == "NOT_TESTED";
        }
        delete values[`${platform.value}automatedUI`];
        delete values[`${platform.value}automatedFN`];
      });
      try {
        save(
          {
            testcaseId: testcaseId!,
            data: values
          },
          {
            onSuccess: () => {
              enqueueSnackbar("Testcase added successfully!", {
                variant: "success"
              });
              if (resetInsteadOfRoute) {
                reset();
              } else {
                navigate(`/testcases/${testcaseId}`);
              }
            },
            onError: () => {
              enqueueSnackbar("Failed to add testcase!", {
                variant: "error"
              });
            }
          }
        );
      } catch (error) {
        enqueueSnackbar("Failed to add testcase!", {
          variant: "error"
        });
      }
    };
  return (
    <Container>
      <Toolbar title="Edit Test Case" />
      <Loader isLoading={testcaseLoading}>
        <Form>
          <Grid data-testid="testcase-view-form" container spacing={3}>
            <Grid data-testid="testcase-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Test Case Description"
                required={true}
                rules={{
                  required: "Test Case Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-expectedResult" xs={12}>
              <FormInput
                control={control}
                name="expectedResult"
                type="text"
                multiline
                rows={7}
                label="Expected Result"
                required={true}
                rules={{
                  required: "Expected Result is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-featureId" xs={12}>
              <FilterTreeSearchComponent
                control={control}
                name="featureId"
                required={true}
                rules={{
                  required: "Feature is required"
                }}
                selectedValue={featureId}
                label="Feature"
                onFilterChange={(val) => {
                  setFeatureId(val);
                  setPlatforms([]);
                  setValue("platforms", []);
                }}
                onClearFilter={() => {
                  setFeatureId("");
                  setPlatforms([]);
                  setValue("platforms", []);
                }}
                setValue={setValue}
                TextProps={{
                  onBlur: () => {
                    setFeatureFieldFocused(false);
                  },
                  onFocus: () => {
                    setFeatureFieldFocused(true);
                  }
                }}
              />
              <FormHelperText error={true}>
                {featureFieldFocused == false &&
                  featureId == "" &&
                  "Feature is required"}
              </FormHelperText>
            </Grid>
            <Grid data-testid="testcase-platforms" xs={12}>
              <FormMultiSelect
                control={control}
                options={platformOptions}
                name="platforms"
                isLoading={featureId == "" ? false : featureLoading}
                label="Platforms"
                required={true}
                rules={{
                  required: "Platforms is required"
                }}
                onChange={(e) => setPlatforms(e.target.value)}
                onRemove={(value) =>
                  setPlatforms(
                    platforms.filter((platform) => platform !== value)
                  )
                }
              />
            </Grid>
            {platforms.map((platform, index) => {
              return (
                <Grid container key={index} xs={12}>
                  {platform !== "api" && platform !== "dev" && (
                    <Grid
                      data-testid={platform + "-testcase-DefaultUIStatus"}
                      container
                      direction="column"
                      spacing="10px"
                      xs={6}
                    >
                      <Grid>
                        <FormSelect
                          options={TESTCASE_STATUS_OPTIONS}
                          control={control}
                          disabled={
                            testcase?.data.count.some(
                              (item) => item.platform.toLowerCase() === platform
                            ) || platformsAutomated[`${platform}UI`]
                          }
                          name={platform + "DefaultUIStatus"}
                          label={
                            platform == "web"
                              ? "Web Default UI Status"
                              : platform == "ios"
                              ? "iOS Default UI Status"
                              : "AND Default UI Status"
                          }
                          required={true}
                          rules={{
                            required: "UI Status is required"
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value == "FAILED" ||
                              e.target.value == "BLOCKED"
                            ) {
                              if (showUiReasonField.includes(platform)) {
                                setShowUiReasonField(
                                  showUiReasonField.filter(
                                    (item) => item !== platform
                                  )
                                );
                              }
                              setShowUiJiraField([
                                ...showUiJiraField,
                                platform
                              ]);
                              return;
                            }
                            if (e.target.value == "HOLD") {
                              if (showUiJiraField.includes(platform)) {
                                setShowUiJiraField(
                                  showUiJiraField.filter(
                                    (item) => item !== platform
                                  )
                                );
                              }
                              setShowUiReasonField([
                                ...showUiReasonField,
                                platform
                              ]);
                              return;
                            }
                            if (showUiJiraField.includes(platform)) {
                              setShowUiJiraField(
                                showUiJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            if (showUiReasonField.includes(platform)) {
                              setShowUiReasonField(
                                showUiReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                          }}
                        />
                      </Grid>

                      <Grid>
                        <FormCheckbox
                          name={platform + "automatedUI"}
                          label="Automated UI Unit Test Complete"
                          control={control}
                          onChange={(e) => {
                            setValue(
                              platform + "DefaultUIStatus",
                              e.target.checked ? "AUTOMATED" : "NOT_TESTED"
                            );
                            const automated = platformsAutomated;
                            automated[`${platform}UI`] = e.target.checked;
                            setPlatformAutomated({ ...automated });

                            if (showUiJiraField.includes(platform)) {
                              setShowUiJiraField(
                                showUiJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            if (showUiReasonField.includes(platform)) {
                              setShowUiReasonField(
                                showUiReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    data-testid={platform + "-testcase-DefaultFNStatus"}
                    xs={6}
                    container
                    direction="column"
                    spacing="10px"
                  >
                    <Grid>
                      <FormSelect
                        control={control}
                        options={TESTCASE_STATUS_OPTIONS}
                        disabled={
                          testcase?.data.count.some(
                            (item) => item.platform.toLowerCase() === platform
                          ) || platformsAutomated[`${platform}FN`]
                        }
                        name={platform + "DefaultFNStatus"}
                        label={
                          platform == "web"
                            ? "Web Default FN Status"
                            : platform == "ios"
                            ? "iOS Default FN Status"
                            : platform == "api"
                            ? "API Default FN Status"
                            : platform == "dev"
                            ? "DEVOps Default FN Status"
                            : "AND Default FN Status"
                        }
                        required={true}
                        rules={{
                          required: "FN Status is required"
                        }}
                        onChange={(e) => {
                          if (
                            e.target.value == "FAILED" ||
                            e.target.value == "BLOCKED"
                          ) {
                            if (showFnReasonField.includes(platform)) {
                              setShowFnReasonField(
                                showFnReasonField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            setShowFnJiraField([...showFnJiraField, platform]);
                            return;
                          }
                          if (e.target.value == "HOLD") {
                            if (showFnJiraField.includes(platform)) {
                              setShowFnJiraField(
                                showFnJiraField.filter(
                                  (item) => item !== platform
                                )
                              );
                            }
                            setShowFnReasonField([
                              ...showFnReasonField,
                              platform
                            ]);
                            return;
                          }
                          if (showFnJiraField.includes(platform)) {
                            setShowFnJiraField(
                              showFnJiraField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                          if (showFnReasonField.includes(platform)) {
                            setShowFnReasonField(
                              showFnReasonField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                        }}
                      />
                    </Grid>

                    <Grid>
                      <FormCheckbox
                        name={platform + "automatedFN"}
                        label="Automated FN Unit Test Complete"
                        control={control}
                        onChange={(e) => {
                          setValue(
                            platform + "DefaultFNStatus",
                            e.target.checked ? "AUTOMATED" : "NOT_TESTED"
                          );
                          const automated = platformsAutomated;
                          automated[`${platform}FN`] = e.target.checked;
                          setPlatformAutomated({ ...automated });

                          if (showFnJiraField.includes(platform)) {
                            setShowFnJiraField(
                              showFnJiraField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                          if (showFnReasonField.includes(platform)) {
                            setShowFnReasonField(
                              showFnReasonField.filter(
                                (item) => item !== platform
                              )
                            );
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  {(showUiJiraField.includes(platform) ||
                    showUiReasonField.includes(platform)) &&
                    (showUiJiraField.includes(platform) ? (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiJiraLink"}
                          disabled={testcase?.data.count.some(
                            (item) => item.platform.toLowerCase() === platform
                          )}
                          control={control}
                          label={
                            platform == "web"
                              ? "Web Jira Link"
                              : platform == "ios"
                              ? "iOS Jira Link"
                              : "AND Jira Link"
                          }
                          required
                          type="text"
                        />
                      </Grid>
                    ) : (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiReason"}
                          multiline
                          rows={4}
                          disabled={testcase?.data.count.some(
                            (item) => item.platform.toLowerCase() === platform
                          )}
                          control={control}
                          label={
                            platform == "web"
                              ? "Web Reason"
                              : platform == "ios"
                              ? "iOS Reason"
                              : "AND Reason"
                          }
                          required
                          type="text"
                        />
                      </Grid>
                    ))}

                  {(showFnJiraField.includes(platform) ||
                    showFnReasonField.includes(platform)) &&
                    (showFnJiraField.includes(platform) ? (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnJiraLink"}
                            control={control}
                            disabled={testcase?.data.count.some(
                              (item) => item.platform.toLowerCase() === platform
                            )}
                            label={
                              platform == "web"
                                ? "Web Jira Link"
                                : platform == "ios"
                                ? "iOS Jira Link"
                                : platform == "api"
                                ? "API Jira Link"
                                : platform == "dev"
                                ? "DEVOps Jira Link"
                                : "AND Jira Link"
                            }
                            required
                            type="text"
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnReason"}
                            multiline
                            disabled={testcase?.data.count.some(
                              (item) => item.platform.toLowerCase() === platform
                            )}
                            rows={4}
                            control={control}
                            label={
                              platform == "web"
                                ? "Web Reason"
                                : platform == "ios"
                                ? "iOS Reason"
                                : platform == "api"
                                ? "API Reason"
                                : platform == "dev"
                                ? "DEVOps Reason"
                                : "AND Reason"
                            }
                            required
                            type="text"
                          />
                        </Grid>
                      </>
                    ))}
                </Grid>
              );
            })}
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <FormLabel
                sx={{ width: "125px", fontSize: "0.875rem", fontWeight: 700 }}
              >
                Is Active
              </FormLabel>
              <FormCheckbox control={control} name="isActive" />
            </Grid>
          </Grid>
          <DevTool control={control} />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        isDisabled={!isValid || isSaving || !isDirty}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/testcases")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
