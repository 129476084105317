import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  styled,
  Typography
} from "@mui/material";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { SubscriptionPlan } from "./SubscriptionPlan";
import { Loader } from "@components/crud/Loader";
import { useEffect, useState } from "react";
import {
  ModelConfig,
  ModelSubscriptionPlan,
  useAdminSubscriptionPlansGet,
  useAdminSubscriptionPlansPut,
  useConfigGet,
  useConfigPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { EditIcon } from "@components/Icons";
import { Button } from "@components/Button";
import { Footer } from "@components/crud/Footer";
import { useForm } from "react-hook-form";
import { FormInput } from "@components/FormInput";
import { enqueueSnackbar } from "notistack";
import { FormSelect } from "@components/FormSelect";
import { FormOrderSelect } from "@components/FormOrderSelect";
import { cleanObject } from "@utils/cleanObject";

const StyledGrid = styled(Grid)`
  margin-top: -330px;
  @media (max-width: 1350px) {
    margin-top: 30px !important;
  }
`;

export const SubscriptionBillingSettings = () => {
  const [isEditing, setEditing] = useState(false);

  const form = useForm({
    mode: "onBlur"
  });

  const {
    reset,
    control,
    getValues,
    formState: { isDirty, isValid }
  } = form;

  const { data: subscriptionPlans, isLoading: isLoading } =
    useAdminSubscriptionPlansGet();

  const { data: configs, isLoading: isLoadingConfigs } = useConfigGet();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [sortOrderList, setSortOrderList] = useState<
    { label: string; value: string }[]
  >([]);
  const [orderUpdated, setOrderUpdated] = useState(false);
  useEffect(() => {
    if (subscriptionPlans && configs) {
      const defaultValues = {
        subscriptionPlans: subscriptionPlans?.data,
        failedRenewalAttempt2: configs?.data.find(
          (config) =>
            config.key === "subscription-payments.failed-renewal-attempt2-days"
        )?.value,
        maxDaysAttempt2: configs?.data.find(
          (config) =>
            config.key ===
            "subscription-payments.failed-renewal-attempt2-max-days"
        )?.value,
        failedRenewalAttemptFinal: configs?.data.find(
          (config) =>
            config.key === "subscription-payments.failed-renewal-final-days"
        )?.value,
        maxDaysFinalAttempt: configs?.data.find(
          (config) =>
            config.key === "subscription.failed-renewal-final-max-days"
        )?.value,
        defaultPlan: configs?.data.find(
          (config) => config.key === "general.default-subscription-plan"
        )?.value,
        planSortOrder: "SORT_ORDER_TEXT"
      };
      const options =
        (configs?.data.find(
          (config) => config.key === "general.subscription-plan-sort-order"
        )?.options as { label: string; value: string }[]) || [];
      const sortOrder =
        (configs?.data.find(
          (config) => config.key === "general.subscription-plan-sort-order"
        )?.value as string[]) || [];
      const sortDisplaytext = sortOrder
        .map(
          (item, index) =>
            `${index + 1}. ${options.find((opt) => opt.value === item)?.label}`
        )
        .join(", ");
      if (
        !(
          sortOrderList &&
          sortOrderList.findIndex(
            (sort) => sort.value === "SORT_ORDER_TEXT"
          ) !== -1
        )
      )
        setSortOrderList([
          ...options,
          { label: sortDisplaytext, value: "SORT_ORDER_TEXT" }
        ]);
      reset(defaultValues, { keepDirtyValues: false });
    }
  }, [subscriptionPlans, configs]);

  const { mutate: save, isLoading: isSaving } = useAdminSubscriptionPlansPut();
  const { mutate: saveConfigs, isLoading: isSavingConfigs } = useConfigPut();
  const onSave = () => {
    const formValues = getValues();

    const cleanPlan = (obj: ModelSubscriptionPlan) => {
      let newObj = {} as ModelSubscriptionPlan;
      newObj = cleanObject(obj);
      if (newObj.features) {
        newObj.features.map((feat, idx) => {
          const cleanedFeat = cleanObject(feat);
          newObj.features![idx] = cleanedFeat;
        });
      }
      return newObj;
    };
    const data = formValues.subscriptionPlans.map((plan) => cleanPlan(plan));
    const configs = [
      {
        key: "subscription-payments.failed-renewal-attempt2-days",
        value: parseInt(formValues.failedRenewalAttempt2)
      },
      {
        key: "subscription-payments.failed-renewal-final-days",
        value: parseInt(formValues.failedRenewalAttemptFinal)
      },
      {
        key: "general.default-subscription-plan",
        value: formValues.defaultPlan
      },
      {
        key: "general.subscription-plan-sort-order",
        value: sortOrderList
          .filter((opt) => opt.value !== "SORT_ORDER_TEXT")
          .map((opt) => opt.value)
      }
    ] as ModelConfig[];
    data.map((plan) => {
      plan.monthlyPrice = parseFloat(plan.monthlyPrice as string);
      plan.yearlyPrice = parseFloat(plan.yearlyPrice as string);
    });
    save(
      {
        data
      },
      {
        onSuccess: () => {
          saveConfigs(
            {
              data: configs
            },
            {
              onSuccess: () => {
                enqueueSnackbar("Saved Successfully!", {
                  variant: "success"
                });
                setEditing(false);
              },
              onError: () => {
                enqueueSnackbar("Failed to save subscription plan!", {
                  variant: "error"
                });
              }
            }
          );
        },
        onError: () => {
          enqueueSnackbar("Failed to save subscription plan!", {
            variant: "error"
          });
        }
      }
    );
  };
  return (
    <>
      <Grid
        item
        container
        direction="column"
        data-testid="subscription-setting"
      >
        <Loader isLoading={isLoading || isLoadingConfigs}>
          <Grid
            item
            container
            direction={"row"}
            justifyContent="space-between"
            style={{ marginBottom: "15px" }}
          >
            <Grid item>
              <Typography
                variant="permissionNames"
                sx={{
                  color: "#00000",
                  fontSize: "14px",
                  font: "inter",
                  lineHeight: "14.52px",
                  letterSpacing: "10%",
                  fontWeight: 400,
                  opacity: "50%"
                }}
              >
                Business to Consumer Subscription plans (Mobile app)
              </Typography>
            </Grid>
            {!isEditing && (
              <Grid item>
                <Button
                  variant="admin-primary"
                  onClick={() => {
                    setEditing(true);
                  }}
                  type="button"
                  startIcon={<EditIcon sx={{ marginTop: "1.5px" }} />}
                >
                  Edit
                </Button>
              </Grid>
            )}
          </Grid>
          <div
            style={{
              display: "flex",
              gap: "30px",
              flexDirection: "column"
            }}
          >
            {subscriptionPlans &&
              getValues().subscriptionPlans &&
              getValues().subscriptionPlans.map((plan, idx) => (
                <Grid item key={plan.planId}>
                  <SubscriptionPlan
                    index={idx}
                    form={form}
                    subscriptionPlan={plan}
                    isEditing={isEditing}
                  />
                </Grid>
              ))}
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              flexDirection: "column",
              marginTop: "30px"
            }}
          >
            <StyledGrid item>
              <Box
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",

                  padding: "10px",
                  borderRadius: "16px",
                  border: "1px solid rgba(196, 196, 196, 1)"
                }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  padding="20px"
                  spacing={"25px"}
                >
                  <Grid
                    item
                    container
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "rgba(30, 41, 59, 1)"
                        }}
                      >
                        Subscription Payments
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item container direction={"row"} spacing="20px">
                    <Grid
                      item
                      xs={6}
                      container
                      direction="column"
                      spacing={"5px"}
                    >
                      <Grid item data-testid="payment-attempt1">
                        <FormInput
                          name="failedRenewalAttempt2"
                          label="Failed Renewal Subscription Payment - 2nd Attempt"
                          required
                          disabled={!isEditing}
                          type="text"
                          control={control}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                days
                              </InputAdornment>
                            ),
                            type: "number"
                          }}
                          rules={{
                            validate: {
                              withinLimit: (days: number) =>
                                days <= getValues().maxDaysAttempt2 ||
                                "Max number of days allowed is " +
                                  getValues().maxDaysAttempt2
                            }
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="permissionNames"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(179, 179, 179, 1)"
                          }}
                        >
                          {"Max number of days allowed is " +
                            getValues().maxDaysAttempt2}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="column"
                      spacing={"5px"}
                      xs={6}
                    >
                      <Grid item data-testid="payment-finalAttempt">
                        <FormInput
                          name="failedRenewalAttemptFinal"
                          label="Failed Renewal Subscription Payment - Final Attempt"
                          required
                          type="text"
                          disabled={!isEditing}
                          control={control}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                days
                              </InputAdornment>
                            ),
                            type: "number"
                          }}
                          rules={{
                            validate: {
                              withinLimit: (days: number) =>
                                days <= getValues().maxDaysFinalAttempt ||
                                "Max number of days allowed is " +
                                  getValues().maxDaysFinalAttempt
                            }
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="permissionNames"
                          style={{
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "rgba(179, 179, 179, 1)"
                          }}
                        >
                          {"Max number of days allowed is " +
                            getValues().maxDaysFinalAttempt}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </StyledGrid>
            <Grid item>
              <Box
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.08)",
                  padding: "10px",
                  borderRadius: "16px",
                  border: "1px solid rgba(196, 196, 196, 1)"
                }}
              >
                <Grid
                  item
                  container
                  direction="column"
                  padding="20px"
                  spacing={"25px"}
                >
                  <Grid
                    item
                    container
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "rgba(30, 41, 59, 1)"
                        }}
                      >
                        General
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item container direction={"row"} spacing="20px">
                    <Grid item xs={6} data-testid="general-defaultPlan">
                      <FormSelect
                        control={control}
                        disabled={!isEditing}
                        name="defaultPlan"
                        label="Subscription Plan Default"
                        options={
                          (configs?.data.find(
                            (config) =>
                              config.key === "general.default-subscription-plan"
                          )?.options as { label: string; value: string }[]) ||
                          []
                        }
                      />
                    </Grid>
                    <Grid item xs={6} data-testid="general-planOrder">
                      <FormOrderSelect
                        control={control}
                        onOrderChange={(newList) => {
                          const newOptions = [] as {
                            label: string;
                            value: string;
                          }[];
                          for (const option of newList) {
                            if (option !== "SORT_ORDER_TEXT") {
                              newOptions.push({
                                value: option,
                                label:
                                  sortOrderList.find(
                                    (opt) => opt.value === option
                                  )?.label || ""
                              });
                            }
                          }
                          const sortDisplaytext = newList
                            .map(
                              (item, index) =>
                                `${index + 1}. ${sortOrderList.find(
                                  (opt) => opt.value === item
                                )?.label}`
                            )
                            .join(", ");
                          if (
                            sortOrderList.find(
                              (opt) => opt.value === "SORT_ORDER_TEXT"
                            )?.label !== sortDisplaytext
                          )
                            setOrderUpdated(true);
                          setSortOrderList([
                            ...newOptions,
                            {
                              label: sortDisplaytext,
                              value: "SORT_ORDER_TEXT"
                            }
                          ]);
                        }}
                        disabled={!isEditing}
                        name="planSortOrder"
                        label="Subscription Plan Sort Order"
                        options={sortOrderList}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        </Loader>
        {isEditing && (
          <div style={{ marginTop: "25px" }}>
            <Footer
              cancelBtnClick={() => setOpenCancelDialog(true)}
              saveBtnClick={onSave}
              isDisabled={(!isDirty && !orderUpdated) || !isValid || isSaving}
              isLoading={
                isLoading || isSaving || isLoadingConfigs || isSavingConfigs
              }
            />
          </div>
        )}
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => {
            setEditing(false);
            reset();
          }}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Grid>
    </>
  );
};
