import { ReactComponent as DashboardSvg } from "@assets/icons/dashboard.svg";
import { ReactComponent as GameConceptSvg } from "@assets/icons/gameConcepts.svg";
import { ReactComponent as GameSystemSvg } from "@assets/icons/gameSystems.svg";
import { ReactComponent as ImportTmpSvg } from "@assets/icons/importTemplateProviders.svg";
import { ReactComponent as LevelSvg } from "@assets/icons/levels.svg";
import { ReactComponent as OrganizationSvg } from "@assets/icons/organization.svg";
import { ReactComponent as EditSvg } from "@assets/icons/pencil.svg";
import { ReactComponent as PlusSignSvg } from "@assets/icons/plus-sign.svg";
import { ReactComponent as PositionSvg } from "@assets/icons/positions.svg";
import { ReactComponent as RoleSvg } from "@assets/icons/roles.svg";
import { ReactComponent as RowOrderingSvg } from "@assets/icons/row-ordering.svg";
import { ReactComponent as SeasonsSvg } from "@assets/icons/seasons.svg";
import { ReactComponent as SettingSvg } from "@assets/icons/settings.svg";
import { ReactComponent as SkillSvg } from "@assets/icons/skills.svg";
import { ReactComponent as SportSvg } from "@assets/icons/sports.svg";
import { ReactComponent as SubRoleSvg } from "@assets/icons/subroles.svg";
import { ReactComponent as TrainingProgramsSvg } from "@assets/icons/training-programs.svg";
import { ReactComponent as UserSvg } from "@assets/icons/users.svg";
import { ReactComponent as CannedSvg } from "@assets/icons/canned.svg";
import { ReactComponent as FeedSVG } from "@assets/icons/feed.svg";
import { ReactComponent as ReportSVG } from "@assets/icons/report.svg";
import { ReactComponent as CloseSvg } from "@assets/icons/close.svg";
import { ReactComponent as UserinSGSVG } from "@assets/icons/user-in-sg.svg";
import { ReactComponent as PendingUserSVG } from "@assets/icons/pending user-in-sg.svg";
import { ReactComponent as BannedUserSVG } from "@assets/icons/banned-user-in-sg.svg";
import { ReactComponent as ParentAcceptSVG } from "@assets/icons/parent-accept-in-sg.svg";
import { ReactComponent as ParentAndKidAcceptSVG } from "@assets/icons/parent-and-kid-accept-in-sg.svg";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as LikeSVG } from "@assets/icons/like.svg";
import { ReactComponent as LoveSVG } from "@assets/icons/love.svg";
import { ReactComponent as ConfusedSVG } from "@assets/icons/confused.svg";
import { ReactComponent as CelebrateSVG } from "@assets/icons/celebrate.svg";
import { ReactComponent as InsightfulSVG } from "@assets/icons/insightful.svg";
import { ReactComponent as NeutralSVG } from "@assets/icons/neutral.svg";
import { ReactComponent as ImportSVG } from "@assets/icons/import.svg";
import { ReactComponent as FixSVG } from "@assets/icons/fix.svg";
import { ReactComponent as DownloadSVG } from "@assets/icons/download.svg";
import { ReactComponent as InfoSVG } from "@assets/icons/info.svg";
import { ReactComponent as LiveSVG } from "@assets/icons/live.svg";
import { ReactComponent as SponsorshipLevelSVG } from "@assets/icons/sponsorship-level.svg";
import { ReactComponent as ReplySVG } from "@assets/icons/replyIcon.svg";
import { ReactComponent as LocationSVG } from "@assets/icons/location.svg";
import { ReactComponent as CalendarSVG } from "@assets/icons/calendarIcon.svg";
import { ReactComponent as BugSVG } from "@assets/icons/bugIcon.svg";
import { ReactComponent as SupportSVG } from "@assets/icons/supportIcon.svg";
import { ReactComponent as QaSVG } from "@assets/icons/qaIcon.svg";
import { ReactComponent as TestcaseSVG } from "@assets/icons/testcaseIcon.svg";
import { ReactComponent as GanttChartSVG } from "@assets/icons/ganttChartIcon.svg";

export const DashboardIcon = (props: SvgIconProps) => (
  <SvgIcon component={DashboardSvg} {...props} />
);
export const OrganizationIcon = (props: SvgIconProps) => (
  <SvgIcon component={OrganizationSvg} {...props} />
);
export const UserIcon = (props: SvgIconProps) => (
  <SvgIcon component={UserSvg} {...props} />
);
export const RoleIcon = (props: SvgIconProps) => (
  <SvgIcon component={RoleSvg} {...props} />
);
export const SubRoleIcon = (props: SvgIconProps) => (
  <SvgIcon component={SubRoleSvg} {...props} />
);
export const ImportTmpIcon = (props: SvgIconProps) => (
  <SvgIcon component={ImportTmpSvg} {...props} />
);
export const SportIcon = (props: SvgIconProps) => (
  <SvgIcon component={SportSvg} {...props} />
);
export const PositionIcon = (props: SvgIconProps) => (
  <SvgIcon component={PositionSvg} {...props} />
);
export const LevelIcon = (props: SvgIconProps) => (
  <SvgIcon component={LevelSvg} {...props} />
);
export const SeasonsIcon = (props: SvgIconProps) => (
  <SvgIcon component={SeasonsSvg} {...props} />
);
export const SkillIcon = (props: SvgIconProps) => (
  <SvgIcon component={SkillSvg} {...props} />
);
export const GameConceptIcon = (props: SvgIconProps) => (
  <SvgIcon component={GameConceptSvg} {...props} />
);
export const GameSystemIcon = (props: SvgIconProps) => (
  <SvgIcon component={GameSystemSvg} {...props} />
);
export const RowOrderingIcon = (props: SvgIconProps) => (
  <SvgIcon component={RowOrderingSvg} {...props} />
);
export const SettingIcon = (props: SvgIconProps) => (
  <SvgIcon component={SettingSvg} {...props} />
);
export const TeamsIcon = (props: SvgIconProps) => (
  <SvgIcon component={TrainingProgramsSvg} {...props} />
);
export const TrainingProgramsIcon = (props: SvgIconProps) => (
  <SvgIcon component={TrainingProgramsSvg} {...props} />
);
export const AddIcon = (props: SvgIconProps) => (
  <SvgIcon component={PlusSignSvg} {...props} />
);
export const EditIcon = (props: SvgIconProps) => (
  <SvgIcon component={EditSvg} {...props} />
);

export const CannedIcon = (props: SvgIconProps) => (
  <SvgIcon component={CannedSvg} {...props} />
);
export const ActiveUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={UserinSGSVG} style={{ color: "white" }} {...props} />
);
export const PendingUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={PendingUserSVG} style={{ color: "white" }} {...props} />
);
export const BannedUserIcon = (props: SvgIconProps) => (
  <SvgIcon component={BannedUserSVG} style={{ color: "white" }} {...props} />
);
export const ParentAcceptIcon = (props: SvgIconProps) => (
  <SvgIcon component={ParentAcceptSVG} style={{ color: "white" }} {...props} />
);
export const ParentAndKidAcceptIcon = (props: SvgIconProps) => (
  <SvgIcon
    component={ParentAndKidAcceptSVG}
    style={{ color: "white" }}
    {...props}
  />
);
export const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon component={CloseSvg} {...props} />
);
export const LikeIcon = (props: SvgIconProps) => (
  <SvgIcon component={LikeSVG} {...props} />
);

export const LoveIcon = (props: SvgIconProps) => (
  <SvgIcon component={LoveSVG} {...props} />
);

export const ConfusedIcon = (props: SvgIconProps) => (
  <SvgIcon component={ConfusedSVG} {...props} />
);

export const CelebrateIcon = (props: SvgIconProps) => (
  <SvgIcon component={CelebrateSVG} {...props} />
);

export const InsightfulIcon = (props: SvgIconProps) => (
  <SvgIcon component={InsightfulSVG} {...props} />
);

export const NeutralIcon = (props: SvgIconProps) => (
  <SvgIcon component={NeutralSVG} {...props} />
);
export const InfoIcon = (props: SvgIconProps) => (
  <SvgIcon component={InfoSVG} {...props} />
);
export const FeedIcon = (props: SvgIconProps) => (
  <SvgIcon component={FeedSVG} {...props} />
);
export const ReportIcon = (props: SvgIconProps) => (
  <SvgIcon component={ReportSVG} {...props} />
);

export const ImportIcon = (props: SvgIconProps) => (
  <SvgIcon component={ImportSVG} {...props} />
);

export const FixIcon = (props: SvgIconProps) => (
  <SvgIcon component={FixSVG} {...props} />
);

export const DownloadIcon = (props: SvgIconProps) => (
  <SvgIcon component={DownloadSVG} {...props} />
);

export const LiveStreamIcon = (props: SvgIconProps) => (
  <SvgIcon component={LiveSVG} {...props} />
);

export const SponsorshipLevelIcon = (props: SvgIconProps) => (
  <SvgIcon component={SponsorshipLevelSVG} {...props} />
);

export const ReplyIcon = (props: SvgIconProps) => (
  <SvgIcon component={ReplySVG} {...props} />
);

export const SportLocationIcon = (props: SvgIconProps) => (
  <SvgIcon component={LocationSVG} {...props} />
);

export const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon component={CalendarSVG} {...props} />
);

export const BugIcon = (props: SvgIconProps) => (
  <SvgIcon component={BugSVG} {...props} />
);

export const SupportIcon = (props: SvgIconProps) => (
  <SvgIcon component={SupportSVG} {...props} />
);

export const QaIcon = (props: SvgIconProps) => (
  <SvgIcon component={QaSVG} {...props} />
);

export const TestcaseIcon = (props: SvgIconProps) => (
  <SvgIcon component={TestcaseSVG} {...props} />
);

export const GanttChartIcon = (props: SvgIconProps) => (
  <SvgIcon component={GanttChartSVG} {...props} />
);
