import { RoleType } from "@sportsgravyengineering/sg-api-react-sdk";
import { Assertion, Enforcer, Model, DefaultRoleManager } from "casbin";
import { getRecoil, setRecoil } from "recoil-nexus";
import {
  casbinModel as casbinModelAtom,
  enforcerAtom,
  organizationsAtom
} from "../recoil/auth";

export type CasbinAssertionJsonModel = {
  key: string;
  value: string;
  tokens: string[];
  policy: string[][];
  fieldIndexMap: {
    [key: string]: number;
  };
};
export type CasbinModelJsonModel = {
  [key: string]: {
    [key: string]: CasbinAssertionJsonModel;
  };
};
export type CasbinPolicyJsonModel = CasbinAssertionJsonModel["policy"];

const rebuildAssertion = (json: CasbinAssertionJsonModel) =>
  Object.assign(new Assertion(), {
    key: json.key,
    value: json.value,
    tokens: json.tokens,
    policy: json.policy,
    fieldIndexMap: new Map(Object.entries(json.fieldIndexMap)),
    rm: new DefaultRoleManager(10)
  });

const rebuildModel = (
  json: CasbinModelJsonModel
): Map<string, Map<string, Assertion>> =>
  Object.entries(json).reduce(
    (model, [key, assertionsObj]) =>
      model.set(
        key,
        Object.entries(assertionsObj).reduce(
          (assertionsMap, [assertionKey, assertionJson]) =>
            assertionsMap.set(assertionKey, rebuildAssertion(assertionJson)),
          new Map<string, Assertion>()
        )
      ),
    new Map<string, Map<string, Assertion>>()
  );

export const getEnforcer = () => {
  let cachedEnforcer = getRecoil(enforcerAtom);
  const casbinModel = getRecoil(casbinModelAtom);
  const model = new Model();
  if (casbinModel) {
    model.model = rebuildModel(casbinModel);
  }

  cachedEnforcer = new Enforcer();
  cachedEnforcer.setModel(model);

  setRecoil(enforcerAtom, cachedEnforcer);

  return cachedEnforcer;
};

export const hasPermission = async (
  roleType: RoleType | "*",
  roleTypeId: string,
  permissionId: string,
  action: string
) => {
  // we need to check for several styles of permissions depending on the roleType.
  const roleChecks = [
    {
      type: roleType,
      id: roleTypeId
    }
  ];
  if (
    roleType === RoleType.ORGANIZATION ||
    roleType === RoleType.TEAM ||
    roleType === RoleType.TRAINING_PROGRAM
  ) {
    roleChecks.push({
      type: RoleType.SYSTEM,
      id: "*"
    });
    if (roleTypeId !== undefined) {
      roleChecks.push({
        type: RoleType.ORGANIZATION,
        id: roleTypeId
      });
    }
  }
  for (const roleCheck of roleChecks) {
    const can = await getEnforcer().enforce(
      roleCheck.type,
      roleCheck.id,
      permissionId,
      action
    );
    if (can) {
      return true;
    }
  }

  return false;
};

export const canAccessDashboard = async () => {
  const perms = [
    ["general.dashboard", "VIEW"],
    ["organization.post", "ON"],
    ["team.teams", "EDIT"],
    ["team.all-social", "ON"],
    ["organization.organization", "EDIT"],
    ["training-programs.training-programs", "EDIT"],
    ["training-programs.all-social", "ON"]
  ];

  const orgAccessMap = new Map<string, boolean>();
  const organizations = getRecoil(organizationsAtom);

  const promises = organizations.map(async (organization) => {
    for (const [permissionId, action] of perms) {
      const can = await hasPermission(
        organization.organizationId === undefined
          ? RoleType.SYSTEM
          : RoleType.ORGANIZATION,
        organization.organizationId === undefined
          ? "*"
          : (organization.organizationId as string),
        permissionId,
        action
      );
      if (can) {
        orgAccessMap.set(organization.organizationId as string, true);
        break;
      }
    }
  });

  await Promise.all(promises);

  const filteredOrgs = organizations.filter((org) =>
    orgAccessMap.get(org.organizationId as string)
  );
  setRecoil(organizationsAtom, filteredOrgs);

  return filteredOrgs.length > 0;
};
