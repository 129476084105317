import React from "react";
import {
  Backdrop,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@components/Button";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "35%",
  width: "500px",
  paddingBottom: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "20%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "& span": { color: "#1E293B", fontSize: "14px", fontWeight: 500 }
}));
export const RemoveRecurringEvent = (props: {
  onClose;
  setRecurringDeleteType;
  onConfirm;
}) => {
  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <Box>
            <Grid
              container
              style={{
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  padding: "32px",
                  paddingBottom: "10px"
                }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    color: "#1E293B",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  Remove recurring event
                </Typography>
                <CloseIcon
                  style={{ fill: "#000", cursor: "pointer" }}
                  onClick={() => props.onClose()}
                />
              </div>
            </Grid>
          </Box>
          <FormControl style={{ paddingLeft: "32px" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="this"
              name="radio-buttons-group"
            >
              <StyledFormControlLabel
                value="this"
                control={
                  <Radio onClick={() => props.setRecurringDeleteType("this")} />
                }
                label="This event"
              />
              <StyledFormControlLabel
                value="future"
                control={
                  <Radio
                    onClick={() => props.setRecurringDeleteType("future")}
                  />
                }
                label="This and following events"
              />
              <StyledFormControlLabel
                value="all"
                control={
                  <Radio onClick={() => props.setRecurringDeleteType("all")} />
                }
                label="All events"
              />
            </RadioGroup>
          </FormControl>
          <Grid
            style={{
              paddingRight: "32px",
              display: "flex",
              gap: "16px",
              justifyContent: "flex-end"
            }}
          >
            <Button onClick={props.onClose} variant="admin-secondary">
              Cancel
            </Button>
            <Button onClick={() => props.onConfirm()} variant="admin-primary">
              Ok
            </Button>
          </Grid>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
