import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { AddIcon } from "@components/Icons";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useAdminImportTmplPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { ERP_TYPE_OPTIONS } from "@utils/constants";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const ImportTemplateProviderCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid }
  } = useForm({
    mode: "onBlur"
  });

  const { mutate: save, isLoading: isSaving } = useAdminImportTmplPost();
  const saveHandler =
    (addVersion = false) =>
    (formValues) => {
      const values = {
        ...formValues
      };

      save(
        {
          data: values
        },
        {
          onSuccess: (data) => {
            const providerId = data.data.providerId;

            enqueueSnackbar("Import Template Provider added successfully!", {
              variant: "success"
            });
            if (addVersion) {
              navigate(`/import-template-providers/${providerId}/create`);
            } else {
              navigate(`/import-template-providers/${providerId}`);
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add import template provider!", {
              variant: "error"
            });
          }
        }
      );
    };

  return (
    <Container>
      <Toolbar title="Add Import Template Provider" />
      <Form>
        <Grid container spacing={3}>
          <Grid xs={12} md={6} data-testid="ImportTpl_create_name">
            <FormInput
              control={control}
              name="name"
              type="text"
              label="Name"
              required={true}
              onChange={(e) => {
                setValue("name", capitalizeEveryWord(e.target.value));
              }}
              rules={{
                required: "Name is required"
              }}
            />
          </Grid>
          <Grid xs={12} md={6} data-testid="ImportTpl_create_erpType">
            <FormSelect
              control={control}
              name="erpType"
              label="ERP Type"
              required={true}
              rules={{ required: "ERP Type is required" }}
              options={ERP_TYPE_OPTIONS}
            />
          </Grid>
          <Grid xs={12} data-testid="ImportTpl_create_desc">
            <FormInput
              control={control}
              name="description"
              type="text"
              label="Description"
              multiline={true}
              TextProps={{
                minRows: 3
              }}
            />
          </Grid>
        </Grid>
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={handleSubmit(saveHandler(false))}
        additionalBtns={[
          <Button
            key="add-version"
            variant="admin-primary"
            type="button"
            onClick={handleSubmit(saveHandler(true))}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
            startIcon={<AddIcon />}
          >
            Save and Add Version
          </Button>
        ]}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/import-template-providers")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
