import { LicenseInfo } from "@mui/x-license-pro";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";

LicenseInfo.setLicenseKey(
  "2984b3e7bf15479286497629cb110e76Tz02NzY2MyxFPTE3MTcyNjY3MjMwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=",
);

export default function App(): JSX.Element {
  return (
    <Router>
      <Routes />
    </Router>
  );
}
