// import { FormRadioGroup } from "@components/FormRadioGroup";
import { Loader } from "@components/crud/Loader";
import { Grid } from "@mui/material";
// import { useAdminSettingsPut } from "@sportsgravyengineering/sg-api-react-sdk";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import {
  useAdminSettingsGet,
  useAdminSettingsPut,
  useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState, useRecoilValue } from "recoil";
import { FormSelect } from "@components/FormSelect";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";

export const CalendarSetting = () => {
  const form = useForm({
    mode: "onBlur"
  });
  const [organizationId] = useRecoilState(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const [disabled, setDisabled] = useState(false);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );

  const { data: settings, isLoading: isLoading } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "general"
  });

  const { data: timezone, isLoading: isLoadingTimeZone } =
    useLookupCountryCountryIdSubdivisionSubdivisionIdTimezoneGet(
      organization!.country!,
      organization!.province!,
      {
        city: organization!.locality
      }
    );

  const { reset, control, getValues } = form;

  useEffect(() => {
    if (settings && timezone) {
      const settingsMap = settings.data.map((setting) => {
        return {
          settingId: setting.settingId,
          value:
            setting.organizationSettings && setting.organizationSettings.length
              ? setting.organizationSettings[0].value
              : timezone.data
        };
      });
      const defaultValues = {
        selectedSettings: settingsMap
      };

      reset(defaultValues, {
        keepDefaultValues: false
      });
    }
  }, [settings, timezone]);

  const { mutate: save } = useAdminSettingsPut();

  const onSave = () => {
    const data = getValues().selectedSettings;
    save(
      {
        data: data,
        params: {
          organizationId: organizationId!
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Saved Successfully!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to save !", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("admin.settings", "EDIT");
      setDisabled(!edit);
    };
    fetchPermissions();
  }, []);

  return (
    <Grid item container direction="column" rowSpacing="24px">
      <Loader isLoading={isLoading || isLoadingTimeZone}>
        {settings &&
          settings.data.map((setting, index) => {
            if (setting.type === "SINGLE_SELECT") {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  key={setting.settingId}
                  data-testid={setting.settingId}
                >
                  <Grid item xs={6}>
                    <FormSelect
                      control={control}
                      disabled={
                        setting.settingId === "general.org-local-timezone" ||
                        disabled
                      }
                      onChange={() => {
                        onSave();
                      }}
                      name={`selectedSettings[${index}].value`}
                      options={
                        setting.options as { label: string; value: string }[]
                      }
                      label={setting.description}
                    />
                  </Grid>
                </Grid>
              );
            }
          })}
      </Loader>
    </Grid>
  );
};
