import { Backdrop, Box, Container, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "./crud/Form";
import { FormInput } from "./FormInput";
import { useForm } from "react-hook-form";
import { Button } from "./Button";
import {
  ModelRelease,
  useAdminReleaseReleaseIdDuplicatePost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import colors from "theme/colors";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});

export const DuplicateReleaseModal = (props: {
  title;
  onClose;
  release: ModelRelease;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const {
    control,
    formState: { isValid },
    handleSubmit
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      parentRelease: props.release.name,
      parentReleaseId: props.release.releaseId
    }
  });

  const { mutate: save, isLoading: isSaving } =
    useAdminReleaseReleaseIdDuplicatePost();
  const saveHandler = () => async (formValues) => {
    const values = {
      ...formValues
    };
    try {
      save(
        {
          releaseId: props.release.releaseId,
          data: values
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Release duplicated successfully!", {
              variant: "success"
            });
            props.onClose();
            navigate(`/releases`);
          },
          onError: () => {
            enqueueSnackbar("Failed to duplicate release!", {
              variant: "error"
            });
          }
        }
      );
    } catch (error) {
      enqueueSnackbar("Failed to duplicate release!", {
        variant: "error"
      });
    }
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Container>
            <Form sx={{ paddingBottom: "0 !important" }}>
              <Grid
                data-testid="release-add-form"
                container
                columnGap={4}
                rowGap={2}
              >
                <Grid data-testid="release-name" xs={5}>
                  <FormInput
                    control={control}
                    name="parentRelease"
                    type="Duplicate from"
                    label="Name"
                    disabled
                    required={true}
                    rules={{
                      required: "Name is required"
                    }}
                  />
                </Grid>
                <Grid data-testid="release-description" xs={5}>
                  <FormInput
                    control={control}
                    name="name"
                    type="text"
                    required
                    rules={{
                      required: "Release Name is required"
                    }}
                    label="Release Name"
                  />
                </Grid>
                <Grid data-testid="release-description" xs={5}>
                  <FormInput
                    control={control}
                    name="description"
                    type="text"
                    label="Description"
                  />
                </Grid>
              </Grid>
            </Form>
            <Grid
              style={{
                margin: "30px",
                display: "flex",
                gap: "16px",
                paddingBottom: "20px",
                justifyContent: "flex-end"
              }}
            >
              <Button onClick={props.onClose} variant="admin-secondary">
                Cancel
              </Button>
              <Button
                disabled={!isValid || isSaving}
                onClick={handleSubmit(saveHandler())}
                isLoading={isSaving}
                variant="admin-primary"
              >
                Save
              </Button>
            </Grid>
          </Container>
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
