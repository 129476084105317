import {
  BannedUserIcon,
  PendingUserIcon,
  ActiveUserIcon
} from "@components/Icons";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getCountries, getUsers } from "@services/Network";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formatFullName from "@utils/formatFullName";
import React from "react";
import { capitalize } from "@utils/capitalize";
import { useRecoilState } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { formatPhone } from "@utils/phoneFormatters";
import { hasPermission } from "@services/Casbin";
import { Loader } from "@components/crud/Loader";

export const Users = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [filter, setFilter] = React.useState("ALL");
  const countries = getCountries();
  const [organizationId] = useRecoilState(organizationAtom);
  const onFillSG = () => {
    return [
      {
        headerName: "Mobile Phone",
        field: "phone",
        minWidth: 100,
        flex: 1,
        sortable: false,
        valueGetter: ({ row }) => (row.phone ? formatPhone(row.phone) : "")
      },
      ...fillLocation()
    ];
  };
  const fillLocation = () => {
    return [
      {
        headerName: "Location",
        field: "state",
        minWidth: 150,
        flex: 1,
        renderCell: (params) => {
          if (params.row.addressPrimary) {
            let country = params.row.addressPrimary.country;
            country =
              countries.data?.find((c) => c.countryId === country)?.name ||
              country;
            return (
              <>
                {capitalize(params.row.addressPrimary.locality) +
                  ", " +
                  params.row.addressPrimary.province.toUpperCase()}
                <br></br>
                {country}
              </>
            );
          } else return <></>;
        }
      }
    ];
  };
  const isOrgUserBanned = (user) => {
    const orgRoles = user.user?.roles?.filter(
      (role) =>
        role.organizationId === organizationId &&
        role.role.type === "ORGANIZATION"
    );
    if (orgRoles && orgRoles.length)
      return orgRoles.every((role) => role.isBanned);
    return false;
  };
  const USER_COLUMNS: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      minWidth: 150,
      flex: 1,
      cellClassName: "super-app-theme--cell",
      valueGetter: ({ row }) => formatFullName(row)
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 100,
      flex: 1,
      sortable: false
    },
    ...(filter === "SG" ? onFillSG() : fillLocation()),
    {
      headerName: "Status",
      field: "status",
      minWidth: 30,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.user && organizationId && isOrgUserBanned(params.row))
          return <BannedUserIcon data-testid="bannedUserIcon" />;
        if (params.row.user && params.row.user.status === "ACCOUNT_LOCKED") {
          return <BannedUserIcon data-testid="bannedUserIcon" />;
        } else if (
          (params.row.status === "PRIMARY" && !organizationId) ||
          (organizationId && params.row.user?.roles?.length > 0)
        )
          return <ActiveUserIcon data-testid="activeUserIcon" />;
        else return <PendingUserIcon data-testid="pendingUserIcon" />;
      }
    }
  ];

  useEffect(() => {
    if (!organizationId) setFilter("SG");
  }, []);
  const filterConfig = {
    field: "searchType",
    defaultValue: organizationId ? "ALL" : "SG",
    options: organizationId
      ? [
          {
            label: "All Users",
            value: "ALL"
          }
        ]
      : [
          {
            label: "SG Users",
            value: "SG"
          },
          {
            label: "All Users",
            value: "ALL"
          }
        ]
  };

  const onAdd = () => {
    navigate("/users/create");
  };

  const onEdit = (user) => {
    navigate(`/users/${user.id}/edit`);
  };
  const onView = (user) => {
    navigate(`/users/${user.id}`);
  };
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const add = await checkPermission("user.users", "ADD");
      const edit = await checkPermission("user.users", "EDIT");
      setPermissions({
        add: add,
        edit: edit
      });
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const [permissions, setPermissions] = useState({
    add: false,
    edit: false
  });
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  return (
    <Loader isLoading={isLoadingPermissions}>
      <TableView
        sx={{
          "& .disabled-row--BANNED": {
            color: "#ABAFB5",
            fontWeight: "400"
          }
        }}
        title="Users"
        useGet={getUsers}
        columns={USER_COLUMNS}
        filterConfig={filterConfig}
        defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={permissions.add ? onAdd : undefined}
        onEdit={permissions.edit ? onEdit : undefined}
        onView={onView}
        refreshKey={refreshKey}
        getRowClassName={(params) =>
          (organizationId && params.row.user && isOrgUserBanned(params.row)) ||
          (params.row.user && params.row.user.status === "ACCOUNT_LOCKED")
            ? `disabled-row--BANNED`
            : ``
        }
        onFilterChange={(value: string) => setFilter(value)}
      />
    </Loader>
  );
};
