import { NotificationCard } from "@components/NotificationCard";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const LabelText = styled(Typography)`
  margin-right: 1rem;
  font-weight: 700;
`;

const ValueText = styled(Typography)`
  font-weight: 400;
`;

const StyledRow = styled("div")`
  align-items: center;
  flex-direction: row;
  display: flex;
  margin-bottom: 0.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

import PropTypes from "prop-types";

export const InfoListCard = ({ infoList }) => {
  return (
    <NotificationCard variant="info">
      {infoList.map((info) => (
        <StyledRow key={`${info.label}-${info.value}`}>
          <LabelText variant="body1">{info.label}</LabelText>
          <ValueText variant="body1">{info.value}</ValueText>
        </StyledRow>
      ))}
    </NotificationCard>
  );
};

InfoListCard.propTypes = {
  infoList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
