import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Backdrop, Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { getCreatePostSport } from "@services/Network";
import { useForm } from "react-hook-form";
import { organizationAtom, organizationsAtom, profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { EditIcon } from "@components/Icons";
import { Delete, Visibility } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  DatePicker,
  LocalizationProvider,
  TimeField
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LiveStreamIcon from "../../../src/assets/icons/liveIconRed.svg";
import DescriptionIcon from "../../../src/assets/icons/descriptionIcon.svg";
import {
  useAdminEventEventIdRecurrenceRecurrenceIdDelete,
  useAdminEventEventIdRecurrenceRecurrenceIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import LocationIcon from "../../../src/assets/icons/locationIcon.svg";
import ShareWithIcon from "../../../src/assets/icons/shareWithIcon.svg";
import { Loader } from "@components/crud/Loader";
import { RRule } from "rrule";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { RemoveRecurringEvent } from "./components/RemoveRecurringEvent";
import { useSnackbar } from "notistack";
import TeamIcon from "../../../src/assets/icons/teamIcon.svg";
import personGroupIcon from "../../../src/assets/icons/personGroupIcon.svg";
import colors from "theme/colors";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  paddingBottom: "24px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  "& fieldset": {
    border: "none !important"
  },
  "& svg[data-testid='CalendarIcon'], svg[data-testid='ClockIcon']": {
    display: "none"
  },
  "& .time-picker button, .date-picker button": {
    display: "none"
  },
  "& input, select": {
    color: "#2B337A",
    "&:active": {
      color: "#000"
    }
  },
  "& .timezone .MuiInputBase-root": {
    padding: 0
  },
  "& .timezone .MuiInputBase-root::before, .recurrence .MuiInputBase-root::before, .shareWith .MuiInputBase-root::before, .title, .title .MuiInputBase-root::before":
    {
      borderBottom: "none !important"
    },
  "& .recurrence .MuiSelect-select, .sport .MuiSelect-select": {
    color: "#2B337A"
  },
  "& .sport .MuiSelect-select": {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  "& .shareWith .MuiInputBase-input::placeholder": {
    fontSize: "16px",
    color: "#64748B",
    fontWeight: 400
  },
  "& .date-picker .MuiInputBase-root ": {
    paddingRight: "0px"
  },
  "& .date-picker .MuiInputBase-input, .time-picker .MuiInputBase-input ": {
    padding: "0 0 0 10px"
  },
  "& .loader": {
    minHeight: "350px"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});
const StyledLiveBox = styled(Box)(({ theme }) => ({
  marginTop: "50px",
  position: "fixed",
  backgroundColor: "#000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "1000px",
  height: "554px",
  zIndex: 9999,
  overflow: "hidden",
  borderRadius: "5px",
  marginLeft: "165px",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "20%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));
const buttonStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  padding: "10px",
  border: "none",
  color: "white",
  width: "100px",
  height: "89px",
  cursor: "pointer"
};
interface ConnectionGroup {
  kind: string;
  id: string;
  name: string;
  description: string;
  orgOwned: boolean;
}
export const CalendarEventView = (props: {
  button;
  onClose;
  displayMessage?;
  setMessage?;
  details?;
  hasPermissionToEdit;
  hasPermissionToDelete;
  openEdit;
  reLoadCalendar?;
}) => {
  dayjs.extend(timezone);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const org = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const loggedInUser = useRecoilValue(profileAtom);
  let sportOptions: { label: string; value: string | undefined }[] = [];
  let sportOptionsLoading = false;
  const [isLiveStream] = useState(props.details.isLiveStream);

  const [viewLive, setViewLive] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [recurringDeleteOpen, setRecurringDeleteOpen] = useState(false);
  const [recurringEventDeleteType, setRecurringEventDeleteType] =
    useState("this");
  const { data: event, isLoading: eventLoading } =
    useAdminEventEventIdRecurrenceRecurrenceIdGet(
      props.details.id,
      props.details.recurrenceId
    );
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminEventEventIdRecurrenceRecurrenceIdDelete();

  const onConfirmDelete = async () => {
    try {
      await deleteAsync({
        eventId: props.details.id,
        recurrenceId: props.details.recurrenceId,
        params: {
          deleteAll: recurringEventDeleteType == "all",
          deleteFuture: recurringEventDeleteType == "future"
        }
      });
      props.onClose();
      enqueueSnackbar("Event deleted successfully", {
        variant: "success"
      });
      props.reLoadCalendar();
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete event.", {
        variant: "error"
      });
    }
  };
  const getRRuleText = (rrule) => {
    let rule = "Does Not Repeat";
    if (rrule) {
      rule = RRule.fromString(rrule).toText();
      rule = rule.charAt(0).toUpperCase() + rule.slice(1);
    }
    return rule;
  };
  const { setValue } = useForm({
    mode: "onBlur"
  });

  if (props.details.action === "create") {
    const sport = getCreatePostSport({ organizationId: organizationId });
    sportOptions = useMemo(
      () =>
        sport?.data.map((sport) => ({
          label: sport.name!,
          value: sport.sportId
        })) || [],
      [sport!.data]
    );
    sportOptionsLoading = sport!.isLoading;
  }
  useEffect(() => {
    if (
      props.details.action === "create" &&
      !sportOptionsLoading &&
      sportOptions &&
      sportOptions.length == 1
    ) {
      setValue("sportId", [sportOptions[0].value]);
    }
  }, [sportOptions]);

  const generateAddress = (location) => {
    if (location) {
      const { lines, locality, province, postalCode, country } = location;
      const street = lines.length > 0 ? lines[0] : "";
      return `${street}, ${locality}, ${province}, ${postalCode}, ${country}`;
    }
  };
  const handleViewLiveClose = () => {
    setViewLive(false);
  };
  const handleRecurringEventDeleteClose = () => {
    setRecurringDeleteOpen(false);
  };
  const personAvatar = (person, index) => {
    return (
      <Container key={index} data-testid={`person-${person.personId}`}>
        <Grid container alignItems="center">
          <Grid>
            <Avatar
              sx={{
                bgcolor: "#" + person.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center"
              }}
              variant="square"
            >
              {`${person.firstName} ${person.lastName}`
                .split(" ")
                .map((name) => name[0])
                .join("")
                .toUpperCase()}
            </Avatar>
          </Grid>

          <Grid
            maxWidth={"fit-content"}
            marginLeft={"11px"}
            xs
            container
            direction="column"
          >
            {loggedInUser?.person?.personId == person.personId && (
              <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                {`${person.firstName} ${person.lastName}`}
                &nbsp;(you){" "}
                <span style={{ color: "#64748B", marginLeft: "5px" }}>
                  {" "}
                  &#x25cf; Creator
                </span>
              </Typography>
            )}
            {loggedInUser?.person?.personId != person.personId && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                  {`${person.firstName} ${person.lastName} ${person.suffix}`}
                </Typography>
              </div>
            )}
            <Typography
              style={{ fontSize: "14px", fontWeight: 400, color: "#64748B" }}
            >
              {`${person.email}`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };

  const transformData = (data) => {
    if (data) {
      const transformedPrograms = data.programs.map((program) => ({
        name: program.name,
        id: program.programId,
        description: "Training Program",
        orgOwned: !!program.organizationId,
        kind: program.$kind
      }));

      const teams = data.teams.map((team) => ({
        name: team.name,
        id: team.teamId,
        description: "Team",
        orgOwned: !!team.organizationId,
        kind: team.$kind
      }));

      const transformedConnectionGroups = data.connectionGroups
        .map((group) => {
          if (group.team) {
            return {
              name: group.team.name,
              description: `${group.name} > ${group.team.name}`,
              id: group.groupId,
              orgOwned: !!group.team.organizationId,
              kind: "Team"
            };
          } else if (group.program) {
            return {
              name: group.name,
              description: `${group.program.name} > ${group.name}`,
              id: group.groupId,
              orgOwned: !!group.program.organizationId,
              kind: group.$kind
            };
          }
        })
        .flat();

      const combinedData = [
        ...teams,
        ...transformedPrograms,
        ...transformedConnectionGroups
      ];

      return combinedData;
    }
  };
  const connectionGroupAvatar = (groups) => {
    //@ts-ignore
    const connectionGroups: ConnectionGroup[] = transformData(groups);
    if (!connectionGroups) return null;
    return connectionGroups.map((connectionGroup, index) => {
      let thumbnailContent;
      if (org) {
        if (
          (connectionGroup?.kind === "Team" ||
            connectionGroup?.kind === "TrainingProgram") &&
          !connectionGroup?.orgOwned
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={TeamIcon}
              alt="Team Icon"
            />
          );
        } else if (
          connectionGroup?.kind === "ConnectionGroup" &&
          !connectionGroup?.orgOwned
        ) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={personGroupIcon}
              alt="Person Group Icon"
            />
          );
        } else if (org.avatarId) {
          thumbnailContent = (
            <Avatar
              variant="square"
              style={{ width: "40px", height: "40px" }}
              src={org.avatar!.baseUrl! + org.avatar!.path!}
              alt="Org Avatar"
            />
          );
        } else {
          if (connectionGroup?.kind === "ConnectionGroup") {
            thumbnailContent = (
              <Avatar
                variant="square"
                style={{ width: "40px", height: "40px" }}
                src={personGroupIcon}
                alt="Org Avatar"
              />
            );
          }
          if (
            connectionGroup?.kind === "Team" ||
            connectionGroup?.kind === "TrainingProgram"
          ) {
            thumbnailContent = (
              <Avatar
                variant="square"
                style={{ width: "40px", height: "40px" }}
                src={TeamIcon}
                alt="Org Avatar"
              />
            );
          }
        }
      }

      return (
        <Container
          key={index}
          data-testid={`connection-group-${connectionGroup.id}`}
        >
          <Grid container alignItems="center">
            <Grid item>{thumbnailContent}</Grid>
            <Grid
              maxWidth={"fit-content"}
              marginLeft={"11px"}
              xs
              container
              direction="column"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                  {`${connectionGroup.name}`}
                </Typography>
              </div>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#64748B"
                }}
              >
                {`${connectionGroup.description}`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    });
  };

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox>
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.details.title}
                </Typography>
              </Grid>
              <Grid>
                {props.details.action != "create" &&
                  event?.data.recurrences![0].liveStream?.status != "LIVE" &&
                  event?.data.recurrences![0].liveStream?.status !=
                    "COMPLETED" &&
                  props.hasPermissionToEdit && (
                    <EditIcon
                      data-testid="editIcon"
                      style={{ marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => {
                        if (isLiveStream) {
                          navigate(
                            `/live-stream/${event?.data.recurrences![0]
                              .liveStream?.streamId}/edit`
                          );
                        } else {
                          props.onClose();
                          props.openEdit();
                        }
                      }}
                    />
                  )}
                {!eventLoading &&
                  props.details.action != "create" &&
                  props.hasPermissionToDelete && (
                    <Delete
                      style={{ marginLeft: "24px", cursor: "pointer" }}
                      onClick={() => {
                        if (event?.data.repeat) {
                          setRecurringDeleteOpen(true);
                        } else {
                          setDeleteOpen(true);
                        }
                      }}
                    />
                  )}
                <CloseIcon
                  data-testid="post-comment-close-icon"
                  style={{ cursor: "pointer", marginLeft: "24px" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          <Box sx={{ margin: "0 24px", minHeight: "350px" }}>
            <Loader isLoading={eventLoading}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "24px",
                  marginBottom: "20px"
                }}
              >
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "4px",
                    backgroundColor: "#1ABC9C"
                  }}
                ></div>
                <Typography
                  style={{
                    fontSize: "20px",
                    lineHeight: "20px",
                    marginLeft: "18px"
                  }}
                >
                  {event?.data.name}
                </Typography>
              </div>
              {!isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "12px",
                    marginBottom: "10px"
                  }}
                >
                  <AccessTimeIcon style={{ fill: "#9FA2AA" }} />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(event?.data.recurrences![0].start)}
                      disabled
                      className="date-picker"
                      format="ddd, MMM DD"
                      sx={{
                        maxWidth: "100px",
                        marginLeft: "8px"
                      }}
                    />
                  </LocalizationProvider>
                  <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      value={dayjs(event?.data.recurrences![0].start)}
                      disabled
                      className="time-picker"
                      sx={{ width: "90px" }}
                    />
                  </LocalizationProvider>
                  <span style={{ color: "#B3B3B3", fontSize: "14px" }}>-</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      value={dayjs(event?.data.recurrences![0].end)}
                      disabled
                      className="time-picker"
                      style={{ width: "90px" }}
                    />
                  </LocalizationProvider>{" "}
                  <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginLeft: "18px",
                      color: "#9e9e9e"
                    }}
                  >
                    {event?.data.timezone}
                  </Typography>
                </div>
              )}
              {isLiveStream && (
                <>
                  {event?.data.recurrences![0].liveStream?.preEventAt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "12px"
                      }}
                      data-testId="preEventContainer"
                    >
                      <AccessTimeIcon style={{ fill: "#9FA2AA" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#9e9e9e",
                          marginRight: "15px",
                          width: "75px"
                        }}
                      >
                        Pre Game
                      </Typography>
                      <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(
                            event?.data.recurrences![0].liveStream.scheduledAt
                          )}
                          disabled
                          className="date-picker"
                          format="ddd, MMM DD"
                          sx={{ maxWidth: "100px", marginLeft: "3px" }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(
                            event?.data.recurrences![0].liveStream.preEventAt
                          )}
                          disabled
                          className="time-picker"
                          sx={{ width: "90px" }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#B3B3B3", fontSize: "14px" }}>
                        -
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(
                            event?.data.recurrences![0].liveStream.scheduledAt
                          )}
                          disabled
                          className="time-picker"
                          style={{ width: "90px" }}
                        />
                      </LocalizationProvider>{" "}
                      <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#9e9e9e"
                        }}
                      >
                        {event.data.timezone}
                      </Typography>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                    data-testid="eventTimeContainer"
                  >
                    {!event?.data.recurrences![0].liveStream?.preEventAt && (
                      <AccessTimeIcon style={{ fill: "#9FA2AA" }} />
                    )}
                    {event?.data.recurrences![0].liveStream?.preEventAt && (
                      <CheckBoxOutlineBlankIcon style={{ fill: "#fff" }} />
                    )}
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        marginLeft: "18px",
                        color: "#9e9e9e",
                        marginRight: "15px",
                        width: "75px"
                      }}
                    >
                      Main Game
                    </Typography>
                    <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(event?.data.recurrences![0].start)}
                        disabled
                        className="date-picker"
                        format="ddd, MMM DD"
                        sx={{ maxWidth: "100px", marginLeft: "3px" }}
                      />
                    </LocalizationProvider>
                    <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        value={dayjs(event?.data.recurrences![0].start)}
                        disabled
                        className="time-picker"
                        sx={{ width: "90px" }}
                      />
                    </LocalizationProvider>
                    <span style={{ color: "#B3B3B3", fontSize: "14px" }}>
                      -
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        value={dayjs(event?.data.recurrences![0].end)}
                        disabled
                        className="time-picker"
                        style={{ width: "90px" }}
                      />
                    </LocalizationProvider>{" "}
                    {!event?.data.recurrences![0].liveStream?.preEventAt && (
                      <>
                        <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                        <Typography
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            marginLeft: "18px",
                            color: "#9e9e9e"
                          }}
                        >
                          {event?.data.timezone}
                        </Typography>
                      </>
                    )}
                  </div>
                  {event?.data.recurrences![0].liveStream?.postEventAt && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px"
                      }}
                      data-testid="postEventContainer"
                    >
                      <CheckBoxOutlineBlankIcon style={{ fill: "#fff" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginLeft: "18px",
                          color: "#9e9e9e",
                          marginRight: "15px",
                          width: "75px"
                        }}
                      >
                        Post Game
                      </Typography>
                      <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(
                            event?.data.recurrences![0].liveStream.postEventAt
                          )}
                          disabled
                          className="date-picker"
                          format="ddd, MMM DD"
                          sx={{ maxWidth: "100px", marginLeft: "3px" }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#B3B3B3" }}>&#x25cf;</span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(
                            event?.data.recurrences![0].liveStream.postEventAt
                          )}
                          disabled
                          className="time-picker"
                          sx={{ width: "90px" }}
                        />
                      </LocalizationProvider>
                      <span style={{ color: "#B3B3B3", fontSize: "14px" }}>
                        -
                      </span>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimeField
                          value={dayjs(
                            event?.data.recurrences![0].liveStream
                              .postEventEndAt
                          )}
                          disabled
                          className="time-picker"
                          style={{ width: "90px" }}
                        />
                      </LocalizationProvider>{" "}
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "559px"
                }}
              >
                <CheckBoxOutlineBlankIcon />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      marginLeft: "18px",
                      color: "#9e9e9e"
                    }}
                  >
                    {getRRuleText(event?.data.repeat).replace(
                      /\s+for\s+\d+\s+times/,
                      ""
                    )}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "24px"
                }}
              >
                <img src={LocationIcon} />
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "18px",
                    color: "#000"
                  }}
                >
                  {generateAddress(event?.data.location)}
                </Typography>
              </div>
              {props.details.isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px"
                  }}
                >
                  <img src={LiveStreamIcon} />
                  <Typography
                    style={{
                      marginLeft: "18px",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      color: "#007AFF",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      navigate(
                        `/live-stream/${event?.data.recurrences![0].liveStream
                          ?.streamId}`
                      )
                    }
                  >
                    View Live Stream
                  </Typography>
                  {event?.data.recurrences![0].liveStream?.status != "LIVE" && (
                    <div
                      style={{
                        width: "106px",
                        height: "32px",
                        padding: "4px 8px 4px 8px",
                        backgroundColor: "#FEF4E4",
                        borderRadius: "4px",
                        marginLeft: "8px"
                      }}
                    >
                      <Typography
                        style={{
                          color: "#603B03",
                          fontWeight: 500,
                          fontSize: "16px",
                          lineHeight: "24px"
                        }}
                      >
                        Not Started
                      </Typography>
                    </div>
                  )}
                  {event?.data.recurrences![0].liveStream?.status == "LIVE" && (
                    <>
                      <div
                        style={{
                          width: "51px",
                          height: "32px",
                          backgroundColor: "#E82C2C",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          marginLeft: "8px"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 500
                          }}
                        >
                          LIVE
                        </Typography>
                      </div>
                      <div
                        style={{
                          width: "135px",
                          height: "32px",
                          backgroundColor: "#2B337A",
                          padding: "4px 8px 4px 8px",
                          borderRadius: "4px",
                          marginLeft: "8px",
                          display: "flex"
                        }}
                        onClick={() => setViewLive(true)}
                      >
                        <Visibility />
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: 500,
                            marginLeft: "8px",
                            cursor: "pointer"
                          }}
                        >
                          Watch Live
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
              )}
              {!isLiveStream && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      position: "relative"
                    }}
                  >
                    <img
                      src={ShareWithIcon}
                      style={{ position: "absolute", top: "10px" }}
                    />
                    <div
                      style={{
                        maxHeight: "160px",
                        overflow: "auto",
                        marginLeft: "20px"
                      }}
                    >
                      {event?.data.attendees
                        ?.sort((a, b) => {
                          if (
                            a.person!.personId ===
                            loggedInUser?.person?.personId
                          )
                            return -1;
                          if (
                            b.person!.personId ===
                            loggedInUser?.person?.personId
                          )
                            return 1;
                          return 0;
                        })
                        .map((attendee, index) =>
                          personAvatar(attendee.person, index)
                        )}
                      {connectionGroupAvatar(event?.data)}
                    </div>
                  </div>
                </>
              )}
              {isLiveStream && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "18px"
                  }}
                >
                  <img src={ShareWithIcon} />

                  <Typography
                    style={{
                      marginLeft: "18px"
                    }}
                  >
                    {event?.data && event?.data.teams.length > 0
                      ? event?.data.teams[0].name
                      : event?.data.programs[0].name}
                  </Typography>
                </div>
              )}
              {event?.data.description && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "25px"
                  }}
                >
                  <img src={DescriptionIcon} />
                  <Typography
                    style={{
                      marginLeft: "18px",
                      color: "#64748B"
                    }}
                  >
                    {event?.data.description}
                  </Typography>
                </div>
              )}
            </Loader>
          </Box>
        </StyledBox>
        {viewLive && (
          <Backdrop
            sx={{
              overflow: "hidden",
              overflowY: "none",
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={true}
          >
            <StyledLiveBox>
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      controlsList:
                        "nodownload noremoteplayback noplaybackrate nofullscreen",
                      disablePictureInPicture: true
                    }
                  }
                }}
                playing={true}
                controls
                height={"100%"}
                width={"100%"}
                url={""}
              />
              <CloseIcon
                onClick={handleViewLiveClose}
                sx={{
                  ...buttonStyle,
                  top: 30,
                  right: 0,
                  height: "55px",
                  width: "95px"
                }}
              />
            </StyledLiveBox>
          </Backdrop>
        )}
        <ConfirmationDialog
          open={deleteOpen}
          title={
            !event?.data.repeat ? "Delete Event" : "Delete Recurring Event?"
          }
          body={
            isLiveStream
              ? "If you delete this event you are effectively deleting the live stream? Are you sure you want to do this?"
              : `Are you sure you want to delete ${event?.data.name}?`
          }
          close={() => setDeleteOpen(false)}
          onConfirm={onConfirmDelete}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          isConfirming={isDeleting}
          confirmBtnVariant="admin-error"
          icon="error"
        />
        {recurringDeleteOpen && (
          <RemoveRecurringEvent
            onClose={handleRecurringEventDeleteClose}
            setRecurringDeleteType={setRecurringEventDeleteType}
            onConfirm={() => {
              setRecurringDeleteOpen(false);
              setDeleteOpen(true);
            }}
          />
        )}
      </Container>
    </Backdrop>
  );
};
