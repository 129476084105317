import { DataGridRenderCellTooltip } from "@components/DataGridRenderCellTooltip";
import { TableView } from "@components/TableView";
import { GridColDef } from "@mui/x-data-grid";
import { getCountries, getSports } from "@services/Network";
import { ModelSport } from "@sportsgravyengineering/sg-api-react-sdk";
import { format, parseISO } from "date-fns";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "@components/crud/Loader";

const LIST_COLUMNS: GridColDef<ModelSport>[] = [
  {
    headerName: "Icon",
    field: "icon",
    minWidth: 40,
    renderCell: (params) => {
      return (
        params.row.icon && (
          <img
            src={
              (params.row.icon?.baseUrl || "") + (params.row.icon?.path || "")
            }
            width={24}
            height={24}
          />
        )
      );
    },
    sortable: false
  },
  { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
  {
    headerName: "Participating Countries",
    field: "countries",
    minWidth: 190,
    flex: 1,
    renderCell: DataGridRenderCellTooltip("country.name", 1)
  },
  {
    headerName: "Created Date",
    field: "createdAt",
    value: "date",
    minWidth: 115,
    flex: 1,
    valueGetter: ({ value }) => format(parseISO(value), "MMM d, yyyy")
  }
];

export const Sports = () => {
  const navigate = useNavigate();
  const [refreshKey] = React.useState(0);
  const [filter, setFilter] = React.useState<string>("All");

  const { data: countries, isLoading: isCountriesLoading } = getCountries({
    staleTime: Infinity
  });

  const filterConfig = useMemo(() => {
    if (countries) {
      const options = countries.map((country) => ({
        label: country.name,
        value: country.countryId
      }));
      return {
        field: "country",
        placeholderOption: { label: "All Countries", value: "All" },
        options: [
          {
            label: "Filter by Country",
            children: options
          }
        ]
      };
    }
  }, [countries]);

  const LIST_COUNTRY_COLUMNS = [
    {
      headerName: "Icon",
      field: "icon",
      minWidth: 40,
      renderCell: (params) => {
        return (
          <img
            src={
              (params.row.icon?.baseUrl || "") + (params.row.icon?.path || "")
            }
            width={24}
            height={24}
          />
        );
      }
    },
    { headerName: "Name", field: "name", minWidth: 150, flex: 1 },
    {
      headerName: "Alternate Name",
      field: "alternateName",
      minWidth: 190,
      flex: 1,

      valueGetter: ({ row }) => {
        return row?.countries?.find((country) => country.countryId === filter)
          ?.name;
      }
    },
    {
      headerName: "Country",
      field: "country",
      flex: 1,
      valueGetter: ({ row }) => {
        return row?.countries?.find((country) => country.countryId === filter)
          ?.country?.name;
      }
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      width: 115,
      flex: 1,
      value: "date",
      valueGetter: ({ value }) => format(parseISO(value), "MMM d, yyyy")
    }
  ];

  const onAdd = () => {
    navigate("/sports/create");
  };

  const onEdit = (sport) => {
    navigate(`/sports/${sport.id}/edit`);
  };

  const onView = (sport) => {
    navigate(`/sports/${sport.id}`);
  };

  const onSortButtonClick = () => {
    navigate(`/sports/country/${filter}`);
  };

  return (
    <Loader isLoading={isCountriesLoading}>
      <TableView
        title="Sports"
        useGet={getSports}
        columns={
          filter && filter !== "All" ? LIST_COUNTRY_COLUMNS : LIST_COLUMNS
        }
        isDeleteDisabled={() => true}
        filterConfig={filterConfig}
        // defaultSort={[{ field: "name", sort: "asc" }]}
        onAdd={onAdd}
        onEdit={onEdit}
        onView={onView}
        refreshKey={refreshKey}
        onFilterChange={(value: string) => setFilter(value)}
        hideSortButton={filter === filterConfig.placeholderOption.value}
        onSortButtonClick={onSortButtonClick}
      />
    </Loader>
  );
};
