export const capitalize = (value: string) => {
  if (!value) return "";
  return value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase();
};

export const capitalizeEveryWord = (str: string): string => {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1);
    }
    return word;
  });
  const capitalizedStr = capitalizedWords.join(" ");
  return capitalizedStr;
};
export const capitalizeFirstCharacter = (text) => {
  if (text.length === 0) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};
