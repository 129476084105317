import { Button } from "@components/Button";
import { EditIcon } from "@components/Icons";
import { Delete, Visibility } from "@mui/icons-material";
import { AddIcon } from "@components/Icons";
import { Badge, Box, Tab, Tabs, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { SyntheticEvent } from "react";

const ToolbarContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: "2.6rem 1.5rem",
  "& h2": {
    fontSize: "32px",
    fontWeight: 300
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  top: 2,
  paddingBottom: 1,
  zIndex: 1,
  overflow: "visible",
  paddingLeft: 24,

  "& .MuiTabs-fixed.MuiTabs-scroller": {
    position: "static"
  },

  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.white.main,
    height: "1px",
    marginBottom: "-1px",
    zIndex: 1,
    transition: "none"
  },

  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 20px",

    "&.Mui-selected": {
      backgroundColor: theme.palette.white.main,
      border: `1px solid ${theme.palette.divider}`,
      borderBottom: "1px solid transparent",
      borderRadius: "12px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0"
    }
  }
}));

export const Toolbar = ({
  title,
  backBtnClick,
  editBtnClick,
  liveBtnClick,
  addBtnClick,
  deleteBtnClick,
  duplicateBtnClick,
  addBtnIcon,
  addBtnLabel,
  tabs,
  badgeCounts,
  showFilters,
  deleteDisabled,
  addBtnLoading
}: {
  title: string;
  addBtnLabel?: string;
  addBtnIcon?: JSX.Element;
  backBtnClick?: () => void;
  editBtnClick?: () => void;
  liveBtnClick?: () => void;
  addBtnClick?: () => void;
  deleteBtnClick?: () => void;
  duplicateBtnClick?: () => void;
  addBtnLoading?: boolean;
  tabs?: {
    tabs: string[];
    activeTab: string;
    onTabChange: (event: SyntheticEvent, tab: string) => void;
    disabled?: string[];
  };
  badgeCounts?: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showFilters?: any;
  deleteDisabled?: boolean;
}) => {
  const renderButtons = () => {
    return backBtnClick ||
      editBtnClick ||
      liveBtnClick ||
      addBtnClick ||
      deleteBtnClick ? (
      <Grid
        xs={12}
        md={"auto"}
        container
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={2}
      >
        {backBtnClick && (
          <Grid>
            <Button
              variant="admin-secondary"
              onClick={backBtnClick}
              type="button"
            >
              Back to List
            </Button>
          </Grid>
        )}
        {duplicateBtnClick && (
          <Grid>
            <Button
              variant="admin-secondary"
              onClick={duplicateBtnClick}
              type="button"
            >
              Duplicate
            </Button>
          </Grid>
        )}
        {editBtnClick && (
          <Grid>
            <Button
              variant="admin-primary"
              onClick={editBtnClick}
              type="button"
              startIcon={<EditIcon sx={{ marginTop: "1.5px" }} />}
            >
              Edit
            </Button>
          </Grid>
        )}
        {liveBtnClick && (
          <Grid>
            <Button
              variant="admin-primary"
              onClick={liveBtnClick}
              type="button"
              startIcon={<Visibility sx={{ marginTop: "1.5px" }} />}
            >
              Watch Live
            </Button>
          </Grid>
        )}
        {addBtnClick && (
          <Grid>
            <Button
              variant="admin-primary"
              onClick={addBtnClick}
              isLoading={addBtnLoading || false}
              disabled={addBtnLoading || false}
              type="button"
              startIcon={
                addBtnIcon || <AddIcon sx={{ marginTop: "1.5px" }}></AddIcon>
              }
            >
              {addBtnLabel}
            </Button>
          </Grid>
        )}
        {deleteBtnClick && (
          <Grid>
            <Button
              variant="admin-error"
              onClick={deleteBtnClick}
              type="button"
              disabled={deleteDisabled}
              startIcon={<Delete sx={{ marginTop: "1.5px" }} />}
            >
              Delete
            </Button>
          </Grid>
        )}
      </Grid>
    ) : null;
  };
  return (
    <>
      <ToolbarContainer flexDirection={showFilters ? "column" : "row"}>
        <Typography variant="h2">{title}</Typography>
        {showFilters ? (
          <Grid
            marginTop={"10px"}
            container
            spacing={2}
            xs={12}
            md={12}
            justifyContent={"space-between"}
          >
            <Grid
              flex={1}
              container
              justifyContent={"flex-start"}
              alignItems={"center"}
              spacing={2}
            >
              {showFilters}
            </Grid>
            {renderButtons()}
          </Grid>
        ) : (
          renderButtons()
        )}
      </ToolbarContainer>
      {!!tabs && (
        <StyledTabs value={tabs.activeTab} onChange={tabs.onTabChange}>
          {tabs.tabs.map((tab, index) => (
            <Tab
              disabled={tabs.disabled?.includes(tab)}
              data-testid={"TAB_BUTTON_" + tab}
              key={tab}
              label={
                <Grid
                  container
                  sx={{
                    flex: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px"
                  }}
                >
                  <Typography variant="tableHeader">{tab}</Typography>
                  {badgeCounts &&
                    index < badgeCounts.length &&
                    badgeCounts[index] > 0 && (
                      <Badge
                        data-testid={"TAB_BADGE_COUNT_" + tab}
                        badgeContent={badgeCounts[index]}
                        color={tabs.activeTab === tab ? "primary" : "secondary"}
                      ></Badge>
                    )}
                </Grid>
              }
              value={tab}
            />
          ))}
        </StyledTabs>
      )}
    </>
  );
};
