import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { PLATFORM_OPTIONS, TESTCASE_STATUS_OPTIONS } from "@utils/constants";
import { FormLabel } from "@mui/material";
import { FormCheckbox } from "@components/FormCheckbox";
import { useSnackbar } from "notistack";
import {
  ModelFeatureTestcase,
  useAdminFeatureTestcaseTestcaseIdDelete,
  useAdminFeatureTestcaseTestcaseIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
export const TestcaseView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get("platform");
  const { testcaseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState({
    edit: false,
    delete: false,
    view: false,
    create: false
  });
  const [showUiReasonField, setShowUiReasonField] = useState<string[]>([]);
  const [showUiJiraField, setShowUiJiraField] = useState<string[]>([]);
  const [showFnReasonField, setShowFnReasonField] = useState<string[]>([]);
  const [showFnJiraField, setShowFnJiraField] = useState<string[]>([]);

  const [testcaseToDelete, setTestcaseToDelete] =
    useState<ModelFeatureTestcase | null>(null);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const { control, reset, setValue } = useForm({
    mode: "onBlur"
  });
  const [platformsAutomated, setPlatformAutomated] = useState({
    iosUI: false,
    iosFN: false,
    andUI: false,
    andFN: false,
    webUI: false,
    webFN: false,
    apiFN: false,
    devFN: false
  });
  const { data: testcase, isLoading: testcaseLoading } =
    useAdminFeatureTestcaseTestcaseIdGet(testcaseId!);
  useEffect(() => {
    if (organizationId) navigate("/not-found");
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const view = await checkPermission("tech.test-cases", "VIEW");
      const create = await checkPermission("tech.test-cases", "ADD");
      const edit = await checkPermission("tech.test-cases", "EDIT");
      const del = await checkPermission("tech.test-cases", "DELETE");
      setPermissions({
        edit,
        delete: del,
        view,
        create
      });
      if (!view) navigate("/not-found");
    };
    fetchPermissions();
  }, [organizationId]);
  const setAutomatedValue = (platform, type) => {
    const automated = platformsAutomated;
    automated[`${platform}${type}`] = true;
    setValue(`${platform}automated${type}`, true);
    setPlatformAutomated({ ...automated });
  };
  useEffect(() => {
    if (testcase?.data) {
      setShowUiJiraField([]);
      setShowUiReasonField([]);
      setShowFnJiraField([]);
      setShowFnReasonField([]);
      const tc = testcase.data;
      reset({
        selectedPlatform: paramValue,
        description: tc.description,
        expectedResult: tc.expectedResult,
        featureId: tc.feature?.name,
        platforms: tc.platforms!.map((platform) => platform.toLowerCase()),
        webUIStatus: tc.webDefaultUIStatus,
        webFNStatus: tc.webDefaultFNStatus,
        iosUIStatus: tc.iosDefaultUIStatus,
        iosFNStatus: tc.iosDefaultFNStatus,
        andUIStatus: tc.andDefaultUIStatus,
        andFNStatus: tc.andDefaultFNStatus,
        apiFNStatus: tc.apiDefaultFNStatus,
        devFNStatus: tc.devDefaultFNStatus,
        isActive: tc.isActive,
        webUiJiraLink: tc.webUiJiraLink,
        webUiReason: tc.webUiReason,
        iosUiJiraLink: tc.iosUiJiraLink,
        iosUiReason: tc.iosUiReason,
        andUiJiraLink: tc.andUiJiraLink,
        andUiReason: tc.andUiReason,
        webFnJiraLink: tc.webFnJiraLink,
        webFnReason: tc.webFnReason,
        iosFnJiraLink: tc.iosFnJiraLink,
        iosFnReason: tc.iosFnReason,
        andFnJiraLink: tc.andFnJiraLink,
        andFnReason: tc.andFnReason,
        devFnJiraLink: tc.devFnJiraLink || "",
        devFnReason: tc.devFnReason || "",
        apiFnJiraLink: tc.apiFnJiraLink || "",
        apiFnReason: tc.apiFnReason || ""
      });
      PLATFORM_OPTIONS.forEach((platform) => {
        if (
          tc[platform.value + "DefaultUIStatus"] === "FAILED" ||
          tc[platform.value + "DefaultUIStatus"] === "BLOCKED"
        ) {
          setShowUiJiraField((prev) => [...prev, platform.value]);
        }
        if (tc[platform.value + "DefaultUIStatus"] === "HOLD") {
          setShowUiReasonField((prev) => [...prev, platform.value]);
        }
        if (
          tc[platform.value + "DefaultFNStatus"] === "FAILED" ||
          tc[platform.value + "DefaultFNStatus"] === "BLOCKED"
        ) {
          setShowFnJiraField((prev) => [...prev, platform.value]);
        }
        if (tc[platform.value + "DefaultFNStatus"] === "HOLD") {
          setShowFnReasonField((prev) => [...prev, platform.value]);
        }
      });

      testcase.data.feature?.platforms.map((platform) => {
        if (platform == "IOS") {
          if (tc.iosDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("ios", "FN");
          if (tc.iosDefaultUIStatus === "AUTOMATED")
            setAutomatedValue("ios", "UI");
        }
        if (platform == "WEB") {
          if (tc.webDefaultFNStatus === "AUTOMATED") {
            setAutomatedValue("web", "FN");
          }
          if (tc.webDefaultUIStatus === "AUTOMATED") {
            setAutomatedValue("web", "UI");
          }
        }
        if (platform == "AND") {
          if (tc.andDefaultFNStatus === "AUTOMATED") {
            setAutomatedValue("and", "FN");
          }
          if (tc.andDefaultUIStatus === "AUTOMATED") {
            setAutomatedValue("and", "UI");
          }
        }
        if (platform == "API") {
          if (tc.apiDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("api", "FN");
        }
        if (platform == "DEV") {
          if (tc.devDefaultFNStatus === "AUTOMATED")
            setAutomatedValue("dev", "FN");
        }
      });

      setPlatforms(tc.platforms!.map((platform) => platform.toLowerCase()));
    }
  }, [testcase]);

  const { mutateAsync, isLoading } = useAdminFeatureTestcaseTestcaseIdDelete();

  const onConfirmDelete = async () => {
    if (!testcaseToDelete?.testCaseId) return;
    try {
      await mutateAsync({ testcaseId: testcaseToDelete.testCaseId });
      enqueueSnackbar("Testcase deleted successfully", { variant: "success" });
      navigate("/testcases");
      setTestcaseToDelete(null);
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete testcase", {
        variant: "error"
      });
      setTestcaseToDelete(null);
    }
  };

  return (
    <Container>
      <Toolbar
        title="View Test Case"
        backBtnClick={() => navigate("/testcases")}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/testcases/${testcaseId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () => setTestcaseToDelete({ testCaseId: testcaseId })
        })}
      />
      <Loader isLoading={testcaseLoading}>
        <Form>
          <Grid data-testid="testcase-view-form" container spacing={3}>
            <Grid data-testid="testcase-description" xs={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Test Case Description"
                disabled
                required={true}
                rules={{
                  required: "Test Case Description is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-expectedResult" xs={12}>
              <FormInput
                control={control}
                name="expectedResult"
                type="text"
                multiline
                disabled
                rows={7}
                label="Expected Result"
                required={true}
                rules={{
                  required: "Expected Result is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-featureId" xs={12}>
              <FormInput
                control={control}
                name="featureId"
                type="text"
                label="Feature"
                disabled
                required={true}
                rules={{
                  required: "Feature is required"
                }}
              />
            </Grid>
            <Grid data-testid="testcase-platforms" xs={12}>
              <FormMultiSelect
                control={control}
                options={PLATFORM_OPTIONS}
                name="platforms"
                label="Platforms"
                value="platforms"
                required={true}
                disabled
                rules={{
                  required: "Platforms is required"
                }}
                onChange={(e) => setPlatforms(e.target.value)}
                onRemove={(value) =>
                  setPlatforms(
                    platforms.filter((platform) => platform !== value)
                  )
                }
              />
            </Grid>
            {platforms.map((platform, index) => {
              return (
                <Grid container key={index} xs={12}>
                  {platform !== "api" && platform !== "dev" && (
                    <Grid
                      data-testid={platform + "-testcase-uiStatus"}
                      container
                      direction="column"
                      spacing="10px"
                      xs={6}
                    >
                      <Grid>
                        <FormSelect
                          options={TESTCASE_STATUS_OPTIONS}
                          control={control}
                          name={platform + "UIStatus"}
                          disabled
                          label={
                            platform == "web"
                              ? "Web Default UI Status"
                              : platform == "ios"
                              ? "iOS Default UI Status"
                              : "AND Default UI Status"
                          }
                          required={true}
                          rules={{
                            required: "UI Status is required"
                          }}
                        />
                      </Grid>

                      <Grid>
                        <FormCheckbox
                          name={platform + "automatedUI"}
                          label="Automated UI Unit Test Complete"
                          control={control}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    data-testid={platform + "-testcase-fnStatus"}
                    container
                    direction="column"
                    spacing="10px"
                    xs={6}
                  >
                    <Grid>
                      <FormSelect
                        control={control}
                        options={TESTCASE_STATUS_OPTIONS}
                        name={platform + "FNStatus"}
                        label={
                          platform == "web"
                            ? "Web Default FN Status"
                            : platform == "ios"
                            ? "iOS Default FN Status"
                            : platform == "api"
                            ? "API Default FN Status"
                            : platform == "dev"
                            ? "DEVOps Default FN Status"
                            : "AND Default FN Status"
                        }
                        disabled
                        required={true}
                        rules={{
                          required: "FN Status is required"
                        }}
                      />
                    </Grid>

                    <Grid>
                      <FormCheckbox
                        name={platform + "automatedFN"}
                        label="Automated FN Unit Test Complete"
                        control={control}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  {(showUiJiraField.includes(platform) ||
                    showUiReasonField.includes(platform)) &&
                    (showUiJiraField.includes(platform) ? (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiJiraLink"}
                          control={control}
                          disabled
                          label={
                            platform == "web"
                              ? "Web Jira Link"
                              : platform == "ios"
                              ? "iOS Jira Link"
                              : "AND Jira Link"
                          }
                          required
                          type="text"
                        />
                      </Grid>
                    ) : (
                      <Grid xs={6}>
                        <FormInput
                          name={platform + "UiReason"}
                          multiline
                          disabled
                          rows={4}
                          control={control}
                          label={
                            platform == "web"
                              ? "Web Reason"
                              : platform == "ios"
                              ? "iOS Reason"
                              : "AND Reason"
                          }
                          required
                          type="text"
                        />
                      </Grid>
                    ))}

                  {(showFnJiraField.includes(platform) ||
                    showFnReasonField.includes(platform)) &&
                    (showFnJiraField.includes(platform) ? (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnJiraLink"}
                            control={control}
                            disabled
                            label={
                              platform == "web"
                                ? "Web Jira Link"
                                : platform == "ios"
                                ? "iOS Jira Link"
                                : platform == "api"
                                ? "API Jira Link"
                                : platform == "dev"
                                ? "DEVOps Jira Link"
                                : "AND Jira Link"
                            }
                            required
                            type="text"
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          xs={6}
                          display={
                            showUiJiraField.includes(platform) ||
                            showUiReasonField.includes(platform)
                              ? "none"
                              : "block"
                          }
                        ></Grid>
                        <Grid xs={6}>
                          <FormInput
                            name={platform + "FnReason"}
                            multiline
                            disabled
                            rows={4}
                            control={control}
                            label={
                              platform == "web"
                                ? "Web Reason"
                                : platform == "ios"
                                ? "iOS Reason"
                                : platform == "api"
                                ? "API Reason"
                                : platform == "dev"
                                ? "DEVOps Reason"
                                : "AND Reason"
                            }
                            required
                            type="text"
                          />
                        </Grid>
                      </>
                    ))}
                </Grid>
              );
            })}
            <Grid
              sx={{ display: "flex", alignItems: "center" }}
              data-testid="testcase-isActive"
            >
              <FormLabel
                sx={{ width: "125px", fontSize: "0.875rem", fontWeight: 700 }}
              >
                Is Active
              </FormLabel>
              <FormCheckbox disabled control={control} name="isActive" />
            </Grid>
          </Grid>
        </Form>
      </Loader>
      <ConfirmationDialog
        open={!!testcaseToDelete}
        title="Delete Test Case"
        body={`Are you sure you want to delete this Test Case?`}
        close={() => setTestcaseToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setTestcaseToDelete(null)}
        isConfirming={isLoading}
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
