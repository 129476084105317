const breakpoints = {
  values: {
    xs: 0,
    sm: 320,
    md: 660,
    lg: 960,
    xl: 1280,
    xxl: 1440,
  },
};

export default breakpoints;
